import type * as React from "react";
import { SearchIcon } from "~/src/utils/icons";
import "./searchInput.css";

const SearchInput = ({
    name,
    placeholder,
    value,
    handleChange,
    customStyle,
}: {
    name: string;
    placeholder: string;
    value?: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    customStyle?: React.CSSProperties;
}) => {
    return (
        <div className="search-wrapper">
            <i className="search-icon">
                <SearchIcon />
            </i>
            <input
                className="search-input"
                id={name}
                type="search"
                name={name}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                style={{ cursor: "text", ...customStyle }}
                autoComplete="on"
            />
            <span className="search-span-border" />
        </div>
    );
};

export default SearchInput;
