import type { Invitation } from "@ommej/types";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "~/src/components/tools/accordion/accordion";
import NormalButton from "~/src/components/tools/buttons/normalButton/normalButton";
import {
    closeInvitation,
    deleteInvitation,
    setStatusInvitation,
} from "~/src/redux/actions/invitation/actions";
import type { GlobalState } from "~/src/types";
import { isActiveFeature } from "~/src/utils/feature-flags";
import { getRoutingState } from "~/src/utils/systemFunctions";
import { roles, route } from "../../../../utils/constants";
import language from "../../../../utils/language";
import { findSingleUnit, getAllChildUnits } from "../../../../utils/unitFunctions";
import SecondaryButton from "../../../tools/buttons/secondaryButton/secondaryButton";
import ErrorComponent from "../../../tools/errorCompnent/errorComponent";
import { CASES_ROUTES } from "../../caseList/caseList";
import "./caseSettings.css";

const invitationStatus = {
    delete: "delete",
    closed: "closed",
    paused: "paused",
    active: "active",
};

const CaseSettings = ({ invitation }: { invitation: Invitation.ToClient }) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [accordion, setAccordion] = useState("");
    const loggedInUser = useSelector((state: GlobalState) => {
        return state.login.loggedInUser;
    });
    const units = useSelector((state: GlobalState) => {
        return state.unit.units;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);

    const checkDeleteButton = () => {
        const homeUnit = findSingleUnit(loggedInUser.unit, units);
        const invitationUnit = findSingleUnit(invitation.ownedBy.unit._id, units);
        const childUnits = getAllChildUnits(homeUnit, units);

        if (
            loggedInUser.roleCode === roles.systemAdmin ||
            invitation.ownedBy._id === loggedInUser._id ||
            invitationUnit?.administrators.includes(loggedInUser._id)
        ) {
            return true;
        }
        if (homeUnit && invitationUnit && childUnits) {
            const checkChilds = childUnits.some((childUnit) => {
                return childUnit._id === invitationUnit._id;
            });
            if (checkChilds && loggedInUser.roleCode === roles.administrator) {
                return true;
            }
        }
        return false;
    };

    const handleCancel = () => {
        setAccordion("");
    };

    // pause
    const handlePausePress = useCallback(
        async (status) => {
            const toggleStatus =
                status === invitationStatus.paused
                    ? invitationStatus.active
                    : invitationStatus.paused;
            try {
                await dispatch(setStatusInvitation(invitation.id, { status: toggleStatus }));
            } catch (_err) {
                setErrorMessage(
                    language[locales].CASE.EDIT_INVITATION.SETTINGS.ERROR_MESSAGE_PAUSE,
                );
            }
        },
        [invitation],
    );

    // Close
    const handleCloseConfirmed = async () => {
        try {
            await dispatch(closeInvitation(invitation.id));
            const options = { state: routingState };
            navigate(`${route.CASES}/${CASES_ROUTES.ALL_CASES}`, options);
        } catch (_err) {
            setErrorMessage(language[locales].CASE.EDIT_INVITATION.SETTINGS.ERROR_MESSAGE_CLOSE);
        }
    };

    const handleCloseClicked = async () => {
        setAccordion(invitationStatus.closed);
    };

    // Delete
    const handleDelete = async () => {
        if (errorMessage) {
            setErrorMessage(undefined);
        }
        try {
            await dispatch(deleteInvitation(invitation.id));
            const options = { state: routingState };
            navigate(`${route.CASES}/${CASES_ROUTES.ALL_CASES}`, options);
        } catch (_err) {
            setErrorMessage(language[locales].CASE.EDIT_INVITATION.SETTINGS.ERROR_MESSAGE_DELETE);
        }
    };

    if (errorMessage) {
        return <ErrorComponent errorText={errorMessage} />;
    }

    return (
        <>
            <h3>{language[locales].CASE.EDIT_INVITATION.SETTINGS.HEADING}</h3>
            <div className="caseSettings-wrapper">
                <section>
                    <SecondaryButton
                        icon={
                            invitation.status.status === invitationStatus.active ? "pause" : "play"
                        }
                        text={
                            invitation.status.status === invitationStatus.active
                                ? "Pausa"
                                : "Aktivera"
                        }
                        handleButton={() => {
                            handlePausePress(invitation.status.status);
                        }}
                    />
                </section>
                <section>
                    <SecondaryButton
                        icon="check"
                        text={language[locales].CASE.EDIT_INVITATION.SETTINGS.CLOSE_BUTTON}
                        handleButton={handleCloseClicked}
                    />
                    <Accordion accordionActive={accordion === invitationStatus.closed}>
                        <div className="caseMove-accordion-wrapper">
                            <p>{`${language[locales].CASE.EDIT_INVITATION.SETTINGS.CONFIRM_CLOSE}`}</p>
                            <div className="caseMove-accordion-buttons">
                                <SecondaryButton
                                    handleButton={handleCancel}
                                    text={`${language[locales].CASE.EDIT_INVITATION.MOVE.CANCEL_BUTTON}`}
                                />
                                <NormalButton
                                    handleButton={handleCloseConfirmed}
                                    text={`${language[locales].CASE.EDIT_INVITATION.SETTINGS.CLOSE_BUTTON}`}
                                    customStyle={{ marginLeft: "0.5rem" }}
                                />
                            </div>
                        </div>
                    </Accordion>
                </section>
                <section>
                    {checkDeleteButton() && isActiveFeature("deleteInvitation") && (
                        <>
                            <SecondaryButton
                                icon="trashbin"
                                text={language[locales].CASE.EDIT_INVITATION.SETTINGS.DELETE_BUTTON}
                                handleButton={() => {
                                    handleDelete();
                                }}
                            />
                            <Accordion accordionActive={accordion === invitationStatus.delete}>
                                <div className="caseMove-accordion-wrapper">
                                    <p>{`${language[locales].CASE.EDIT_INVITATION.SETTINGS.CONFIRM_DELETE}`}</p>
                                    <div className="caseMove-accordion-buttons">
                                        <SecondaryButton
                                            handleButton={handleCancel}
                                            text={`${language[locales].CASE.EDIT_INVITATION.MOVE.CANCEL_BUTTON}`}
                                        />
                                        <NormalButton
                                            handleButton={handleDelete}
                                            text={`${language[locales].CASE.EDIT_INVITATION.SETTINGS.DELETE_BUTTON}`}
                                            customStyle={{ marginLeft: "0.5rem" }}
                                        />
                                    </div>
                                </div>
                            </Accordion>
                        </>
                    )}
                </section>
            </div>
        </>
    );
};

export default CaseSettings;
