import type { AnswerId, Beb2, QuestionId } from "@ommej/types";
import type React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AlarmIcon from "~/src/media/images/svg/alarm-peach.svg";
import type { ClientInfo } from "~/src/types";
import AnswersViewer from "./answersViewer";
import "./questionSection.css";

const QuestionSection = ({
    id,
    beb,
    client,
    header,
    intro,
    questionsToInclude,
    filter = undefined,
    marked = undefined,
    icon = undefined,
    showOthers = false,
    showAnswers = true,
    showSubHeaders = true,
    openSection = false,
}: {
    id?: string;
    beb: Beb2;
    client: ClientInfo;
    header: string | React.ReactNode;
    intro?: string | React.ReactNode;
    questionsToInclude: Set<QuestionId>;
    filter?: Set<QuestionId>;
    marked?: Set<QuestionId>;
    icon?: React.ReactNode;
    showOthers?: boolean;
    showAnswers?: boolean;
    showSubHeaders?: boolean;
    openSection?: boolean;
}) => {
    type TagData = {
        resources: Record<QuestionId, Set<AnswerId>>;
        worries: Record<QuestionId, Set<AnswerId>>;
        help: Record<QuestionId, Set<AnswerId>>;
        problems: Record<QuestionId, Set<AnswerId>>;
        alarms: Record<QuestionId, Set<AnswerId>>;
        other: Record<QuestionId, Set<AnswerId>>;
    };

    const [accordionData, setAccordionData] = useState<TagData>();
    const [hasAlarms, setHasAlarms] = useState(false);
    const [hasMatches, setHasMatches] = useState(false);
    const [open, setOpen] = useState(false);
    const [showSection, setShowSection] = useState<string>();
    const location = useLocation();

    useEffect(() => {
        const { hash } = location;
        if (hash?.substring(1) === id) {
            setOpen(true);
            setShowSection(id);
            if (window.location.hash) {
                window.history.replaceState(null, "", window.location.pathname);
            }
        }
    }, []);

    useEffect(() => {
        if (!showSection) {
            return;
        }
        const eSection = document.getElementById(showSection);
        if (!eSection) {
            return;
        }
        window.scrollTo({
            top: eSection.offsetTop - 8,
            behavior: "smooth",
        });
        eSection.classList.add("question-section-highlight");
    }, [showSection]);

    useEffect(() => {
        // keep track of questions in resources, worryHelp & alarmProblem.
        // Questions not in this set will be shown in "others".
        const seenQuestions = new Set<QuestionId>();

        const data: TagData = {
            resources: {},
            worries: {},
            help: {},
            problems: {},
            alarms: {},
            other: {},
        };
        let alarms = false;
        let hasIncluded = false;

        for (const qid of questionsToInclude) {
            if (filter && filter.size > 0 && !filter.has(qid)) {
                continue;
            }

            hasIncluded = true;
            const seenAnswers = new Set<AnswerId>();

            // RESOURCES
            if (beb.answerTags.RESOURCE?.[qid]) {
                seenQuestions.add(qid);
                const aids = beb.answerTags.RESOURCE[qid];
                if (aids) {
                    data.resources[qid] = new Set(aids);
                    for (const aid of aids) {
                        seenAnswers.add(aid);
                    }
                }
            }

            // HELP
            if (beb.answerTags.HELP?.[qid]) {
                seenQuestions.add(qid);
                const aids = beb.answerTags.HELP[qid];
                if (aids) {
                    data.help[qid] = new Set(aids);
                    for (const aid of aids) {
                        seenAnswers.add(aid);
                    }
                }
            }

            // WORRIES
            if (beb.answerTags.WORRIES?.[qid]) {
                seenQuestions.add(qid);
                const aids = beb.answerTags.WORRIES[qid];
                if (aids) {
                    data.worries[qid] = new Set(aids);
                    for (const aid of aids) {
                        seenAnswers.add(aid);
                    }
                }
            }

            // ALARMS
            if (beb.answerTags.ALARM?.[qid]) {
                seenQuestions.add(qid);
                const aids = beb.answerTags.ALARM[qid];
                if (aids) {
                    data.alarms[qid] = new Set(aids);
                    alarms = true;
                    for (const aid of aids) {
                        seenAnswers.add(aid);
                    }
                }
            }

            // OTHERS
            if (showOthers) {
                const aids = beb.answers[qid]?.answers
                    .map((answer) => {
                        return answer.id;
                    })
                    .filter((aid) => !seenAnswers.has(aid));
                if (aids && aids.length > 0) {
                    data.other[qid] = new Set(aids);
                }
            }
        }

        setAccordionData(data);
        setHasAlarms(alarms);
        setHasMatches(hasIncluded);
    }, [beb, filter, questionsToInclude, showOthers]);

    if (!accordionData || !hasMatches) {
        return null;
    }

    function getDom() {
        if (!accordionData) {
            return null;
        }

        const sections: Array<{
            key: keyof TagData;
            header: string;
            showAnswers: boolean;
            color?: string;
        }> = [
            { key: "resources", header: "Resurser", showAnswers: true },
            { key: "alarms", header: "Identifierade risker", showAnswers: true },
            { key: "help", header: "Vill ha hjälp med", showAnswers: false, color: "#f8f6dd" },
            { key: "worries", header: "Oroar", showAnswers: false, color: "#f8f6dd" },
            { key: "other", header: "Övrigt", showAnswers: true },
        ];

        return (
            <section
                id={id}
                key={`questionSection-${header}`}
                className="conversation-section-wrapper conversation-answers"
            >
                <details
                    open={(filter && filter.size > 0) || openSection || open}
                    className="question-section-details"
                >
                    <summary style={{ padding: 0 }}>
                        <div className="conversationAnswers-accordion">
                            {icon}
                            <span style={{ marginLeft: "0.5rem" }}>
                                <h2 style={{ fontSize: "1.125rem" }}>
                                    {header} {hasAlarms && <img src={AlarmIcon} alt="alarm icon" />}
                                </h2>
                            </span>
                        </div>
                    </summary>
                    {intro}

                    {sections.map((section) => {
                        if (Object.keys(accordionData[section.key]).length === 0) {
                            return null;
                        }
                        return (
                            <section
                                style={{ paddingTop: "1.5rem" }}
                                key={`questionSection-${header}-${section.key}`}
                            >
                                {showSubHeaders && (
                                    <h3 className="answersViewer-header">{section.header}</h3>
                                )}
                                <AnswersViewer
                                    beb={beb}
                                    filter={[accordionData[section.key]]}
                                    marked={marked}
                                    client={client}
                                    uniqueness={`questionSection-${header}-${section.key}`}
                                    showAnswers={showAnswers && section.showAnswers}
                                    color={section.color}
                                />
                            </section>
                        );
                    })}
                </details>
            </section>
        );
    }

    return getDom();
};

export default QuestionSection;
