import type { Invitation as InvitationType } from "@ommej/types";
import { useSelector } from "react-redux";
import AlarmIcon from "~/src/media/images/svg/alarm.svg";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import CaseForms from "../modals/components/caseForms";
import CaseInformation from "../modals/components/caseInformation";
import CaseKey from "../modals/components/caseKey";
import CaseMove from "../modals/components/caseMove";
import CasePoke from "../modals/components/casePoke";
import CaseSend from "../modals/components/caseSend";
import CaseSettings from "../modals/components/caseSettings";
import CaseShare from "../modals/components/caseShare";
import "./manage.css";
import ManageExternalRefs from "./manageExternalRefs";

type InvitationProps = {
    invitation: InvitationType.ToClient;
    setInvitation: (invitation: InvitationType.ToClient) => void;
};

export default function ManageInvitation({ invitation, setInvitation }: InvitationProps) {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { CASE_DETAILS } = language[locales];
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });

    const hasProrenata = currentUser?.features ? currentUser.features.includes("prorenata") : false;

    return (
        <div className="invitation-wrapper">
            {invitation.clients?.removed && invitation.clients.removed.length > 0 && (
                <div className="handleCase-removed">
                    <img src={AlarmIcon} alt="" />
                    <p className="text-red">{CASE_DETAILS.REMOVED}</p>
                </div>
            )}
            <div className="handleCase-wrapper">
                <div className="handleCase-column">
                    <section className="handleCase-sub-wrapper">
                        <CaseKey invitation={invitation} setInvitation={setInvitation} />
                    </section>
                    <section className="handleCase-sub-wrapper">
                        <CaseSettings invitation={invitation} />
                    </section>
                    <section className="handleCase-sub-wrapper">
                        <CaseMove invitation={invitation} />
                    </section>
                    <section className="handleCase-sub-wrapper">
                        <CaseShare invitation={invitation} />
                    </section>
                    {hasProrenata && (
                        <section className="handleCase-sub-wrapper">
                            <ManageExternalRefs invitation={invitation} />
                        </section>
                    )}
                </div>
                <div className="handleCase-column">
                    <section className="handleCase-sub-wrapper">
                        <CaseInformation invitation={invitation} />
                    </section>
                    <section className="handleCase-sub-wrapper">
                        {!(
                            (invitation.maxNumberOfClients === 1 &&
                                invitation.clients?.accepted &&
                                invitation.clients.accepted.length > 0) ||
                            (invitation.clients?.removed && invitation.clients.removed.length > 0)
                        ) ? (
                            <CaseSend invitation={invitation} />
                        ) : (
                            <CasePoke invitationId={invitation.id} />
                        )}
                    </section>
                    {!(invitation.clients?.removed && invitation.clients.removed.length > 0) && (
                        <section className="handleCase-sub-wrapper">
                            <CaseForms invitation={invitation} />
                        </section>
                    )}
                </div>
            </div>
        </div>
    );
}
