// @ts-check

import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import language from "../../../utils/language";

import Indicator from "./indicator";

/** @type {import('react').FunctionComponent<{ description?: string|undefined }>} */
const ErrorIndicator = ({ description }) => {
    const { locales } = useSelector(
        /**
         * @param {import('~/src/types').GlobalState} state
         * @returns {import('~/src/types').GlobalState["language"]}
         */
        (state) => {
            return state.language;
        },
    );
    const { BASIC_ERROR_TEXT } = language[locales].TOOLS;

    return (
        <Indicator
            modifier="error"
            description={description === undefined ? BASIC_ERROR_TEXT : description}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
                <title>Något har gått fel</title>
                <path
                    stroke="currentColor"
                    strokeWidth="1.3"
                    d="M5 14.58A6.58 6.58 0 0 1 10.9 11a6.56 6.56 0 0 1 6.1 4"
                />
                <circle cx="8" cy="8" r="1" fill="currentColor" />
                <circle cx="14" cy="8" r="1" fill="currentColor" />
            </svg>
        </Indicator>
    );
};

ErrorIndicator.defaultProps = {
    description: undefined,
};

ErrorIndicator.propTypes = {
    description: PropTypes.string,
};

export default ErrorIndicator;
