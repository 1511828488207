// @ts-check

import { scaleOrdinal } from "d3-scale";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import Sunburst from "sunburst-chart";
import "./sunburst.css";

const colorLevels = [
    scaleOrdinal(["#f3eeee", "#e3e0dc", "#dad2c8", "#d0c6b8", "#c7b8a4", "#b2a490"]),
    scaleOrdinal(["#eeb3b9", "#e18d95", "#ce6771"]),
    scaleOrdinal(["#6b76da", "#4956ca", "#313a8f"]),
];

/**
 * @typedef SunburstData
 * @property {string} id
 * @property {string} name
 * @property {number|null|undefined} [value]
 * @property {SunburstData[]} children
 */

/** @typedef {(node: import('sunburst-chart').Node, dataNode: import('sunburst-chart').DataNode|undefined) => string} SunburstChartTextFn */

/**
 * @param {import('sunburst-chart').DataNode} dataNode
 * @returns {import('sunburst-chart').DataNode}
 */
export const findRootDataNode = (dataNode) => {
    let node = dataNode;

    while (node.parent) {
        node = node.parent;
    }

    return node;
};

/**
 * @typedef SunburstChartProps
 * @property {SunburstData} data
 * @property {SunburstChartTextFn|undefined} [rootLabel]
 * @property {SunburstChartTextFn|undefined} [rootTooltipContent]
 * @property {SunburstChartTextFn|undefined} [tooltipContent]
 */

/** @type {import('react').FunctionComponent<SunburstChartProps>} */
export const SunburstChart = ({ data, rootLabel, rootTooltipContent, tooltipContent }) => {
    /** @type {import('react').MutableRefObject<HTMLDivElement|null>} */
    const divRef = useRef(null);
    /** @type {import('sunburst-chart').SunburstChartInstance|undefined} */
    let myChart;

    useEffect(() => {
        if (!divRef.current) {
            return;
        }

        myChart = Sunburst();

        myChart
            .data(data)
            .width(500)
            .height(500)
            .color((d, parent) => {
                if (!parent) {
                    return "#dfe1ec";
                }
                const colorLevel = colorLevels[parent.depth];
                if (!colorLevel) {
                    return "#fff";
                }
                return colorLevel(d.name);
            })
            .radiusScaleExponent(0.8)
            .label((node) => {
                if (node.__dataNode?.parent) {
                    return node.name || "";
                }
                return rootLabel ? rootLabel(node, node.__dataNode) : "";
            })
            .tooltipContent((node, dataNode) => {
                if (dataNode.parent) {
                    return tooltipContent ? tooltipContent(node, dataNode) : "";
                }
                return rootTooltipContent ? rootTooltipContent(node, dataNode) : "";
            })(divRef.current);
    }, []);

    useEffect(() => {
        if (myChart) {
            myChart.data(data);
        }
    }, [data]);

    return <div ref={divRef} />;
};

SunburstChart.defaultProps = {
    rootLabel: undefined,
    rootTooltipContent: undefined,
    tooltipContent: undefined,
};

SunburstChart.propTypes = {
    // @ts-expect-error converted from ts-ignore
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.number,
        children: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
    rootLabel: PropTypes.func,
    rootTooltipContent: PropTypes.func,
    tooltipContent: PropTypes.func,
};
