import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import logo from "../../../media/images/svg/logo_notext.svg";
import menu from "../../../media/images/svg/menu.svg";
import language from "../../../utils/language/index";
import Modal from "../../tools/modal/modal";
import NavComponent from "../navComponent/navComponent";
import ICONS from "../navIcons";
import "./navMobile.css";

const NavMobile = ({ routes }) => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const unitObj = useSelector((state) => {
        return state.unit.unitObj;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [show, setShow] = useState(false);
    const location = useLocation();

    return (
        <div className="navmobile-background">
            <div className="navmobile-navcontainer">
                {routes.map((route) => {
                    if (
                        route.link === location.pathname ||
                        location.pathname.includes(route.link)
                    ) {
                        return (
                            <div key={route.link} className="navmobile-activelink">
                                <span className="navmobile-icon">{ICONS[route.icon]}</span>
                                <h2>{route.text}</h2>
                            </div>
                        );
                    }
                    return null;
                })}
                <button
                    className="navmobile-button"
                    type="button"
                    onClick={() => {
                        setShow(!show);
                    }}
                >
                    <img src={menu} alt={language[locales].NAV.MENU} />
                    {language[locales].NAV.MENU}
                </button>
                {show && (
                    <Modal show={show} setShow={setShow}>
                        <div className="navmobile-user-container">
                            <div className="navmobile-logo-wrapper">
                                <img src={logo} className="navmobile-logo" alt="logo" />
                            </div>
                            <div className="navmobile-user-wrapper">
                                <p className="swanky">{currentUser.username}</p>
                                <p className="text-description">
                                    {!unitObj[currentUser.unit]
                                        ? ""
                                        : unitObj[currentUser.unit].name}
                                </p>
                            </div>
                        </div>
                        <div className="navmobile-nav-wrapper">
                            <NavComponent routes={routes} show={show} setShow={setShow} />
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    );
};

NavMobile.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NavMobile;

/* {routes.map((route) => {
  if (route.link === location.pathname) {
    return (
      <div key={route.link} className="navmobile-activelink">
        <span className="navmobile-icon">{ICONS[route.icon]}</span>
        <h2>{route.text}</h2>
      </div>
    );
  }
})} */
