import { GetAvatar } from "@ommej/componente";
import type { Invitation } from "@ommej/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ArrowIcon from "~/src/media/images/svg/arrow_down.svg";
import SkeletonHomeCard from "~/src/skeletons/skeletonHomeCard";
import type { GlobalState } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import { route } from "~/src/utils/constants";
import language from "~/src/utils/language";
import { formatDate } from "~/src/utils/systemFunctions";
import "./activities.css";
import { Link } from "react-router-dom";

function formatRelativeDate(date: Date, locales: string): string {
    const { RELATIVE_TIME } = language[locales];
    const MS_HOUR = 60 * 60 * 1000;

    const now = new Date().getTime();
    const diff = now - date.getTime();

    if (diff < MS_HOUR) {
        return RELATIVE_TIME.RECENTLY;
    }

    if (diff > MS_HOUR && diff < 2 * MS_HOUR) {
        return `1 ${RELATIVE_TIME.HOUR_AGO}`;
    }

    if (diff > 2 * MS_HOUR && diff < 3 * MS_HOUR) {
        return `2 ${RELATIVE_TIME.HOURS_AGO}`;
    }

    if (diff > 3 * MS_HOUR && diff < 4 * MS_HOUR) {
        return `3 ${RELATIVE_TIME.HOURS_AGO}`;
    }

    if (diff > 4 * MS_HOUR && diff < 5 * MS_HOUR) {
        return `4 ${RELATIVE_TIME.HOURS_AGO}`;
    }

    if (diff < 24 * MS_HOUR) {
        return date.toLocaleTimeString(locales, { hour: "2-digit", minute: "2-digit" });
    }

    return formatDate(date, locales);
}

const Activities = () => {
    enum State {
        WAITING = 0,
        NO_CONTENT = 1,
    }
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const [activities, setActivities] = useState<Invitation.Activities | State>(State.WAITING);

    const {
        HOME: { ACTIVITIES },
    } = language[locales];

    useEffect(() => {
        async function load() {
            try {
                const response = await reqWithPath(
                    `users/${currentUser._id}/invitations/activity`,
                    "get",
                    "",
                );
                const content = await response.json();
                if (content.length === 0) {
                    setActivities(State.NO_CONTENT);
                } else {
                    setActivities(content);
                }
            } catch (err) {
                console.error("Failed to load activities", err);
                setActivities(State.NO_CONTENT);
            }
        }
        load();
    }, []);

    if (activities === State.WAITING) {
        return <SkeletonHomeCard />;
    }

    if (activities === State.NO_CONTENT) {
        return null;
    }

    return (
        <div className="home-card">
            <div className="home-card-header">
                <h2>{ACTIVITIES.HEADER}</h2>
                <a href={route.CASES}>
                    {ACTIVITIES.CASES}
                    <img src={ArrowIcon} alt="arrow" />
                </a>
            </div>
            <div className="activities-wrapper">
                {activities.map((activity) => {
                    return (
                        <Link
                            className="activity-item"
                            to={`${route.CASE}/${activity.invitation.id}`}
                            relative="path"
                            key={activity.invitation.name + activity.timestamp}
                        >
                            <div className="activity-avatar">
                                <GetAvatar avatarData={activity.avatar} />
                            </div>
                            <span>
                                <span className="activity-invitation">
                                    {activity.invitation.name}
                                </span>
                                &nbsp;
                                {activity.type === "ACCEPTED" && ACTIVITIES.ACCEPTED}
                                {activity.type === "ANSWERED" &&
                                    `${ACTIVITIES.ANSWERED} ${activity.data} ${ACTIVITIES.QUESTIONS}`}
                                {activity.type === "LOGIN" && ACTIVITIES.LOGIN}
                                {activity.type === "CREATED" && ACTIVITIES.CREATED}
                                {activity.type === "ALARM" &&
                                    `${ACTIVITIES.ALARM1} ${activity.data} ${ACTIVITIES.ALARM2}`}
                                <br />
                                <span
                                    className="text-description text-grey"
                                    title={new Intl.DateTimeFormat(locales, {
                                        dateStyle: "long",
                                        timeStyle: "long",
                                    }).format(new Date(activity.timestamp))}
                                >
                                    {formatRelativeDate(new Date(activity.timestamp), locales)}
                                </span>
                            </span>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default Activities;
