import "./skeleton.css";

type SkeletonProps = {
    type: string;
    height?: string | number | null;
};

const Skeleton = ({ type, height }: SkeletonProps) => {
    if (height) {
        return <div className={`skeleton ${type}`} style={{ height }} />;
    }
    return <div className={`skeleton ${type}`} />;
};

export default Skeleton;
