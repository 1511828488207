import { reqWithPath, reqWithPayload } from "../../../utils/api";
import { dispatchError } from "../actionHelpers";
import { LOADING_LOGIN, LOGIN, LOGIN_ERROR, LOGOUT } from "./type";

export const login = (loginObj) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_LOGIN });
        try {
            // First let's see if we have a cookie:
            const res = await reqWithPath("user/me", "get", "");
            const user = await res.json();
            dispatch({
                type: LOGIN,
                payload: user,
            });
        } catch (e) {
            // Nope, if someone provided user/pass in loginObj
            // let's try that one also
            if (loginObj) {
                // we don't need the resolved value
                await reqWithPayload("user/login", "post", loginObj);
                const res = await reqWithPath("user/me", "get", "");
                const user = await res.json();
                dispatch({
                    type: LOGIN,
                    payload: user,
                });
            } else {
                throw e;
            }
        }
        /* Nothing is returned, a token should be set by the server.
         * If this promise resolves consider the token set. If any api call
         * throws(none HTTP 200 OK) then handle it gracefully
         * outside this function.
         */
    };
};

export const getLoggedInUser = () => {
    return async (dispatch) => {
        dispatch({ type: LOADING_LOGIN });
        try {
            const res = await reqWithPath("user/me", "get", "");
            const user = await res.json();
            dispatch({
                type: LOGIN,
                payload: user,
            });
        } catch (error) {
            dispatchError(error, dispatch, LOGIN_ERROR);
        }
    };
};

export const logout = () => {
    return async (dispatch) => {
        try {
            await reqWithPath("user/logout", "post", "");
        } catch (_err) {
            // do nothing
        } finally {
            dispatch({
                type: LOGOUT,
            });
        }
    };
};
