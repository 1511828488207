"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSvgModel = void 0;
const handleColor = (element, color) => {
    if (element.classList.contains("no-color")) {
        return;
    }
    if (element.classList.contains("background")) {
        // TODO change hardcoded color when the user will be able to change background color
        element.setAttribute("fill", "#FFF4E9");
    }
    else if (element.classList.contains("shadow")) {
        element.setAttribute("fill", color);
        element.setAttribute("filter", "brightness(85%)");
    }
    else {
        element.setAttribute("fill", color);
    }
};
const handleAttribute = (svgString, color) => {
    const arr = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgString, "image/svg+xml");
    const documentRoot = doc.documentElement;
    const documentChildren = doc.documentElement.children;
    if (!documentRoot.getElementsByTagName("svg")[0]) {
        if (color) {
            for (const childElement of Array.from(documentChildren)) {
                handleColor(childElement, color);
            }
        }
        arr.push({
            data: documentRoot.innerHTML,
            number: Number(documentRoot.getAttribute("z-index")),
            color,
        });
    }
    for (const childElement of Array.from(documentChildren)) {
        if (childElement.children && color) {
            for (const child of Array.from(childElement.children)) {
                handleColor(child, color);
            }
        }
        childElement.tagName === "svg" &&
            arr.push({
                data: childElement.innerHTML,
                number: Number(childElement.getAttribute("z-index")),
                color,
            });
    }
    return arr;
};
const getSvgModel = (avatarModel) => __awaiter(void 0, void 0, void 0, function* () {
    const fetchPromises = avatarModel.map((modelPart) => {
        return fetch(`/assets/avatarParts/${modelPart.id}.svg`);
    });
    const requests = yield Promise.allSettled(fetchPromises);
    const dataPromises = requests.map((request) => {
        return request.status === "fulfilled" && request.value.text();
    });
    const data = yield Promise.allSettled(dataPromises);
    const addAttributeArray = data.map((svgString, i) => {
        if (svgString.status === "fulfilled" && svgString.value) {
            return handleAttribute(svgString.value, avatarModel[i].color);
        }
        throw new Error("failed to fetch svg");
    });
    return addAttributeArray.flat().sort((a, b) => {
        return a.number - b.number;
    });
});
exports.getSvgModel = getSvgModel;
