import Skeleton from "./skeleton";
import "./skeleton.css";

const SkeletonNav = () => {
    return (
        <div className="skeletonNav">
            <Skeleton type="heading-small" />
        </div>
    );
};

export default SkeletonNav;
