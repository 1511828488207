import { GetAvatar } from "@ommej/componente";
import type { AnswersBase, Beb2, Invitation } from "@ommej/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Beb from "~/src/components/case/caseDetails/beb";
import ManageInvitation from "~/src/components/case/caseDetails/manage";
import Tabs, { type TabsObject } from "~/src/components/tools/tabs";
import { startGuide } from "~/src/guide";
import ArrowBack from "~/src/media/images/svg/arrow-back.svg";
import BigGroupIcon from "~/src/media/images/svg/bigGroup.svg";
import { getLoggedInUser } from "~/src/redux/actions/login/actions";
import Skeleton from "~/src/skeletons/skeleton";
import SkeletonHomeCard from "~/src/skeletons/skeletonHomeCard";
import type { AnswersHistory, ClientBebData, ClientInfo, GlobalState } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import { route } from "~/src/utils/constants";
import { getInvitationQuantity, getInvitationStatus } from "~/src/utils/invitationFunctions";
import language from "~/src/utils/language";
import { formatDate } from "~/src/utils/systemFunctions";
import StatisticsQuestions from "../../statistics/statisticsQuestions";
import { CASES_ROUTES } from "../caseList/caseList";
import BackaBarnet from "./backaBarnet";
import Report from "./report";
import "./caseDetails.css";
import { genderTypes } from "@ommej/metadata";
import Conversation from "./conversation";

export type ClientData = {
    answers: AnswersBase | Record<string, never>;
    info: ClientInfo;
    beb: ClientBebData;
    beb2: Beb2;
};

const TAB_ROUTES = {
    REPORT: "report",
    BEB: "beb",
    HANDLE: "handle",
    GROUP: "group",
    STATISTICS: "statistics",
    BACKA_BARNET: "backa_barnet",
    CONVERSATION: "conversation",
};

const TAB_VIEWS = {
    HANDLE: "HANDLE",
    CLIENT: "CLIENT",
    GROUP: "GROUP",
};

const CaseDetails = () => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const { CASE_DETAILS } = language[locales];
    const [tabView, setTabView] = useState<TabsObject[]>([]);
    const [currentInvitation, setCurrentInvitation] = useState<Invitation.ToClient>();
    const [invitationQuantity, setInvitationQuantity] = useState(-1);
    const [clientData, setClientData] = useState<ClientData>();
    const [answerHistory, setAnswerHistory] = useState<AnswersHistory>();
    const [errorMessage, setErrorMessage] = useState(false);
    const params = useParams();
    const [currentView, setCurrentView] = useState<string | undefined>();
    const { invitationId } = params;
    const baseUrl = `/case/${invitationId}/`;
    const isBackaBarnetConnected = currentUser?.features
        ? currentUser.features.includes("backa_barnet")
        : false;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { ABOUT_CASE, CASE_LIST, COMMON } = language[locales];

    const TAB_STRING = {
        REPORT: COMMON.OVERVIEW,
        BEB: CASE_DETAILS.BEB,
        HANDLE: CASE_DETAILS.HANDLE,
        STATISTICS: CASE_DETAILS.STATISTICS,
        BACKA_BARNET: CASE_DETAILS.BACKA_BARNET,
        CONVERSATION: CASE_DETAILS.CONVERSATION,
    };

    useEffect(() => {
        getInvitation();
    }, []);

    useEffect(() => {
        if (!currentInvitation || invitationQuantity === -1) {
            return;
        }

        // Default views
        // | case type | client status | answers status | view to show |
        // |-----------+---------------+----------------+--------------|
        // | single    | none          | none           | handle       |
        // | single    | waiting       | none           | handle       |
        // | single    | accepted      | none           | handle       |
        // | single    | accepted      | exists         | report       |
        // | multiple  | none          | none           | handle       |
        // | multiple  | waiting       | none           | handle       |
        // | multiple  | accepted      | none*          | statistics   |
        // | multiple  | accepted      | exists         | statistics   |
        //
        // * we currently don't go through all clients to check if they have
        // any answers, so for now we show statistics for all multi cases with
        // accepted clients.
        //
        // We should probably handle cases with denied clients too.
        if (!params.route) {
            const view = getView(
                currentInvitation.clients,
                invitationQuantity,
                currentInvitation.maxNumberOfClients ?? -1,
            );

            if (
                view === TAB_VIEWS.CLIENT &&
                clientData &&
                Object.keys(clientData.answers).length > 0
            ) {
                navigate(TAB_ROUTES.REPORT, { replace: true });
            } else if (view === TAB_VIEWS.GROUP) {
                navigate(TAB_ROUTES.STATISTICS, { replace: true });
            } else {
                navigate(TAB_ROUTES.HANDLE, { replace: true });
            }
        } else {
            setCurrentView(params.route);
        }
    }, [params, currentInvitation, invitationQuantity, clientData]);

    useEffect(() => {
        if (
            !tabView.some((tab) => {
                return tab.tabString === TAB_STRING.CONVERSATION;
            }) ||
            currentView === TAB_ROUTES.CONVERSATION
        ) {
            return;
        }
        startGuide(currentUser, "CASE_CONVERSATION_TAB", locales, () => {
            dispatch(getLoggedInUser());
        });
    }, [tabView]);

    const getClientInfoById = async (clientId: string) => {
        const response = await reqWithPath(
            "invitations",
            "get",
            `${invitationId}/clients/${clientId}`,
        );
        return response.json();
    };

    const getClientAnswersById = async (clientId: string): Promise<ClientData["answers"]> => {
        const response = await reqWithPath(
            "invitations",
            "get",
            `${invitationId}/clients/${clientId}/answers`,
        );
        return response.json();
    };

    const getClientBebById = async (clientId: string) => {
        const response = await reqWithPath(
            "invitations",
            "get",
            `${invitationId}/clients/${clientId}/beb`,
        );
        return response.json();
    };

    const getClientBeb2ById = async (clientId: string) => {
        const response = await reqWithPath(
            "invitations",
            "get",
            `${invitationId}/clients/${clientId}/beb2`,
        );
        if (response.status === 200) {
            return response.json();
        }
        return {};
    };

    const getData = async (clientId: string): Promise<ClientData | undefined> => {
        let data;
        try {
            const [answersObj, client, beb, beb2] = await Promise.all([
                getClientAnswersById(clientId),
                getClientInfoById(clientId),
                getClientBebById(clientId),
                getClientBeb2ById(clientId),
            ]);
            data = { answers: answersObj, info: client, beb, beb2: beb2.active };
            setClientData(data);
            if (answersObj?.answers) {
                // add latest answers to the historic answers and set the time
                const history = answersObj.history || {};
                const timestamp = Math.floor(new Date(answersObj.lastUpdated).getTime() / 1000);
                history[`${timestamp}`] = {
                    answers: answersObj.answers,
                    time: answersObj.lastUpdated,
                };
                setAnswerHistory(history);
            }
        } catch (error) {
            console.error(error);
            setErrorMessage(true);
        }
        return data;
    };

    const getView = (
        clients: Invitation.Clients | undefined,
        quantity: number,
        maxNumberOfClients: number,
    ) => {
        if (!clients || !quantity) {
            return TAB_VIEWS.HANDLE;
        }
        if (maxNumberOfClients === 0) {
            return TAB_VIEWS.GROUP;
        }
        if (maxNumberOfClients === 0 && quantity === 0) {
            return TAB_VIEWS.HANDLE;
        }
        if (clients.accepted && clients.accepted.length >= 1) {
            return TAB_VIEWS.CLIENT;
        }
        if (clients.pending && clients.pending.length >= 1) {
            return TAB_VIEWS.HANDLE;
        }
        return TAB_VIEWS.HANDLE;
    };

    const getInvitation = async () => {
        try {
            const response = await reqWithPath("invitations", "get", `${invitationId}`);
            const invitation = await response.json();

            const quantity = getInvitationQuantity(invitation);
            setInvitationQuantity(quantity);
            let data: Awaited<ReturnType<typeof getData>>;
            if (invitation.clients) {
                const view = getView(invitation.clients, quantity, invitation.maxNumberOfClients);
                if (
                    view === TAB_VIEWS.GROUP &&
                    invitation.clients.accepted &&
                    invitation.clients.accepted.length > 0
                ) {
                    const clientId: string | undefined = invitation.clients.accepted[0]?.client;
                    if (!clientId) {
                        setErrorMessage(true);
                        return;
                    }
                    data = await getData(clientId);
                }
                if (view === TAB_VIEWS.CLIENT && invitation.clients.accepted) {
                    const clientId: string | undefined = invitation.clients.accepted[0]?.client;
                    if (!clientId) {
                        setErrorMessage(true);
                        return;
                    }
                    data = await getData(clientId);
                }

                const tabs: TabsObject[] = [];
                switch (view) {
                    case TAB_VIEWS.CLIENT:
                        if (data?.answers && Object.keys(data.answers).length > 0) {
                            tabs.push({
                                tabString: TAB_STRING.REPORT,
                                route: `${baseUrl}${TAB_ROUTES.REPORT}`,
                            });
                            tabs.push({
                                tabString: TAB_STRING.BEB,
                                route: `${baseUrl}${TAB_ROUTES.BEB}`,
                            });
                            if (quantity === 1) {
                                tabs.push({
                                    tabString: TAB_STRING.CONVERSATION,
                                    route: `${baseUrl}${TAB_ROUTES.CONVERSATION}`,
                                    class: "guide-case_conversation_tab-tab",
                                });
                            }
                        }
                        break;
                    case TAB_VIEWS.GROUP:
                        // TODO: could check if any client has answers and only show
                        // statistics if they do
                        if (invitation.clients && invitation.clients.accepted.length > 0) {
                            tabs.push({
                                tabString: TAB_STRING.STATISTICS,
                                route: `${baseUrl}${TAB_ROUTES.STATISTICS}`,
                            });
                        }
                        break;
                    default:
                        break;
                }
                if (
                    isBackaBarnetConnected &&
                    quantity === 1 &&
                    data?.beb.active.BB &&
                    data.beb.active.BB.length > 0
                ) {
                    tabs.push({
                        tabString: TAB_STRING.BACKA_BARNET,
                        route: `${baseUrl}${TAB_ROUTES.BACKA_BARNET}`,
                    });
                }
                tabs.push({
                    tabString: TAB_STRING.HANDLE,
                    route: `${baseUrl}${TAB_ROUTES.HANDLE}`,
                });
                setTabView(tabs);
            } else {
                setTabView([
                    { tabString: TAB_STRING.HANDLE, route: `${baseUrl}${TAB_ROUTES.HANDLE}` },
                ]);
                setCurrentView(TAB_ROUTES.HANDLE);
            }
            setCurrentInvitation(invitation);
        } catch (error) {
            console.error(error);
            setErrorMessage(true);
        }
    };

    const renderTabs = () => {
        if (!currentView) {
            return null;
        }
        switch (currentView) {
            case TAB_ROUTES.BEB:
                return (
                    clientData?.beb &&
                    currentInvitation && (
                        <Beb
                            bebCurrent={clientData.beb2}
                            client={clientData.info}
                            invitation={currentInvitation}
                            snapshots={(answerHistory && Object.keys(answerHistory)) || []}
                        />
                    )
                );
            case TAB_ROUTES.STATISTICS:
                return (
                    <div style={{ padding: "2rem" }}>
                        <StatisticsQuestions showOptionAll={false} showOptionClosed={false} embed />
                    </div>
                );
            case TAB_ROUTES.BACKA_BARNET:
                return (
                    clientData &&
                    currentInvitation && (
                        <BackaBarnet
                            invitation={currentInvitation}
                            beb={clientData.beb2}
                            client={clientData.info}
                            setInvitation={setCurrentInvitation}
                        />
                    )
                );
            case TAB_ROUTES.CONVERSATION:
                return (
                    clientData &&
                    currentInvitation && (
                        <Conversation
                            invitation={currentInvitation}
                            beb={clientData.beb2}
                            client={clientData.info}
                        />
                    )
                );
            case TAB_ROUTES.HANDLE:
                return currentInvitation ? (
                    <ManageInvitation
                        invitation={currentInvitation}
                        setInvitation={setCurrentInvitation}
                    />
                ) : null;
            case TAB_ROUTES.REPORT:
            default:
                return (
                    clientData &&
                    currentInvitation &&
                    answerHistory && (
                        <Report
                            client={clientData.info}
                            bebCurrent={clientData.beb2}
                            invitation={currentInvitation}
                            answersHistory={answerHistory}
                        />
                    )
                );
        }
    };

    function getHeader() {
        if (!currentInvitation) {
            return (
                <div className="caseDetails-header">
                    <Skeleton type="image" height="10rem" />
                    <Skeleton type="heading" height="3rem" />
                    <Skeleton type="text" height="9rem" />
                </div>
            );
        }

        const status = getInvitationStatus(
            currentInvitation.status.status,
            currentInvitation.clients,
            invitationQuantity,
            language[locales],
        );

        const single =
            currentInvitation.maxNumberOfClients != null
                ? currentInvitation.maxNumberOfClients === 1
                : invitationQuantity === 1;

        if (single) {
            const gender =
                clientData?.info?.profile && genderTypes[clientData.info.profile.gender]?.sv;
            const birthyear = clientData?.info.profile?.birthYear;
            const avatar = clientData?.info.profile?.avatar;

            // Search general + all other forms for latest update
            let lastUpdated = clientData?.answers.lastUpdated || 0;
            for (const formStat of Object.values(clientData?.info.formsStat || [])) {
                if (formStat.lastUpdated > lastUpdated) {
                    lastUpdated = formStat.lastUpdated;
                }
            }

            return (
                <div className="caseDetails-header">
                    <div className="caseDetails-header-avatar-wrapper">
                        <GetAvatar avatarData={avatar} />
                    </div>
                    <div>
                        <h1 className="caseDetails-header-header">{currentInvitation.name}</h1>
                        <div className="caseDetails-header-info-pills">
                            {birthyear && (
                                <span className="caseDetails-header-info-pill">
                                    {`${ABOUT_CASE.YEAR} ${birthyear}`}
                                </span>
                            )}
                            {gender && (
                                <span className="caseDetails-header-info-pill">{gender}</span>
                            )}
                        </div>
                    </div>

                    <div className="caseDetails-header-info-items">
                        <span>{ABOUT_CASE.CREATED}</span>
                        {formatDate(currentInvitation.created)}
                        <span>{ABOUT_CASE.LAST_ANSWER}</span>
                        {formatDate(lastUpdated, locales)}
                        {currentInvitation?.code && (
                            <>
                                <span>{ABOUT_CASE.CODE}</span>
                                {currentInvitation.code.replace(/.{3}/, "$&-")}
                            </>
                        )}
                        <span>{CASE_LIST.STATUS}</span>
                        {status.string}
                    </div>
                </div>
            );
        }

        return (
            <div className="caseDetails-header">
                <div className="caseDetails-header-avatar-wrapper">
                    <img src={BigGroupIcon} alt="group icon" />
                </div>
                <div>
                    <h1 className="caseDetails-header-header">{currentInvitation.name}</h1>
                    <div className="caseDetails-header-info-pills">
                        <span className="caseDetails-header-info-pill">
                            {" "}
                            {`${ABOUT_CASE.GROUP_WITH} ${invitationQuantity} ${ABOUT_CASE.CHILDREN}`}
                        </span>
                    </div>
                </div>

                <div className="caseDetails-header-info-items">
                    <span>{ABOUT_CASE.CREATED}</span>
                    {formatDate(currentInvitation.created)}
                    {currentInvitation?.code && (
                        <>
                            <span>{ABOUT_CASE.CODE}</span>
                            {currentInvitation.code.replace(/.{3}/, "$&-")}
                        </>
                    )}
                    <span>{CASE_LIST.STATUS}</span>
                    {status.string}
                </div>
            </div>
        );
    }

    if (errorMessage) {
        return (
            <div className="app-component-container">
                <Link
                    to={`${route.CASES}/${CASES_ROUTES.ALL_CASES}`}
                    className="caseDetails-backButton"
                >
                    <img src={ArrowBack} alt="arrow back" />
                    {CASE_DETAILS.BACK}
                </Link>
                <div
                    className="caseDetails-wrapper"
                    style={{
                        alignItems: "center",
                        border: "2px solid var(--color-peach)",
                        borderTopLeftRadius: "var(--border-radius-20)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <p style={{ width: "30rem" }}>{CASE_DETAILS.ERROR_MESSAGE}</p>
                    <Link
                        to={`${route.CASES}/${CASES_ROUTES.ALL_CASES}`}
                        className="caseDetails-backButton"
                        style={{ marginTop: "2rem", fontWeight: "bold" }}
                    >
                        {CASE_DETAILS.BACK}
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="app-component-container">
            <Link
                to={`${route.CASES}/${CASES_ROUTES.ALL_CASES}`}
                className="caseDetails-backButton"
            >
                <img src={ArrowBack} alt="arrow back" />
                {CASE_DETAILS.BACK}
            </Link>
            {!currentInvitation || !tabView ? (
                <>
                    {getHeader()}
                    <SkeletonHomeCard
                        style={{ width: "100%", height: "600px", marginTop: "2rem" }}
                    />
                </>
            ) : (
                <>
                    {getHeader()}
                    <Tabs tabs={tabView}>
                        <div className="caseDetails-wrapper">{renderTabs()}</div>
                    </Tabs>
                </>
            )}
        </div>
    );
};

export default CaseDetails;
