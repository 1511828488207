import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { userRoles } from "../../../../../utils/constants";
import { checkRole } from "../../../../../utils/userFunctions";
import ProfileIcon from "../profileIcon/profileIcon";
import "./profileCard.css";

const ProfileCard = ({ unit }) => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const unitsObj = useSelector((state) => {
        return state.unit.unitObj;
    });

    if (!unit) {
        return null;
    }
    return (
        <div className="profileCard-wrapper">
            <div className="profileCard-icon-text">
                <ProfileIcon />
                <div className="profileCard-text-wrapper">
                    <h4>{unit.name}</h4>
                    <p className="text-description text-grey">
                        {userRoles[checkRole(unit, currentUser, unitsObj)] || undefined}
                    </p>
                </div>
            </div>
        </div>
    );
};

ProfileCard.propTypes = {
    unit: PropTypes.shape(Types.unit).isRequired,
};

export default ProfileCard;
