"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswersViewer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const answersViewerSingle_1 = require("./answersViewerSingle");
const answersViewerStats_1 = require("./answersViewerStats");
const AnswersViewer = ({ form, answersData, profile }) => {
    if (answersData.format === "answers") {
        return (0, jsx_runtime_1.jsx)(answersViewerSingle_1.AnswersViewerSingle, { form: form, formAnswers: answersData.data, profile: profile });
    }
    if (answersData.format === "stats") {
        return (0, jsx_runtime_1.jsx)(answersViewerStats_1.AnswersViewerStats, { form: form, formAnswers: answersData.data });
    }
    return null;
};
exports.AnswersViewer = AnswersViewer;
