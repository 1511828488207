import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Types from "~/src/utils/types";
import Can from "../../../../../rbac/can";
import { updateUnit } from "../../../../../redux/actions/units/actions";
import language from "../../../../../utils/language";
import { findSingleUnit, getAllChildUnits } from "../../../../../utils/unitFunctions";
import Accordion from "../../../../tools/accordion/accordion";
import NormalButton from "../../../../tools/buttons/normalButton/normalButton";
import SecondaryButton from "../../../../tools/buttons/secondaryButton/secondaryButton";
import Dropdown from "../../../../tools/dropdown/dropdown";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import "./unitMove.css";

const UnitMove = ({ unitObj, setUnitObj, role, setShow }) => {
    const { units } = useSelector((state) => {
        return state.unit;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [possibleParentUnits, setPossibleParentUnits] = useState([]);
    const [currentParent, setCurrentParent] = useState(unitObj.parentUnit);
    const [accordion, setAccordion] = useState(false);
    const [currentUnit] = useState(unitObj);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const allChildUnits = getAllChildUnits(unitObj, units);
        const unitsExceptChildUnits = units.filter((unit) => {
            return !allChildUnits.includes(unit);
        });
        const unitsExceptCurrentParentOwnChildren = unitsExceptChildUnits.filter((unit) => {
            return unit._id !== unitObj._id && unit._id !== unitObj.parentUnit;
        });
        setPossibleParentUnits(
            unitsExceptCurrentParentOwnChildren.map((unit) => {
                return { value: unit._id, text: unit.name };
            }),
        );
        return () => {
            setErrorMessage("");
        };
    }, []);

    const handleChange = (itemId) => {
        const newParentUnit = findSingleUnit(itemId, units);
        setCurrentParent(findSingleUnit(currentParent, units));
        setAccordion(true);
        setUnitObj({ ...unitObj, parentUnit: newParentUnit._id });
    };

    const handleCancel = () => {
        setAccordion(false);
        setUnitObj({ ...unitObj, parentUnit: currentUnit.parentUnit });
        setCurrentParent(currentUnit.parentUnit);
        setErrorMessage("");
    };

    const handleMove = async () => {
        try {
            await dispatch(updateUnit(unitObj, currentParent));
            setShow(false);
        } catch (_err) {
            setErrorMessage(language[locales].ADMIN.UNIT.EDIT_UNIT.MOVE.ERROR);
        }
    };

    const handleYes = useCallback(() => {
        return (
            <>
                <Dropdown
                    items={possibleParentUnits}
                    handleChange={(itemId) => {
                        handleChange(itemId);
                    }}
                />
                <Accordion accordionActive={accordion}>
                    <div className="unitMove-accordion-wrapper">
                        <p>{language[locales].ADMIN.UNIT.EDIT_UNIT.MOVE.CONFIRM}</p>
                        <p className="unitMove-error-text text-error">{errorMessage}</p>
                        <div className="unitMove-accordion-buttons">
                            <SecondaryButton
                                handleButton={handleCancel}
                                text={language[locales].ADMIN.UNIT.EDIT_UNIT.MOVE.CANCEL_BUTTON}
                            />
                            <NormalButton
                                handleButton={handleMove}
                                text={language[locales].ADMIN.UNIT.EDIT_UNIT.MOVE.MOVE_BUTTON}
                                customStyle={{ marginLeft: "0.5rem" }}
                            />
                        </div>
                    </div>
                </Accordion>
            </>
        );
    }, [possibleParentUnits, accordion, errorMessage]);

    return (
        <FluidWrapper height="hg-small">
            <h3>{language[locales].ADMIN.UNIT.EDIT_UNIT.MOVE.HEADING}</h3>
            <div className="unitMove-wrapper">
                <Can
                    role={role}
                    perform="unit:update"
                    yes={() => {
                        return handleYes();
                    }}
                    no={() => {
                        return null;
                    }}
                />
            </div>
        </FluidWrapper>
    );
};

UnitMove.propTypes = {
    unitObj: PropTypes.shape(Types.unit).isRequired,
    setUnitObj: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    setShow: PropTypes.func.isRequired,
};

export default UnitMove;
