import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import language from "../../../../utils/language";
import GhostButton from "../../../tools/buttons/ghostButton/ghostButton";
import RoundButton from "../../../tools/buttons/roundButton/roundButton";
import UnitIcon from "../../common/unitIcon/unitIcon";
import "./searchUnitCard.css";

const SearchUnitCard = ({ unit, handleClick, handleRoundButton, isActive }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [showButton, setShowButton] = useState(false);

    return (
        <>
            <section
                className="searchUnitCard-button-wrapper"
                onMouseEnter={() => {
                    setShowButton(true);
                }}
                onMouseLeave={() => {
                    setShowButton(false);
                }}
            >
                <div className="searchUnitCard-main-wrapper">
                    <UnitIcon unit={unit} />
                    {isActive ? (
                        <RoundButton
                            className="searchUnitCard-roundButton"
                            icon="cancel"
                            handleButton={handleRoundButton}
                        />
                    ) : (
                        showButton && (
                            <div className="userCard-button-wrapper">
                                <GhostButton
                                    tabIndex="0"
                                    text={language[locales].ADMIN.UNIT.ADMIN_CARDS.SEARCH_UNIT_CARD}
                                    handleGhostButton={handleClick}
                                />
                            </div>
                        )
                    )}
                </div>
            </section>
            <span className="searchUnitCard-span-border" />
        </>
    );
};

SearchUnitCard.defaultProps = {
    unit: undefined,
};

SearchUnitCard.propTypes = {
    handleClick: PropTypes.func.isRequired,
    handleRoundButton: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    unit: PropTypes.shape(Types.unit),
};

export default SearchUnitCard;
