// @ts-check

/** @typedef {AbortSignal|undefined} MaybeAbortSignal */
/** @typedef {{ signal: undefined, abort: () => void }} FakeAbortController */

/**
 * @returns {AbortController | FakeAbortController}
 */
const getAbortController = () => {
    if (window.AbortController) {
        return new AbortController();
    }
    return {
        signal: undefined,
        abort: () => {
            console.warn("abort");
        },
    };
};

export default getAbortController;
