import type { Beb2 } from "@ommej/types";
import Chartjs from "chart.js/auto";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import "./chart.css";

// ***** Chart Options ***** //
Chartjs.defaults.font.family = "'Quicksand', sans-serif";
Chartjs.defaults.elements.line.tension = 0.2;

export const reportBuild = [
    {
        heading: "Familj",
        tag: "FAMILY",
    },
    {
        heading: "Boende",
        tag: "BASE",
    },
    {
        heading: "Mående",
        tag: "MYSELF",
    },
    {
        heading: "Skola",
        tag: "SCHOOL",
    },
    {
        heading: "Fritid",
        tag: "LEISURE",
    },
];

function getChartData(beb: Beb2) {
    function getArrayLength(array, tagQuestions) {
        if (!array || array.length === 0) {
            return 0;
        }
        if (!tagQuestions || tagQuestions.length === 0) {
            return 0;
        }
        const filteredArray = array.filter((arr) => {
            return tagQuestions.includes(arr);
        });
        return filteredArray.length;
    }

    return {
        help: reportBuild.map((reportSection) => {
            return getArrayLength(
                Object.keys(beb.answerTags.HELP || {}),
                beb.questionTags[reportSection.tag],
            );
        }),
        worry: reportBuild.map((reportSection) => {
            return getArrayLength(
                Object.keys(beb.answerTags.WORRIES || {}),
                beb.questionTags[reportSection.tag],
            );
        }),
        problem: reportBuild.map((reportSection) => {
            return getArrayLength(
                Object.keys(beb.answerTags.PROBLEM || {}),
                beb.questionTags[reportSection.tag],
            );
        }),
    };
}

const ReportChart = ({ beb }: { beb: Beb2 }) => {
    let chart;
    const radarChart = useRef<HTMLCanvasElement | null>(null);
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });

    const { REPORT_LIST } = language[locales].CASE.REPORT.TAB_REPORT;
    const { QUESTION_TAGS, COMMON } = language[locales];

    const buildChart = () => {
        if (!radarChart.current) {
            return;
        }
        const data = getChartData(beb);
        chart = new Chartjs(radarChart.current, {
            type: "radar",
            data: {
                labels: [
                    QUESTION_TAGS.FAMILY,
                    COMMON.ACCOMMODATION,
                    COMMON.FEELING,
                    QUESTION_TAGS.SCHOOL,
                    QUESTION_TAGS.LEISURE,
                ],
                datasets: [
                    {
                        label: REPORT_LIST.HELP,
                        data: data.help,
                        backgroundColor: "rgba(240, 164, 171, 0.2)",
                        borderColor: "#ce6771",
                        pointBackgroundColor: "rgba(240, 164, 171, 0.2)",
                        borderWidth: 3,
                    },
                    {
                        label: REPORT_LIST.WORRY,
                        data: data.worry,
                        backgroundColor: "rgba(237, 220, 194, 0.2)",
                        borderColor: "#d6c1a2",
                        pointBackgroundColor: "rgba(238, 220, 194, 0.2)",
                        borderWidth: 3,
                    },
                    {
                        label: REPORT_LIST.PROBLEM,
                        data: data.problem,
                        backgroundColor: "rgba(248, 246, 218, 0.2)",
                        borderColor: "#f3e672",
                        pointBackgroundColor: "rgba(248, 246, 218, 0.2)",
                        borderWidth: 3,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: "bottom",
                        align: "start",
                        labels: {
                            usePointStyle: true,
                            padding: 13,
                            font: {
                                size: 12,
                            },
                            boxHeight: 7,
                            boxWidth: 7,
                        },
                    },
                },
                scales: {
                    r: {
                        pointLabels: {
                            font: {
                                size: 12,
                                weight: "bold",
                            },
                        },
                        min: 0,
                    },
                },
            },
        });
    };

    useEffect(() => {
        buildChart();
        return () => {
            chart.destroy();
        };
    }, [beb]);

    if (!beb) {
        return null;
    }

    return (
        <section className="chart-wrapper">
            <canvas ref={radarChart} />
        </section>
    );
};

export default ReportChart;
