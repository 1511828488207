import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { ErrorWithCause } from "pony-cause";

// Language related
import { useSelector } from "react-redux";
import language from "../../utils/language";

import getAbortController from "../../utils/abort";
import { reqWithPayload, resolveFetchError } from "../../utils/api";
import ErrorIndicator from "../tools/indicator/errorIndicator";
import LoadingIndicator from "../tools/indicator/loadingIndicator";
import NotFoundIndicator from "../tools/indicator/notFoundIndicator";
import StatisticsPieChart from "./answer-chart";
import "./question-graph.css";

/**
 * @typedef TreeStatistics
 * @property {string} questionId
 * @property {string} answerId
 * @property {number} clientCount
 * @property {TreeStatistics[]} [correlations]
 */

/** @typedef {{ questions: string[], answers: TreeStatistics[] }} StatisticsResponse */

/**
 * @param {string} formId
 * @param {number} formVer
 * @param {string[]} questions
 * @param {boolean} includeAll
 * @param {boolean} includeClosed
 * @param {string[]|undefined} [invitations]
 * @param {import('../../utils/abort').MaybeAbortSignal} [signal]
 * @returns {Promise<{ err: Error, result?: never } | { err?: never, result: StatisticsResponse[] }>}
 */
const fetchStatistics = async (
    formId,
    formVer,
    questions,
    includeAll,
    includeClosed,
    invitations,
    signal,
) => {
    try {
        const response = await reqWithPayload(
            `statistics/${encodeURIComponent(formId)}/${encodeURIComponent(formVer)}`,
            "post",
            invitations
                ? {
                      groups: [questions],
                      invitations,
                      includeClosed,
                      includeAll,
                  }
                : {
                      groups: [questions],
                      includeClosed,
                      includeAll,
                  },
            { signal },
        );

        return { result: await response.json() };
    } catch (err) {
        return {
            err: new ErrorWithCause("Failed to fetch statistics", {
                cause: await resolveFetchError(err),
            }),
        };
    }
};

const CLASS_NAME = "question-graph";

/**
 * @param {object} props
 * @param {object} props.form
 * @param {string} props.formId
 * @param {number} props.formVersion
 * @param {boolean} props.includeAll
 * @param {boolean} props.includeClosed
 * @param {string|undefined} [props.invitationId]
 * @param {string[]} props.questions
 * @returns {JSX.Element}
 */
const QuestionGraph = (props) => {
    const { locales } = useSelector(
        /**
         * @param {import('~/src/types').GlobalState} state
         * @returns {import('~/src/types').GlobalState["language"]}
         */
        (state) => {
            return state.language;
        },
    );
    const { NO_MATCHES } = language[locales].STATISTICS;

    const { form, formId, formVersion, invitationId, questions, includeAll, includeClosed } = props;

    const invitations = invitationId ? [invitationId] : undefined;

    /** @type {import('../../utils/ts-types').UseState<StatisticsResponse[] | Error | undefined>} */
    const [data, setData] = useState();

    useEffect(() => {
        let unmounted = false;
        const abortController = getAbortController();

        setData(undefined);

        fetchStatistics(
            formId,
            formVersion,
            questions,
            includeAll,
            includeClosed,
            invitations,
            abortController.signal,
        ).then(({ err, result }) => {
            if (unmounted) {
                return;
            }
            if (err) {
                if (process.env.NODE_ENV === "development") {
                    console.log("Encountered an error when fetching statistics:", err);
                }
                setData(err);
            } else {
                setData(result);
            }
        });

        return () => {
            unmounted = true;
            abortController.abort();
        };
    }, [formId, formVersion, questions]);

    if (data instanceof Error) {
        return (
            <div className={CLASS_NAME}>
                <ErrorIndicator />
            </div>
        );
    }
    if (!data) {
        return (
            <div className={CLASS_NAME}>
                <LoadingIndicator />
            </div>
        );
    }
    if (!data[0] || !data[0].answers || data[0].answers.length === 0) {
        return (
            <div className={CLASS_NAME}>
                <NotFoundIndicator description={NO_MATCHES} />
            </div>
        );
    }

    return (
        <div className={CLASS_NAME}>
            <StatisticsPieChart form={form} data={data[0]} />
        </div>
    );
};

QuestionGraph.defaultProps = {
    invitationId: undefined,
    form: undefined,
};

QuestionGraph.propTypes = {
    form: PropTypes.object,
    formId: PropTypes.string.isRequired,
    formVersion: PropTypes.number.isRequired,
    invitationId: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    includeAll: PropTypes.bool.isRequired,
    includeClosed: PropTypes.bool.isRequired,
};

export default QuestionGraph;
