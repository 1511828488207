// @ts-check

import PropTypes from "prop-types";

export const AccountIcon = () => {
    return (
        <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <title>Ärenden</title>
            <g className="accountIcon-fill">
                <path
                    d="M9.5,9.1c0.7-0.5,1.1-1.3,1.1-2.1c0-1.5-1.2-2.7-2.7-2.7S5.2,5.5,5.2,6.9c0,0.9,0.4,1.6,1.1,2.1C3.1,10,1,13.7,1,18.6v0.7
          h13.7v-0.7C14.7,13.8,12.5,10.1,9.5,9.1z M7.9,5.6c0.8,0,1.4,0.6,1.4,1.4S8.6,8.3,7.9,8.3S6.5,7.7,6.5,6.9S7.1,5.6,7.9,5.6z
          M2.3,18c0.2-4.6,2.4-7.8,5.5-7.8c3,0,5.3,3.3,5.5,7.8H2.3z"
                />
                <path
                    d="M17.4,6.5c0.3-0.4,0.5-0.8,0.5-1.4C17.9,3.9,17,3,15.8,3s-2.1,0.9-2.1,2.1c0,0.5,0.2,1,0.5,1.3c-0.7,0.3-1.4,0.8-2,1.4
          c0.4,0.2,0.7,0.5,1,0.8c0.7-0.8,1.5-1.2,2.5-1.2c2.2,0,4,2.5,4.2,5.8h-3.8c0.1,0.4,0.3,0.9,0.4,1.3h4.7v-0.7
          C21.3,10.3,19.7,7.4,17.4,6.5z M15.8,5.9c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S16.2,5.9,15.8,5.9z"
                />
            </g>
        </svg>
    );
};

export const GearIcon = () => {
    return (
        <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <title>Admin</title>
            <g className="gearIcon-fill">
                <path
                    d="M10.5,14.7c-1.7,0-3.1-1.4-3.1-3.2c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.2,1.4,3.2,3.1C13.7,13.2,12.2,14.7,10.5,14.7z M10.5,9.6
          c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.8,1.9,1.8s1.8-0.8,1.8-1.8C12.3,10.5,11.5,9.6,10.5,9.6z"
                />
                <path d="M15.2,19.4H5.9l-4.6-8l4.6-8h9.3l4.6,8L15.2,19.4z M6.6,18.1h7.8l3.9-6.7l-3.9-6.7H6.6l-3.9,6.7L6.6,18.1z" />
            </g>
        </svg>
    );
};

export const StatisticsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <title>Statistik</title>
            <path
                className="statisticsIcon-fill"
                d="M15.75 16h-1.5V6h1.5v10Zm-7.5 0V7h1.5v9h-1.5Zm3 0V9h1.5v7h-1.5Zm-6 0v-5h1.5v5h-1.5Z"
            />
        </svg>
    );
};

export const ArrowUpIcon = () => {
    return (
        <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <title>Överblick</title>
            <polygon
                className="arrowUpIcon-fill"
                points="19.8,10.7 19.2,6 14.6,7 16.7,8.5 13.3,13.6 7.5,8.5 2,15.4 3,16.2 7.7,10.4 13.6,15.6 17.7,9.2 "
            />
        </svg>
    );
};

export const HeartIcon = () => {
    return (
        <svg width="80%" height="80%" viewBox="0 -2 18 18" xmlns="http://www.w3.org/2000/svg">
            <title>Artiklar</title>
            <path
                className="heartIcon-fill"
                d="M12.9211 2.3C13.7911 2.3 14.6411 2.76 15.2411 3.57C15.9711 4.54 16.1811 5.79 15.8211 7C15.2511 8.91 13.0011 10.93 10.1611 13.48C9.83111 13.77 9.50111 14.07 9.16111 14.38L9.15111 14.37L9.14111 14.38C8.80111 14.07 8.47111 13.77 8.14111 13.48C5.29111 10.93 3.04111 8.91 2.47111 7C2.11111 5.79 2.32111 4.54 3.05111 3.57C3.65111 2.76 4.50111 2.3 5.37111 2.3C6.33111 2.3 7.29111 2.86 8.13111 3.92L9.15111 5.2L10.1711 3.92C11.0111 2.86 11.9611 2.3 12.9211 2.3ZM12.9211 1C11.6711 1 10.3311 1.62 9.14111 3.11C7.96111 1.62 6.62111 1 5.37111 1C2.57111 1 0.261112 4.1 1.22111 7.37C2.03111 10.09 5.32111 12.64 9.14111 16.15C12.9611 12.64 16.2511 10.09 17.0611 7.37C18.0411 4.1 15.7211 1 12.9211 1Z"
            />
        </svg>
    );
};

export const PencilIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
            <title>Ändra</title>
            <path
                className="pencilIcon-fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1685 6.13723L10.9153 6.5075L12.9187 7.88102L13.1754 7.50573C13.5483 6.94793 13.4071 6.20123 12.8579 5.81573C12.2995 5.44423 11.553 5.58675 11.1685 6.13723ZM12.1848 8.95406L10.1814 7.58054L6.80405 12.5188L7.14619 14.1658L8.81109 13.887L12.1848 8.95406ZM10.1 5.39677C10.8883 4.26364 12.4354 3.96621 13.587 4.73943L13.596 4.74541C14.7291 5.53367 15.0265 7.08091 14.2532 8.23251L14.2501 8.23713L9.57168 15.0778L6.12768 15.6545L5.41871 12.2416L10.1 5.39677ZM13.1636 18.5789H4.88916L4.88916 17.2789H13.1636V18.5789ZM16.745 18.5789H14.9116V17.2789H16.745V18.5789Z"
                fill="var(--color-dark-indigo)"
            />
        </svg>
    );
};

export const PlusIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <title>Lägg till</title>
            <g className="plusIcon-fill">
                <path
                    stroke="#ffffff"
                    d="M5.2877 8.71231H12.7123"
                    strokeWidth="1.3"
                    strokeMiterlimit="10"
                />
                <path stroke="#ffffff" d="M9 5V12.4246" strokeWidth="1.3" strokeMiterlimit="10" />
            </g>
        </svg>
    );
};

export const PlusIndigoIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Lägg till</title>
            <path
                className="plusIndigoIcon-fill"
                stroke="var(--color-dark-indigo)"
                d="M5.2877 8.71231H12.7123"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
            <path
                className="plusIndigoIcon-fill"
                stroke="var(--color-dark-indigo)"
                d="M9 5V12.4246"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
        </svg>
    );
};

export const ArrowRightIcon = ({ className, customStyle }) => {
    return (
        <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={customStyle}
        >
            <title>Högerpil</title>
            <path
                className={className}
                d="M1 9.16L5.13 5.08L1 1"
                stroke="#979797"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
        </svg>
    );
};
ArrowRightIcon.propTypes = {
    className: PropTypes.string,
    customStyle: PropTypes.objectOf(PropTypes.string),
};
ArrowRightIcon.defaultProps = {
    className: "",
    customStyle: {},
};

export const ArrowDownIcon = () => {
    return (
        <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Pil nedåt</title>
            <path
                className="arrowDownIcon-fill"
                d="M6.00152 8.55566L10.9882 13.6034L15.9749 8.55566"
                stroke="var(--color-dark-indigo)"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
        </svg>
    );
};

export const TrashBinIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Ta bort</title>
            <g className="trashbinIcon-fill" fill="var(--color-dark-indigo)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.6323 9.52357H5.4541V7.88721H18.6323V9.52357Z"
                />
                <path d="M15.076 10.7346L14.3233 16.1564H9.76328L9.01055 10.7346H7.36328L8.33419 17.7928H15.7524L16.7233 10.7346H15.076Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.69824 5.45459H14.1928V8.72732H12.5564V7.09095H11.3346V8.07277H9.69824V5.45459Z"
                />
            </g>
        </svg>
    );
};

export const CheckIcon = () => {
    return (
        <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Välj</title>
            <path
                d="M19.7 10.6v.7a8.3 8.3 0 1 1-5-7.6"
                stroke="#313A8F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.7 4.7 11.3 13l-2.5-2.5"
                stroke="#313A8F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const OnePersonIcon = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>En person</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.96949 1.3C5.45034 1.3 5.02949 1.72085 5.02949 2.24C5.02949 2.75915 5.45034 3.18 5.96949 3.18C6.48864 3.18 6.90949 2.75915 6.90949 2.24C6.90949 1.72085 6.48864 1.3 5.96949 1.3ZM3.72949 2.24C3.72949 1.00288 4.73237 0 5.96949 0C7.20661 0 8.20949 1.00288 8.20949 2.24C8.20949 3.47712 7.20661 4.48 5.96949 4.48C4.73237 4.48 3.72949 3.47712 3.72949 2.24Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.03379 5.72182C3.0098 4.35709 4.40028 3.55994 5.97 3.55994C7.55676 3.55994 8.94053 4.44356 9.90235 5.82626C10.8627 7.20687 11.43 9.11267 11.43 11.2899V11.9399H0.5V11.2899C0.5 8.98986 1.064 7.07787 2.03379 5.72182ZM1.81699 10.6399H10.1109C10.0112 8.96579 9.52925 7.56645 8.83515 6.56861C8.05447 5.44631 7.03324 4.85994 5.97 4.85994C4.87972 4.85994 3.8602 5.40279 3.09121 6.47805C2.39795 7.44742 1.91198 8.85192 1.81699 10.6399Z"
                fill="black"
            />
        </svg>
    );
};

export const OrganizationIcon = () => {
    return (
        <svg
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>En grupp</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8073 0.712827C12.0184 0.582812 12.2863 0.582914 12.4972 0.713092L19.4374 4.99582C19.683 5.14736 19.797 5.44068 19.7169 5.71493C19.6368 5.98919 19.382 6.17811 19.0922 6.17811H5.19986C4.90994 6.17811 4.65508 5.98907 4.57509 5.71469C4.4951 5.44032 4.60933 5.14696 4.8551 4.99555L11.8073 0.712827ZM7.46428 4.89811H16.8311L12.1518 2.01051L7.46428 4.89811Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.81494 13.6127V7.16345H8.11494V13.6127H6.81494Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.98438 13.6127V7.16345H11.2844V13.6127H9.98438Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1528 13.6127V7.16345H14.4528V13.6127H13.1528Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3218 13.6127V7.16345H17.6218V13.6127H16.3218Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.56152 12.7755H18.7309V14.0555H5.56152V12.7755Z"
                fill="black"
            />
        </svg>
    );
};

export const OrganizationBigIcon = () => {
    return (
        <svg
            width="60"
            height="36"
            viewBox="0 0 60 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>En grupp</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.9361 0.234144C28.4355 -0.0782777 29.0694 -0.0780305 29.5685 0.234781L45.9889 10.526C46.57 10.8902 46.8397 11.595 46.6503 12.2541C46.4608 12.9131 45.858 13.3671 45.1722 13.3671H12.3029C11.617 13.3671 11.014 12.9128 10.8247 12.2535C10.6355 11.5942 10.9057 10.8892 11.4872 10.5254L27.9361 0.234144ZM17.6605 10.2913H39.8225L28.7511 3.35244L17.6605 10.2913Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1243 31.2321V15.7348H19.2001V31.2321H16.1243Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.623 31.2321V15.7348H26.6988V31.2321H23.623Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.1196 31.2321V15.7348H34.1954V31.2321H31.1196Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.6174 31.2321V15.7348H41.6932V31.2321H38.6174Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1586 29.2203H44.3174V32.2961H13.1586V29.2203Z"
                fill="black"
            />
        </svg>
    );
};

export const GroupBigIcon = () => {
    return (
        <svg
            width="61"
            height="37"
            viewBox="0 0 61 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>En grupp</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5615 6.9323C29.0062 6.9323 27.7648 8.18083 27.7648 9.69697C27.7648 11.2131 29.0062 12.4616 30.5615 12.4616C32.1169 12.4616 33.3583 11.2131 33.3583 9.69697C33.3583 8.18083 32.1169 6.9323 30.5615 6.9323ZM24.6892 9.69697C24.6892 6.46073 27.3291 3.85669 30.5615 3.85669C33.794 3.85669 36.4339 6.46073 36.4339 9.69697C36.4339 12.9332 33.794 15.5373 30.5615 15.5373C27.3291 15.5373 24.6892 12.9332 24.6892 9.69697Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0143 19.265C22.6436 15.6112 26.3716 13.499 30.5618 13.499C34.8172 13.499 38.5436 15.8424 41.1438 19.5557C43.7397 23.2629 45.273 28.3932 45.2569 34.2649L45.2527 35.7985H15.8667V34.2607C15.8667 28.0415 17.3998 22.8982 20.0143 19.265ZM18.9771 32.7229H42.1462C41.9167 28.0427 40.5831 24.117 38.6244 21.3199C36.4598 18.2286 33.5921 16.5746 30.5618 16.5746C27.4971 16.5746 24.6464 18.0936 22.5108 21.0614C20.5563 23.7774 19.2068 27.7163 18.9771 32.7229Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.3682 11.6843C41.4659 8.97736 44.3839 7.39624 47.6542 7.39624C51.1427 7.39624 54.1697 9.32177 56.2658 12.3213C58.3573 15.3144 59.5826 19.436 59.5826 24.13V25.6678H47.6542V22.5922H56.4565C56.2237 19.0912 55.2027 16.1695 53.7447 14.083C52.0826 11.7045 49.9143 10.4718 47.6542 10.4718C45.4525 10.4718 43.3904 11.5149 41.7992 13.5682L39.3682 11.6843Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.4689 4.27751C46.5926 4.27751 45.9018 4.97958 45.9018 5.82163C45.9018 6.66367 46.5926 7.36574 47.4689 7.36574C48.3451 7.36574 49.0359 6.66368 49.0359 5.82163C49.0359 4.97958 48.3451 4.27751 47.4689 4.27751ZM42.8262 5.82163C42.8262 3.25948 44.9156 1.2019 47.4689 1.2019C50.0222 1.2019 52.1115 3.25948 52.1115 5.82163C52.1115 8.38377 50.0222 10.4413 47.4689 10.4413C44.9156 10.4413 42.8262 8.38377 42.8262 5.82163Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.51354 22.5922C4.74633 19.0912 5.76736 16.1695 7.22534 14.083C8.88742 11.7045 11.0557 10.4718 13.3158 10.4718C15.5175 10.4718 17.5796 11.5149 19.1708 13.5682L21.6019 11.6843C19.5041 8.97736 16.5861 7.39624 13.3158 7.39624C9.82734 7.39624 6.80032 9.32177 4.70427 12.3213C2.61276 15.3144 1.38745 19.436 1.38745 24.13V25.6678H13.3158V22.5922H4.51354Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5309 4.27751C12.6546 4.27751 11.9638 4.97958 11.9638 5.82163C11.9638 6.66367 12.6546 7.36574 13.5309 7.36574C14.4071 7.36574 15.0979 6.66367 15.0979 5.82163C15.0979 4.97958 14.4071 4.27751 13.5309 4.27751ZM8.88818 5.82163C8.88818 3.25948 10.9776 1.2019 13.5309 1.2019C16.0842 1.2019 18.1735 3.25948 18.1735 5.82163C18.1735 8.38377 16.0842 10.4413 13.5309 10.4413C10.9776 10.4413 8.88818 8.38377 8.88818 5.82163Z"
                fill="black"
            />
        </svg>
    );
};

export const PersonBigIcon = () => {
    return (
        <svg
            width="60"
            height="36"
            viewBox="0 0 60 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>En person</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.6366 3.81042C28.0812 3.81042 26.8397 5.05902 26.8397 6.57526C26.8397 8.0915 28.0812 9.34011 29.6366 9.34011C31.1921 9.34011 32.4335 8.0915 32.4335 6.57526C32.4335 5.05902 31.1921 3.81042 29.6366 3.81042ZM23.7639 6.57526C23.7639 3.33882 26.404 0.734619 29.6366 0.734619C32.8692 0.734619 35.5093 3.33882 35.5093 6.57526C35.5093 9.8117 32.8692 12.4159 29.6366 12.4159C26.404 12.4159 23.7639 9.8117 23.7639 6.57526Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0878 16.144C21.7172 12.49 25.4455 10.3777 29.636 10.3777C33.8916 10.3777 37.6182 12.7212 40.2186 16.4348C42.8147 20.1421 44.348 25.2728 44.332 31.1448L44.3278 32.6785H14.9399V31.1406C14.9399 24.9211 16.4732 19.7774 19.0878 16.144ZM18.0505 29.6027H41.221C40.9916 24.9222 39.6578 20.9963 37.6991 18.199C35.5343 15.1075 32.6665 13.4535 29.636 13.4535C26.571 13.4535 23.7202 14.9725 21.5844 17.9405C19.6298 20.6567 18.2803 24.5958 18.0505 29.6027Z"
                fill="black"
            />
        </svg>
    );
};

export const QuestionMarkIcon = () => {
    return (
        <svg
            width="6"
            height="11"
            viewBox="0 0 6 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Hjälp</title>
            <path
                d="M2.78014 7.25992C2.78014 6.11992 2.61459 5.46823 3.72014 4.92992C5.63014 3.99992 5.50014 0.639925 2.78014 0.649925C2.78014 0.649925 0.310136 0.649925 0.690136 3.52992"
                stroke="var(--color-dark-indigo)"
                strokeWidth="1.3"
                strokeMiterlimit="10"
            />
            <path
                d="M2.85 10.0999C3.31944 10.0999 3.7 9.71934 3.7 9.2499C3.7 8.78046 3.31944 8.3999 2.85 8.3999C2.38056 8.3999 2 8.78046 2 9.2499C2 9.71934 2.38056 10.0999 2.85 10.0999Z"
                fill="var(--color-dark-indigo)"
            />
        </svg>
    );
};

export const RefreshIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Uppdatera</title>
            <g className="refreshIcon-fill" fill="var(--color-dark-indigo)">
                <path d="M15.3094 8.23574L13.634 10L13.317 7.9924L13 6L15.0075 6.24335L17 6.47148L15.3094 8.23574Z" />
                <path d="M6.82053 14.132L8.59709 12.4696L8.79517 14.4924L8.99414 16.5L7.00443 16.1387L5.02889 15.7935L6.82053 14.132Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.4304 10.9299C15.4304 9.4932 14.7745 8.21564 13.7401 7.37415L14.5606 6.36572C15.8862 7.44423 16.7304 9.08667 16.7304 10.9299C16.7304 14.1689 14.0993 16.7999 10.8604 16.7999V15.4999C13.3814 15.4999 15.4304 13.451 15.4304 10.9299Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8705 6.3C8.34947 6.3 6.30049 8.34898 6.30049 10.87C6.30049 12.3067 6.95636 13.5843 7.99069 14.4258L7.17028 15.4342C5.84462 14.3557 5.00049 12.7133 5.00049 10.87C5.00049 7.63101 7.6315 5 10.8705 5V6.3Z"
                />
            </g>
        </svg>
    );
};

export const PauseIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Pause</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.1998 15C7.08935 15 6.9998 14.9105 6.9998 14.8L6.9998 8.2C6.9998 8.08954 7.08935 8 7.1998 8L9.0998 8C9.21026 8 9.2998 8.08954 9.2998 8.2L9.2998 14.8C9.2998 14.9105 9.21026 15 9.0998 15L7.1998 15Z"
                fill="var(--color-dark-indigo)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1998 15C12.0893 15 11.9998 14.9105 11.9998 14.8L11.9998 8.2C11.9998 8.08954 12.0893 8 12.1998 8L14.0998 8C14.2103 8 14.2998 8.08954 14.2998 8.2L14.2998 14.8C14.2998 14.9105 14.2103 15 14.0998 15L12.1998 15Z"
                fill="var(--color-dark-indigo)"
            />
        </svg>
    );
};

export const PlayIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Starta</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.75 11.4331C15.0833 11.2406 15.0833 10.7595 14.75 10.567L8.75 7.10293C8.41667 6.91048 8 7.15105 8 7.53595L8 14.4642C8 14.8491 8.41667 15.0896 8.75 14.8972L14.75 11.4331Z"
                fill="var(--color-dark-indigo)"
            />
        </svg>
    );
};

export const SearchIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Sök</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.40988 5.3C7.13999 5.3 5.29988 7.14011 5.29988 9.41C5.29988 11.6799 7.13999 13.52 9.40988 13.52C11.6798 13.52 13.5199 11.6799 13.5199 9.41C13.5199 7.14011 11.6798 5.3 9.40988 5.3ZM3.99988 9.41C3.99988 6.42214 6.42202 4 9.40988 4C12.3977 4 14.8199 6.42214 14.8199 9.41C14.8199 12.3979 12.3977 14.82 9.40988 14.82C6.42202 14.82 3.99988 12.3979 3.99988 9.41Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5195 12.6003L17.0195 16.1003L16.1002 17.0196L12.6002 13.5196L13.5195 12.6003Z"
                fill="black"
            />
        </svg>
    );
};

export const CancelIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <title>Avbryt</title>
            <g className="cancelIcon-fill" fill="var(--color-dark-indigo)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.447 15.3262L6.56096 7.44019L7.44114 6.56001L15.3271 14.446L14.447 15.3262Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.56095 14.446L14.4469 6.55995L15.3271 7.44013L7.44114 15.3261L6.56095 14.446Z"
                />
            </g>
        </svg>
    );
};

export const AnalysisIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <title>Analysera</title>
            <path
                className="analysisIcon-fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8866 13.0908H11.6593V4.90894H12.8866V13.0908ZM6.7502 13.0908L6.7502 5.72712L7.97747 5.72712L7.97747 13.0908H6.7502ZM9.20475 13.0908L9.20475 7.36348H10.432L10.432 13.0908H9.20475ZM4.29565 13.0908V8.99985H5.52293V13.0908H4.29565Z"
                fill="var(--color-dark-indigo)"
            />
        </svg>
    );
};

export const PrintIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Skriv ut</title>
            <g className="printIcon-fill" fill="var(--color-dark-indigo)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.34961 7.0001C4.34961 6.64111 4.64062 6.3501 4.99961 6.3501H7.33164V7.6501H5.64961V13.1001H6.49951V14.4001H4.99961C4.64062 14.4001 4.34961 14.1091 4.34961 13.7501V7.0001ZM14.4996 6.3501H16.9996C17.3586 6.3501 17.6496 6.64111 17.6496 7.0001V13.7501C17.6496 14.1091 17.3586 14.4001 16.9996 14.4001H15.4995V13.1001H16.3496V7.6501H14.4996V6.3501Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.64961 15.35V11.5H7.34961V16.65H14.6496V11.5H13.3496V15.35H8.64961Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5 11.8999H6.5V10.5999H15.5V11.8999Z"
                />
                <path d="M15 9.5C15 9.77614 14.7761 10 14.5 10C14.2239 10 14 9.77614 14 9.5C14 9.22386 14.2239 9 14.5 9C14.7761 9 15 9.22386 15 9.5Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7 5.3H8.3V6.7H13.7V5.3ZM7 4V8H15V4H7Z"
                />
            </g>
        </svg>
    );
};
