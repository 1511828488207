import { AccountIcon, ArrowUpIcon, GearIcon, HeartIcon, StatisticsIcon } from "../../utils/icons";

const ICONS = {
    account: <AccountIcon />,
    gear: <GearIcon />,
    arrowUp: <ArrowUpIcon />,
    heart: <HeartIcon />,
    statistics: <StatisticsIcon />,
};

export default ICONS;
