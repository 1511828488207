import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux/store";
import { isDemo, isDevEnv } from "./utils/constants";
import "./index.css";

const container = document.getElementById("root");
// biome-ignore lint/style/noNonNullAssertion: container won't be null here
const root = createRoot(container!);
root.render(
    <Provider store={store}>
        <App />
        {isDemo && <div className="app-demo-container">TESTMILJÖ</div>}
        {isDevEnv && <div className="app-demo-container app-dev-container">DEV</div>}
    </Provider>,
);
