import type { Invitation } from "@ommej/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "~/src/components/tools/accordion/accordion";
import NormalButton from "~/src/components/tools/buttons/normalButton/normalButton";
import SecondaryButton from "~/src/components/tools/buttons/secondaryButton/secondaryButton";
import Dropdown, { type DropdownItemsArray } from "~/src/components/tools/dropdown/dropdown";
import { updateInvitation } from "~/src/redux/actions/invitation/actions";
import type { GlobalState } from "~/src/types";
import { roles } from "~/src/utils/constants";
import language from "~/src/utils/language";
import SharedUserCard from "./cards/sharedUserCard";
import "./caseShare.css";

export type SharedUser = {
    _id: string;
    username: string;
    unit: {
        _id: string;
        name: string;
    };
};

const CaseShare = ({ invitation }: { invitation: Invitation.ToClient }) => {
    const users = useSelector((state: GlobalState) => {
        return state.user.users;
    });
    const loggedInUser = useSelector((state: GlobalState) => {
        return state.login.loggedInUser;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const [dropdownArray, setDropdownArray] = useState<DropdownItemsArray>([]);
    const [accordion, setAccordion] = useState(false);
    const [sharedToUsersOrig] = useState(invitation.sharedToUsers);
    const [newSharedToUsers, setNewSharedToUsers] = useState(invitation.sharedToUsers);

    const createSharedToUsersDropDownValue = (userObj) => {
        // Mimic the objects that backend fills sharedToUsers with.
        // We don't want a mix of objects formats in shareToUsers
        const newSharedToUsersObj: SharedUser = {
            _id: userObj._id,
            username: userObj.username,
            unit: {
                _id: userObj.unit._id,
                name: userObj.unit.name,
            },
        };
        return {
            value: JSON.stringify(newSharedToUsersObj),
            text: newSharedToUsersObj.username,
        };
    };

    useEffect(() => {
        setDropdownArray(
            users
                .filter((user) => {
                    return (
                        user.roleCode !== roles.systemAdmin &&
                        user._id !== loggedInUser._id &&
                        !invitation.sharedToUsers.some((sharedUser) => {
                            return sharedUser._id === user._id;
                        }) &&
                        user._id !== invitation.ownedBy._id
                    );
                })
                .sort((a, b) => {
                    if (a.username.toLowerCase() < b.username.toLowerCase()) {
                        return -1;
                    }
                    if (a.username.toLowerCase() > b.username.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
                .map((user) => {
                    // Populate the dropdown with the users as stringified values
                    return createSharedToUsersDropDownValue(user);
                }),
        );
    }, []);

    const removeSharedToUser = (sharedToUserObj: SharedUser) => {
        const user = sharedToUserObj;
        // Remove the user from the array of new sharedToUsers
        const newArr = newSharedToUsers.filter((checkUser) => {
            return checkUser._id !== user._id;
        });
        setNewSharedToUsers(newArr);

        /* Add the removed user to the dropdown so we can choose it again in case
         * we clicked the wrong one
         * Populate the dropdown with the user as stringified value
         */
        const newDropDownValue = createSharedToUsersDropDownValue(user);
        setDropdownArray([...dropdownArray, newDropDownValue]);
        setAccordion(newArr.length > 0);
    };

    const addSharedToUser = (dropDownValue: string) => {
        let user: SharedUser;
        // dropDownValue is always stringified user object
        try {
            user = JSON.parse(dropDownValue);
            // Remove user from dropdown
            const filteredDropDownArray = dropdownArray.filter((dropDownItem) => {
                if (dropDownItem.value) {
                    // type assertion: we create the value ourselves in this component as JSON.stringify
                    const checkUser = JSON.parse(dropDownItem.value as string);
                    return checkUser._id !== user._id;
                }
                return false;
            });
            setDropdownArray(filteredDropDownArray);
            setAccordion(true);
            setNewSharedToUsers([...newSharedToUsers, user]);
        } catch (_e) {
            // Just return without changing anything
        }
    };

    const onCancelSharedToUsers = () => {
        /* Add the removed user to the dropdown so we can choose it again in case
         * we clicked the wrong one.
         * Populate the dropdown with the user as stringified value
         */
        const usersBackToDropDown = newSharedToUsers.map((user) => {
            return createSharedToUsersDropDownValue(user);
        });
        const newSortedDropDownArray = [...dropdownArray, ...usersBackToDropDown].sort((a, b) => {
            if (a.text.toLowerCase() < b.text.toLowerCase()) {
                return -1;
            }
            if (a.text.toLowerCase() > b.text.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        setDropdownArray(newSortedDropDownArray);
        setNewSharedToUsers(sharedToUsersOrig);
        setAccordion(false);
    };

    const onConfirmShareToUsers = async () => {
        const sendObj = { ...invitation, sharedToUsers: newSharedToUsers };
        await dispatch(updateInvitation(sendObj));
        setAccordion(false);
    };

    return (
        <>
            <h3>{`${language[locales].CASE.EDIT_INVITATION.SHARE.HEADING}`}</h3>
            <div className="caseShare-dropdown-wrapper">
                <Dropdown
                    items={dropdownArray}
                    handleChange={(user) => {
                        addSharedToUser(user);
                    }}
                />
            </div>
            <div className="caseShare-user-wrapper">
                <h4>{`${language[locales].CASE.EDIT_INVITATION.SHARE.SUB_HEADING}`}</h4>
                {newSharedToUsers.map((user) => {
                    return (
                        <SharedUserCard
                            key={user._id}
                            user={user}
                            deleteUser={removeSharedToUser}
                        />
                    );
                })}
            </div>
            <Accordion accordionActive={accordion}>
                <div className="caseMove-accordion-wrapper">
                    <p>{`${language[locales].CASE.EDIT_INVITATION.SHARE.CONFIRM_SHARE}`}</p>
                    <div className="caseMove-accordion-buttons">
                        <SecondaryButton
                            handleButton={onCancelSharedToUsers}
                            text={`${language[locales].CASE.EDIT_INVITATION.SHARE.CANCEL_BUTTON}`}
                        />
                        <NormalButton
                            handleButton={onConfirmShareToUsers}
                            text={`${language[locales].CASE.EDIT_INVITATION.SHARE.CONFIRM_BUTTON}`}
                            customStyle={{ marginLeft: "0.5rem" }}
                        />
                    </div>
                </div>
            </Accordion>
        </>
    );
};

export default CaseShare;
