import Skeleton from "./skeleton";
import "./skeleton.css";

type SkeletonHomeCardProps = {
    style?: NonNullable<unknown>;
};

const SkeletonHomeCard = ({ style }: SkeletonHomeCardProps) => {
    return (
        <div className="skeleton-wrapper" style={style}>
            <Skeleton type="image" />
            <Skeleton type="heading" />
            <Skeleton type="text" />
        </div>
    );
};

export default SkeletonHomeCard;
