import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../redux/actions/users/actions";
import NormalButton from "../../../tools/buttons/normalButton/normalButton";
import RoundButton from "../../../tools/buttons/roundButton/roundButton";
import SecondaryButton from "../../../tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "../../../tools/fluidWrapper/fluidWrapper";
import Textfield from "../../../tools/form/textfield/textfield";
import "./profileName.css";

const ProfileName = () => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const [changeName, setChangeName] = useState(false);
    const [currentName] = useState(currentUser.username);
    const [error, setError] = useState("");
    const [sendObj, setSendObj] = useState({
        role: currentUser.roleCode.toLowerCase(),
        _id: currentUser._id,
        email: currentUser.email,
        username: currentUser.username,
        unit: currentUser.unit,
    });
    const dispatch = useDispatch();

    const handleChangeName = (e) => {
        setError("");
        setSendObj({ ...sendObj, username: e.target.value });
    };

    const handleCancel = () => {
        setError("");
        setSendObj({ ...sendObj, username: currentName });
        setChangeName(false);
    };

    const handleSend = () => {
        // get two api request due to backend dont return full unitobj //
        if (sendObj.username.length > 2) {
            dispatch(updateUser(sendObj));
            setChangeName(false);
        }
        setError("Namnet måste ha minst 2 bokstäver");
    };

    return (
        <FluidWrapper height="hg-small">
            {changeName ? (
                <div className="profileName-wrapper">
                    <h3>Namn</h3>
                    <Textfield
                        type="text"
                        name="name"
                        text="Ändra namn"
                        value={sendObj.username}
                        handleChange={(e) => {
                            handleChangeName(e);
                        }}
                        inputError={error}
                        customStyle={{ width: "18rem" }}
                    />
                    <div className="profileName-button-wrapper">
                        <SecondaryButton text="Avbryt" handleButton={handleCancel} />
                        <NormalButton
                            text="Spara"
                            handleButton={handleSend}
                            customStyle={{ marginLeft: "0.5rem" }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="profileName-noChange-header">
                        <h3>Namn</h3>
                        <RoundButton
                            icon="pencil"
                            handleButton={() => {
                                setChangeName(true);
                            }}
                        />
                    </div>
                    <h4 className="profileName-noChange-name">{sendObj.username}</h4>
                </>
            )}
        </FluidWrapper>
    );
};

export default ProfileName;
