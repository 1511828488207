const rules = {
    SYSTEM_ADMIN: {
        static: [
            "unit:create",
            "unit:read",
            "unit:update",
            "unit:delete",
            "unit:browse",
            "user:create",
            "user:read",
            "user:update",
            "user:delete",
            "user:browse",
        ],
    },
    ADMINISTRATOR: {
        static: [
            "unit:create",
            "unit:read",
            "unit:update",
            "unit:delete",
            "unit:browse",
            "user:create",
            "user:read",
            "user:update",
            "user:delete",
            "user:browse",
        ],
    },
    USER: {
        static: ["unit:read", "user:read"],
    },
};

/*
  org_admin: {
    static: [
      'organization:render',
      'institution:create',
      'institution:read',
      'institution:update',
      'institution:delete',
      'institution:render',
      'group:create',
      'group:read',
      'group:update',
      'group:delete',
      'group:render',
      'users:org_mngr',
      'users:inst_mngr',
      'users:group_mngr',
      'users:user',
      'users:render',
    ],
    dynamic: {
      'organization:update': ({ userId, data }) => {
        if (!userId || !data) {
          return false;
        }
        if (data.administrators.includes(userId)) {
          return data;
        }
        return false;
      },
      'organization:read': ({ userId, data }) => {
        if (!userId || !data) {
          return false;
        }
        if (data.administrators.includes(userId)) {
          return data;
        }
        return false;
      },
      'users:org_admin': ({ users, role }) => {
        if (!users || !role) {
          return false;
        }
      },
    },
  },
 */

export default rules;
