import type { Invitation } from "@ommej/types";
import { roles } from "./constants";

export const findUserObject = (id, users) => {
    const findUser = users.find((user) => {
        return user._id === id;
    });
    return findUser;
};

const isUserAdminInUnit = (unit, user, unitsObj) => {
    let currentUnit = unit;
    let isAdmin = false;
    /* In frontend, the only way of knowing if we've reached the top of units is
     * that the parent of a unit does not exist in unitsObj. That's our
     * 'system root' in this context.
     */
    while (currentUnit && !isAdmin) {
        isAdmin =
            currentUnit.administrators.includes(user._id) ||
            (user.unit === currentUnit._id && user.roleCode === roles.administrator);
        currentUnit = unitsObj[`${currentUnit.parentUnit}`];
    }
    return isAdmin;
};

export const checkRole = (unit, user, unitsObj) => {
    if (!unit && user.roleCode === roles.systemAdmin) {
        return roles.systemAdmin;
    }
    if (user.roleCode === roles.systemAdmin) {
        return roles.systemAdmin;
    }
    if (!unit) {
        console.log("calling CheckRole() with undefined unit");
        return "Undefined role";
    }
    if (user.unit === unit._id) {
        return user.roleCode;
    }
    if (isUserAdminInUnit(unit, user, unitsObj)) {
        return roles.administrator;
    }
    return roles.user;
};

export const checkIfUserHasInvitation = (user, invitations) => {
    if (!user || !invitations) {
        return false;
    }
    return invitations.some((invitation) => {
        return invitation.ownedBy._id === user._id || invitation.sharedToUsers.includes(user._id);
    });
};

export const checkIfUserOwnsInvitation = (userId: string, invitations: Invitation.ToClient[]) => {
    if (!userId || !invitations) {
        return false;
    }
    return invitations.some((invitation) => {
        return invitation.ownedBy._id === userId;
    });
};

/* Checks if a user is admin in the same unit, or above,
 * as the owner of the case
 */
export const userHasAdminAccessToCase = (user, checkCase, units) => {
    const ownersUnit = units.find((item) => {
        return item._id === checkCase.ownedBy.unit._id;
    });
    return isUserAdminInUnit(ownersUnit, user, units);
};
