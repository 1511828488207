import type { Invitation } from "@ommej/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "~/src/components/tools/modal/modal";
import type { GlobalState } from "~/src/types";
import { INVITE_CRUMBS } from "~/src/utils/constants";
import { ArrowRightIcon } from "~/src/utils/icons";
import language from "~/src/utils/language";
import CaseCreated from "./tabs/caseCreated";
import ChooseInvitation from "./tabs/chooseInvitation";
import ChooseName from "./tabs/chooseName";
import SendInvite from "./tabs/sendInvite";
import "./addInvitation.css";

const START_CRUMB = "CREATE";

const AddInvitation = () => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { ADD_INVITATION } = language[locales].CASE;
    const [tab, setTab] = useState<string | null>(START_CRUMB);
    const [crumbs, setCrumbs] = useState<string[]>([START_CRUMB]);
    const [show, setShow] = useState(true);
    const [isGroup, setIsGroup] = useState(false);
    const [invitationName, setInvitationName] = useState("");
    const [fullInvitation, setFullInvitation] = useState<Invitation.InvitationWithId | undefined>();

    const handleCrumbs = (crumb: string) => {
        setCrumbs([...(crumbs || []), crumb]);
        setTab(crumb);
    };

    const handleCrumbClick = (crumb: string, idx: number) => {
        const filteredCrumb = crumbs.filter((_, i) => {
            return i <= idx;
        });
        setCrumbs(filteredCrumb);
        setTab(crumb);
    };

    useEffect(() => {
        return () => {
            setTab(null);
            setCrumbs([]);
            setInvitationName("");
        };
    }, []);

    const renderTabs = () => {
        switch (tab) {
            case INVITE_CRUMBS.SINGLE:
            case INVITE_CRUMBS.GROUP:
                return (
                    <ChooseName
                        invitationName={invitationName}
                        setInvitationName={setInvitationName}
                        handleCrumbs={handleCrumbs}
                        setFullInvitation={setFullInvitation}
                        isGroup={isGroup}
                    />
                );
            case INVITE_CRUMBS.CREATED:
                return (
                    <CaseCreated
                        setCrumbs={setCrumbs}
                        handleCrumbs={handleCrumbs}
                        isSuccess={false}
                    />
                );
            case INVITE_CRUMBS.INVITE:
                return (
                    <SendInvite
                        fullInvitation={fullInvitation}
                        isGroup={isGroup}
                        handleCrumbs={handleCrumbs}
                    />
                );
            case INVITE_CRUMBS.SUCCESS:
                return <CaseCreated setCrumbs={setCrumbs} handleCrumbs={handleCrumbs} isSuccess />;
            default:
                return <ChooseInvitation handleCrumbs={handleCrumbs} setIsGroup={setIsGroup} />;
        }
    };

    if (!crumbs) {
        return null;
    }
    return (
        <Modal show={show} setShow={setShow} type="desktop">
            <Modal.ModalHeader>
                <div className="addInvitation-header-wrapper">
                    {crumbs.map((crumb, idx) => {
                        return (
                            <button
                                key={crumb}
                                className="addInvitation-button-header"
                                type="button"
                                onClick={() => {
                                    handleCrumbClick(crumb, idx);
                                }}
                            >
                                {idx !== 0 ? <ArrowRightIcon /> : null}
                                <h3>{ADD_INVITATION[crumb]}</h3>
                            </button>
                        );
                    })}
                </div>
            </Modal.ModalHeader>
            {renderTabs()}
        </Modal>
    );
};

export default AddInvitation;
