import { useState } from "react";
import "./conversationWelcome.css";

const ConversationWelcome = ({ className }: { className: string }) => {
    const [open, setOpen] = useState(false);

    return (
        <section className={`conversationWelcome-wrapper ${className}`}>
            <h2>Välkommen till samtalsunderlag</h2>
            <p>
                Att prata med barn kräver förståelse, tålamod och en förmåga att skapa en trygg
                atmosfär där barnet känner sig hört och förstått. Nedan följer en guide till
                samtalsmetoder som kan stärka barn i svåra situationer och underlätta
                kommunikationen.
            </p>
            <details
                className="conversationWelcome-details"
                onToggle={(e) => {
                    return setOpen((e.currentTarget as HTMLDetailsElement).open);
                }}
            >
                <summary>{!open ? "Visa mer" : "Visa mindre"}</summary>
                <h3>Skapa Trygghet i Samtalet</h3>
                <ul className="conversationWelcome-list">
                    <li>
                        <h4>Avdramatisera</h4>
                        <p>
                            Förklara att barnets känslor och upplevelser är normala och att andra
                            har känt likadant. Detta minskar barnets känsla av isolering.
                            <br /> <span className="text-bold">Exempel:</span> &quot;Många barn jag
                            träffar har upplevt liknande situationer, och det finns stöd att
                            få.&quot;
                        </p>
                    </li>
                    <li>
                        <h4>Affirmera</h4>
                        <p>
                            Uppmuntra och stärk barnets egna positiva handlingar eller känslor genom
                            att bekräfta dem.
                            <br /> <span className="text-bold">Exempel:</span> &quot;Det visar på
                            stort mod att du vågade säga ifrån, även när det var svårt.&quot;
                        </p>
                    </li>
                    <li>
                        <h4>Bekräfta</h4>
                        <p>
                            Visa att du lyssnar aktivt och förstår, utan att döma, genom att
                            reflektera barnets berättelse i dina egna ord.
                            <br /> <span className="text-bold">Exempel:</span> &quot;Om barnet
                            berättar något, omformulera detta för att visa förståelse.&quot;
                        </p>
                    </li>
                    <li>
                        <h4>Informera</h4>
                        <p>
                            Ibland är det viktigaste inte att få barnet att prata utan att ge
                            information om vad som händer härnäst.
                            <br /> <span className="text-bold">Exempel:</span> &quot;Klargör din
                            roll och hur du kommer att interagera med barnet och dess familj.&quot;
                        </p>
                    </li>
                </ul>
                <h3>Förbättra kommunikationen</h3>
                <ul className="conversationWelcome-list">
                    <li>
                        <h4>Speglingar</h4>
                        <p>
                            Använd speglingar för att visa att du uppmärksammat och förstått barnets
                            känslor och uttryck.
                            <br /> <span className="text-bold">Exempel:</span> &quot;Så du känner
                            dig rädd när situationen blir spänd hemma.&quot;
                        </p>
                    </li>
                    <li>
                        <h4>Reflektioner</h4>
                        <p>
                            Direkta eller omformulerade upprepningar av barnets uttalanden som visar
                            att du verkligen lyssnar.
                            <br /> <span className="text-bold">Exempel:</span> &quot;Du känner dig
                            utanför i skolan&quot;, eller &quot;Det verkar som att du önskar du hade
                            någon att umgås med.&quot;
                        </p>
                    </li>
                    <li>
                        <h4>Sammanfattningar</h4>
                        <p>
                            Används för att visa att du har förstått och uppmärksammat det
                            viktigaste i barnets berättelse.
                            <br /> <span className="text-bold">Exempel:</span> &quot;Efter en längre
                            berättelse, sammanfatta de viktigaste punkterna för att bekräfta din
                            förståelse.&quot;
                        </p>
                    </li>
                    <li>
                        <h4>Sammanhangsmarkering</h4>
                        <p>
                            Hjälper barnet att förstå varför samtalet äger rum och vad som kan
                            förväntas.
                            <br /> <span className="text-bold">Exempel:</span> &quot; Inled samtalet
                            med att förklara syftet och återkom till detta vid behov för att
                            klargöra sammanhanget.&quot;
                        </p>
                    </li>
                </ul>
                <h4 style={{ marginTop: "3rem" }}>Kom ihåg!</h4>
                <p>
                    Din roll i samtalet är av stor betydelse. Även om du inte kan lösa alla problem
                    som barnet står inför, är det ofta tillräckligt att ge barnet utrymme att
                    uttrycka sig och känna sig lyssnat på och bekräftat. Att dela sina tankar och
                    känslor kan i sig vara helande och stärkande för barnet. Genom att anpassa din
                    kommunikation och använda dessa tekniker visar du på ett aktivt och medkännande
                    lyssnande som kan göra stor skillnad i barnets liv.
                    <br />
                    <br />
                    Det är viktigt att barnet vet att det har rätt att uttrycka sig men även att det
                    inte är tvunget att göra det. Visa alltid att du är redo att ta emot vad barnet
                    har att dela med sig av, och balansera ditt stöd med barnets behov i varje
                    enskilt möte.
                </p>
            </details>
        </section>
    );
};

export default ConversationWelcome;
