import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import language from "../../../../../utils/language";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import Textfield from "../../../../tools/form/textfield/textfield";
import "./userCredentials.css";

const UserCredentials = ({ user, setUser }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    return (
        <FluidWrapper height="hg-small">
            <h3>{language[locales].ADMIN.USER.ADD_USER.NAME}</h3>
            <div className="userCredentials-wrapper">
                <Textfield
                    text={language[locales].ADMIN.USER.ADD_USER.NAME_INPUT}
                    type="text"
                    name="username"
                    value={user.username}
                    handleChange={handleChange}
                    customStyle={{ width: "18rem" }}
                />
                <Textfield
                    text={language[locales].ADMIN.USER.ADD_USER.EMAIL_INPUT}
                    type="email"
                    name="email"
                    value={user.email}
                    handleChange={handleChange}
                    customStyle={{ width: "18rem" }}
                />
            </div>
        </FluidWrapper>
    );
};

UserCredentials.propTypes = {
    user: PropTypes.shape({
        roleCode: PropTypes.string,
        email: PropTypes.string,
        username: PropTypes.string,
        unit: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Types.unit)]),
    }).isRequired,
    setUser: PropTypes.func.isRequired,
};

export default UserCredentials;
