import type { Unit } from "@ommej/types";
import { useEffect, useState } from "react";
import FluidWrapper from "~/src/components/tools/fluidWrapper/fluidWrapper";
import { reqWithPath } from "~/src/utils/api";

type UnitConfigProps = {
    unitObj: Unit.UnitType;
};

const UnitConfig = ({ unitObj }: UnitConfigProps) => {
    const [config, setConfig] = useState<Unit.UnitConfig>();

    useEffect(() => {
        async function load() {
            try {
                const response = await reqWithPath(`units/${unitObj._id}/config`, "get", "");
                const content = await response.json();
                setConfig(content);
            } catch (err) {
                console.error("Failed to load activities", err);
            }
        }
        load();
    }, [unitObj]);

    return (
        <FluidWrapper height="hg-normal">
            <textarea readOnly rows={15} value={config ? JSON.stringify(config, null, 4) : ""} />
        </FluidWrapper>
    );
};
export default UnitConfig;
