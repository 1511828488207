export const LOADING_UNITS = "LOADING_UNITS";

export const LOADING_DONE = "LOADING_DONE";

export const GET_ALL_UNITS = "GET_ALL_UNITS";

export const ADD_UNIT = "ADD_UNIT";

export const ADD_PARENT_UNIT = "ADD_PARENT_UNIT";

export const DELETE_UNIT = "DELETE_UNIT";

export const DELETE_CHILD_UNITS = "DELETE_CHILD_UNITS";

export const UPDATE_UNIT = "UPDATE_UNIT";

export const UPDATE_NEW_PARENT_UNIT = "UPDATE_NEW_PARENT_UNIT";

export const UPDATE_OLD_PARENT_UNIT = "UPDATE_OLD_PARENT_UNIT";

export const BUILD_UNIT_OBJECT = "BUILD_UNIT_OBJECT";

export const UNIT_ERROR = "UNIT_ERROR";
