/**
 * Very basic feature flag system that could down the line be made more sophisticated
 * Apart from enabling flags in code, it's also possible to enable features via
 * URL fragment. The fragment should be formatted similar to the query
 * parameter, with feature flags separated with comma (,), example:
 *
 *     https://admin.ommej.se/#features=prorenata,deleteInvitation&someother=something
 */

// Feature is always active, even in production
const productionFlags = {
    statisticsGroupInvitation: true,
    deleteInvitation: false,
};

// Feature is only active in dev
const devFlags = {};

export const isActiveFeature = (flag: keyof typeof productionFlags | keyof typeof devFlags) => {
    const hash = window.location.hash;
    if (hash) {
        // substring(1) to get passed the "#"
        const hashParts = hash.substring(1).split("&");
        for (const part of hashParts) {
            if (!part.startsWith("features=")) {
                continue;
            }
            const hashFeatureFlags = part.substring("features=".length).split(",");
            for (const hashFeatureFlag of hashFeatureFlags) {
                if (flag === hashFeatureFlag) {
                    return true;
                }
            }
        }
    }
    return (
        // Feature is always active, even in production
        productionFlags[flag] ||
        // Feature is only active in dev
        (devFlags[flag] && process.env.NODE_ENV === "development")
    );
};
