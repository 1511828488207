"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAvatar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const getAvatarPath_1 = require("../helpers/getAvatarPath");
const renderAvatarParts_1 = require("./renderAvatarParts");
const GetAvatar = ({ avatarData, title, downloadable = false }) => {
    // if you can't see your avatar (AvatarParts) you need to create CSS to
    // set a width and height since the avatar width is in percent.
    // The avatarParts is in a section so add this for example
    // .parentdiv > section { width: 10rem, height: 10rem }
    if (!title) {
        title = "avatar";
    }
    if (avatarData) {
        let path;
        if (typeof avatarData === "string") {
            path = (0, getAvatarPath_1.getAvatarPath)(avatarData);
        }
        else if (avatarData.type === "ID" && typeof avatarData.data === "string") {
            path = (0, getAvatarPath_1.getAvatarPath)(avatarData.data);
        }
        else if (avatarData.type === "BINARY" && typeof avatarData.data === "string") {
            path = avatarData.data;
        }
        if (path) {
            if (downloadable) {
                return ((0, jsx_runtime_1.jsx)("a", { href: path, download: "avatar.svg", children: (0, jsx_runtime_1.jsx)("img", { src: path, alt: title }) }));
            }
            return (0, jsx_runtime_1.jsx)("img", { src: path, alt: title });
        }
        if (typeof avatarData === "object" &&
            avatarData.type === "PARTS" &&
            Array.isArray(avatarData.data)) {
            return ((0, jsx_runtime_1.jsx)(renderAvatarParts_1.RenderAvatarParts, { downloadable: downloadable, title: title, avatarModel: avatarData.data }));
        }
    }
    return (0, jsx_runtime_1.jsx)("img", { src: (0, getAvatarPath_1.getAvatarPath)(undefined), alt: "F\u00F6rvald avatar" });
};
exports.GetAvatar = GetAvatar;
