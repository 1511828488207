import type { Beb2, Form, Invitation, ProfileHistoryEntry } from "@ommej/types";
import { useSelector } from "react-redux";
import type { AnswersHistory, ClientInfo, GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import "./report.css";
import { GetAvatar, MoodChartGraph } from "@ommej/componente";
import { houseStrings, personTypes } from "@ommej/metadata";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GhostButton from "~/src/components/tools/buttons/ghostButton/ghostButton";
import type { DropdownItemsObject } from "~/src/components/tools/dropdown/dropdown";
import Dropdown from "~/src/components/tools/dropdown/dropdown";
import { reqWithPath } from "~/src/utils/api";
import type { LanguageLocales } from "~/src/utils/ts-types";
import CaseFormCard, { type SentFormWithMetadata } from "../modals/components/cards/caseFormCard";
import AnswersViewer from "./answersViewer";
import { BEB_FILTERS } from "./beb";
import KasamChart from "./kasamChart";
import ReportChart from "./reportChart";
import StegenChart from "./stegenChart";
import { getQuestionTagImage, getSentForms } from "./utils";

type ReportProps = {
    client: ClientInfo;
    invitation: Invitation.ToClient;
    bebCurrent: Beb2;
    answersHistory: AnswersHistory;
};

function getColumnStyle(columns: number) {
    return {
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: "2rem",
    };
}

const Resources = ({
    bebs,
    client,
}: {
    bebs: Array<{ timestamp: string | "current"; data: Beb2 }>;
    client: ReportProps["client"];
}) => {
    return (
        <div style={{ ...getColumnStyle(bebs.length), marginBottom: "2rem" }}>
            {bebs.map((beb, column) => {
                return (
                    <div
                        key={`report-resource-${column}-${beb.timestamp}`}
                        className="conversation-section-wrapper"
                    >
                        <h3
                            style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                marginBottom: "2rem",
                            }}
                        >
                            <Link
                                relative="path"
                                to={`../beb?filter=${BEB_FILTERS.RESOURCES.value}`}
                                style={{ textDecoration: "none" }}
                            >
                                Identifierade resurser
                            </Link>
                        </h3>
                        <AnswersViewer
                            beb={beb.data}
                            filter={[beb.data.answerTags.RESOURCE]}
                            client={client}
                            uniqueness="report-qs-help"
                            columns={bebs.length > 1 ? 1 : 3}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const Alarms = ({
    bebs,
    client,
}: {
    bebs: Array<{ timestamp: string | "current"; data: Beb2 }>;
    client: ReportProps["client"];
}) => {
    return (
        <div style={{ ...getColumnStyle(bebs.length), marginBottom: "2rem" }}>
            {bebs.map((beb, column) => {
                return (
                    <div
                        key={`report-alarm-${column}-${beb.timestamp}`}
                        className="conversation-section-wrapper"
                    >
                        <h3
                            style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                marginBottom: "2rem",
                            }}
                        >
                            <Link
                                relative="path"
                                to={`../beb?filter=${BEB_FILTERS.ALARM.value}`}
                                style={{ textDecoration: "none" }}
                            >
                                Identifierade risker
                            </Link>
                        </h3>

                        {beb.data.answerTags.ALARM &&
                            Object.keys(beb.data.answerTags.ALARM).length > 0 && (
                                <AnswersViewer
                                    beb={beb.data}
                                    filter={[beb.data.answerTags.ALARM]}
                                    client={client}
                                    uniqueness="report-qs-alarm"
                                    columns={bebs.length > 1 ? 1 : 3}
                                />
                            )}

                        {beb.data.answerTags.HELP &&
                            Object.keys(beb.data.answerTags.HELP).length > 0 && (
                                <>
                                    <h4 className="report-risk-subheader">
                                        <Link
                                            relative="path"
                                            to={`../beb?filter=${BEB_FILTERS.HELP.value}`}
                                        >
                                            Barnet vill ha hjälp med
                                        </Link>
                                    </h4>
                                    <AnswersViewer
                                        beb={beb.data}
                                        filter={[beb.data.answerTags.HELP]}
                                        client={client}
                                        uniqueness="report-qs-help"
                                        showAnswers={false}
                                        color="#f8f6dd"
                                        columns={bebs.length > 1 ? 1 : 3}
                                    />
                                </>
                            )}

                        {beb.data.answerTags.WORRIES &&
                            Object.keys(beb.data.answerTags.WORRIES).length > 0 && (
                                <>
                                    <h4 className="report-risk-subheader">
                                        <Link
                                            relative="path"
                                            to={`../beb?filter=${BEB_FILTERS.WORRIES.value}`}
                                        >
                                            Barnet är oroligt för
                                        </Link>
                                    </h4>
                                    <AnswersViewer
                                        beb={beb.data}
                                        filter={[beb.data.answerTags.WORRIES]}
                                        client={client}
                                        uniqueness="report-qs-worries"
                                        showAnswers={false}
                                        color="#f8f6dd"
                                        columns={bebs.length > 1 ? 1 : 3}
                                    />
                                </>
                            )}
                    </div>
                );
            })}
        </div>
    );
};

const Chart = ({
    bebs,
    header,
}: {
    bebs: Array<{ timestamp: string | "current"; data: Beb2 }>;
    header: string;
}) => {
    return (
        <div style={getColumnStyle(bebs.length)}>
            {bebs.map((beb, column) => {
                return (
                    <div
                        className="conversation-section-wrapper"
                        key={`report-chart-${column}-${beb.timestamp}`}
                    >
                        <h2 style={{ marginBottom: "1.5rem" }}>{header}</h2>
                        <ReportChart beb={beb.data} />
                    </div>
                );
            })}
        </div>
    );
};

const Persons = ({
    client,
    header,
}: {
    client: ReportProps["client"];
    header: string;
}) => {
    if (!client.profile?.persons) {
        return null;
    }

    return (
        <div style={getColumnStyle(1)}>
            <div className="conversation-section-wrapper" key="report-persons">
                <h2 style={{ marginBottom: "1.5rem" }}>{header}</h2>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, auto)",
                    }}
                >
                    {Object.entries(client.profile.persons).map(([personId, personData]) => {
                        return (
                            <Link
                                relative="path"
                                to={`../beb#${personId}`}
                                key={`report.persons.${personId}`}
                                className="report-card-link"
                            >
                                <GetAvatar avatarData={personData.avatar} />
                                {personData.type
                                    ? personTypes[personData.type]?.lang.sv || "-"
                                    : "-"}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const Houses = ({
    bebs,
    client,
    locales,
}: {
    bebs: Array<{ timestamp: string | "current"; data: Beb2 }>;
    client: ReportProps["client"];
    locales: LanguageLocales;
}) => {
    const { COMMON } = language[locales];

    if (!client.profile?.houses) {
        return null;
    }

    return (
        <div style={getColumnStyle(bebs.length)}>
            {bebs.map((beb, column) => {
                if (!client.profile) {
                    return null;
                }

                let housePersonMap = client.profile.housePersonMap;
                if (client.profile.history?.housePersonMap && beb.timestamp !== "current") {
                    // find the last historical entry before the beb timestamp,
                    // i.e., we want to find the state of the profile at the
                    // time of answering
                    let dateBeforeBeb = new Date(0);
                    let lastEntryBeforeBeb: ProfileHistoryEntry | undefined = undefined;
                    const bebTimestamp = new Date(Number(beb.timestamp) * 1000);
                    for (const historyEntry of client.profile.history.housePersonMap) {
                        const historyEntryTimestamp = new Date(historyEntry.timestamp);
                        if (
                            historyEntryTimestamp < bebTimestamp &&
                            historyEntryTimestamp > dateBeforeBeb
                        ) {
                            lastEntryBeforeBeb = historyEntry;
                            dateBeforeBeb = historyEntryTimestamp;
                        }
                    }
                    if (lastEntryBeforeBeb) {
                        housePersonMap =
                            lastEntryBeforeBeb.value as typeof client.profile.housePersonMap;
                    }
                }

                return (
                    <div
                        className="conversation-section-wrapper"
                        key={`report-houses-${column}-${beb.timestamp}`}
                    >
                        <h2 style={{ marginBottom: "1.5rem" }}>{COMMON.LIVING_SITUATION}</h2>

                        {client.profile &&
                            Object.entries(client.profile.houses).map(([houseId, house], i) => {
                                if (!client.profile) {
                                    return null;
                                }
                                const personIds = housePersonMap[houseId];
                                const persons: string[] = [];
                                if (personIds) {
                                    for (const personId of personIds) {
                                        const person = client.profile.persons[personId];
                                        if (!person?.type || !personTypes[person.type]) {
                                            continue;
                                        }

                                        // biome-ignore lint/style/noNonNullAssertion: checked above
                                        persons.push(personTypes[person.type]!.lang[locales]);
                                    }
                                }
                                return (
                                    <Link
                                        relative="path"
                                        to={`../beb#${houseId}`}
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "4rem auto",
                                            gridTemplateRows: "4rem",
                                            alignItems: "center",
                                            justifyContent: "start",
                                        }}
                                        className="report-card-link"
                                        key={`report.houses.${houseId}`}
                                    >
                                        <div
                                            style={{
                                                background: "var(--color-indigo-ice)",
                                                borderRadius: "50%",
                                                boxSizing: "content-box",
                                                display: "grid",
                                                height: "2rem",
                                                padding: "0.5rem",
                                                placeItems: "center",
                                                width: "2rem",
                                            }}
                                        >
                                            <img
                                                src={`/assets/houses/${house.avatar}.svg`}
                                                alt={houseStrings[house.type].lang[locales]}
                                                style={{
                                                    width: "2rem",
                                                    height: "2rem",
                                                    objectFit: "contain",
                                                }}
                                                title={houseStrings[house.type].lang[locales]}
                                            />
                                        </div>
                                        <span>
                                            <h3
                                                style={{ margin: 0, fontWeight: "bold" }}
                                            >{`${COMMON.ACCOMMODATION} ${i + 1}`}</h3>
                                            <div className="text-grey">{persons?.join(", ")}</div>
                                        </span>
                                    </Link>
                                );
                            })}
                    </div>
                );
            })}
        </div>
    );
};

const FormRequests = ({
    invitation,
    locales,
    client,
}: {
    invitation: Invitation.ToClient;
    locales: LanguageLocales;
    client: ReportProps["client"];
}) => {
    const { CASE } = language[locales];
    const [sentForms, setSentForms] = useState<Array<SentFormWithMetadata>>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getSentForms(invitation).then((forms) => setSentForms(forms));
    }, [invitation]);

    if (sentForms.length === 0) {
        return null;
    }

    return (
        <div className="conversation-section-wrapper">
            <h2 style={{ marginBottom: "1.5rem" }}>{CASE.EDIT_INVITATION.FORMS.SUBHEADER}</h2>
            {sentForms.map((sentForm: SentFormWithMetadata) => {
                return (
                    <CaseFormCard
                        sentForm={sentForm}
                        key={sentForm.id}
                        onClick={() => {
                            navigate(`../beb#${sentForm.id}`, {
                                relative: "path",
                            });
                        }}
                        disabled={
                            !client.formsStat?.[sentForm.id] ||
                            client.formsStat[sentForm.id]?.nAnswers === 0
                        }
                        numberOfAnwers={client.formsStat?.[sentForm.id]?.nAnswers ?? 0}
                    />
                );
            })}
        </div>
    );
};

const Report = ({ client, invitation, bebCurrent, answersHistory }: ReportProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { CASE, COMMON, QUESTION_TAGS } = language[locales];
    const [bebs, setBebs] = useState<Array<{ timestamp: string | "current"; data: Beb2 }>>([
        { timestamp: "current", data: bebCurrent },
    ]);
    const [moodData, setMoodData] = useState();
    const [dropdownArray, setDropdownArray] = useState<DropdownItemsObject>({});

    const TAGS_TO_INCLUDE: Array<Form.QuestionTags> = [
        "MYSELF",
        "SCHOOL",
        "FAMILY",
        "LEISURE",
        "BASE",
        "OMMEJ",
    ];

    useEffect(() => {
        const getMoodData = async () => {
            try {
                const res = await reqWithPath(
                    `invitations/${invitation.id}/clients/${client.id}`,
                    "get",
                    "moods",
                );
                if (res.status === 200) {
                    const moods = await res.json();
                    setMoodData(moods);
                }
            } catch (error) {
                // Don't need to handle the error. If it's an error we don't
                // show the graph component
                console.error(error);
            }
        };
        getMoodData();
    }, []);

    useEffect(() => {
        const filterDropdown: DropdownItemsObject = {};
        if (answersHistory) {
            for (const timestamp of Object.keys(answersHistory)) {
                filterDropdown[timestamp] = {
                    value: timestamp,
                    text: new Date(Number(timestamp) * 1000).toLocaleDateString(),
                };
            }
            filterDropdown.current = {
                value: "current",
                text: "Senaste svaren",
            };
        }
        setDropdownArray(filterDropdown);
    }, []);

    const getClientBebById = async (clientId: string, snapshot: string, index) => {
        const response = await reqWithPath(
            "invitations",
            "get",
            `${invitation.id}/clients/${clientId}/beb2/${snapshot === "current" ? "" : snapshot}`,
        );
        const data = await response.json();
        const bebsTmp = [...bebs];
        bebsTmp[index] = { timestamp: snapshot, data: data.active };
        setBebs(bebsTmp);
    };

    function getHeaderDom() {
        return (
            <>
                <div className="tabBeb-header-wrapper print-hide" style={{ marginBottom: "2rem" }}>
                    <h2 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>{COMMON.OVERVIEW}</h2>
                    <div style={{ display: "flex", alignItems: "end" }}>
                        <GhostButton
                            text={
                                bebs.length !== 1
                                    ? language[locales].CASE.REPORT.CLOSE_COMPARE_VIEW
                                    : language[locales].CASE.REPORT.OPEN_COMPARE_VIEW
                            }
                            handleGhostButton={() => {
                                if (bebs.length === 1) {
                                    const bebsTmp = [...bebs];
                                    // start with both showing the current beb
                                    bebsTmp[1] = { timestamp: "current", data: bebCurrent };
                                    setBebs(bebsTmp);
                                } else {
                                    setBebs([{ timestamp: "current", data: bebCurrent }]);
                                }
                            }}
                        />

                        <GhostButton
                            customStyle={{ marginLeft: "1rem" }}
                            icon="print"
                            text={language[locales].TOOLS.PRINT}
                            handleGhostButton={() => {
                                window.print();
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${bebs.length}, auto)`,
                        placeItems: "center",
                        marginBottom: "2rem",
                    }}
                >
                    {client.profile &&
                        bebs.length > 1 &&
                        bebs.map((_, column) => {
                            return (
                                <div
                                    key={`report-dropdown-${
                                        // biome-ignore lint/suspicious/noArrayIndexKey: corresponds to column
                                        column
                                    }`}
                                >
                                    <Dropdown
                                        items={dropdownArray}
                                        handleChange={(item) => {
                                            getClientBebById(client.id, item, column);
                                        }}
                                    />
                                </div>
                            );
                        })}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="tabBeb-wrapper report-wrapper">
                {getHeaderDom()}

                <Resources bebs={bebs} client={client} />
                <Alarms bebs={bebs} client={client} />

                {bebs.length === 1 ? (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${bebs.length === 1 ? 2 : 1}, minmax(auto, 1fr))`,
                            gap: "2rem",
                            marginTop: "2rem",
                        }}
                    >
                        <Chart bebs={bebs} header={COMMON.RISK_OVERVIEW} />
                        <div className="conversation-section-wrapper">
                            <h2 style={{ marginBottom: "1.5rem" }}>
                                {CASE.REPORT.TAB_REPORT.MOOD_HEADER}
                            </h2>
                            {moodData && Object.keys(moodData).length > 0 ? (
                                <MoodChartGraph
                                    data={moodData}
                                    language={locales as LanguageLocales}
                                    dayInterval={30}
                                />
                            ) : (
                                <p style={{ margin: "2rem 0" }}>
                                    {CASE.REPORT.TAB_REPORT.MOOD_NO_MOOD}
                                </p>
                            )}
                        </div>
                    </div>
                ) : (
                    <Chart bebs={bebs} header={COMMON.RISK_OVERVIEW} />
                )}

                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${bebs.length === 1 ? 2 : 1}, minmax(auto, 1fr)`,
                        gap: "2rem",
                        marginTop: "2rem",
                        marginBottom: "2rem",
                    }}
                >
                    {bebs.length === 1 && <Persons client={client} header={COMMON.NETWORK} />}
                    <Houses bebs={bebs} client={client} locales={locales} />
                </div>

                {bebs.length === 1 && (
                    <div className="conversation-section-wrapper" style={{ marginBottom: "2rem" }}>
                        <h2 style={{ marginBottom: "1.5rem" }}>{COMMON.CATEGORY}</h2>
                        <div
                            style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-around",
                                textAlign: "center",
                            }}
                        >
                            {TAGS_TO_INCLUDE.map((tag) => {
                                return (
                                    <Link
                                        key={`report.tag.${tag}`}
                                        relative="path"
                                        to={`../beb#${tag}`}
                                        className="report-card-link report-tag-link"
                                    >
                                        {getQuestionTagImage(client, tag)}
                                        {QUESTION_TAGS[tag]}
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                )}

                {bebs.length === 1 && bebs[0] ? (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${bebs.length === 1 ? 2 : 1}, minmax(auto, 1fr)`,
                            gap: "2rem",
                            marginTop: "2rem",
                            marginBottom: "2rem",
                        }}
                    >
                        <div className="conversation-section-wrapper">
                            <h2 style={{ marginBottom: "1.5rem" }}>
                                {CASE.REPORT.TAB_REPORT.STEGEN}
                            </h2>
                            <StegenChart beb={bebs[0].data} />
                        </div>

                        <div className="conversation-section-wrapper">
                            <h2 style={{ marginBottom: "1.5rem" }}>
                                {CASE.REPORT.TAB_REPORT.KASAM}
                            </h2>
                            <KasamChart beb={bebs[0].data} gender={client.profile?.gender} />
                        </div>
                    </div>
                ) : (
                    <div style={getColumnStyle(bebs.length)}>
                        {bebs.map((beb, column) => {
                            return (
                                <>
                                    <div
                                        className="conversation-section-wrapper"
                                        key={`report.stegen-${column}-${beb.timestamp}`}
                                    >
                                        <h2 style={{ marginBottom: "1.5rem" }}>
                                            {CASE.REPORT.TAB_REPORT.STEGEN}
                                        </h2>
                                        <StegenChart beb={beb.data} />
                                    </div>
                                </>
                            );
                        })}
                        {bebs.map((beb, column) => {
                            return (
                                <>
                                    <div
                                        className="conversation-section-wrapper"
                                        key={`report.kasam-${column}-${beb.timestamp}`}
                                    >
                                        <h2 style={{ marginBottom: "1.5rem" }}>
                                            {CASE.REPORT.TAB_REPORT.KASAM}
                                        </h2>
                                        <KasamChart
                                            beb={beb.data}
                                            gender={client.profile?.gender}
                                        />
                                    </div>
                                </>
                            );
                        })}
                    </div>
                )}

                {bebs.length === 1 && (
                    <FormRequests invitation={invitation} client={client} locales={locales} />
                )}
            </div>
        </>
    );
};

export default Report;
