import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import BigButton from "~/src/components/tools/buttons/bigButton/bigButton";
import GhostButton from "~/src/components/tools/buttons/ghostButton/ghostButton";
import SearchInput from "~/src/components/tools/form/search/searchInput";
import Tooltip from "~/src/components/tools/tooltip/tooltip";
import close from "~/src/media/images/svg/close.svg";
import { QuestionMarkIcon } from "~/src/utils/icons";
import language from "~/src/utils/language/index";
import "./pickerPopup.css";

const ListItem = ({ id, text, setSelectedItem, selected }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [shouldShowButton, setShouldShowButton] = useState(false);

    function showButton() {
        setShouldShowButton(true);
    }

    function hideButton() {
        setShouldShowButton(false);
    }

    return (
        <li
            onMouseEnter={showButton}
            onMouseLeave={hideButton}
            className={selected ? "pickerPopup-item pickerPopup-item-selected" : "pickerPopup-item"}
        >
            {text}
            {shouldShowButton && (
                <GhostButton
                    text={language[locales].TOOLS.SELECT}
                    handleGhostButton={() => {
                        setSelectedItem({ [id]: text });
                    }}
                />
            )}
        </li>
    );
};

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    setSelectedItem: PropTypes.func.isRequired,
    selected: PropTypes.bool,
};

ListItem.defaultProps = {
    selected: false,
};

const PickerPopup = ({
    header,
    items,
    setShow,
    setSelected,
    allowMultiple = false,
    helpText,
    addAllCategory = false,
}) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });

    // set the first filter as default
    const [currentFilter, setCurrentFilter] = useState(items.entries().next().value);
    const [searchValue, setSearchValue] = useState("");
    const [selectedItems, setSelectedItems] = useState({});
    const eFilters = useRef();
    const initial = useRef(true);
    const allKey = useRef();

    // create an "all" filter if requested
    if (initial.current && addAllCategory) {
        const allItems = {};
        allKey.current = {
            text: language[locales].TOOLS.FILTER_ALL,
            id: "all123id",
        };
        let alreadyHaveAll = false;
        for (const [key, item] of items) {
            if (key.id === allKey.current.id) {
                alreadyHaveAll = true;
                break;
            }
            // get all values related to the category
            if (key.values) {
                for (const [id, text] of Object.entries(key.values)) {
                    allItems[id] = text;
                }
            }
            // get all values in the category
            for (const [id, text] of Object.entries(item)) {
                allItems[id] = text;
            }
        }
        // don't add a new 'all' filter if it already exist
        if (!alreadyHaveAll) {
            items.set(allKey.current, allItems);
        }
    }

    useEffect(() => {
        // don't want to change currentTypes on first render
        if (initial.current) {
            initial.current = false;
            return;
        }

        const filtered = {};
        for (const val of items) {
            for (const [id, text] of Object.entries(val).filter(([, text]) => {
                return text.toLowerCase().includes(searchValue);
            })) {
                filtered[id] = text;
            }
        }

        setCurrentFilter([allKey.current, filtered]);
    }, [searchValue]);

    function handleCancel() {
        setShow(false);
    }

    function handleSave() {
        setSelected(selectedItems);
        setShow(false);
    }

    function setFilter(event) {
        const filterId = event.target.dataset.filter;
        const itr = items.keys();
        let item = itr.next().value;
        let currentKey;
        while (item) {
            if (item.id === filterId) {
                currentKey = item;
                break;
            }
            item = itr.next().value;
        }

        if (!currentKey || !items.has(currentKey)) {
            return;
        }

        setCurrentFilter([currentKey, items.get(currentKey)]);
    }

    function updateValues(newItem) {
        if (allowMultiple) {
            // values from the currently selected filter
            const filterTags = currentFilter[0]?.values;
            // combine filter values, the currently selected items and the newly
            // selected item
            const selected = { ...filterTags, ...selectedItems, ...newItem };
            setSelectedItems(selected);
        } else {
            setSelectedItems(newItem);
        }
    }

    function handleRemove(event) {
        const itemId = event.currentTarget.dataset.id;
        const newSelected = { ...selectedItems };
        delete newSelected[itemId];
        setSelectedItems(newSelected);
    }

    return (
        <div id="pickerPopup">
            <section id="pickerPopup-content">
                <button id="pickerPopup-close-button" type="button" onClick={handleCancel}>
                    <img src={close} alt="close modal" />
                </button>
                <div id="pickerPopup-header">
                    <h2>{header}</h2>
                    {helpText && (
                        <Tooltip title={helpText} position="top">
                            <button type="button" aria-label="hjälp" id="pickerPopup-question">
                                <QuestionMarkIcon />
                            </button>
                        </Tooltip>
                    )}
                </div>
                <SearchInput
                    name="search"
                    placeholder={language[locales].TOOLS.SEARCH}
                    value={searchValue}
                    handleChange={(e) => {
                        setSearchValue(e.target.value.toLowerCase());
                    }}
                />
                <div id="pickerPopup-inner">
                    <div ref={eFilters} id="pickerPopup-wrapper-filter">
                        <h4>{language[locales].TOOLS.FILTER}</h4>
                        {Array.from(items.entries()).map(([filter]) => {
                            return (
                                <button
                                    className={
                                        currentFilter?.[0]?.id === filter.id
                                            ? "pickerPopup-button-filter pickerPopup-button-filter-selected"
                                            : "pickerPopup-button-filter"
                                    }
                                    type="button"
                                    data-filter={filter.id}
                                    key={filter.id}
                                    id={filter.id}
                                    onClick={setFilter}
                                >
                                    {filter.text}
                                </button>
                            );
                        })}
                    </div>
                    <div id="pickerPopup-wrapper-items">
                        <h4>{language[locales].TOOLS.SELECT}</h4>
                        <ul id="pickerPopup-items">
                            {Object.entries(currentFilter[1]).map(([id, text]) => {
                                if (selectedItems?.[id]) {
                                    return (
                                        <ListItem
                                            selected
                                            key={id}
                                            id={id}
                                            text={text}
                                            setSelectedItem={(item) => {
                                                updateValues(item);
                                            }}
                                        />
                                    );
                                }
                                return (
                                    <ListItem
                                        key={id}
                                        id={id}
                                        text={text}
                                        setSelectedItem={(item) => {
                                            updateValues(item);
                                        }}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div id="pickerPopup-footer">
                    <div id="pickerPopup-footer-selected">
                        {Object.keys(selectedItems).length !== 0 && (
                            <h4>{language[locales].TOOLS.SELECTED}</h4>
                        )}
                        {Object.entries(selectedItems).map(([id, text]) => {
                            return (
                                <div key={id} className="pickerPopup-footer-selected-item">
                                    {text}
                                    <button
                                        data-id={id}
                                        className="pickerPopup-footer-selected-item-remove"
                                        type="button"
                                        onClick={handleRemove}
                                    >
                                        <img src={close} alt="close modal" />
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                    <div id="pickerPopup-button-done">
                        <BigButton
                            text={language[locales].CASE.HANDLE_INVITATION.FINISHED_BUTTON}
                            handleButton={() => {
                                handleSave();
                            }}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

PickerPopup.propTypes = {
    header: PropTypes.string.isRequired,
    items: PropTypes.instanceOf(Map).isRequired,
    setShow: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    allowMultiple: PropTypes.bool,
    helpText: PropTypes.string,
    addAllCategory: PropTypes.bool,
};

PickerPopup.defaultProps = {
    allowMultiple: false,
    helpText: undefined,
    addAllCategory: false,
};

export default PickerPopup;
