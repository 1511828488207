import {
    ArrowDownIcon,
    PencilIcon,
    PlusIcon,
    PlusIndigoIcon,
    RefreshIcon,
} from "~/src/utils/icons";
import "./normalButton.css";

export default function NormalButton({
    text,
    icon,
    color = "blue",
    handleButton,
    disabled = false,
    className,
    customStyle,
    type = "button",
}: {
    text: string;
    icon?: string;
    color?: string;
    disabled?: boolean;
    className?: string;
    handleButton?: (e: React.MouseEvent<HTMLElement> | React.FormEvent) => void;
    customStyle?: React.CSSProperties;
    type?: "button" | "submit" | "reset";
}) {
    const ICONS = {
        pencil: <PencilIcon />,
        plus: <PlusIcon />,
        plusIndigo: <PlusIndigoIcon />,
        refresh: <RefreshIcon />,
        arrowDown: <ArrowDownIcon />,
    };
    return (
        <button
            type={type}
            disabled={disabled}
            className={`normalButton-wrapper normalButton-${color} ${
                icon && "normalButton-icon"
            } ${className} `}
            style={customStyle}
            onClick={handleButton}
            onSubmit={(e) => {
                if (handleButton) {
                    handleButton(e);
                }
            }}
        >
            {icon && ICONS[icon]}
            <p className="text-description normalButton-text">{text}</p>
        </button>
    );
}
