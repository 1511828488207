import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import type { PropsWithChildren, TransitionEventHandler } from "react";
import "./accordion.css";

export type AccordionProps = PropsWithChildren & {
    accordionActive: boolean;
    onTransitionEnd?: TransitionEventHandler<HTMLDivElement>;
};

const Accordion = ({ accordionActive, onTransitionEnd, children }: AccordionProps) => {
    const [heightState, setHeightState] = useState(0);
    const accordion = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (accordion?.current) {
            setHeightState(accordionActive ? accordion.current.scrollHeight : 0);
        }
    }, [accordionActive, children]);

    const activeClass = accordionActive ? "accordion-active" : "";

    return (
        <section className={`accordion-section ${activeClass}`}>
            <div
                onTransitionEnd={onTransitionEnd}
                className="accordion-content"
                ref={accordion}
                style={{ maxHeight: `${heightState}px` }}
            >
                {children}
            </div>
        </section>
    );
};

Accordion.propTypes = {
    accordionActive: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

export default Accordion;
