import { reqWithPath, reqWithPathAndPayload, reqWithPayload } from "../../../utils/api";
import { dispatchError } from "../actionHelpers";
import {
    ADD_INVITATION,
    BUILD_INVITATION_OBJECT,
    CLOSE_INVITATION,
    DELETE_INVITATION,
    GET_ALL_INVITATIONS,
    INVITATION_ERROR,
    LOADING_INVITATION,
    UPDATE_INVITATION,
} from "./type";

export const getAllInvitations = () => {
    return async (dispatch) => {
        await dispatch({ type: LOADING_INVITATION });
        const response = await reqWithPath("invitations", "get", "");
        const invitations = await response.json();
        await dispatch({
            type: GET_ALL_INVITATIONS,
            payload: invitations,
        });
        await dispatch({
            type: BUILD_INVITATION_OBJECT,
        });
        return invitations;
    };
};

export const addInvitation = (newInvitation) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_INVITATION });
        try {
            const response = await reqWithPayload("invitations", "post", newInvitation);
            if (response.ok) {
                const invitation = await response.json();
                await dispatch({
                    type: ADD_INVITATION,
                    payload: invitation,
                });
                await dispatch({
                    type: BUILD_INVITATION_OBJECT,
                });
                return invitation;
            }
            throw response;
        } catch (error) {
            dispatchError(error, dispatch, INVITATION_ERROR);
            return undefined;
        }
    };
};

export const deleteInvitation = (invitationId) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_INVITATION });
        try {
            const response = await reqWithPath("invitations", "delete", invitationId);
            if (response.ok) {
                const id = await response.json();
                await dispatch({
                    type: DELETE_INVITATION,
                    payload: id,
                });
                await dispatch({
                    type: BUILD_INVITATION_OBJECT,
                });
            } else {
                throw response;
            }
        } catch (error) {
            dispatchError(error, dispatch, INVITATION_ERROR);
            throw error;
        }
    };
};

export const setStatusInvitation = (invitationId, status) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_INVITATION });
        try {
            const response = await reqWithPathAndPayload(
                "invitations",
                "put",
                `${invitationId}/status`,
                status,
            );
            if (response.ok) {
                const invitation = await response.json();
                await dispatch({
                    type: UPDATE_INVITATION,
                    payload: invitation,
                });
                await dispatch({
                    type: BUILD_INVITATION_OBJECT,
                });
            } else {
                throw response;
            }
        } catch (error) {
            dispatchError(error, dispatch, INVITATION_ERROR);
            throw error;
        }
    };
};

export const closeInvitation = (invitationId) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_INVITATION });
        try {
            const response = await reqWithPath(
                "invitations",
                "put",
                `${invitationId}/status/close`,
            );
            if (response.ok) {
                await dispatch({
                    type: CLOSE_INVITATION,
                    payload: invitationId,
                });
                await dispatch({
                    type: BUILD_INVITATION_OBJECT,
                });
            } else {
                throw response;
            }
        } catch (error) {
            dispatchError(error, dispatch, INVITATION_ERROR);
            throw error;
        }
    };
};

export const updateInvitation = (newInvitation) => {
    return async (dispatch) => {
        const { id, ...rest } = newInvitation;
        dispatch({ type: LOADING_INVITATION });
        try {
            const response = await reqWithPathAndPayload("invitations", "put", id, rest);
            if (response.ok) {
                const invitation = await response.json();
                dispatch({
                    type: UPDATE_INVITATION,
                    payload: invitation,
                });
                await dispatch({
                    type: BUILD_INVITATION_OBJECT,
                });
                return invitation;
            }
            throw response;
        } catch (error) {
            dispatchError(error, dispatch, INVITATION_ERROR);
            throw error;
        }
    };
};
