import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UnitTags from "~/src/components/admin/units/modals/components/unitTags";
import Types from "~/src/utils/types";
import { addUnit } from "../../../../../../redux/actions/units/actions";
import language from "../../../../../../utils/language";
import BigButton from "../../../../../tools/buttons/bigButton/bigButton";
import Modal from "../../../../../tools/modal/modal";
import UnitAccess from "../../components/unitAccess";
import UnitCreate from "../../components/unitCreate";
import "./tabAddUnit.css";

const TabAddUnit = ({ setUnitObj, unitObj, show, setShow, setTab, role }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();

    const sendUnit = () => {
        if (unitObj) {
            if (unitObj.name.length > 2 && unitObj.administrators.length > 0) {
                dispatch(addUnit(unitObj));
                setShow(!show);
            } else {
                setErrorMessage("Stämmer inte");
            }
        }
    };

    return (
        <>
            <Modal.ModalBody>
                <main className="tabAddUnit-body-wrapper">
                    <div className="tabAddUnit-column">
                        <UnitCreate unitObj={unitObj} setUnitObj={setUnitObj} />
                        <UnitTags unitObj={unitObj} setUnitObj={setUnitObj} />
                    </div>
                    <div className="tabAddUnit-column">
                        <UnitAccess
                            unitObj={unitObj}
                            setUnitObj={setUnitObj}
                            setTab={setTab}
                            role={role}
                        />
                    </div>
                </main>
                <p className="text-error">{errorMessage}</p>
            </Modal.ModalBody>
            <Modal.ModalFooter>
                <BigButton
                    text={language[locales].ADMIN.UNIT.ADD_UNIT.CREATE_BUTTON}
                    handleButton={sendUnit}
                    disabled={unitObj.name.length < 2 || unitObj.administrators.length === 0}
                />
            </Modal.ModalFooter>
        </>
    );
};

TabAddUnit.propTypes = {
    role: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    setUnitObj: PropTypes.func.isRequired,
    unitObj: PropTypes.shape(Types.unit).isRequired,
};

export default TabAddUnit;
