import PropTypes from "prop-types";
import "./radioButton.css";

const RadioButton = ({ label, id, value, checked, handleRadio }) => {
    return (
        <label className="radioButton-wrapper" htmlFor={id}>
            {label}
            <input
                id={id}
                type="radio"
                checked={checked}
                name="radio"
                value={value}
                onChange={handleRadio}
            />
            <span className="radioButton-custom" />
        </label>
    );
};

RadioButton.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    handleRadio: PropTypes.func.isRequired,
};

export default RadioButton;
