// @ts-check

import PropTypes from "prop-types";
import "./pageHeader.css";

/**
 * @typedef PageHeaderProps
 * @property {import('react').ReactNode|undefined} [children]
 * @property {string} heading
 * @property {string|undefined} [subheading]
 */

/** @type {import('react').FunctionComponent<PageHeaderProps>} */
const PageHeader = ({ children, heading, subheading = "" }) => {
    return (
        <div className="page-header">
            <h3>
                {heading}
                {children && " "}
                {children}
            </h3>
            {subheading && <div className="page-header__subheading">{subheading}</div>}
        </div>
    );
};

PageHeader.defaultProps = {
    children: undefined,
    subheading: "",
};

PageHeader.propTypes = {
    children: PropTypes.node,
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
};

export default PageHeader;
