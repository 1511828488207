import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { addUser } from "../../../../../../redux/actions/users/actions";
import language from "../../../../../../utils/language";
import BigButton from "../../../../../tools/buttons/bigButton/bigButton";
import FluidWrapper from "../../../../../tools/fluidWrapper/fluidWrapper";
import RadioButton from "../../../../../tools/form/radio/radioButton";
import Textfield from "../../../../../tools/form/textfield/textfield";
import "./tabAddUser.css";

const TabAddUser = ({ setTab, unitObj }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const [userObj, setUserObj] = useState({
        username: "",
        email: "",
        password: "",
        role: "user",
        unit: unitObj._id,
    });

    const handleChange = (e) => {
        setUserObj({ ...userObj, [e.target.name]: e.target.value });
    };

    const handleRadio = (e) => {
        setUserObj({ ...userObj, role: e.target.value });
    };

    const sendUser = () => {
        if (userObj) {
            if (userObj.username.length > 2 && userObj.password.length >= 5 && userObj.unit) {
                dispatch(addUser(userObj));
            }
            setTab();
        }
    };

    return (
        <div className="tabAddUser-main-wrapper">
            <FluidWrapper customStyle={{ width: "31rem", marginTop: "3rem" }}>
                <h3>{language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_HEADING}</h3>
                <div className="tabAddUser-input-wrapper">
                    <Textfield
                        text={language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_NAME_INPUT}
                        type="text"
                        name="username"
                        value={userObj.username}
                        handleChange={handleChange}
                        customStyle={{ width: "18rem" }}
                    />
                    <Textfield
                        text={language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_EMAIL_INPUT}
                        type="email"
                        name="email"
                        value={userObj.email}
                        handleChange={handleChange}
                        customStyle={{ width: "18rem" }}
                    />
                    <Textfield
                        text={language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_PASSWORD_INPUT}
                        type="password"
                        name="password"
                        value={userObj.password}
                        handleChange={handleChange}
                        customStyle={{ width: "18rem" }}
                    />
                </div>
            </FluidWrapper>
            <FluidWrapper height="hg-small" customStyle={{ width: "31rem" }}>
                <h3>{language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_COMPETENCE}</h3>
                <div className="tabAddUser-input-wrapper">
                    <RadioButton
                        id="1"
                        label={language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_RADIO1}
                        value="user"
                        checked={userObj.role === "user"}
                        handleRadio={(e) => {
                            return handleRadio(e);
                        }}
                    >
                        {language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_RADIO1}
                    </RadioButton>
                    <RadioButton
                        id="2"
                        label={language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_RADIO2}
                        value="administrator"
                        checked={userObj.role === "administrator"}
                        handleRadio={(e) => {
                            return handleRadio(e);
                        }}
                    >
                        {language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_RADIO2}
                    </RadioButton>
                </div>
            </FluidWrapper>
            <footer className="tabAddUser-footer-wrapper">
                <BigButton
                    text={language[locales].ADMIN.UNIT.ADD_UNIT.TAB_USER_BUTTON}
                    handleButton={sendUser}
                />
            </footer>
        </div>
    );
};

TabAddUser.propTypes = {
    setTab: PropTypes.func.isRequired,
    unitObj: PropTypes.shape(Types.unit).isRequired,
};

export default TabAddUser;
