import type { User } from "@ommej/types";
import type { UserWithId } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import language from "./utils/language/index";
import "./guide.css";
import type { UserGuideId } from "@ommej/types/src/userTypes";
import type { DriveStep } from "driver.js";

const GUIDES: Record<UserGuideId, Array<DriveStep>> = {
    ONBOARDING: [
        {
            element: ".guide-onboarding-home",
            popover: {
                title: "Välkommen!",
                description:
                    'Detta är din <span class="text-bold">överblickssida</span>. När du kommit igång med dina ärenden kan du se barnens aktivitet, anmäla dig till kommande evenemang och annat.',
            },
        },
        {
            element: ".guide-onboarding-cases",
            popover: {
                title: "Ärendelistan",
                description:
                    "Här samlas alla ärenden som skapats av dig eller dina medarbetare. Här kan du även skapa nya ärenden.",
            },
        },
        {
            element: ".guide-onboarding-admin",
            popover: {
                title: "Är du en administratör",
                description:
                    "Isåfall är det här du kan hantera dina enheter och skapa konton åt dina medarbetare.",
            },
        },
        {
            element: ".guide-onboarding-statistics",
            popover: {
                title: "Se statistik",
                description:
                    "Här hittar du statistik utifrån vad barnen har svarat i Ommej-appen, både på vanliga frågor och era uppföljningsformulär.",
            },
        },
        {
            element: ".guide-onboarding-help",
            popover: {
                title: "Behöver du mer hjälp?",
                description:
                    "På vår hjälpsida hittar du svar på alla dina frågor och kan även kontakta support.",
            },
        },
        {
            popover: {
                title: "Ska vi köra igång?! 🚀 ",
                description:
                    "Nu är du redo att bjuda in barn till appen, tryck för att komma igång!",
            },
        },
    ],
    CASE_CONVERSATION_TAB: [
        {
            element: ".guide-case_conversation_tab-tab",
            popover: {
                title: "Samtalsunderlag",
                description:
                    "Vi har lagt till ett nytt sätt att se information om barnet. Den här tabben passar bra att använda i ett samtal med barnet.",
            },
        },
    ],
    CASE_CONVERSATION_VIEW: [
        {
            element: ".guide-case_conversation_view-welcome",
            popover: {
                title: "Välkommen",
                description:
                    "Här skriver vi lite om hur du kan använda innehållet i den här fliken.",
            },
        },
        {
            element: ".guide-case_conversation_view-talk",
            popover: {
                title: "Samtalsförslag",
                description:
                    "Texter markerade med en samtalsbubbla är förslag på saker att ta upp med barnet.",
            },
        },
        {
            element: ".guide-case_conversation_view-intro",
            popover: {
                title: "Inledning",
                description:
                    "Här kan du påbörja ditt samtal med barnet. Barnet kan vara med och se skärmen, och använda symbolerna istället för att prata ifall hen föredrar det.",
            },
        },
        {
            element: ".guide-case_conversation_view-resource",
            popover: {
                title: "Bra saker i ditt liv",
                description:
                    "Här listar vi de resursfaktorer som barnet själv har angett i sitt liv. ",
            },
        },
        {
            element: ".guide-case_conversation_view-accommodations",
            popover: {
                title: "Boenden",
                description:
                    "Här ser du vilka boenden barnet har angett i appen och vilka som bor där.",
            },
        },
        {
            element: ".guide-case_conversation_view-resource-important",
            popover: {
                title: "Viktiga saker",
                description:
                    "Här samlar vi alla de saker som barnet angett att hen vill ha hjälp med och som oroar hen.",
            },
        },
        {
            element: ".guide-case_conversation_view-stegen",
            popover: {
                title: "Mitt liv just nu",
                description:
                    "Här visar vi hur barnet svarat på frågor kring livets stege, och några samtalspunkter kring detta.",
            },
        },
        {
            element: ".guide-case_conversation_view-accommodations-answers",
            popover: {
                title: "Boenden och dess personer",
                description:
                    "Här listas alla svar barnet angett kopplat till deras boende och de som bor där.",
            },
        },
        {
            element: ".guide-case_conversation_view-answers",
            popover: {
                title: "Utvalda svar per kategori",
                description:
                    "Här visas sedan alla saker som barnet angett som resurser, vill ha hjälp med, oroar sig för samt svar som vi vill uppmärksamma, uppdelat i ämneskategorier.",
            },
        },
        {
            element: ".guide-case_conversation_view-end",
            popover: {
                title: "Avslut",
                description: "Här är några punkter att avsluta samtalet med.",
            },
        },
        {
            element: ".guide-case_conversation_view-forms",
            popover: {
                title: "Uppföljning",
                description:
                    "Om du vill skicka ut ett uppföljningsformulär kring det här mötet, eller en annan uppsättning frågor, kan du göra det här. ",
            },
        },
    ],
    CASE_BEB_VIEW: [
        {
            popover: {
                title: "Alla svar",
                description:
                    "I denna vy hittar du alla barnets svar uppdelade på personer, boenden och kategorier. Även svar på eventuella uppföljningsfrågor redovisas här. Du kan söka och filtrera för att hitta just det du är intresserad av.",
                side: "top",
            },
        },
        {
            element: ".guide-case_beb_view-category .conversation-section-wrapper",
            popover: {
                title: "Svar",
                description:
                    "Du kan se alla svar i en viss sektion genom att trycka på +. Svaren är även uppdelade inom sektionen, exempelvis visas resurser (färgade gröna), risker (röda), vad barnet vill ha hjälp med, osv.",
                side: "top",
            },
            onHighlightStarted(element) {
                const eFirstDetail = element?.querySelector("details");
                if (eFirstDetail) {
                    eFirstDetail.open = true;
                }
            },
            onDeselected(element) {
                const eFirstDetail = element?.querySelector("details");
                if (eFirstDetail) {
                    eFirstDetail.open = false;
                }
            },
        },
        {
            element: "#beb-search",
            popover: {
                title: "Sök",
                description:
                    "Här kan du söka efter frågor, prova att skriva något ord från frågan du vill hitta!",
                side: "top",
            },
        },
        {
            element: ".guide-case_beb_view-filter_wrapper",
            popover: {
                title: "Filter",
                description:
                    "Här finns möjlighet för dig att själv filtrera ut vad du vill se. Exempelvis kanske du bara vill se frågor som har en identifierad risk som svar eller så kanske du vill se hur barnets svar såg ut en tid tillbaka.",
                side: "top",
            },
            onHighlightStarted(element: HTMLDetailsElement) {
                if (element) {
                    element.open = true;
                }
            },
            onDeselected(element: HTMLDetailsElement) {
                if (element) {
                    element.open = false;
                }
            },
        },
    ],
};

async function onDone(user: UserWithId, guide: User.UserGuideId, onGuideDone: () => void) {
    try {
        await reqWithPath("users", "post", `${user._id}/guides/${guide}/completed`);
    } catch (_e) {
        console.error(`User guide ${guide} already completed`);
    } finally {
        onGuideDone();
    }
}

export async function startGuide(
    user: UserWithId,
    guide: User.UserGuideId,
    locales: string,
    onGuideDone: () => void,
) {
    const forceRun = window.location.search.includes("forceGuides");
    if (Object.keys(user?.guides ?? {}).includes(guide) && !forceRun) {
        return;
    }

    const { driver } = await import("driver.js");

    const { COMMON } = language[locales];

    const driverObj = driver({
        showProgress: true,
        nextBtnText: COMMON.NEXT,
        prevBtnText: COMMON.PREVIOUS,
        doneBtnText: COMMON.DONE,
        onDestroyed: () => {
            onDone(user, guide, onGuideDone);
        },
        progressText: `{{current}} ${COMMON.OF} {{total}}`,
        steps: GUIDES[guide],
    });
    driverObj.drive();
}
