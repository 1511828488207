import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BreadCrumbs from "~/src/components/tools/breadCrumbs";
import SquareWrapper from "~/src/components/tools/squareWrapper/squareWrapper";
import IconStatsForms from "~/src/media/images/svg/stats-forms.svg";
import IconStatsOmmej from "~/src/media/images/svg/stats-ommej.svg";
import type { GlobalState } from "~/src/types";
import { route } from "~/src/utils/constants";
import language from "~/src/utils/language";
import "./statistics.css";

export const Statistics = () => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });

    const labels = language[locales].STATISTICS;

    return (
        <div className="app-component-container">
            <BreadCrumbs />
            <SquareWrapper>
                <Link className="big-link-card" to={`${route.STATISTICS}/questions`}>
                    <div style={{ backgroundColor: "#fad7a3" }}>
                        <img src={IconStatsOmmej} alt={labels.STATS_OMMEJ} />
                    </div>
                    <h2>{labels.STATS_OMMEJ}</h2>
                </Link>
            </SquareWrapper>
            <SquareWrapper>
                <Link className="big-link-card" to={`${route.STATISTICS}/forms`}>
                    <div style={{ backgroundColor: "#dfe1ec" }}>
                        <img src={IconStatsForms} alt={labels.STATS_FORMS} />
                    </div>
                    <h2>{labels.STATS_FORMS}</h2>
                </Link>
            </SquareWrapper>
        </div>
    );
};
