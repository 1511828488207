import type { Reducer } from "redux";
import type { LanguageLocales } from "~/src/utils/ts-types";

const LOADING_LANGUAGE = "LOADING_LANGUAGE";
const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
const LANGUAGE_ERROR = "LANGUAGE_ERROR";

type LanguageState = {
    locales: LanguageLocales;
    loading?: boolean;
    error?: unknown;
};

const initialState: LanguageState = {
    locales: "sv",
};

const languageReducer: Reducer<LanguageState> = (currentState, action) => {
    const state = currentState || initialState;
    switch (action.type) {
        case LOADING_LANGUAGE:
            return {
                ...state,
                loading: true,
            };
        case CHANGE_LANGUAGE:
            return {
                ...state,
                locales: action.payload as LanguageLocales,
                loading: false,
            };
        case LANGUAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default languageReducer;
