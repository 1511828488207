import { useState } from "react";
import type * as React from "react";
import { NavLink, useParams } from "react-router-dom";
import "./tabs.css";

export type TabsObject = { tabString: string; route: string; class?: string };

type TabsProps = {
    tabs: TabsObject[];
    children: React.ReactNode;
};

const Tabs = ({ tabs, children }: TabsProps) => {
    // activetab holds order to a11y attributes in child <div>
    const [activeTab, setActiveTab] = useState(0);
    const params = useParams();

    return (
        <>
            <ul className="tabs-wrapper" role="tablist" aria-labelledby="tablist">
                {tabs.map((tab, index) => {
                    return (
                        <li key={tab.tabString}>
                            <NavLink
                                id={`tab-${index + 1}`}
                                role="tab"
                                aria-selected={
                                    params.route ? tab.route.includes(params.route) : false
                                }
                                aria-controls={`tabpanels-${index + 1}`}
                                to={tab.route}
                                onClick={() => {
                                    setActiveTab(index + 1);
                                }}
                                className={({ isActive }) => {
                                    return `tabs-button tabs-button-link ${isActive ? "tabs-active" : ""} ${tab.class || ""}`;
                                }}
                            >
                                {tab.tabString}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
            <div
                id={`tabpanel-${activeTab}`}
                role="tabpanel"
                aria-labelledby={`tab-${activeTab}`}
                style={{ width: "100%" }}
            >
                {children}
            </div>
        </>
    );
};

export default Tabs;
