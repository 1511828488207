import { GetAvatar } from "@ommej/componente";
import { houseStrings, personTypes } from "@ommej/metadata";
import type { Beb2, Uuid } from "@ommej/types";
import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { ClientInfo, GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import AnswersViewer from "./answersViewer";
import "./conversation.css";
import "./questionSection.css";

const ConversationAccommodationAnswers = ({
    beb,
    client,
    className,
}: {
    beb: Beb2;
    client: ClientInfo;
    className: string;
}) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { COMMON } = language[locales];
    const [personsNotInHouses, setPersonsNotInHouses] = useState<Uuid[]>([]);

    useEffect(() => {
        if (!client.profile) {
            return;
        }

        const allPersonIds = new Set(Object.keys(client.profile.persons));
        const personsInHouses = new Set(Object.values(client.profile.housePersonMap).flat());
        const personsNotInHousesTmp = new Set<string>();
        for (const personId of allPersonIds) {
            if (!personsInHouses.has(personId)) {
                personsNotInHousesTmp.add(personId);
            }
        }
        setPersonsNotInHouses(Array.from(personsNotInHousesTmp));
    }, [client]);

    const getAccommodationQuestions = useCallback(
        (houseId: string) => {
            const houseMentions = beb.houses[houseId];

            if (!houseMentions) {
                return null;
            }

            return (
                <AnswersViewer
                    uniqueness={`conversationAnswers-${houseId}`}
                    beb={beb}
                    filter={[houseMentions]}
                    client={client}
                    showAnswers={false}
                />
            );
        },
        [beb],
    );

    const getPersons = useCallback(
        (houseId: string | undefined) => {
            if (!client.profile || (houseId && !client.profile.housePersonMap[houseId])) {
                return null;
            }

            const dom: ReactNode[] = [];

            let personIds: Uuid[] = [];
            if (houseId && client.profile.housePersonMap[houseId]) {
                // type assertion: checked above
                personIds = client.profile.housePersonMap[houseId] as Uuid[];
            } else {
                personIds = personsNotInHouses;
            }

            // non-null assertion: checked above
            for (const personId of personIds) {
                const person = client.profile.persons[personId];

                if (!person) {
                    continue;
                }

                const personMentions = beb.persons[personId];
                if (!personMentions) {
                    return null;
                }

                const n = Object.keys(personMentions).length;

                const personNode = (
                    <div className="conversationAnswers-accordion">
                        <GetAvatar avatarData={person.avatar} />
                        <span>
                            <h3 style={{ fontWeight: "bold" }}>
                                {person.type && personTypes[person.type]?.lang[locales]}
                            </h3>
                            <span className="text-grey">{`${n} ${COMMON.ANSWERS.toLowerCase()}`}</span>
                        </span>
                    </div>
                );

                dom.push(
                    <div key={`${houseId}-${personId}`} style={{ marginBottom: "2rem" }}>
                        {personNode}
                    </div>,
                );
                dom.push(
                    <section
                        key={`accommodationAnswer-${houseId}-${personId}`}
                        style={{ marginBottom: "1.5rem" }}
                    >
                        <AnswersViewer
                            uniqueness="accommodationAnswer"
                            beb={beb}
                            filter={[personMentions]}
                            client={client}
                            showAnswers={false}
                        />
                    </section>,
                );
            }
            return dom;
        },
        [client, beb, personsNotInHouses],
    );

    const getPersonStrings = useCallback(
        (houseId: string | undefined) => {
            if (!client.profile || (houseId && !client.profile.housePersonMap[houseId])) {
                return null;
            }

            let personIds: Uuid[] = [];
            if (houseId && client.profile.housePersonMap[houseId]) {
                // type assertion: checked above
                personIds = client.profile.housePersonMap[houseId] as Uuid[];
            } else {
                personIds = personsNotInHouses;
            }

            const strings: string[] = [];
            // non-null assertion: checked above
            for (const personId of personIds) {
                const person = client.profile?.persons[personId];
                if (!person?.type || !personTypes[person.type]) {
                    return null;
                }
                // biome-ignore lint/style/noNonNullAssertion: checked above
                strings.push(personTypes[person.type]!.lang[locales]);
            }

            return strings.join(", ");
        },
        [client, personsNotInHouses],
    );

    const getDom = useCallback(() => {
        if (!client.profile?.houses) {
            return null;
        }

        const dom: ReactNode[] = [];
        let i = 1;
        for (const [houseId, house] of Object.entries(client.profile.houses)) {
            const accommodationQuestionDom = getAccommodationQuestions(houseId);
            const node = (
                <section
                    key={houseId}
                    className="conversation-section-wrapper conversation-answers"
                >
                    <details className="question-section-details">
                        <summary style={{ padding: 0 }}>
                            <div className="conversationAnswers-accordion">
                                <img
                                    src={`/assets/houses/${house.avatar}.svg`}
                                    alt={houseStrings[house.type].lang[locales]}
                                    style={{
                                        width: "3.5rem",
                                        height: "3.5rem",
                                        objectFit: "contain",
                                    }}
                                    title={houseStrings[house.type].lang[locales]}
                                />
                                <span>
                                    <h2
                                        style={{ fontSize: "1.125rem" }}
                                    >{`${COMMON.ACCOMMODATION} ${i}`}</h2>
                                    <span className="text-grey">{getPersonStrings(houseId)}</span>
                                </span>
                            </div>
                        </summary>
                        <h3 style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                            Mina svar om boendet:
                        </h3>
                        {!accommodationQuestionDom && (
                            <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                                {language[locales].CASE.REPORT.TAB_REPORT.NO_ANSWER}
                            </div>
                        )}
                        <div
                            style={{
                                alignItems: "start",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "1.5rem",
                            }}
                        >
                            {accommodationQuestionDom}
                        </div>
                        <div style={{ marginTop: "3rem" }}>{getPersons(houseId)}</div>
                    </details>
                </section>
            );

            dom.push(node);
            i += 1;
        }

        // add persons not in any house
        if (personsNotInHouses.length > 0) {
            const node = (
                <section
                    key="house-others"
                    className="conversation-section-wrapper conversation-answers"
                >
                    <details className="question-section-details">
                        <summary style={{ padding: 0 }}>
                            <div className="conversationAnswers-accordion">
                                <span>
                                    <h2 style={{ fontSize: "1.125rem" }}>Personer utan boende</h2>
                                    <span className="text-grey">{getPersonStrings(undefined)}</span>
                                </span>
                            </div>
                        </summary>
                        <div style={{ marginTop: "3rem" }}>{getPersons(undefined)}</div>
                    </details>
                </section>
            );

            dom.push(node);
        }

        return dom;
    }, [client, beb]);

    return <div className={className}>{getDom()}</div>;
};

export default ConversationAccommodationAnswers;
