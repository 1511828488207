import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import language from "../../../utils/language/index";
import UnitCard from "./cards/unitCard";
import "./unitLevel.css";

const UnitLevel = ({
    unit,
    role,
    unitObj,
    nextStep,
    selectedCrumb,
    setSelected,
    setCurrentUnit,
    show,
    setShow,
}) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });

    const handleClick = (unt) => {
        selectedCrumb(unt);
        setSelected(unt);
        nextStep();
    };

    const handleIcon = (unt) => {
        setCurrentUnit(unt);
        setShow(!show);
    };

    return (
        <div className="unitLevel-wrapper">
            {unit.childUnits.length !== 0 ? (
                unit.childUnits.map((child) => {
                    return (
                        <UnitCard
                            key={child}
                            unit={unitObj[child]}
                            role={role}
                            button="pencil"
                            handleClick={() => {
                                handleClick(unitObj[child]);
                            }}
                            handleIcon={() => {
                                handleIcon(unitObj[child]);
                            }}
                        />
                    );
                })
            ) : (
                <div className="unitLevel-noUnits">{language[locales].ADMIN.UNIT.UNIT_LEVEL}</div>
            )}
        </div>
    );
};

UnitLevel.propTypes = {
    unit: PropTypes.shape(Types.unit).isRequired,
    role: PropTypes.string.isRequired,
    unitObj: PropTypes.objectOf(PropTypes.object).isRequired,
    nextStep: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    setCurrentUnit: PropTypes.func.isRequired,
    selectedCrumb: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
};

export default UnitLevel;
