"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarThumbs = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const types_1 = require("../types");
const renderAvatarParts_1 = require("./renderAvatarParts");
require("./avatarThumbs.css");
const AvatarThumbs = ({ activeArray, activeThumb, avatarModel, handleThumb, }) => {
    const [currentHead, setCurrentHead] = (0, react_1.useState)();
    const buttonRef = (0, react_1.useRef)(null);
    const emptyRef = (0, react_1.useRef)(null);
    const isInHeadThumb = activeThumb.id.startsWith("head-");
    (0, react_1.useEffect)(() => {
        const head = avatarModel.find((part) => {
            return part.id.startsWith("head-");
        });
        setCurrentHead(head);
    }, [avatarModel]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (buttonRef.current) {
            buttonRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
            });
        }
        else {
            (_a = emptyRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
            });
        }
    }, [activeThumb]);
    if (!currentHead) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("section", { className: "avatarThumbs-wrapper", children: activeArray.map((partId) => {
            if (partId === "empty") {
                return ((0, jsx_runtime_1.jsx)("button", { className: `avatarThumbs-button ${partId === activeThumb.id && "avatarThumbs-active"}`, type: "button", ref: emptyRef, onClick: () => {
                        return handleThumb(partId);
                    }, "aria-label": "Ta bort del av avatar", id: partId, children: (0, jsx_runtime_1.jsxs)("svg", { width: "51", height: "51", viewBox: "0 0 51 51", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-labelledby": partId, children: [(0, jsx_runtime_1.jsx)("title", { children: "tom" }), (0, jsx_runtime_1.jsx)("path", { d: "M25.4995 46.75C37.2356 46.75 46.7495 37.236 46.7495 25.5C46.7495 13.7639 37.2356 4.25 25.4995 4.25C13.7635 4.25 4.24951 13.7639 4.24951 25.5C4.24951 37.236 13.7635 46.75 25.4995 46.75Z", stroke: "#D7D1C9", strokeWidth: "3.42742", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M10.4751 10.4766L40.5226 40.5241", stroke: "#D7D1C9", strokeWidth: "3.42742", strokeLinecap: "round", strokeLinejoin: "round" })] }) }, partId));
            }
            return ((0, jsx_runtime_1.jsx)("button", { className: `avatarThumbs-button ${partId === activeThumb.id && "avatarThumbs-active"}`, type: "button", ref: partId === activeThumb.id ? buttonRef : null, onClick: () => {
                    return handleThumb(partId);
                }, "aria-label": `Välj del: ${partId}`, children: isInHeadThumb ? ((0, jsx_runtime_1.jsx)(renderAvatarParts_1.RenderAvatarParts, { avatarModel: [{ id: partId, color: activeThumb.color }], type: types_1.RenderAvatarType.THUMBNAIL, title: `Del av avatar: ${partId}` })) : ((0, jsx_runtime_1.jsx)(renderAvatarParts_1.RenderAvatarParts, { avatarModel: [
                        { id: partId, color: activeThumb.color },
                        currentHead,
                    ], type: types_1.RenderAvatarType.THUMBNAIL, title: `Del av avatar: ${partId}` })) }, partId));
        }) }));
};
exports.AvatarThumbs = AvatarThumbs;
