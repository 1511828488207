import type { Beb2, Invitation } from "@ommej/types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CaseForms from "~/src/components/case/modals/components/caseForms";
import GhostButton from "~/src/components/tools/buttons/ghostButton/ghostButton";
import { startGuide } from "~/src/guide";
import DotsIcon from "~/src/media/images/svg/dots_grey.svg";
import { getLoggedInUser } from "~/src/redux/actions/login/actions";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import type { ClientData } from "./caseDetails";
import ConversationAccommodationAnswers from "./conversationAccommodationAnswers";
import ConversationAccommodations from "./conversationAccommodations";
import ConversationAnswers from "./conversationAnswers";
import ConversationEnd from "./conversationEnd";
import ConversationIntro from "./conversationIntro";
import ConversationResource from "./conversationResource";
import ConversationStegen from "./conversationStegen";
import ConversationWelcome from "./conversationWelcome";
import "./conversation.css";

type ConversationProps = {
    invitation: Invitation.ToClient;
    client: ClientData["info"];
    beb: Beb2;
};

const Conversation = ({ invitation, client, beb }: ConversationProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { CASE_DETAILS, TOOLS, CONVERSATION } = language[locales];
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (!beb) {
            return;
        }
        startGuide(currentUser, "CASE_CONVERSATION_VIEW", locales, () => {
            dispatch(getLoggedInUser());
        });
    }, [beb]);

    if (!beb) {
        return null;
    }

    return (
        <div className="conversation-wrapper">
            <header className="conversation-header">
                <h1>{CASE_DETAILS.CONVERSATION}</h1>
                <GhostButton
                    text={TOOLS.PRINT}
                    icon="print"
                    handleGhostButton={() => {
                        window.print();
                    }}
                />
            </header>

            <ConversationWelcome className="guide-case_conversation_view-welcome" />

            <ConversationIntro className="guide-case_conversation_view-intro" />
            <img src={DotsIcon} alt="section divider" className="conversation-divider" />

            <ConversationResource
                beb={beb}
                type="resource"
                client={client}
                className="guide-case_conversation_view-resource"
            />
            <img src={DotsIcon} alt="section divider" className="conversation-divider" />

            <ConversationAccommodations
                client={client}
                className="guide-case_conversation_view-accommodations"
            />
            <img src={DotsIcon} alt="section divider" className="conversation-divider" />

            <ConversationResource
                beb={beb}
                type="important"
                client={client}
                className="guide-case_conversation_view-resource-important"
            />
            <img src={DotsIcon} alt="section divider" className="conversation-divider" />

            <ConversationStegen beb={beb} className="guide-case_conversation_view-stegen" />
            {beb.questionTags.STEGEN && beb.questionTags.STEGEN.length > 0 && (
                <img src={DotsIcon} alt="section divider" className="conversation-divider" />
            )}

            <ConversationAccommodationAnswers
                beb={beb}
                client={client}
                className="guide-case_conversation_view-accommodations-answers"
            />
            <img src={DotsIcon} alt="section divider" className="conversation-divider" />

            <ConversationAnswers
                beb={beb}
                client={client}
                questionTagsToInclude={["FAMILY", "SCHOOL", "MYSELF", "LEISURE", "BASE"]}
                className="guide-case_conversation_view-answers"
            />
            <img src={DotsIcon} alt="section divider" className="conversation-divider" />

            <ConversationEnd className="guide-case_conversation_view-end" />
            <img src={DotsIcon} alt="section divider" className="conversation-divider" />

            {/* Add forms */}
            {!(invitation.clients?.removed && invitation.clients.removed.length > 0) && (
                <section className="conversation-section-wrapper print-hide guide-case_conversation_view-forms">
                    <h2>{CONVERSATION.FORM_HEADER}</h2>
                    <h3 className="conversation-sub-header-talk">{CONVERSATION.FORM_SUBHEADER}</h3>
                    <p style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                        {CONVERSATION.FORM_TEXT}
                    </p>
                    <div className="conversation-caseForm-wrapper">
                        <CaseForms invitation={invitation} />
                    </div>
                </section>
            )}
        </div>
    );
};

export default Conversation;
