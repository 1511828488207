import type { Beb2 } from "@ommej/types";
import type { ReactNode } from "react";
import type { ClientInfo } from "~/src/types";
import AnswersViewer from "./answersViewer";
import ConversationSmilyes from "./conversationSmilyes";

type ConversationResourceProps = {
    beb: Beb2;
    type: "resource" | "important";
    client: ClientInfo;
    className: string;
};

const ConversationResource = ({ beb, type, client, className }: ConversationResourceProps) => {
    const conversationModel = {
        resource: [
            {
                header: "Bra saker i ditt liv",
                subheader:
                    "Du har säkert många bra saker i ditt liv. De här har du valt att berätta om i Ommej-appen",
                data: beb.answerTags.RESOURCE,
                showAnswers: true,
                uniqueness: "J8OYd",
                color: undefined,
            },
        ],
        important: [
            {
                header: "Detta är viktigt för dig",
                subheader: "Detta är de saker du svarat i appen som du vill ha hjälp med",
                data: beb.answerTags.HELP,
                showAnswers: false,
                uniqueness: "eZY7t",
                color: "#f8f6dd",
            },
            {
                header: "",
                subheader: "Detta är de saker som du säger oroar dig",
                data: beb.answerTags.WORRIES,
                showAnswers: false,
                uniqueness: "Kvvd2",
                color: "#f8f6dd",
            },
        ],
    };

    function getDom() {
        const dom: Array<ReactNode> = [];
        for (const item of conversationModel[type]) {
            dom.push(
                <div key={item.header} style={{ marginBottom: "3rem" }}>
                    {item.header && <h2>{item.header}</h2>}
                    <h3>{item.subheader}</h3>
                    <AnswersViewer
                        beb={beb}
                        filter={[item.data]}
                        client={client}
                        showAnswers={item.showAnswers}
                        uniqueness={item.uniqueness}
                        color={item.color}
                    />
                </div>,
            );
        }

        return dom;
    }

    return (
        <section className={`conversation-section-wrapper ${className}`}>
            {getDom()}
            <div>
                <h3 className="conversation-sub-header-talk">Hur känns det att prata om detta?</h3>
                <ConversationSmilyes />
            </div>
        </section>
    );
};

export default ConversationResource;
