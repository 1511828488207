import {
    AnalysisIcon,
    ArrowDownIcon,
    PencilIcon,
    PlusIcon,
    PlusIndigoIcon,
    PrintIcon,
    RefreshIcon,
    TrashBinIcon,
} from "../../../../utils/icons";
import "./ghostButton.css";

const GhostButton = ({
    icon,
    text,
    className,
    handleGhostButton,
    right,
    customStyle,
}: {
    icon?: string;
    text: string;
    className?: string;
    handleGhostButton: () => void;
    right?: boolean;
    customStyle?: React.CSSProperties;
}) => {
    const ICONS = {
        pencil: <PencilIcon />,
        plus: <PlusIcon />,
        plusIndigo: <PlusIndigoIcon />,
        refresh: <RefreshIcon />,
        arrowDown: <ArrowDownIcon />,
        analysis: <AnalysisIcon />,
        trashbin: <TrashBinIcon />,
        print: <PrintIcon />,
    };

    return (
        <button
            type="button"
            className={`iconButton-wrapper ${!icon && "iconButton-noIcon"} ${
                right && "iconButton-right"
            } ${className}`}
            style={customStyle}
            onClick={handleGhostButton}
        >
            {right && <p className="text-description iconButton-text">{text}</p>}
            {icon && ICONS[icon]}
            {!right && <p className="text-description iconButton-text">{text}</p>}
        </button>
    );
};

export default GhostButton;
