import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import type { GlobalState } from "~/src/types";
import { getRoutingState } from "~/src/utils/systemFunctions";
import { route } from "../../utils/constants";
import language from "../../utils/language";
import BigButton from "../tools/buttons/bigButton/bigButton";
import SearchInput from "../tools/form/search/searchInput";
import AddInvitation from "./modals/addInvitation/addInvitation";
import "./caseHeader.css";

type CaseHeaderProps = {
    setSearchString: (searchValue: string) => void;
};

const CaseHeader = ({ setSearchString }: CaseHeaderProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { CASE, COMMON } = language[locales];
    const [searchValue, setSearchValue] = useState("");
    const location = useLocation();
    const routingState = getRoutingState(location);
    const navigate = useNavigate();

    useEffect(() => {
        setSearchString(searchValue);
    }, [searchValue]);

    return (
        <>
            <div className="caseHeader-wrapper">
                <div className="caseHeader-search">
                    <h3>{COMMON.CASES}</h3>
                    <SearchInput
                        name="search"
                        placeholder={CASE.HEADER_SEARCH}
                        value={searchValue}
                        customStyle={{ width: "100%" }}
                        handleChange={(e) => {
                            setSearchValue(e.target.value.toLowerCase());
                        }}
                    />
                </div>
                <BigButton
                    text={CASE.HEADER_BUTTON}
                    icon="plus"
                    className="caseHeader-bigbutton"
                    handleButton={() => {
                        const options = { state: routingState };
                        navigate(route.CASES_ADD, options);
                    }}
                />
            </div>
            {location.pathname === route.CASES_ADD && <AddInvitation />}
        </>
    );
};

export default CaseHeader;
