"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswersViewerStats = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./answersViewerSingle.css");
const COLORS = [
    "rgba(252, 208, 186, 1)",
    "rgba(225, 141, 149, 1)",
    "rgba(226, 235, 210, 1)",
    "rgba(202, 202, 202, 1)",
    "rgba(202, 141, 208, 1)",
];
function getChart(form, questionId, answers) {
    const data = {
        total: 0,
        entries: [],
    };
    for (const [answerId, count] of Object.entries(answers)) {
        data.total += count;
        switch (form.questions[questionId].answers[answerId].type) {
            case "text":
                // type assertion: type is text
                data.entries.push({
                    text: form.questions[questionId].answers[answerId].text.sv,
                    count,
                });
                break;
            case "none":
                data.entries.push({ text: "inget svar", count });
                break;
            default:
                data.entries.push({ text: "-", count });
                break;
        }
    }
    const degreePerAnswerCount = 360 / data.total;
    let total = 0;
    let style = "";
    data.entries.forEach((d, i) => {
        style += ` ${COLORS[i % (COLORS.length - 1)]} ${total}deg ${total + degreePerAnswerCount * d.count}deg,`;
        total += degreePerAnswerCount * d.count;
    });
    // remove last ','
    style = style.slice(0, -1);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "answerViewer-chart", children: [(0, jsx_runtime_1.jsx)("div", { className: "answerViewer-chart-chart", style: {
                    background: `conic-gradient(${style})`,
                }, children: (0, jsx_runtime_1.jsx)("div", { className: "answerViewer-chart-hole" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "answerViewer-legend", children: [data.entries.map((d, i) => {
                        return ((0, jsx_runtime_1.jsxs)("div", { className: "answerViewer-legend-entry", children: [(0, jsx_runtime_1.jsx)("span", { className: "answerViewer-legend-entry-color", style: { backgroundColor: COLORS[i % (COLORS.length - 1)] } }), (0, jsx_runtime_1.jsx)("span", { children: `${Math.round((d.count / data.total) * 100)}%` }), (0, jsx_runtime_1.jsx)("span", { children: d.text })] }, questionId + d.text));
                    }), (0, jsx_runtime_1.jsxs)("span", { children: ["Totalt: ", data.total, " svar"] })] })] }));
}
const AnswersViewerStats = ({ form, formAnswers }) => {
    const multipleClients = formAnswers.metadata.clientCount > 1;
    if (Object.keys(formAnswers.data).length === 0) {
        return (0, jsx_runtime_1.jsx)("p", { children: "Inget barn har svarat." });
    }
    if (multipleClients) {
        return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: Object.entries(formAnswers.data).map(([questionKey, answerItem]) => {
                var _a;
                if (!form.questions[questionKey]) {
                    console.error("Cannot find question in form", questionKey);
                    return null;
                }
                switch ((_a = form.questions[questionKey]) === null || _a === void 0 ? void 0 : _a.type) {
                    case "single":
                    case "rate":
                    case "multiple":
                    case "frequency":
                        return ((0, jsx_runtime_1.jsxs)("div", { className: "answersViewerMultiple-question-wrapper", children: [(0, jsx_runtime_1.jsx)("p", { className: "answersViewerMultiple-question-text", children: form.questions[questionKey].text.sv }), getChart(form, questionKey, answerItem)] }, questionKey));
                    default:
                        return null;
                }
            }) }));
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: Object.entries(formAnswers.data).map(([questionKey, answerItem]) => {
            var _a;
            if (!form.questions[questionKey]) {
                console.error("Cannot find question in form", questionKey);
                return null;
            }
            switch ((_a = form.questions[questionKey]) === null || _a === void 0 ? void 0 : _a.type) {
                case "single":
                case "rate":
                case "multiple":
                case "frequency":
                    return ((0, jsx_runtime_1.jsxs)("div", { className: "answersViewerSingle-question-wrapper", children: [(0, jsx_runtime_1.jsx)("p", { children: form.questions[questionKey].text.sv }), Object.entries(answerItem).map(([answerId, count]) => {
                                if (!form.questions[questionKey].answers[answerId].type ||
                                    form.questions[questionKey].answers[answerId].type !==
                                        "text") {
                                    return null;
                                }
                                return ((0, jsx_runtime_1.jsx)("p", { className: "answersViewerSingle-answer", children: `${
                                    // type assertion: type is text
                                    form.questions[questionKey].answers[answerId].text.sv}${count > 1 ? ` (${count})` : ""}` }, answerId));
                            })] }, questionKey));
                default:
                    return null;
            }
        }) }));
};
exports.AnswersViewerStats = AnswersViewerStats;
