import type { Invitation } from "@ommej/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllInvitations } from "~/src/redux/actions/invitation/actions";
import Skeleton from "~/src/skeletons/skeleton";
import type { GlobalState, InvitationView } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import language from "~/src/utils/language/index";
import "./myInvitations.css";

type LocaledStats = { text: string; data: number | null };
type InvStats = {
    numInvs: LocaledStats;
    numAlarms: LocaledStats;
    numAnswers: LocaledStats;
    numFollowUps: LocaledStats;
};

const MyInvitations = () => {
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const invitations: Invitation.ToClient[] = useSelector((state: GlobalState) => {
        return state.invitation.invitations;
    });
    const invitationsLoaded = useSelector((state: GlobalState) => {
        return state.invitation.loaded;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { HOME } = language[locales];
    const { CASES, ALARM_ANSWERS, FOLLOW_UPS, SENT } = language[locales].COMMON;

    const invStatsOrigin: InvStats = {
        numInvs: { text: CASES, data: null },
        numAlarms: { text: ALARM_ANSWERS, data: null },
        numAnswers: { text: language[locales].STATISTICS.ANSWER_COUNT, data: null },
        numFollowUps: { text: `${SENT} ${FOLLOW_UPS.toLowerCase()}`, data: null },
    };
    const [invStats, setInvStats] = useState<InvStats>(invStatsOrigin);
    const [loading, setLoading] = useState(true);

    let isMounted = true;
    const loadResources = async () => {
        if (!invitationsLoaded) {
            try {
                await dispatch(getAllInvitations());
                if (isMounted) {
                    setErrorMessage(null);
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };

    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        // get data from /invitations/view
        const getNumAlarms = async () => {
            const response = await reqWithPath("invitations", "get", "view");
            const invViews: InvitationView[] = await response.json();
            type InvsMetadata = {
                numAlarms: number;
                numAnswers: number;
            };
            const metaDataStart: InvsMetadata = { numAlarms: 0, numAnswers: 0 };
            const InvsMetadata: InvsMetadata = invViews.reduce(
                (metaData: InvsMetadata, item: InvitationView) => {
                    if (item.ownedBy.id === currentUser._id) {
                        return {
                            numAlarms: metaData.numAlarms + item.metadata.numAlarms,
                            numAnswers: metaData.numAnswers + item.metadata.numAnswers,
                        };
                    }
                    return metaData;
                },
                metaDataStart,
            );
            setInvStats((current: InvStats) => {
                return {
                    ...current,
                    numAlarms: { ...current.numAlarms, data: InvsMetadata.numAlarms },
                    numAnswers: { ...current.numAnswers, data: InvsMetadata.numAnswers },
                };
            });
            setLoading(false);
        };
        getNumAlarms();
    }, []);

    useEffect(() => {
        // get data from /invitations
        type InvsMetadata = {
            numInvs: number;
            numFollowUps: number;
        };
        const metaDataStart: InvsMetadata = { numInvs: 0, numFollowUps: 0 };
        const invsMetadata = invitations.reduce(
            (metaData: InvsMetadata, item: Invitation.ToClient) => {
                if (item.ownedBy._id === currentUser._id) {
                    return {
                        numInvs: metaData.numInvs + 1,
                        numFollowUps: metaData.numFollowUps + (item?.forms ? item.forms.length : 0),
                    };
                }
                return metaData;
            },
            metaDataStart,
        );

        setInvStats((current: InvStats) => {
            return {
                ...current,
                numInvs: { ...current.numInvs, data: invsMetadata.numInvs },
                numFollowUps: { ...current.numFollowUps, data: invsMetadata.numFollowUps },
            };
        });
    }, [invitations]);

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }

    return (
        <div className="home-card myInvitations-wrapper">
            <div className="home-card-header">
                <h1>{language[locales].HOME.MY_INVITATIONS.HEADER}</h1>
                <Link style={{ fontSize: "0.85rem" }} to="/cases">
                    {HOME.ACTIVITIES.CASES}&nbsp;&nbsp;&gt;
                </Link>
            </div>
            <div className="myInvitations-stats-wrapper">
                {Object.values(invStats).map((value: LocaledStats) => {
                    return (
                        <div
                            key={value.text}
                            className="myInvitations-stats-item-wrapper"
                            style={{ color: value.data === 0 ? "var(--color-grey)" : "black" }}
                        >
                            {loading ? (
                                <Skeleton type="small-image" height={66} />
                            ) : (
                                <div
                                    style={{
                                        color: value.data === 0 ? "var(--color-grey)" : "black" /* really need to remove the reset stuff in
                  index.css, this here shouldn't be needed - styling the parent should be enough */,
                                    }}
                                    className="myInvitations-stats-item-value"
                                >
                                    {value.data}
                                </div>
                            )}
                            <span className="myInvitations-stats-item-text">{value.text}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MyInvitations;
