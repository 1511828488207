import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Types from "~/src/utils/types";
import Can from "../../../../../rbac/can";
import { getAllUsers } from "../../../../../redux/actions/users/actions";
import language from "../../../../../utils/language";
import NormalButton from "../../../../tools/buttons/normalButton/normalButton";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import UserCard from "../../cards/userCard";
import "./unitAccess.css";

const UnitAccess = ({ unitObj, setUnitObj, setTab, role }) => {
    const userObj = useSelector((state) => {
        return state.user.userObj;
    });
    const usersLoaded = useSelector((state) => {
        return state.user.loaded;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();

    const deleteAccess = (userId) => {
        const users = unitObj.users.filter((user) => {
            return user !== userId;
        });
        const administrators = unitObj.administrators.filter((admin) => {
            return admin !== userId;
        });
        setUnitObj({ ...unitObj, users, administrators });
    };

    let isMounted = true;
    const loadResources = async () => {
        if (!usersLoaded) {
            try {
                await dispatch(getAllUsers());
                if (isMounted) {
                    setErrorMessage(null);
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };

    const handleYes = useCallback(() => {
        return (
            <NormalButton
                icon="plus"
                text={language[locales].ADMIN.UNIT.EDIT_UNIT.ACCESS_BUTTON}
                handleButton={() => {
                    setTab("Access");
                }}
            />
        );
    }, []);

    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    if (!usersLoaded) {
        return null;
    }
    return (
        <FluidWrapper customStyle={{ height: "22rem" }}>
            <header className="unitAccess-header-wrapper">
                <h3>{language[locales].ADMIN.UNIT.EDIT_UNIT.ACCESS_HEADING}</h3>
                <Can
                    role={role}
                    perform="unit:update"
                    yes={() => {
                        return handleYes();
                    }}
                    no={() => {
                        return null;
                    }}
                />
            </header>
            <div className="unitAccess-name-wrapper">
                <h4 className="text-grey">{`${unitObj.users.length + unitObj.administrators.length} ${
                    language[locales].ADMIN.UNIT.EDIT_UNIT.ACCESS_QUANTITY
                }`}</h4>
                <div className="unitAccess-name-noscroll-wrapper">
                    {unitObj.administrators?.map((admin) => {
                        return (
                            userObj[admin] && (
                                <UserCard
                                    key={admin}
                                    text="Ta bort"
                                    user={userObj[admin]}
                                    role={role}
                                    icon="trashbin"
                                    handleGhostButton={() => {
                                        return deleteAccess(admin);
                                    }}
                                />
                            )
                        );
                    })}
                    {unitObj.users?.map((user) => {
                        return (
                            userObj[user] && (
                                <UserCard
                                    key={user}
                                    user={userObj[user]}
                                    role={role}
                                    text="Ta bort"
                                    icon="trashbin"
                                    handleGhostButton={() => {
                                        return deleteAccess(user);
                                    }}
                                />
                            )
                        );
                    })}
                </div>
            </div>
        </FluidWrapper>
    );
};

UnitAccess.propTypes = {
    unitObj: PropTypes.shape(Types.unit).isRequired,
    setUnitObj: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
};

export default UnitAccess;
