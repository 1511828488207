import PropTypes, { string } from "prop-types";
import "./modalBody.css";

const ModalBody = ({ children, customStyle }) => {
    return (
        <main className="modalBody-wrapper" style={customStyle}>
            {children}
        </main>
    );
};

ModalBody.defaultProps = {
    customStyle: null,
};

ModalBody.propTypes = {
    children: PropTypes.node.isRequired,
    customStyle: PropTypes.objectOf(string),
};

export default ModalBody;
