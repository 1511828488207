import { useEffect, useRef, useState } from "react";
import "./tooltip.css";

export default function Tooltip({
    title,
    position = "bottom",
    children,
}: {
    title: string;
    position?: string;
    children: React.ReactNode;
}) {
    const node = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    const mouseEvent = ({ target }) => {
        if (node.current?.contains(target)) {
            return;
        }
        setVisible(false);
    };

    useEffect(() => {
        document.addEventListener("mouseenter", mouseEvent);
        return () => {
            document.removeEventListener("mouseenter", mouseEvent);
        };
    }, []);

    return (
        <div
            className="tooltip-container"
            ref={node}
            onMouseEnter={() => {
                setVisible(!visible);
            }}
            onMouseLeave={() => {
                setVisible(!visible);
            }}
        >
            {children}
            {visible && <div className={`tooltip-content ${position}`}>{title}</div>}
        </div>
    );
}
