import { getSmiley } from "@ommej/componente";

const ConversationSmilyes = () => {
    const smileyNames = ["CRAPPY", "BAD", "MEH", "OK", "GOOD", "SUPER"];

    return (
        <div className="conversation-smileys-wrapper">
            {smileyNames.map((smiley) => {
                return (
                    <img
                        src={getSmiley(smiley)}
                        alt={smiley}
                        key={smiley}
                        style={{ margin: "1.5rem 1rem 0.5rem 0" }}
                    />
                );
            })}
        </div>
    );
};

export default ConversationSmilyes;
