// @ts-check

export const findSingleUnit = (id, units) => {
    const findUnit = units.find((unit) => {
        return unit._id === id;
    });
    return findUnit;
};

export const getAllChildUnits = (unit, units) => {
    if (!unit || !units) {
        return undefined;
    }
    const allUnits = units;
    const resultObject = {};
    const getChildUnits = (targetUnit) => {
        const childUnits = allUnits.filter((u) => {
            return u.parentUnit === targetUnit._id;
        });
        for (const u of childUnits) {
            Object.assign(resultObject, { [u._id.toString()]: u });
            getChildUnits(u);
        }
        return Object.values(resultObject);
    };
    return getChildUnits(unit);
};

export const checkIfUnitHasOwner = (unit, users) => {
    if (!unit || !users) {
        return false;
    }
    return users.some((user) => {
        return user.unit._id === unit._id;
    });
};
