import { GetAvatar } from "@ommej/componente";
import type { Invitation } from "@ommej/types";
import { type ColumnDef, createColumnHelper } from "@tanstack/react-table";
import AlarmIcon from "~/src/media/images/svg/alarm.svg";
import GroupIcon from "~/src/media/images/svg/group-icon.svg";
import UndefinedAvatar from "~/src/media/images/svg/undefinedAvatar.svg";
import type { InvitationView } from "~/src/types";
import { getInvitationQuantity, getInvitationStatus } from "~/src/utils/invitationFunctions";
import language from "~/src/utils/language";
import { formatDate } from "~/src/utils/systemFunctions";

const renderAnswerString = (
    clients: Invitation.Clients,
    locales: string,
    maxNumberOfClients = 1,
) => {
    const { CASE_LIST } = language[locales];

    if (maxNumberOfClients === 1) {
        if (Object.keys(clients).length === 0) {
            return CASE_LIST.NO_CHILD;
        }
        if (clients.pending && clients.pending.length >= 1) {
            return CASE_LIST.INVITATION_SENT;
        }
        if (clients.denied && clients.denied?.length >= 1) {
            return CASE_LIST.INVITATION_DENIED;
        }
        if (clients.removed && clients.removed.length > 0) {
            return CASE_LIST.INVITATION_REMOVED;
        }
    }
    if (maxNumberOfClients !== 1) {
        // in a group case we only show connected children
        if (clients.accepted) {
            return `${clients.accepted.length} ${CASE_LIST.CHILD_CONNECTED}`;
        }
    }
    return "";
};

const getColumnItems = (locales: string) => {
    const { CASE_LIST, COMMON } = language[locales];
    const columnHelper = createColumnHelper<InvitationView>();

    const columnItems = {
        id: columnHelper.accessor("id", {
            header: CASE_LIST.ID,
            cell: (info) => {
                return info.renderValue();
            },
        }),
        type: columnHelper.display({
            id: CASE_LIST.TYPE,
            header: () => {
                return (
                    <div
                        style={{
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        {CASE_LIST.TYPE}
                    </div>
                );
            },
            size: 90,
            cell: (info) => {
                const { maxNumberOfClients } = info.row.original;
                if (!info.row.original.metadata.avatar && maxNumberOfClients === 1) {
                    return (
                        <div style={{ margin: "auto" }} className="table-centered-image-wrapper">
                            <img src={UndefinedAvatar} alt="undefined avatar" />
                        </div>
                    );
                }
                if (
                    // only two == to check for both undefined and null
                    (maxNumberOfClients != null && maxNumberOfClients !== 1) ||
                    (maxNumberOfClients == null && getInvitationQuantity(info.row.original) > 1)
                ) {
                    return (
                        <div style={{ margin: "auto" }} className="table-centered-image-wrapper">
                            <img src={GroupIcon} alt="group" />
                        </div>
                    );
                }
                return (
                    <div style={{ margin: "auto" }} className="table-centered-image-wrapper">
                        <GetAvatar avatarData={info.row.original.metadata.avatar} />
                    </div>
                );
            },
        }),
        name: columnHelper.accessor("name", {
            header: () => {
                return <div>{CASE_LIST.NAME}</div>;
            },
            size: 200,
            cell: (info) => {
                const quantity = getInvitationQuantity(info.row.original);
                const clients = info.row.original?.clients;
                if (!clients) {
                    return null;
                }
                return (
                    <div className="bold-text">
                        <p
                            style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            }}
                            title={info.renderValue() ?? ""}
                        >
                            {info.renderValue()}
                        </p>
                        <p className="text-description dark-grey">{`${
                            quantity === 1 &&
                            info.row.original?.clients?.accepted &&
                            info.row.original.clients.accepted.length > 0
                                ? `${info.row.original.metadata.numAnswers} svar`
                                : renderAnswerString(
                                      clients,
                                      locales,
                                      info.row.original?.maxNumberOfClients,
                                  )
                        } `}</p>
                    </div>
                );
            },
        }),
        alarm: columnHelper.accessor("metadata.numAlarms", {
            header: () => {
                return <div style={{ margin: "auto" }}>{CASE_LIST.ALARM}</div>;
            },
            enableGlobalFilter: false,
            size: 60,
            cell: (info) => {
                return (
                    info.getValue() > 0 && (
                        <div className="table-small-centered-image-wrapper">
                            <img src={AlarmIcon} alt="alarm answer" />
                        </div>
                    )
                );
            },
        }),
        created: columnHelper.accessor("created", {
            header: () => {
                return <div>{CASE_LIST.CREATED}</div>;
            },
            size: 175,
            cell: (info) => {
                return formatDate(info.renderValue(), locales);
            },
        }),
        status: columnHelper.accessor("status", {
            enableSorting: false,
            header: () => {
                return <div>{CASE_LIST.STATUS}</div>;
            },
            size: 100,
            cell: (info) => {
                const quantity = getInvitationQuantity(info.row.original);
                if (
                    quantity > 1 ||
                    (info.row.original.clients?.accepted &&
                        info.row.original?.clients.accepted.length >= 1 &&
                        info.getValue() === "active")
                ) {
                    return null;
                }
                const status = getInvitationStatus(
                    info.getValue(),
                    info.row.original?.clients,
                    quantity,
                    language[locales],
                );
                return (
                    <span className={`table-badge table-${status.status.toLowerCase()}`}>
                        {status.string}
                    </span>
                );
            },
        }),
        owner: columnHelper.accessor("ownedBy.username", {
            header: () => {
                return <div>{CASE_LIST.OWNER}</div>;
            },
            cell: (info) => {
                return info.renderValue();
            },
        }),
        unit: columnHelper.accessor("ownedBy.unit", {
            header: () => {
                return <div>{CASE_LIST.UNIT}</div>;
            },
            cell: (info) => {
                return info.renderValue();
            },
        }),
        sharedWith: columnHelper.accessor("sharedToUsers", {
            header: () => {
                return <div>{CASE_LIST.SHARED_WITH}</div>;
            },
            cell: (info) => {
                return `${info.getValue().length} ${COMMON.USERS}`;
            },
        }),
    };
    return columnItems;
};

type columnsType = {
    [Property in keyof typeof language.sv.CASE_LIST.CASE_LIST_TAB_TITLES]: (
        | ColumnDef<InvitationView, any>
        | undefined
    )[];
};

export const getCaseListColumns = (locales: string): columnsType => {
    const columnItems = getColumnItems(locales);
    const columns: columnsType = {
        ALL_CASES: [
            columnItems.type,
            columnItems.name,
            columnItems.alarm,
            columnItems.owner,
            columnItems.unit,
            columnItems.created,
            columnItems.status,
        ],
        MY_CASES: [
            columnItems.type,
            columnItems.name,
            columnItems.alarm,
            columnItems.created,
            columnItems.status,
        ],
        SHARED_BY_ME: [
            columnItems.type,
            columnItems.name,
            columnItems.alarm,
            columnItems.sharedWith,
            columnItems.unit,
            columnItems.created,
            columnItems.status,
        ],
        SHARED_WITH_ME: [
            columnItems.type,
            columnItems.name,
            columnItems.alarm,
            columnItems.owner,
            columnItems.unit,
            columnItems.created,
            columnItems.status,
        ],
    };
    return columns;
};
