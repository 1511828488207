import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../redux/actions/users/actions";
import NormalButton from "../../../tools/buttons/normalButton/normalButton";
import RoundButton from "../../../tools/buttons/roundButton/roundButton";
import SecondaryButton from "../../../tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "../../../tools/fluidWrapper/fluidWrapper";
import Textfield from "../../../tools/form/textfield/textfield";
import "./profileEmail.css";

const ProfileEmail = () => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const [changeEmail, setChangeEmail] = useState(false);
    const [currentEmail] = useState(currentUser.email);
    const [error, setError] = useState("");
    const [sendObj, setSendObj] = useState({
        role: currentUser.roleCode.toLowerCase(),
        _id: currentUser._id,
        email: currentUser.email,
        username: currentUser.username,
        unit: currentUser.unit,
    });
    const dispatch = useDispatch();

    const handleChangeEmail = (e) => {
        setError("");
        setSendObj({ ...sendObj, email: e.target.value });
    };

    const handleCancel = () => {
        setError("");
        setSendObj({ ...sendObj, email: currentEmail });
        setChangeEmail(false);
    };

    const handleSend = () => {
        // get two api request due to backend dont return full unitobj //
        if (sendObj.email.length > 2) {
            dispatch(updateUser(sendObj));
            setChangeEmail(false);
        }
        setError("Du måste ha en giltlig e-post");
    };

    return (
        <FluidWrapper height="hg-small">
            {changeEmail ? (
                <div className="profileEmail-wrapper">
                    <h3>Email</h3>
                    <div className="profileEmail-textfield-wrapper">
                        <Textfield
                            type="email"
                            name="email"
                            text="Ändra email"
                            value={sendObj.email}
                            handleChange={(e) => {
                                handleChangeEmail(e);
                            }}
                            inputError={error}
                            customStyle={{ width: "18rem", marginRight: "1rem" }}
                        />
                    </div>
                    <div className="profileEmail-button-wrapper">
                        <SecondaryButton text="Avbryt" handleButton={handleCancel} />
                        <NormalButton
                            text="Spara"
                            handleButton={handleSend}
                            customStyle={{ marginLeft: "0.5rem" }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="profileEmail-noChange-header">
                        <h3>Email</h3>
                        <RoundButton
                            icon="pencil"
                            handleButton={() => {
                                setChangeEmail(true);
                            }}
                        />
                    </div>
                    <h4 className="profileEmail-noChange-name">{sendObj.email}</h4>
                </>
            )}
        </FluidWrapper>
    );
};

export default ProfileEmail;
