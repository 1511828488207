import type { CSSProperties } from "react";
import type * as React from "react";
import "./squareWrapper.css";

type SquareWrapperProps = {
    children: React.ReactNode;
    className?: string;
    customStyle?: CSSProperties;
};

const SquareWrapper = ({ children, className, customStyle }: SquareWrapperProps) => {
    return (
        <div className={`squareWrapper-container ${className || ""}`} style={customStyle}>
            {children}
        </div>
    );
};

export default SquareWrapper;
