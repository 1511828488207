import type { Form, Uuid } from "@ommej/types";
import { ErrorWithCause } from "pony-cause";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "~/src/components/tools/breadCrumbs";
import type { GlobalState } from "~/src/types";
import { getRoutingState } from "~/src/utils/systemFunctions";
import type { LanguageLocales } from "~/src/utils/ts-types";
import getAbortController from "../../utils/abort";
import { reqWithPayload, resolveFetchError } from "../../utils/api";
import { route } from "../../utils/constants";
import language from "../../utils/language";
import GhostButton from "../tools/buttons/ghostButton/ghostButton";
import FluidWrapper from "../tools/fluidWrapper/fluidWrapper";
import InvitationName from "../tools/invitation/invitationName";
import PageHeader from "../tools/pageHeader/pageHeader";
import PickerPopup from "../tools/pickerPopup";
import { getQuestions } from "./question-data";
import QuestionGraph from "./question-graph";
import "./statistics.css";

const fetchForm = async (formId, formVersion, signal) => {
    try {
        const response = await reqWithPayload(
            `data/forms/${formId}/${formVersion}`,
            "get",
            undefined,
            {
                signal,
            },
        );

        return { result: await response.json() };
    } catch (err) {
        return {
            err: new ErrorWithCause("Failed to fetch form", {
                cause: await resolveFetchError(err),
            }),
        };
    }
};

type StatisticsQuestionsProps = {
    showOptionAll?: boolean;
    showOptionClosed?: boolean;
    embed?: boolean;
};

const StatisticsQuestions = ({
    showOptionAll = true,
    showOptionClosed = true,
    embed = false,
}: StatisticsQuestionsProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const {
        HEADER_HEADING_ALL,
        HEADER_HEADING_CASE,
        HEADER_SUBHEADING,
        SELECTOR_LABEL,
        STATS_CLOSED_CASES,
        STATS_ALL_CASES,
    } = language[locales].STATISTICS;

    const [form, setForm] = useState<Form.FormData>();
    const [formId] = useState("general");
    const [formVersion] = useState(2);
    const [showPicker, setShowPicker] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState<Record<Uuid, string>>();
    const [includeAll, setIncludeAll] = useState(false);

    const {
        invitationId,
        questionsParam = "a601bc68-da0b-47a2-9fdf-c5446f32b2be",
    }: { invitationId?: string; questionsParam?: string } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);
    const [includeClosed, setIncludeClosed] = useState(false);
    const [questions, setQuestions] = useState<Uuid[]>([]);

    useEffect(() => {
        const qIds = questionsParam?.split(",") || [];
        if (!qIds) {
            return;
        }
        setQuestions(qIds);
    }, [questionsParam]);

    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });

    useEffect(() => {
        let unmounted = false;
        const abortController = getAbortController();
        fetchForm(formId, formVersion, abortController.signal).then(({ err, result }) => {
            if (unmounted) {
                return;
            }
            if (err) {
                console.error("Encountered an error when fetching form:", err);
            } else {
                setForm(result);
                const qs: Record<Uuid, string> = {};
                for (const question of questions) {
                    if (question === "gender") {
                        // these strings for gender and birthYear are from getQuestions in
                        // question-data.js and should preferably match those
                        qs[question] = "Könstillhörighet vs ålder";
                    } else if (question === "birthYear") {
                        qs[question] = "Ålder vs könstillhörighet";
                    } else {
                        qs[question] = result.questions[question]?.text?.sv || "";
                    }
                }
                setSelectedQuestions(qs);
            }
        });
        return () => {
            unmounted = true;
            abortController.abort();
        };
    }, [questions, formId, formVersion]);

    const content = useMemo(() => {
        return (
            <>
                <PageHeader
                    heading={invitationId ? HEADER_HEADING_CASE : HEADER_HEADING_ALL}
                    subheading={HEADER_SUBHEADING}
                >
                    {invitationId && <InvitationName {...{ invitationId }} />}
                </PageHeader>
                <div className="statistics-selected_statement">
                    <GhostButton
                        handleGhostButton={() => {
                            setShowPicker(true);
                        }}
                        text={SELECTOR_LABEL}
                        customStyle={{ marginRight: "1rem", minWidth: "10rem" }}
                    />
                </div>
                <div className="statistics-filters">
                    {showOptionAll && currentUser.email.endsWith("ommej.se") && (
                        <label htmlFor="includeAll" className="statistics-filter">
                            <input
                                onChange={() => {
                                    setIncludeAll(!includeAll);
                                }}
                                type="checkbox"
                                id="includeAll"
                                name="includeAll"
                            />
                            {STATS_ALL_CASES}
                        </label>
                    )}
                    {showOptionClosed && (
                        <label htmlFor="includeClosed" className="statistics-filter">
                            <input
                                onChange={() => {
                                    setIncludeClosed(!includeClosed);
                                }}
                                type="checkbox"
                                id="includeClosed"
                                name="includeClosed"
                            />
                            {STATS_CLOSED_CASES}
                        </label>
                    )}
                </div>
                <div className="statistics-statement">
                    <div className="statistics-statement__selection">
                        {showPicker && (
                            <PickerPopup
                                header={language[locales].STATISTICS.SELECTOR_LABEL}
                                items={getQuestions(form, locales as LanguageLocales, language)}
                                setShow={setShowPicker}
                                addAllCategory
                                allowMultiple
                                setSelected={(selected: Record<string, string>) => {
                                    if (!selected) {
                                        // nothing was selected in pickerpopup
                                        return;
                                    }

                                    const qIds = Object.keys(selected);

                                    const subpath = invitationId
                                        ? `/case/${invitationId}`
                                        : "/questions";
                                    const options = { state: routingState };
                                    if (!embed) {
                                        navigate(
                                            `${route.STATISTICS}${subpath}/${qIds.join(",")}`,
                                            options,
                                        );
                                    } else {
                                        setQuestions(qIds);
                                    }
                                    setSelectedQuestions(selected);
                                }}
                            />
                        )}
                    </div>
                    <div className={questions.length > 1 ? "statistics-graph-wrapper" : ""}>
                        {!showPicker &&
                            form &&
                            questions &&
                            questions.map((question) => {
                                return (
                                    <Fragment key={question}>
                                        <div className="statistics-grid-item">
                                            <h3 style={{ marginTop: "1.5rem" }}>
                                                {selectedQuestions?.[question] || ""}
                                            </h3>
                                            <div className="statistics-statement__result">
                                                <QuestionGraph
                                                    form={form}
                                                    formId={formId}
                                                    formVersion={formVersion}
                                                    invitationId={invitationId}
                                                    questions={[question]}
                                                    includeAll={includeAll}
                                                    includeClosed={includeClosed}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                );
                            })}
                    </div>
                </div>
            </>
        );
    }, [
        invitationId,
        selectedQuestions,
        form,
        formId,
        formVersion,
        questions,
        includeAll,
        includeClosed,
        showPicker,
    ]);

    if (embed) {
        return content;
    }
    return (
        <div className="app-component-container">
            <BreadCrumbs />
            <FluidWrapper height="hg-small" customStyle={{ maxWidth: "64rem" }}>
                {content}
            </FluidWrapper>
        </div>
    );
};

export default StatisticsQuestions;
