import type { Form, Invitation } from "@ommej/types";
import { useState } from "react";
import type * as React from "react";
import { useSelector } from "react-redux";
import GhostButton from "~/src/components/tools/buttons/ghostButton/ghostButton";
import type { LanguageState } from "~/src/types";
import language from "~/src/utils/language";
import "./caseFormCard.css";
import { formatDate } from "~/src/utils/systemFunctions";

export type SentFormWithMetadata = Invitation.FormRequest & {
    metadata: Form.Metadata;
};
type Props = {
    sentForm: SentFormWithMetadata;
    setShowAnswers?: (showAnswers: boolean) => void;
    handleShowAnswersClicked?: (form: SentFormWithMetadata) => void;
    onClick?: (form: SentFormWithMetadata) => void;
    disabled?: boolean;
    numberOfAnwers?: number;
};
const CaseFormCard: React.FC<Props> = ({
    sentForm,
    setShowAnswers,
    handleShowAnswersClicked,
    onClick,
    disabled = false,
    numberOfAnwers,
}: Props) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const [showButton, setShowButton] = useState(false);

    const showForm = (formToShow: SentFormWithMetadata) => {
        setShowAnswers?.(true);
        handleShowAnswersClicked?.(formToShow);
    };

    return (
        <>
            <div
                className={`caseFormCard-sent-card ${disabled && "caseFormCard-sent-card-disabled"}`}
                onMouseEnter={
                    disabled
                        ? undefined
                        : () => {
                              setShowButton(true);
                          }
                }
                onMouseLeave={
                    disabled
                        ? undefined
                        : () => {
                              setShowButton(false);
                          }
                }
                key={sentForm.id}
                onClick={
                    disabled
                        ? undefined
                        : () => {
                              onClick?.(sentForm);
                          }
                }
                onKeyPress={
                    disabled
                        ? undefined
                        : () => {
                              onClick?.(sentForm);
                          }
                }
                style={{ cursor: onClick && !disabled ? "pointer" : "initial" }}
            >
                <div>
                    {sentForm.metadata.name}
                    <div className="text-description text-grey">
                        {`${
                            language[locales].CASE.EDIT_INVITATION.SMS.SENT
                        }: ${formatDate(new Date(sentForm.created))}`}
                        {numberOfAnwers !== undefined &&
                            ` | ${numberOfAnwers} ${language[locales].COMMON.ANSWERS.toLowerCase()}`}
                    </div>
                </div>
                {!onClick && showButton && (
                    <GhostButton
                        text="Se svar"
                        handleGhostButton={() => {
                            showForm(sentForm);
                        }}
                    />
                )}
            </div>
            <span className="userCard-span-border" />
        </>
    );
};

export default CaseFormCard;
