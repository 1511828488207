import PropTypes from "prop-types";
import { OrganizationIcon } from "~/src/utils/icons";
import Types from "~/src/utils/types";
import "./unitIcon.css";

const UnitIcon = ({ unit }) => {
    if (!unit) {
        return null;
    }
    return (
        <div className="uniticon-main-wrapper">
            <div className="uniticon-icon-wrapper" style={{ background: "var(--color-beige)" }}>
                <OrganizationIcon />
            </div>
            <div className="uniticon-text-wrapper">
                <h4>{unit.name}</h4>
            </div>
        </div>
    );
};

UnitIcon.propTypes = {
    unit: PropTypes.shape(Types.unit).isRequired,
};

export default UnitIcon;
