import type { BBObject, Form } from "@ommej/types";
import {
    ArcElement,
    Chart,
    type ChartType,
    PolarAreaController,
    RadialLinearScale,
} from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AlarmIcon from "~/src/media/images/svg/alarm-peach.svg";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import "./backaBarnetChart.css";

Chart.register(PolarAreaController, ArcElement, RadialLinearScale);

const BBcolors = [
    "#29438C",
    "#4A427A",
    "#CB6D99",
    "#C85C70",
    "#D38456",
    "#E1AE5D",
    "#79AF81",
    "#76B4B9",
];

type BBObjectWithAlarmData = BBObject & { alarms?: Partial<Record<Form.QuestionTagsBB, boolean>> };

type BackaBarnetChartProps = {
    data: BBObjectWithAlarmData;
};

const BackaBarnetChart = ({ data }: BackaBarnetChartProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { BACKA_BARNET, QUESTION_TAGS, COMMON } = language[locales];
    const [alarmIconStylings, setAlarmIconStylings] = useState<Map<string, Record<string, string>>>(
        new Map(),
    );
    const canvasElement = useRef<HTMLCanvasElement | null>(null);
    let backaBarnetChart: Chart | null;

    const buildChart = (): void => {
        if (!canvasElement.current) {
            return;
        }
        backaBarnetChart = new Chart(canvasElement.current, {
            type: "polarArea" as ChartType,
            data: {
                labels: Object.keys(data.values),
                datasets: [
                    {
                        label: "BackaBarnet-chart",
                        data: Object.values(data.values).map((value) => {
                            return Math.round(value);
                        }),
                        borderWidth: 2,
                        backgroundColor: BBcolors,
                    },
                ],
            },
            options: {
                scales: {
                    r: {
                        max: 6,
                        angleLines: {
                            display: true,
                        },
                        ticks: {
                            stepSize: 1,
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        backgroundColor: "#4956ca",
                        bodyColor: "#ffffff",
                        displayColors: false,
                        padding: 16,
                        callbacks: {
                            title: (titleItem) => {
                                return titleItem.map((title) => {
                                    return `${QUESTION_TAGS[title.label]}, ${COMMON.AVERAGE_VALUE.toLowerCase()}: ${title.raw}`;
                                });
                            },
                            label: (tooltipItem) => {
                                if (data.answers[tooltipItem.label]) {
                                    return data.answers[tooltipItem.label].map((item) => {
                                        if (item.question?.text && item.answer?.text) {
                                            return `${item.question.text.sv}: ${item.answer.text.sv}`;
                                        }
                                        return null;
                                    });
                                }
                                return null;
                            },
                        },
                    },
                },
            },
        });
    };

    useEffect(() => {
        if (data.alarms) {
            const alarmStylings = new Map();
            Object.entries(data.alarms).forEach(([key, value], index, thisArray) => {
                if (!value) {
                    return;
                }
                const numSections = thisArray.length;
                const angle = (((index + 1) * 2 - 1) * 2 * Math.PI) / (2 * numSections);
                const sinAngle = Math.sin(angle);
                const cosAngle = Math.cos(angle);
                // origin + radius of circle´in unit "percent"(%) times cos/sin(angle)
                const xPos = 50 + 50 * sinAngle;
                const yPos = 50 - 50 * cosAngle;
                // extend radius of alarmIcons circle by 1rem
                const extraRadius = "1rem";

                const alarmIconStyling = {
                    left: `calc(${xPos}% + ${extraRadius}*${sinAngle})`,
                    top: `calc(${yPos}% - ${extraRadius}*${cosAngle})`,
                    /* Compensate for left/top placement.
                     * - top/left percentage refers to parent's area
                     * - translate percentage refers to area of object itself.
                     * Instead of object's top left corner at (x,y)(%) of parent's area
                     * we get object's center at (x,y)(%) of parent's area.
                     */
                    transform: "translate(-50%, -50%)",
                };

                alarmStylings.set(key, alarmIconStyling);
            });
            setAlarmIconStylings(alarmStylings);
        }
        buildChart();
        return () => {
            backaBarnetChart?.destroy();
        };
    }, [data]);

    if (!data) {
        return null;
    }
    return (
        <div className="backaBarnetChart-wrapper">
            <canvas ref={canvasElement}>
                <h2>{BACKA_BARNET.DIAGRAM}</h2>
                <ul>
                    {Object.entries(data.values).map(([key, value]) => {
                        return (
                            <li key={key} style={{ display: "flex" }}>
                                <p>{`${QUESTION_TAGS[key]}: ${BACKA_BARNET.CHILD_VALUE} ${value}`}</p>
                            </li>
                        );
                    })}
                </ul>
            </canvas>
            {Object.keys(data.values).map((key) => {
                return (
                    alarmIconStylings.has(key) && (
                        <div key={key}>
                            <img
                                className="alarm-indicator"
                                style={alarmIconStylings.get(key)}
                                src={AlarmIcon}
                                alt="alarm answers present"
                            />
                        </div>
                    )
                );
            })}
        </div>
    );
};

export default BackaBarnetChart;
