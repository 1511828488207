import type { Form, Invitation } from "@ommej/types";
import { type ReactNode, useEffect, useState } from "react";
import type * as React from "react";
import { useSelector } from "react-redux";
import Accordion from "~/src/components/tools/accordion/accordion";
import NormalButton from "~/src/components/tools/buttons/normalButton/normalButton";
import SecondaryButton from "~/src/components/tools/buttons/secondaryButton/secondaryButton";
import Dropdown from "~/src/components/tools/dropdown/dropdown";
import type { DropdownItemsArray, OptionItem } from "~/src/components/tools/dropdown/dropdown";
import Tooltip from "~/src/components/tools/tooltip/tooltip";
import PreviewIcon from "~/src/media/images/svg/preview.svg";
import type { LanguageState } from "~/src/types";
import { reqWithPath, reqWithPayload } from "~/src/utils/api";
import { QuestionMarkIcon } from "~/src/utils/icons";
import { willReplaceForm } from "~/src/utils/invitationFunctions";
import language from "~/src/utils/language";
import CaseFormCard from "./cards/caseFormCard";
import type { SentFormWithMetadata } from "./cards/caseFormCard";
import FormPreview from "./formPreview";
import ShowFollowUp from "./showFollowUp";
import "./caseForms.css";

type Props = {
    invitation: Invitation.ToClient;
    header?: ReactNode;
};

const CaseForms: React.FC<Props> = ({ invitation, header }: Props) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const { FORMS } = language[locales].CASE.EDIT_INVITATION;
    const [availableForms, setAvailableForms] = useState<Form.Metadata[]>([]);
    const [dropDownItems, setDropDownItems] = useState<DropdownItemsArray>([]);
    const [currentInvitation, setCurrentInvitation] = useState<Invitation.ToClient>(invitation);
    const [sentForms, setSentForms] = useState<SentFormWithMetadata[]>();
    const [currentForm, setCurrentForm] = useState<Form.Metadata>();
    const [shouldShowComponent, setShouldShowComponent] = useState(false);
    const [showAnswers, setShowAnswers] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [formToShow, setFormToShow] = useState<SentFormWithMetadata>();
    const [formWillOverride, setFormWillOverride] = useState(false);

    useEffect(() => {
        // Filter forms and turn them into dropdown items
        // We want surveys, the ones with shareable:false and mutable:false
        const dropdownItems: DropdownItemsArray = availableForms.reduce(
            (workingCopy: DropdownItemsArray, item: Form.Metadata) => {
                if (!item.shareable && !item.mutable) {
                    const oI: OptionItem = {
                        value: `${item.id}-${item.version}`,
                        text: item.name,
                    };
                    workingCopy.push(oI);
                }
                return workingCopy;
            },
            [],
        );
        setShouldShowComponent(!!dropdownItems.length);
        setDropDownItems(
            dropdownItems.sort((a, b) => {
                return a.text.localeCompare(b.text);
            }),
        );
    }, [availableForms]);

    useEffect(() => {
        const getAvailableForms = async () => {
            try {
                const req = await reqWithPath("data/forms", "get", "");
                const formsMetadata: Form.Metadata[] = await req.json();
                setAvailableForms(formsMetadata);
            } catch (error) {
                console.error(error);
                // TODO handle error
            }
        };
        getAvailableForms();
    }, [invitation]);

    const handleValue = (form: string) => {
        const selectedform = availableForms.find((item: Form.Metadata) => {
            return form === `${item.id}-${item.version}`;
        });
        setCurrentForm(selectedform);
    };

    const handleShowAnswersClicked = (form: SentFormWithMetadata) => {
        setFormToShow(form);
    };

    const handleSendForm = async () => {
        try {
            if (currentForm) {
                const formRef: Form.Ref = {
                    id: currentForm.id,
                    version: currentForm.version,
                };
                await reqWithPayload(`invitations/${currentInvitation.id}/forms`, "post", formRef);
                const updatedInvitationResponse = await reqWithPayload(
                    `invitations/${invitation.id}`,
                    "get",
                );
                const updatedInvitation = await updatedInvitationResponse.json();
                setCurrentInvitation(updatedInvitation);
            }
        } catch (error) {
            console.error(error);
        }
    };
    /* Populate the sentForms list with necessary data
     * Do this whenever the available forms has been loaded
     * or the invitation has been updated and reloaded.
     */
    useEffect(() => {
        if (!currentInvitation.forms) {
            return;
        }
        const sentFormsWithMetadata = currentInvitation.forms.reduce(
            (workingCopy: SentFormWithMetadata[], sentForm: Invitation.FormRequest) => {
                const foundInAvailable = availableForms.find((availableForm) => {
                    return (
                        availableForm.id === sentForm.form.id &&
                        availableForm.version === sentForm.form.version
                    );
                });
                if (foundInAvailable) {
                    workingCopy.push(
                        Object.assign(sentForm, {
                            metadata: foundInAvailable,
                        }),
                    );
                }
                return workingCopy;
            },
            [],
        );
        setSentForms(sentFormsWithMetadata);
    }, [availableForms, currentInvitation]);

    useEffect(() => {
        setFormWillOverride(false);

        async function load() {
            if (!currentForm || !sentForms) {
                return;
            }

            const willReplace = await willReplaceForm(currentForm, currentInvitation);
            setFormWillOverride(willReplace);
        }

        load();
    }, [currentForm]);

    if (!shouldShowComponent) {
        return null;
    }

    return (
        <>
            {showPreview && currentForm && (
                <FormPreview setShow={setShowPreview} form={currentForm} />
            )}
            {showAnswers && formToShow && (
                <ShowFollowUp
                    show={showAnswers}
                    invitation={currentInvitation}
                    setShow={setShowAnswers}
                    formMetadata={formToShow}
                />
            )}
            <div className="caseForms-header">
                {header || <h3>{FORMS.HEADING}</h3>}
                <Tooltip title={FORMS.TOOLTIP} position="left">
                    <QuestionMarkIcon />
                </Tooltip>
            </div>
            <div className="caseForms-dropdown">
                <Dropdown
                    items={dropDownItems}
                    handleChange={(form) => {
                        handleValue(form);
                    }}
                    value={!currentForm ? undefined : `${currentForm.id}-${currentForm.version}`}
                />
                <NormalButton
                    text={FORMS.BUTTON_ADD}
                    color="green"
                    handleButton={() => {
                        handleSendForm();
                    }}
                />
            </div>
            {currentForm && Object.keys(currentForm).length > 0 && (
                <button
                    type="button"
                    className="caseForm-preview-button"
                    onClick={() => {
                        setShowPreview(true);
                    }}
                >
                    <img src={PreviewIcon} alt={FORMS.PREVIEW} />
                    {FORMS.PREVIEW_FORM}
                </button>
            )}
            {formWillOverride && (
                <Accordion accordionActive={formWillOverride}>
                    <div style={{ paddingTop: 0 }} className="caseMove-accordion-wrapper">
                        {FORMS.DUPLICATE_FORM_WARNING}
                    </div>
                    <div className="caseMove-accordion-buttons">
                        <SecondaryButton
                            handleButton={() => {
                                setCurrentForm(undefined);
                            }}
                            text={`${language[locales].CASE.EDIT_INVITATION.MOVE.CANCEL_BUTTON}`}
                        />
                    </div>
                </Accordion>
            )}

            {sentForms && sentForms.length > 0 && (
                <div className="caseForms-sent">
                    <h3>{FORMS.SUBHEADER}</h3>
                    {sentForms.map((sentForm: SentFormWithMetadata) => {
                        return (
                            <CaseFormCard
                                sentForm={sentForm}
                                key={sentForm.id}
                                setShowAnswers={setShowAnswers}
                                handleShowAnswersClicked={handleShowAnswersClicked}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default CaseForms;
