// @ts-check

export const copyToClipboard = (copy) => {
    const textField = document.createElement("textarea");
    textField.innerText = copy;
    document.body.appendChild(textField);
    textField.select();
    const copyValue = document.execCommand("copy");
    textField.remove();
    return copyValue;
};

export const generateRandomString = () => {
    let link = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const length = Math.floor(Math.random() * 6) + 5;
    for (let i = 0; i < length; i += 1) {
        link += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return link;
};

export const formatDate = (date, locale = "sv") => {
    let dateStr;
    if (!date || date === -1) {
        dateStr = "-";
    } else {
        const dateObj = new Date(Date.parse(date));
        dateStr = new Intl.DateTimeFormat(locale, { dateStyle: "long" }).format(dateObj);
    }
    return dateStr;
};

export const getRoutingState = (location) => {
    const routingState = {
        ommejReferer: true,
        from: location.pathname,
    };
    return routingState;
};
