import { useCallback, useState } from "react";
import type * as React from "react";
import { useSelector } from "react-redux";
import type { GlobalState, Unit } from "~/src/types";
import Can from "../../../../rbac/can";
import { roles } from "../../../../utils/constants";
import { ArrowRightIcon } from "../../../../utils/icons";
import language from "../../../../utils/language/index";
import NormalButton from "../../../tools/buttons/normalButton/normalButton";
import AddUnit from "../modals/addUnit/addUnit";
import "./unitHeader.css";

type UnitHeaderProps = {
    crumbs: Unit[];
    setCrumbs: React.Dispatch<React.SetStateAction<Unit[]>>;
    handleStep: (currentStep: number) => void;
    setSelected: React.Dispatch<React.SetStateAction<Unit | null>>;
    selected: Unit | null;
    role: string;
};

const UnitHeader = ({
    crumbs,
    setCrumbs,
    handleStep,
    setSelected,
    selected,
    role,
}: UnitHeaderProps) => {
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const [show, setShow] = useState(false);

    const openModal = () => {
        setShow(!show);
    };

    const handleStart = () => {
        setSelected(null);
        setCrumbs([]);
        handleStep(-1);
    };

    const handleBreadcrumb = (idx) => {
        const filteredCrumb = [...crumbs].slice(0, idx + 1);
        setCrumbs(filteredCrumb);
        handleStep(idx);
        setSelected(filteredCrumb[filteredCrumb.length - 1] ?? selected);
    };

    const handleYes = useCallback(() => {
        return (
            <NormalButton
                icon="plus"
                className="unitHeader-create-button"
                text={language[locales].ADMIN.UNIT.ADD_UNIT_BUTTON}
                handleButton={() => {
                    openModal();
                }}
            />
        );
    }, []);

    return (
        <div className="unitHeader-wrapper">
            <div className="unitHeader-breadcrumb-wrapper">
                {crumbs.length === 0 ? (
                    <h3>{language[locales].ADMIN.UNIT.UNIT_HEADER}</h3>
                ) : (
                    <>
                        <button className="unit-breadcrumb" type="button" onClick={handleStart}>
                            Start
                        </button>
                        {crumbs.map((crumb, i) => {
                            return (
                                <button
                                    className="unit-breadcrumb"
                                    key={crumb._id}
                                    onClick={() => {
                                        handleBreadcrumb(i);
                                    }}
                                    type="button"
                                >
                                    <ArrowRightIcon />
                                    {crumb.name}
                                </button>
                            );
                        })}
                    </>
                )}
            </div>
            {crumbs.length !== 0 && currentUser.roleCode !== roles.user ? (
                <Can
                    role={role}
                    perform="unit:create"
                    yes={() => {
                        return handleYes();
                    }}
                    no={() => {
                        return null;
                    }}
                    data={undefined}
                />
            ) : null}
            {show && <AddUnit show={show} setShow={setShow} unit={selected} role={role} />}
        </div>
    );
};

export default UnitHeader;
