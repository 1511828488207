import { useSelector } from "react-redux";
import language from "../../../../utils/language";
import FluidWrapper from "../../../tools/fluidWrapper/fluidWrapper";
import ProfileCard from "../components/profileCard/profileCard";
import "./profileUnit.css";

const ProfileUnit = () => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const { units, loading } = useSelector((state) => {
        return state.unit;
    });
    const unitObj = useSelector((state) => {
        return state.unit.unitObj;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });

    if (loading || units.length === 0 || unitObj.length === 0) {
        return null;
    }

    return (
        <FluidWrapper height="hg-small">
            <div className="profileUnit-noChange-header">
                <h3>{language[locales].ADMIN.PROFILE.PROFILE_UNIT.HEADING}</h3>
            </div>
            <div className="profileUnit-noChange-wrapper">
                <ProfileCard unit={unitObj[currentUser.unit]} active={false} />
            </div>
        </FluidWrapper>
    );
};

export default ProfileUnit;
