import {
    CancelIcon,
    PencilIcon,
    PlusIcon,
    QuestionMarkIcon,
    TrashBinIcon,
} from "~/src/utils/icons";
import "./roundButton.css";

const ICONS = {
    pencil: <PencilIcon />,
    plus: <PlusIcon />,
    question: <QuestionMarkIcon />,
    cancel: <CancelIcon />,
    trashbin: <TrashBinIcon />,
};

export default function RoundButton({
    handleButton,
    icon,
    border = true,
    color,
    className,
    customStyle,
}: {
    handleButton?: () => void;
    icon: keyof typeof ICONS;
    border?: boolean;
    color?: string;
    className?: string;
    customStyle?: React.CSSProperties;
}) {
    return (
        <button
            className={`roundButton-wrapper ${className} ${border && "roundButton-border"} ${
                color === "beige" && "roundButton-beige"
            } `}
            type="button"
            onClick={() => {
                handleButton?.();
            }}
            style={customStyle}
        >
            {icon && ICONS[icon]}
        </button>
    );
}
