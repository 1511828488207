import type { CSSProperties } from "react";
import type * as React from "react";
import { PencilIcon, PlusIcon } from "../../../../utils/icons";
import "./bigButton.css";

type BigButtonProps = {
    text: string;
    icon?: string;
    className?: string;
    handleButton?: (e: React.MouseEvent<HTMLElement> | React.FormEvent) => void;
    color?: string;
    customStyle?: CSSProperties;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
};

const BigButton = ({
    text,
    icon,
    color = "blue",
    className,
    handleButton,
    customStyle,
    disabled,
    type = "button",
}: BigButtonProps) => {
    const ICONS = {
        pencil: <PencilIcon />,
        plus: <PlusIcon />,
    };

    return (
        <button
            type={type}
            disabled={disabled}
            className={`bigbutton-wrapper bigbutton-${color} ${className}`}
            onClick={handleButton}
            onSubmit={(e) => {
                if (handleButton) {
                    handleButton(e);
                }
            }}
            style={customStyle && customStyle}
        >
            {icon && ICONS[icon]}
            <p>{text}</p>
        </button>
    );
};

export default BigButton;
