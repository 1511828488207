import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import BreadCrumbs from "~/src/components/tools/breadCrumbs";
import SearchInput from "~/src/components/tools/form/search/searchInput";
import { getRoutingState } from "~/src/utils/systemFunctions";
import { getAllInvitations } from "../../../redux/actions/invitation/actions";
import { getAllUsers } from "../../../redux/actions/users/actions";
import { roles, route } from "../../../utils/constants";
import language from "../../../utils/language";
import FluidWrapper from "../../tools/fluidWrapper/fluidWrapper";
import UserCard from "./cards/userCard";
import UserHeader from "./header/userHeader";
import User from "./modals/user/user";
import "./users.css";

const Users = () => {
    const usersLoaded = useSelector((state) => {
        return state.user.loaded;
    });
    const users = useSelector((state) => {
        return state.user.users;
    });
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const dispatch = useDispatch();
    let { id } = useParams();
    const location = useLocation();
    const routingState = getRoutingState(location);

    let isMounted = true;
    const loadResources = async () => {
        if (!usersLoaded) {
            try {
                await dispatch(getAllUsers());
                await dispatch(getAllInvitations());
                if (isMounted) {
                    setErrorMessage(null);
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };
    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    const handleUser = (user) => {
        id = user._id;
    };

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    if (!usersLoaded) {
        return null;
    }
    return (
        <div className="app-component-container">
            <BreadCrumbs />
            <FluidWrapper height="hg-minHeight" customStyle={{ marginBottom: "7rem" }}>
                <UserHeader />
                <div style={{ marginBottom: "1.5rem" }}>
                    <SearchInput
                        name="search-users"
                        placeholder="Sök användare"
                        value={searchValue}
                        handleChange={(e) => {
                            setSearchValue(e.target.value.toLowerCase());
                        }}
                    />
                </div>

                {users.length === 0 ? (
                    <div>{language[locales].ADMIN.USER.USERCARD_NO_USER}</div>
                ) : (
                    users
                        .filter((user) => {
                            return user.roleCode !== roles.systemAdmin;
                        })
                        .filter((user) => {
                            return user._id !== currentUser._id;
                        })
                        .filter((user) => {
                            return (
                                user.username.toLowerCase().includes(searchValue) ||
                                user.email.toLowerCase().includes(searchValue)
                            );
                        })
                        .map((user) => {
                            return (
                                <Link
                                    key={user._id}
                                    className="users-link-wrapper"
                                    to={`${route.ADMIN_USERS}/${user._id}`}
                                    state={routingState}
                                >
                                    <UserCard
                                        user={user}
                                        text={language[locales].ADMIN.USER.USERCARD_TEXT}
                                        handleGhostButton={handleUser}
                                        button="pencil"
                                    />
                                </Link>
                            );
                        })
                )}
            </FluidWrapper>
            {id && <User id={id} />}
        </div>
    );
};

export default Users;
