import { houseStrings, personTypes } from "@ommej/metadata";
import { type ReactNode, useCallback } from "react";
import { useSelector } from "react-redux";
import type { ClientInfo, GlobalState } from "~/src/types";
import language from "~/src/utils/language";

const ConversationAccommodations = ({
    client,
    className,
}: {
    client: ClientInfo;
    className: string;
}) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { COMMON } = language[locales];

    const getDom = useCallback(() => {
        if (!client.profile?.houses) {
            return null;
        }

        const houses: ReactNode[] = [];
        let i = 1;
        for (const [houseId, house] of Object.entries(client.profile.houses)) {
            const personIds = client.profile.housePersonMap[houseId];
            const persons: string[] = [];
            if (personIds) {
                for (const personId of personIds) {
                    const person = client.profile.persons[personId];
                    if (!person?.type || !personTypes[person.type]) {
                        continue;
                    }

                    // biome-ignore lint/style/noNonNullAssertion: checked above
                    persons.push(personTypes[person.type]!.lang[locales]);
                }
            }

            const node = (
                <div
                    key={houseId}
                    style={{
                        display: "grid",
                        placeItems: "center",
                        textAlign: "center",
                        margin: 0,
                        maxWidth: "12rem",
                    }}
                >
                    <div
                        style={{
                            background: "var(--color-indigo-ice)",
                            borderRadius: "50%",
                            boxSizing: "content-box",
                            display: "grid",
                            height: "8rem",
                            padding: "0.75rem",
                            placeItems: "center",
                            width: "8rem",
                        }}
                    >
                        <img
                            src={`/assets/houses/${house.avatar}.svg`}
                            alt={houseStrings[house.type].lang[locales]}
                            style={{ width: "5rem", height: "5rem", objectFit: "contain" }}
                            title={houseStrings[house.type].lang[locales]}
                        />
                    </div>
                    <span>
                        <h3
                            style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                        >{`${COMMON.ACCOMMODATION} ${i}`}</h3>
                        <div className="text-grey">{persons?.join(", ")}</div>
                    </span>
                </div>
            );
            i += 1;

            houses.push(node);
        }

        return houses;
    }, [client]);

    return (
        <section className={`conversation-section-wrapper ${className}`} style={{}}>
            <h2 style={{ marginBottom: "2rem" }}>{COMMON.YOU_LIVE_HERE}</h2>
            <div
                style={{
                    alignItems: "start",
                    display: "grid",
                    gap: "1.5rem",
                    gridTemplateColumns: "auto auto auto",
                    justifyContent: "center",
                }}
            >
                {getDom()}
            </div>
        </section>
    );
};

export default ConversationAccommodations;
