import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonHomeCard from "~/src/skeletons/skeletonHomeCard";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import "./news.css";

const defaultImgUrl = "https://files.ommej.se/images/front/1.1.jpg";

type Post = {
    title: {
        rendered: string;
    };
    content: {
        rendered: string;
    };
    _links: {
        "wp:featuredmedia": {
            embeddable: boolean;
            href: string;
        };
    };
    _embedded: {
        "wp:featuredmedia": Array<{
            source_url: string;
            link: string;
        }>;
    };
};

type Posts = Array<Post>;

const getPosts = async (): Promise<Posts | null> => {
    // gets all posts of type 'horizon'. _embed makes sure we also get the imgUrl. For
    // _embed to work it seems we also need _links.wp:featuredmedia
    const response = await fetch(
        "https://ommej.se/wp-json/wp/v2/horizon?_fields=title,content,_embedded,_links.wp:featuredmedia,_embedded&_embed",
    );

    if (response.status === 200) {
        const jsonData = await response.json();
        return jsonData;
    }
    return null;
};

const News = () => {
    enum NewsState {
        WAITING = 0,
        NO_CONTENT = 1,
    }
    const [currentPost, setCurrentPost] = useState<Post | NewsState>(NewsState.WAITING);

    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });

    useEffect(() => {
        getPosts()
            .then((posts) => {
                if (posts) {
                    setCurrentPost(
                        posts[Math.floor(Math.random() * posts.length)] || NewsState.NO_CONTENT,
                    );
                } else {
                    setCurrentPost(NewsState.NO_CONTENT);
                }
            })
            .catch((err) => {
                console.error("Failed to fetch news", err);
                setCurrentPost(NewsState.NO_CONTENT);
            });
    }, []);

    if (currentPost === NewsState.WAITING) {
        return <SkeletonHomeCard />;
    }

    if (currentPost === NewsState.NO_CONTENT) {
        return null;
    }

    const imgUrl = currentPost._embedded?.["wp:featuredmedia"]?.["0"]?.source_url || defaultImgUrl;
    const postLink = currentPost._embedded?.["wp:featuredmedia"]?.["0"]?.link;

    return (
        <div className="home-card" style={{ padding: "0" }}>
            <img className="news-img" src={imgUrl} alt="" />
            <div className="news-text-wrapper">
                <h2>{currentPost.title.rendered}</h2>
                <div
                    className="news-small-text"
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: allow
                    dangerouslySetInnerHTML={{
                        __html: currentPost.content.rendered.replace(/[\n\r]/g, ""),
                    }}
                />
                {postLink && (
                    <a href={postLink} style={{ display: "flex", alignItems: "center" }}>
                        {language[locales].COMMON.READ_FULL_ARTICLE}
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none">
                            <title>Extern länk</title>
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth=".9"
                                d="M12.6 9v2.8a1 1 0 0 1-.9 1h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h2.7m1.9-1.3H14v2.8M9 9.5l5-5"
                            />
                        </svg>
                    </a>
                )}
            </div>
        </div>
    );
};

export default News;
