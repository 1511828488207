import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Types from "~/src/utils/types";
import Can from "../../../../rbac/can";
import { userRoles } from "../../../../utils/constants";
import GhostButton from "../../../tools/buttons/ghostButton/ghostButton";
import "./userCard.css";

const UserCard = ({ user, role, text, icon, handleGhostButton, visibleButton }) => {
    const [showButton, setShowButton] = useState(false);

    const handleYes = useCallback(() => {
        return (
            <GhostButton
                tabIndex="0"
                text={text}
                icon={icon}
                handleGhostButton={handleGhostButton}
            />
        );
    }, []);

    if (!user) {
        return null;
    }

    return (
        <>
            <div
                className="userCard-small-wrapper"
                onMouseEnter={() => {
                    setShowButton(true);
                }}
                onMouseLeave={() => {
                    setShowButton(false);
                }}
            >
                <div className="userCard-small-main-wrapper">
                    <div className="userCard-small-text-wrapper">
                        <p>{user.username}</p>
                        <p className="text-description text-grey">{`${userRoles[user.roleCode]} - ${
                            user.unit.name
                        }`}</p>
                    </div>
                    {showButton && visibleButton && (
                        <div className="userCard--small-button-wrapper">
                            <Can
                                role={role}
                                perform="unit:update"
                                yes={() => {
                                    return handleYes();
                                }}
                                no={() => {
                                    return null;
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <span className="userCard--small-span-border" />
        </>
    );
};

UserCard.defaultProps = {
    handleGhostButton: null,
    text: "Hantera",
    visibleButton: true,
};

UserCard.propTypes = {
    user: PropTypes.shape({
        _id: PropTypes.string,
        email: PropTypes.string,
        unit: PropTypes.shape(Types.unit),
        username: PropTypes.string,
        roleCode: PropTypes.string,
    }).isRequired,
    role: PropTypes.string.isRequired,
    text: PropTypes.string,
    icon: PropTypes.string.isRequired,
    visibleButton: PropTypes.bool,
    handleGhostButton: PropTypes.func,
};

export default UserCard;
