import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PasswordStrength from "~/src/components/tools/passwordStrength";
import { minimumPasswordLength } from "~/src/utils/constants";
import { updateUserPassword } from "../../../../redux/actions/users/actions";
import language from "../../../../utils/language";
import NormalButton from "../../../tools/buttons/normalButton/normalButton";
import RoundButton from "../../../tools/buttons/roundButton/roundButton";
import SecondaryButton from "../../../tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "../../../tools/fluidWrapper/fluidWrapper";
import Textfield from "../../../tools/form/textfield/textfield";
import "./profilePassword.css";

const ProfilePassword = () => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const [changePassword, setChangePassword] = useState(false);
    const [currentPassword] = useState("**********");
    const [error, setError] = useState("");
    const [newPassword, setNewPassword] = useState({
        password: "",
        confirmPassword: "",
    });
    const [sendObj, setSendObj] = useState({
        role: currentUser.roleCode.toLowerCase(),
        _id: currentUser._id,
        email: currentUser.email,
        username: currentUser.username,
        password: newPassword.password,
        unit: currentUser.unit,
    });
    const dispatch = useDispatch();

    const handlePasswordCharacter = (e) => {
        setError("");
        setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
        if (e.target.name === "password") {
            setSendObj({ ...sendObj, password: e.target.value });
        }
    };

    const handleCancel = () => {
        setError("");
        setChangePassword(false);
        setNewPassword({ ...newPassword, password: "", confirmPassword: "" });
    };

    let isMounted = true;
    const handleSend = async () => {
        if (newPassword.password.length < minimumPasswordLength) {
            setError(language[locales].RESET_PASSWORD.ERROR_TOO_SHORT);
        } else if (newPassword.password === newPassword.confirmPassword) {
            // need to validate password
            try {
                await dispatch(updateUserPassword(sendObj._id, sendObj.password));
                setChangePassword(false);
            } catch (_e) {
                if (isMounted) {
                    setError(language[locales].ADMIN.USER.EDIT_USER.PASSWORD_ERROR);
                }
            }
        } else {
            setError(language[locales].ADMIN.USER.EDIT_USER.PASSWORD_MISMATCH);
        }
    };

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <FluidWrapper height="hg-small">
            {changePassword ? (
                <div className="profilePassword-wrapper">
                    <h3>Lösenord</h3>
                    <div className="profilePassword-textfield-wrapper">
                        <Textfield
                            type="password"
                            name="password"
                            text="Fyll i nytt lösenord"
                            value={newPassword.password}
                            handleChange={(e) => {
                                handlePasswordCharacter(e);
                            }}
                            customStyle={{ width: "15rem" }}
                        />
                        <PasswordStrength password={newPassword.password} locales={locales} />
                        <Textfield
                            type="password"
                            name="confirmPassword"
                            text="Konfirmera ditt nya lösenord"
                            value={newPassword.confirmPassword}
                            handleChange={(e) => {
                                handlePasswordCharacter(e);
                            }}
                            inputError={error}
                            customStyle={{ width: "15rem" }}
                        />
                    </div>
                    <div className="profilePassword-button-wrapper">
                        <SecondaryButton text="Avbryt" handleButton={handleCancel} />
                        <NormalButton
                            text="Spara"
                            handleButton={handleSend}
                            customStyle={{ marginLeft: "0.5rem" }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="profilePassword-noChange-header">
                        <h3>Lösenord</h3>
                        <RoundButton
                            icon="pencil"
                            handleButton={() => {
                                setChangePassword(true);
                            }}
                        />
                    </div>
                    <h4 className="profilePassword-noChange-name">{currentPassword}</h4>
                </>
            )}
        </FluidWrapper>
    );
};

export default ProfilePassword;
