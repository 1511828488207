import type { Invitation } from "@ommej/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import { getAllUnits } from "../../../../redux/actions/units/actions";
import { getAllUsers } from "../../../../redux/actions/users/actions";
import language from "../../../../utils/language";
import { formatDate } from "../../../../utils/systemFunctions";
import "./caseInformation.css";

const CaseInformation = ({ invitation }: { invitation: Invitation.ToClient }) => {
    const usersLoaded = useSelector((state: GlobalState) => {
        return state.user.loaded;
    });
    const unitsLoaded = useSelector((state: GlobalState) => {
        return state.unit.loaded;
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const dispatch = useDispatch();

    let isMounted = true;

    const loadResources = async () => {
        try {
            if (!unitsLoaded) {
                await dispatch(getAllUnits());
            }
            if (!usersLoaded) {
                await dispatch(getAllUsers());
            }
            if (isMounted) {
                setErrorMessage(null);
            }
        } catch (_error) {
            if (isMounted) {
                setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
            }
        }
    };

    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    if (!usersLoaded || !unitsLoaded) {
        return null;
    }
    return (
        <>
            <h3 className="caseInformation-header">
                {language[locales].CASE.EDIT_INVITATION.INFO.HEADING}
            </h3>
            <div className="caseInformation-paragraph-wrapper">
                <h4 className="text-grey">
                    {language[locales].CASE.EDIT_INVITATION.INFO.CREATED_BY}
                </h4>
                <h4>{`${invitation.createdBy?.username ?? language[locales].COMMON.REMOVED_USER}`}</h4>
            </div>
            <div className="caseInformation-paragraph-wrapper">
                <h4 className="text-grey">
                    {language[locales].CASE.EDIT_INVITATION.INFO.OWNED_BY}
                </h4>
                <h4>{`${invitation.ownedBy?.username ?? language[locales].COMMON.REMOVED_USER}`}</h4>
            </div>
            <div className="caseInformation-paragraph-wrapper">
                <h4 className="text-grey">{language[locales].CASE.EDIT_INVITATION.INFO.DATE}</h4>
                <h4>{formatDate(invitation.created, locales)}</h4>
            </div>
            <div className="caseInformation-paragraph-wrapper">
                <h4 className="text-grey">{language[locales].CASE.EDIT_INVITATION.INFO.UNIT}</h4>
                <h4>{invitation.ownedBy.unit.name}</h4>
            </div>
            <div className="caseInformation-paragraph-wrapper">
                <h4 className="text-grey">{language[locales].CASE.EDIT_INVITATION.INFO.PAUSE}</h4>
                <h4>
                    {invitation.status.status === "active"
                        ? language[locales].CASE.EDIT_INVITATION.INFO.PAUSE1
                        : `${language[locales].CASE.EDIT_INVITATION.INFO.PAUSE2} - ${formatDate(
                              invitation.status.date,
                              locales,
                          )}`}
                </h4>
            </div>
            {invitation.code && (
                <div className="caseInformation-paragraph-wrapper">
                    <h4 className="text-grey">
                        {language[locales].CASE.EDIT_INVITATION.INFO.INVITE}
                    </h4>
                    <h4>{invitation.code.replace(/.{3}/, "$&-")}</h4>
                </div>
            )}
        </>
    );
};

export default CaseInformation;
