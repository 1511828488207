export const dispatchError = async (error, dispatch, type) => {
    if (error?.text) {
        let errorMessage;
        try {
            errorMessage = await error.json();
        } catch (_err) {
            errorMessage = { error: error.status ?? "" };
        }
        return dispatch({ type, payload: errorMessage.error ?? errorMessage.message });
    }
    return dispatch({ type, payload: error ?? "" });
};
