import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Types from "~/src/utils/types";
import Can from "../../../../../rbac/can";
import { deleteUnit } from "../../../../../redux/actions/units/actions";
import { roles } from "../../../../../utils/constants";
import language from "../../../../../utils/language";
import SecondaryButton from "../../../../tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import "./unitSettings.css";

const UnitSettings = ({ unitObj, show, setShow, role }) => {
    const users = useSelector((state) => {
        return state.user.users;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [userConnections, setUserConnections] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const connection = users.some((user) => {
            return user.unit._id === unitObj._id;
        });
        setUserConnections(connection);
    }, []);

    const sendDelete = () => {
        if (
            unitObj.childUnits.length === 0 &&
            !userConnections &&
            [roles.systemAdmin, roles.administrator].includes(role)
        ) {
            dispatch(deleteUnit(unitObj._id));
            setShow(!show);
        } else {
            setErrorMessage(language[locales].ADMIN.UNIT.EDIT_UNIT.SETTINGS_DELETE_UNIT_ERROR);
        }
    };

    const handleYes = useCallback(() => {
        return (
            <SecondaryButton
                icon="trashbin"
                text={language[locales].ADMIN.UNIT.EDIT_UNIT.SETTINGS_BUTTON}
                handleButton={sendDelete}
            />
        );
    }, []);

    return (
        <FluidWrapper height="hg-small" customStyle={{ height: "9.6rem" }}>
            <div>
                <h3>{language[locales].ADMIN.UNIT.EDIT_UNIT.SETTINGS_HEADING}</h3>
                <div className="userSettings-wrapper">
                    <Can
                        role={role}
                        perform="unit:delete"
                        yes={() => {
                            return handleYes();
                        }}
                        no={() => {
                            return null;
                        }}
                    />
                </div>
                <p className="text-error">{errorMessage}</p>
            </div>
        </FluidWrapper>
    );
};

UnitSettings.propTypes = {
    unitObj: PropTypes.shape(Types.unit).isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
};

export default UnitSettings;
