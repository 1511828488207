import {
    type SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import type { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EmptyTable from "~/src/media/images/svg/emptyTable.svg";
import ArrowSmallUp from "~/src/media/images/svg/small_arrow_up.svg";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import "./table.css";
import GhostButton from "./buttons/ghostButton/ghostButton";

type TableProps<T> = {
    data: T[];
    columns: ColumnDef<T>[];
    noDataMessage: string;
    searchString: string;
    handleRowClicked: (T) => void;
    sortingId: keyof T & string;
};

const tableRowsToShow = 10;
// The comma in <T,> is needed :
// https://github.com/microsoft/TypeScript/issues/15713#issuecomment-499474386
const Table = <T,>({
    data,
    columns,
    noDataMessage,
    searchString,
    handleRowClicked,
    sortingId,
}: TableProps<T>) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { TABLE, COMMON } = language[locales];
    const initialSort = [
        {
            id: sortingId,
            desc: true,
        },
    ];
    const [sorting, setSorting] = useState<SortingState>(initialSort);
    const tableInstance = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    useEffect(() => {
        if (searchString) {
            tableInstance.setGlobalFilter(searchString);
        } else {
            tableInstance.setGlobalFilter("");
        }
    }, [searchString]);

    if (data.length === 0) {
        return (
            <div className="empty-table-wrapper">
                <img src={EmptyTable} alt="" />
                <h1>{TABLE.EMPTY_HEADING}</h1>
                <p className="dark-grey">{noDataMessage ?? null}</p>
            </div>
        );
    }
    return (
        <>
            <table className="table-wrapper">
                <thead>
                    {tableInstance.getHeaderGroups().map((headerGroup) => {
                        return (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            key={header.id}
                                            className={`table-header ${
                                                header.column.getCanSort() ? "" : "sorting-disabled"
                                            }`}
                                            style={{
                                                width: `${
                                                    (header.getContext().column.getSize() /
                                                        header.getContext().table.getTotalSize()) *
                                                    100
                                                }%`,
                                            }}
                                            onClick={header.column.getToggleSortingHandler()}
                                            // biome-ignore lint/a11y/noNoninteractiveTabindex: th is interactive here
                                            tabIndex={0}
                                            onKeyPress={header.column.getToggleSortingHandler()}
                                        >
                                            <div className="table-header-content">
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                                {{
                                                    asc: (
                                                        <img
                                                            className="table-sorting-arrow"
                                                            src={ArrowSmallUp}
                                                            alt="sort up"
                                                        />
                                                    ),
                                                    desc: (
                                                        <img
                                                            className="table-sorting-arrow"
                                                            src={ArrowSmallUp}
                                                            style={{ transform: "rotate(180deg)" }}
                                                            alt="sort down"
                                                        />
                                                    ),
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>
                <tbody className="table-body">
                    {tableInstance.getRowModel().rows.map((row) => {
                        return (
                            <tr
                                tabIndex={0}
                                onClick={() => {
                                    handleRowClicked(row.original);
                                }}
                                onKeyPress={() => {
                                    handleRowClicked(row.original);
                                }}
                                key={row.id}
                                className="table-row"
                            >
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id} className="table-cell">
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <p className="table-showRows">{`${COMMON.SHOWS} ${tableInstance.getRowModel().rows.length} ${
                COMMON.OF
            } ${tableInstance.getFilteredRowModel().rows.length}`}</p>
            {tableInstance.getRowModel().rows.length !==
                tableInstance.getFilteredRowModel().rows.length && (
                <div className="table-showRows-button">
                    <GhostButton
                        text={COMMON.SHOW_MORE}
                        handleGhostButton={() => {
                            tableInstance.setPageSize(
                                tableInstance.getState().pagination.pageSize + tableRowsToShow,
                            );
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default Table;
