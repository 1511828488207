import { personTypes } from "@ommej/metadata";
import type { Beb2, Person, QuestionObject, Uuid } from "@ommej/types";
import type { ReactNode } from "react";
import { useSelector } from "react-redux";
import AlarmIcon from "~/src/media/images/svg/alarm-peach.svg";
import ResourceIcon from "~/src/media/images/svg/resource_smiley.svg";
import type { ClientInfo, GlobalState } from "~/src/types";
import { getQuestionAndAnswersStrings } from "~/src/utils/misc";
import type { LanguageLocales } from "~/src/utils/ts-types";
import "./backaBarnetAnswers.css";

type QuestionId = Uuid;
type AnswerId = Uuid;
type ResourceId = Uuid;

const QuestionCard = ({
    // which answers from the questionObject that should be included, if
    // undefined, all answers will be included
    answersToInclude,
    client,
    color,
    icon,
    opacity,
    locale,
    questionObject,
    uniqueness,
    showAnswers = true,
}: {
    answersToInclude?: Array<AnswerId> | undefined;
    client: ClientInfo;
    color: string;
    icon?: "ALARM" | "RESOURCE" | undefined;
    opacity: number;
    locale: LanguageLocales;
    questionObject: QuestionObject | undefined;
    uniqueness: string;
    showAnswers?: boolean;
}) => {
    if (!questionObject) {
        return null;
    }

    const getCard = (aQuestionObject: QuestionObject) => {
        const questionStrings = getQuestionAndAnswersStrings(
            client.profile,
            aQuestionObject,
            locale as LanguageLocales,
        );
        return questionStrings.map((qString, qIndex) => {
            return (
                <li
                    className="backaBarnetAnswers-qa"
                    style={{
                        background: color,
                        opacity,
                        border: `2px ${color} solid`,
                    }}
                    key={`questionCard-${uniqueness}-${qString.question}-${qIndex}`}
                >
                    {showAnswers && Array.isArray(qString.answers) ? (
                        <>
                            <p className="backaBarnetAnswers-question">{`${qString.question}:`}</p>
                            <p
                                key={`questionCard-${uniqueness}-${qString.question}-${qString.answers.join()}`}
                                className="backaBarnetAnswers-answer"
                            >
                                {qString.answers.join(", ")}
                            </p>
                        </>
                    ) : icon ? (
                        <p className="backaBarnetAnswers-question">{`${qString.question}`}</p>
                    ) : (
                        qString.question
                    )}
                    {icon && (
                        <img
                            src={icon === "ALARM" ? AlarmIcon : ResourceIcon}
                            className="backaBarnetAnswers-alarm-img"
                            alt={icon === "ALARM" ? "risk" : "resurs"}
                            title={icon === "ALARM" ? "risk" : "resurs"}
                        />
                    )}
                </li>
            );
        });
    };

    const getPersonsInAccommodation = (houseId: ResourceId) => {
        if (!client.profile || !houseId) {
            return [];
        }
        return client.profile.housePersonMap[houseId]?.map((person) => {
            return {
                id: person,
                type:
                    client.profile?.persons[person]?.type &&
                    // type assertion: checked on the lines above that nothing is undefined
                    personTypes[(client.profile.persons[person] as Person).type as string]?.lang.sv,
            };
        });
    };

    const getAccommodationsCard = (aQuestionObject: QuestionObject) => {
        // find the answer with the type accommodations
        const accommodationAnswer = aQuestionObject.answers.find((answer) => {
            return answer.type === "accommodations";
        });

        if (!accommodationAnswer) {
            return getCard(aQuestionObject);
        }

        return (
            <li
                className="backaBarnetAnswers-qa"
                style={{
                    background: color,
                    opacity,
                    border: `2px ${color} solid`,
                }}
                key={`questionCard-${uniqueness}-${accommodationAnswer.id}`}
            >
                <p className="backaBarnetAnswers-question">
                    {aQuestionObject.question.text[locale]}
                </p>
                {showAnswers &&
                    accommodationAnswer.data.map((houseId) => {
                        const inAccommodations = getPersonsInAccommodation(houseId);
                        return (
                            client.profile && (
                                <div
                                    key={`questionCard-${uniqueness}-${accommodationAnswer.id}-${houseId}`}
                                    style={{ marginTop: "0.75rem" }}
                                >
                                    <img
                                        src={`/assets/houses/${client?.profile?.houses[houseId]?.avatar}.svg`}
                                        className="backaBarnetAnswers-house-img"
                                        alt="house"
                                        width="3rem"
                                    />
                                    <br />
                                    {inAccommodations?.map((person, index) => {
                                        return (
                                            <span
                                                className="backaBarnetAnswers-answer"
                                                key={`questionCard-${uniqueness}-${accommodationAnswer.id}-${houseId}-${person.id}`}
                                            >
                                                {`${index > 0 ? ", " : ""} ${person.type}`}
                                            </span>
                                        );
                                    })}
                                </div>
                            )
                        );
                    })}
                {icon && (
                    <img
                        src={icon === "ALARM" ? AlarmIcon : ResourceIcon}
                        alt={icon === "ALARM" ? "risk" : "resurs"}
                        className="backaBarnetAnswers-alarm-img"
                    />
                )}
            </li>
        );
    };

    function getDom(
        aQuestionObject: QuestionObject,
        aAnswersToInclude: Array<AnswerId> | undefined,
    ) {
        const filteredQuestionObject: Partial<QuestionObject> = {};
        filteredQuestionObject.question = aQuestionObject.question;
        if (aAnswersToInclude) {
            filteredQuestionObject.answers = aQuestionObject.answers.filter((answer) => {
                return aAnswersToInclude.includes(answer.id);
            });
        } else {
            filteredQuestionObject.answers = aQuestionObject.answers;
        }

        if (filteredQuestionObject.question.type === "accommodations") {
            return getAccommodationsCard(filteredQuestionObject as QuestionObject);
        }
        return getCard(filteredQuestionObject as QuestionObject);
    }

    return <>{getDom(questionObject, answersToInclude)}</>;
};

type AnswersViewerProps = {
    beb: Beb2;
    filter: Array<
        Record<QuestionId, Set<AnswerId> | Array<AnswerId>> | Array<AnswerId> | undefined
    >;
    marked?: Set<QuestionId>;
    client: ClientInfo;
    // something unique that we can use as part of key
    uniqueness: string;
    showAnswers?: boolean;
    // color will override the coloring based on the answer type (alarm,
    // resource, ...)
    color?: string;
    columns?: number;
};

const AnswersViewer = ({
    beb,
    filter,
    marked = undefined,
    client,
    uniqueness,
    showAnswers = true,
    color,
    columns = 3,
}: AnswersViewerProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });

    function getStyle(
        aBeb: Beb2,
        qid: QuestionId,
        aids: Array<AnswerId> | undefined,
    ): { color: string; opacity: number; icon?: "ALARM" | "RESOURCE" | undefined } {
        let opacity = 1;
        if (marked?.has(qid)) {
            return {
                color: "var(--color-orange-light)",
                opacity,
                icon: aBeb.answerTags.ALARM?.[qid]
                    ? "ALARM"
                    : aBeb.answerTags.RESOURCE?.[qid]
                      ? "RESOURCE"
                      : undefined,
            };
        }

        // dim non-marked cards
        if (marked && marked.size > 0) {
            opacity = 0.4;
        }

        if (color) {
            return { color, opacity };
        }

        const types: Array<{
            key: keyof Beb2["answerTags"];
            style: ReturnType<typeof getStyle>;
        }> = [
            {
                key: "ALARM",
                style: {
                    color: "var(--color-ice-peach)",
                    opacity,
                    icon: "ALARM",
                },
            },
            {
                key: "RESOURCE",
                style: {
                    color: "var(--color-green-BB)",
                    opacity,
                    icon: "RESOURCE",
                },
            },
        ];

        for (const type of types) {
            if (
                aBeb.answerTags[type.key]?.[qid]?.some((aid) => {
                    if (!aids) {
                        // if no answers are provided, it's enough that the
                        // question is in the answerTags
                        return true;
                    }
                    return aids.includes(aid);
                })
            ) {
                return type.style;
            }
        }

        return { color: "var(--color-light-grey)", opacity };
    }

    function getNode(qid: QuestionId, aids?: Array<AnswerId> | undefined) {
        const style = getStyle(beb, qid, aids);
        const node = (
            <QuestionCard
                answersToInclude={aids}
                client={client}
                color={style.color}
                icon={style.icon}
                opacity={style.opacity}
                key={`answersViewer-${uniqueness}-${qid}-${Array.isArray(aids) ? aids.join() : Math.random()}`}
                uniqueness={`answersViewer-${uniqueness}-${qid}-${Array.isArray(aids) ? aids.join() : Math.random()}`}
                locale={locales}
                questionObject={beb.answers[qid]}
                showAnswers={showAnswers}
            />
        );
        return node;
    }

    function getQuestionCards() {
        const dom: Array<ReactNode> = [];
        for (const entry of filter) {
            if (!entry) {
                continue;
            }
            // handling Beb2.QuestionTags, other fields are objects and are handled
            // in the else
            if (Array.isArray(entry)) {
                for (const qid of entry) {
                    const node = getNode(qid);
                    dom.push(node);
                }
            } else {
                for (const [qid, aids] of Object.entries(entry)) {
                    const node = getNode(qid, Array.from(aids));
                    dom.push(node);
                }
            }
        }
        return dom;
    }

    return (
        <ul
            className="backaBarnetAnswers-qa-wrapper"
            style={{
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
            }}
        >
            {getQuestionCards()}
        </ul>
    );
};

export default AnswersViewer;
