import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { updateUser } from "../../../../../redux/actions/users/actions";
import language from "../../../../../utils/language";
import NormalButton from "../../../../tools/buttons/normalButton/normalButton";
import RoundButton from "../../../../tools/buttons/roundButton/roundButton";
import SecondaryButton from "../../../../tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import Textfield from "../../../../tools/form/textfield/textfield";
import "./userName.css";

const UserName = ({ user, setUser, username, setUsername }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [toggleComponent, setToggleComponent] = useState(false);
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    const handleCancel = () => {
        setUser({ ...user, username });
        setToggleComponent(false);
    };

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSend = () => {
        // get two api request due to backend dont return full unitobj //
        if (user.username.length > 2) {
            dispatch(updateUser(user));
            setToggleComponent(false);
            setUsername(user.username);
        } else {
            setError(language[locales].ADMIN.USER.EDIT_USER.USER_NAME.INPUT_ERROR);
        }
    };

    if (!user) {
        return (
            <FluidWrapper height="hg-small">
                {language[locales].COMPONENT.ERROR_RESOURCE_LOADING}
            </FluidWrapper>
        );
    }
    return (
        <FluidWrapper height="hg-small">
            {toggleComponent ? (
                <>
                    <div className="userName-wrapper">
                        <h3>{language[locales].ADMIN.USER.EDIT_USER.USER_NAME.HEADING}</h3>
                        <Textfield
                            text={language[locales].ADMIN.USER.EDIT_USER.USER_NAME.INPUT_NAME}
                            type="text"
                            name="username"
                            value={user.username}
                            inputError={error}
                            handleChange={handleChange}
                            customStyle={{ width: "18rem" }}
                        />
                    </div>
                    <div className="userName-button-wrapper">
                        <SecondaryButton
                            text={language[locales].ADMIN.USER.EDIT_USER.USER_NAME.CANCEL_BUTTON}
                            handleButton={handleCancel}
                        />
                        <NormalButton
                            text={language[locales].ADMIN.USER.EDIT_USER.USER_NAME.SAVE_BUTTON}
                            handleButton={handleSend}
                            customStyle={{ marginLeft: "0.5rem" }}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="userName-noChange-wrapper">
                        <h3>{language[locales].ADMIN.USER.EDIT_USER.USER_NAME.HEADING}</h3>
                        <RoundButton
                            icon="pencil"
                            handleButton={() => {
                                setToggleComponent(true);
                            }}
                        />
                    </div>
                    <h4 className="userName-noChange-name">{user.username}</h4>
                </>
            )}
        </FluidWrapper>
    );
};

UserName.defaultProps = {
    username: undefined,
};

UserName.propTypes = {
    user: PropTypes.shape({
        _id: PropTypes.string,
        roleCode: PropTypes.string,
        email: PropTypes.string,
        username: PropTypes.string,
        unit: PropTypes.shape(Types.unit),
    }).isRequired,
    setUser: PropTypes.func.isRequired,
    username: PropTypes.string,
    setUsername: PropTypes.func.isRequired,
};

export default UserName;
