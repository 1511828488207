// @ts-check

import { apiUrl } from "./constants";

/** @typedef {import('type-fest').JsonValue} JsonValue */
/** @typedef {import('./abort').MaybeAbortSignal} MaybeAbortSignal */
/** @typedef {'get'|'post'|'put'|'patch'|'delete'} HttpMethod */

/**
 * @typedef RequestOptions
 * @property {MaybeAbortSignal} [signal]
 */

/**
 * @param {string} url
 * @param {HttpMethod} method
 * @param {object} options
 * @param {JsonValue|undefined} [options.payload]
 * @param {string|undefined} [options.path]
 * @param {MaybeAbortSignal} [options.signal]
 * @returns {Promise<Response>}
 */
const request = async (url, method, { payload, path, signal } = {}) => {
    const pathUrl = path ? `${url}/${path}` : url;
    const response = await fetch(pathUrl, {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        method,
        // These are tricks for only adding the key if it has a value, else skipping it
        ...(payload ? { body: JSON.stringify(payload) } : {}),
        ...(signal ? { signal } : {}),
    });

    if (response.ok) {
        return response;
    }

    throw response;
};

/**
 * @param {string} url
 * @param {HttpMethod} method
 * @param {JsonValue} [payload]
 * @param {RequestOptions} [options]
 * @returns {Promise<Response>}
 */
export const reqWithPayload = async (url, method, payload, options = {}) => {
    return request(apiUrl + url, method, { ...options, payload });
};

/**
 * @param {string} url
 * @param {HttpMethod} method
 * @param {string} path
 * @param {RequestOptions} [options]
 * @returns {Promise<Response>}
 */
export const reqWithPath = async (url, method, path, options = {}) => {
    return request(apiUrl + url, method, { ...options, path });
};

/**
 * @param {string} url
 * @param {HttpMethod} method
 * @param {string} [path]
 * @param {JsonValue} [payload]
 * @param {RequestOptions} [options]
 * @returns {Promise<Response>}
 */
export const reqWithPathAndPayload = async (url, method, path, payload, options = {}) => {
    return request(apiUrl + url, method, { ...options, payload, path });
};

/**
 * @param {unknown} err
 * @returns {Promise<Error>}
 */
export const resolveFetchError = async (err) => {
    if (err instanceof Response) {
        const json = await err.json().catch(() => {
            console.error("resolveFetchError");
        });
        const message = json?.message || json?.error;

        if (message && typeof message === "string") {
            return new Error(`HTTP error ${err.status} with message: ${message}`);
        }

        return new Error(
            `HTTP error ${err.status} without message (Status text: ${err.statusText})`,
        );
    }

    if (err instanceof Error) {
        return err;
    }

    return new Error("Unknown Error");
};
