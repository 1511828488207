export const LOADING_INVITATION = "LOADING_INVITATION";

export const GET_ALL_INVITATIONS = "GET_ALL_INVITATION";

export const BUILD_INVITATION_OBJECT = "BUILD_INVITATION_OBJECT";

export const ADD_INVITATION = "ADD_INVITATION";

export const DELETE_INVITATION = "DELETE_INVITATION";

export const CLOSE_INVITATION = "CLOSE_INVITATION";

export const UPDATE_INVITATION = "UPDATE_INVITATION";

export const INVITATION_ERROR = "INVITATION_ERROR";
