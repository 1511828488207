import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoutingState } from "~/src/utils/systemFunctions";
import Can from "../../../../rbac/can";
import { route } from "../../../../utils/constants";
import language from "../../../../utils/language";
import NormalButton from "../../../tools/buttons/normalButton/normalButton";
import AddUser from "../modals/addUser/addUser";
import "./userHeader.css";

const UserHeader = () => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);

    const handleYes = useCallback(() => {
        return (
            <NormalButton
                icon="plus"
                className="userHeader-create-button"
                text={language[locales].ADMIN.USER.USERHEADER_ADD_BUTTON}
                handleButton={() => {
                    const options = { state: routingState };
                    navigate(route.ADMIN_USERS_ADD, options);
                }}
            />
        );
    }, []);

    return (
        <div className="userHeader-wrapper">
            <h3>{language[locales].ADMIN.USER.USERHEADER_HEADING}</h3>
            <Can
                role={currentUser.roleCode}
                perform="unit:create"
                yes={() => {
                    return handleYes();
                }}
                no={() => {
                    return null;
                }}
            />
            {location.pathname === route.ADMIN_USERS_ADD && <AddUser />}
        </div>
    );
};

export default UserHeader;
