import { select, pointer } from 'd3-selection';
import Kapsule from 'kapsule';

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

function styleInject(css, ref) {
  if (ref === void 0) ref = {};
  var insertAt = ref.insertAt;
  if (!css || typeof document === 'undefined') {
    return;
  }
  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');
  style.type = 'text/css';
  if (insertAt === 'top') {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild);
    } else {
      head.appendChild(style);
    }
  } else {
    head.appendChild(style);
  }
  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

var css_248z = ".tooltip {\n  position: absolute;\n  padding: 5px;\n  border-radius: 3px;\n  font: 12px sans-serif;\n  color: #eee;\n  background: rgba(0,0,0,0.65);\n  pointer-events: none;\n}\n";
styleInject(css_248z);

var index = Kapsule({
  props: {
    content: {
      "default": false
    }
  },
  init: function init(domNode, state) {
    var isD3Selection = !!domNode && _typeof(domNode) === 'object' && !!domNode.node && typeof domNode.node === 'function';
    var el = select(isD3Selection ? domNode.node() : domNode);
    state.tooltipEl = el.append('div').attr('class', 'tooltip');
    state.mouseInside = false;
    el.on('mousemove.tooltip', function (ev) {
      state.mouseInside = true;
      var mousePos = pointer(ev);
      var domNode = el.node();
      var canvasWidth = domNode.offsetWidth;
      var canvasHeight = domNode.offsetHeight;
      state.tooltipEl.style('left', mousePos[0] + 'px').style('top', mousePos[1] + 'px')
      // adjust horizontal position to not exceed canvas boundaries
      .style('transform', "translate(-".concat(mousePos[0] / canvasWidth * 100, "%, ").concat(
      // flip to above if near bottom
      canvasHeight - mousePos[1] < 100 ? 'calc(-100% - 6px)' : '21px', ")"));
    });
    el.on('mouseover.tooltip', function () {
      state.mouseInside = true;
      state.content && state.tooltipEl.style('display', 'inline');
    });
    el.on('mouseout.tooltip', function () {
      state.mouseInside = false;
      state.tooltipEl.style('display', 'none');
    });
  },
  update: function update(state) {
    state.tooltipEl.style('display', !!state.content && state.mouseInside ? 'inline' : 'none');
    state.tooltipEl.html(state.content || '');
  }
});

export { index as default };
