import { LOADING_LOGIN, LOGIN, LOGIN_ERROR, LOGOUT } from "../actions/login/type";

const initialState = {
    loggedInUser: undefined,
    currentUser: undefined,
    auth: false,
    loading: false,
    error: null,
};

const loginReducer = (currentState, action) => {
    const state = currentState || initialState;
    switch (action.type) {
        case LOADING_LOGIN:
            return {
                ...state,
                loading: true,
            };
        case LOGIN:
            return {
                ...state,
                loggedInUser: {
                    _id: action.payload._id,
                    username: action.payload.username,
                    email: action.payload.email,
                    unit: action.payload.unit,
                    roleCode: action.payload.roleCode,
                    guides: action.payload.guides,
                    features: action.payload.features,
                },
                // At the moment currentUser always becomes loggedInUser
                currentUser: {
                    _id: action.payload._id,
                    username: action.payload.username,
                    email: action.payload.email,
                    unit: action.payload.unit,
                    roleCode: action.payload.roleCode,
                    guides: action.payload.guides,
                    features: action.payload.features,
                },
                auth: true,
                loading: false,
                error: null,
            };
        case LOGOUT:
            return {
                ...state,
                loggedInUser: undefined,
                currentUser: undefined,
                auth: false,
                loading: false,
                error: null,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default loginReducer;
