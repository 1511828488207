"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarColorSwatches = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./avatarColorSwatches.css");
const AvatarColorSwatches = ({ colorArray, activeColor, handleColor, }) => {
    return ((0, jsx_runtime_1.jsx)("section", { className: "avatarColorSwatches-wrapper", children: colorArray.map((color) => {
            return ((0, jsx_runtime_1.jsx)("div", { className: `avatarColorSwatches-button-wrapper ${color === activeColor && "avatarColorSwatches-active"}`, children: (0, jsx_runtime_1.jsx)("button", { className: "avatarColorSwatches-button", type: "button", "aria-label": "Active color", style: { background: color }, onClick: () => {
                        handleColor(color);
                    } }) }, color));
        }) }));
};
exports.AvatarColorSwatches = AvatarColorSwatches;
