// @ts-check

import { ensureArray } from "./misc";

/**
 * @param {string|string[]} classNames
 * @param {undefined|string|string[]} modifiers
 * @returns {string}
 */
const classNameModifiers = (classNames, modifiers) => {
    const resolvedClassNames = ensureArray(classNames);

    if (!modifiers) {
        return resolvedClassNames.join(" ");
    }

    /** @type {string[]} */
    const result = [];
    const resolvedModifiers = ensureArray(modifiers);

    for (const baseName of resolvedClassNames) {
        result.push(baseName);
        for (const modifierName of resolvedModifiers) {
            result.push(`${baseName}--${modifierName}`);
        }
    }

    return result.join(" ");
};

export default classNameModifiers;
