import type { Beb2, QuestionId } from "@ommej/types";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import "./chart.css";

// ***** Chart Options ***** //
Chart.defaults.font.family = "'Quicksand', sans-serif";
Chart.defaults.elements.line.tension = 0.2;

const KasamChart = ({ beb, gender }: { beb: Beb2; gender: string | undefined }) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { TAB_REPORT } = language[locales].CASE.REPORT;
    const { COMMON } = language[locales];
    const barChart = useRef<HTMLCanvasElement | null>(null);
    let chart;

    // These are the question ids for KASAM-19. (It's not the same questions to
    // KASAM-13).
    const KASAM_QIDS = {
        understandable: [
            "80910184-1f46-49f2-9e42-3678fbe069a6",
            "18c77c95-bda3-4c52-a6b6-0c5c666683dc",
            "97be5c44-5e9d-40ec-b0fa-b6a4fc8b9735",
            "102e920d-e474-4392-8460-e390145458ee",
            "4bfb8057-ddb6-4ac7-8ccd-302d673a0154",
        ],
        manageable: [
            "eaa8ea7e-eabd-4db8-9825-fe2726d3fafb",
            "890b6814-472c-4812-942d-2ae359a1eac3",
            "0e0d4da4-2841-4c5b-a970-2a461fd5daf2",
            "1d01f133-c516-4382-8421-18ca16a84b87",
            "806783c9-1712-4074-bd36-409d75e0f309",
            "80f5bfba-9b8f-4acc-a34d-5bdc46376ff9",
            "655d5eff-2f9b-415c-94a9-b06a317b74e3",
        ],
        meaningful: [
            "4ffc4af5-e1d1-4204-a08a-36a042f86dda",
            "489ae193-c25e-4104-b598-843dffe758ba",
            "b3f5955e-c2e4-49fa-a0a3-6b7104db1c31",
            "83a99092-b6ed-40cb-8cc8-419c017e6765",
        ],
    };

    function getChartNumber(beb: Beb2, qid: QuestionId) {
        const answer = beb.answers[qid]?.answers[0]?.text?.[locales];
        switch (answer) {
            case "aldrig":
                return 1;
            case "ibland":
                return 2;
            case "ofta":
                return 3;
            case "alltid":
                return 4;
            default:
                return 0;
        }
    }

    const CONFIG = {
        LABELS: [
            TAB_REPORT.KASAM_LABEL1, // meaningful
            TAB_REPORT.KASAM_LABEL2, // manageable
            TAB_REPORT.KASAM_LABEL3, // understandable
            TAB_REPORT.KASAM_LABEL4, // total
        ],
        GENDER: {
            MALE: {
                TEXT: `${COMMON.AVERAGE_VALUE} ${COMMON.BOYS.toLowerCase()}`,
                DATA: [12.3, 21.9, 14.2, 48.4],
            },
            FEMALE: {
                TEXT: `${COMMON.AVERAGE_VALUE} ${COMMON.GIRLS.toLowerCase()}`,
                DATA: [12.5, 21.0, 13.3, 46.9],
            },
            OTHERS: {
                TEXT: `${COMMON.AVERAGE_VALUE} ${COMMON.TOTAL.toLowerCase()}`,
                DATA: [12.4, 21.5, 13.8, 47.6],
            },
        },
    };

    function buildChart(values: Record<keyof typeof KASAM_QIDS, number>, total: number) {
        if (!barChart.current) {
            return;
        }
        chart = new Chart(barChart.current, {
            type: "bar",
            data: {
                labels: CONFIG.LABELS,
                datasets: [
                    {
                        label:
                            gender === "MALE" || gender === "FEMALE"
                                ? CONFIG.GENDER[gender].TEXT
                                : CONFIG.GENDER.OTHERS.TEXT,
                        fill: false,
                        data:
                            gender === "MALE" || gender === "FEMALE"
                                ? CONFIG.GENDER[gender].DATA
                                : CONFIG.GENDER.OTHERS.DATA,
                        borderColor: "rgba(225, 141, 149, 1)",
                        backgroundColor: "rgba(225, 141, 149, 1)",
                        type: "line",
                    },
                    {
                        label: TAB_REPORT.CHILD_VALUE,
                        data: [values.meaningful, values.manageable, values.understandable, total],
                        backgroundColor: "rgba(107, 118, 218, 1)",
                    },
                ],
            },
            plugins: [ChartDataLabels],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        display: true,
                        position: "bottom",
                        align: "start",
                        labels: {
                            usePointStyle: true,
                            padding: 13,
                            font: {
                                size: 12,
                            },
                            boxHeight: 7,
                            boxWidth: 7,
                        },
                    },
                },
            },
        });
    }

    useEffect(() => {
        const values: Record<keyof typeof KASAM_QIDS, number> = {
            understandable: 0,
            manageable: 0,
            meaningful: 0,
        };
        let total = 0;
        for (const [topic, qids] of Object.entries(KASAM_QIDS)) {
            if (!values[topic]) {
                values[topic] = 0;
            }
            for (const qid of qids) {
                values[topic] += getChartNumber(beb, qid);
            }
            total += values[topic];
        }
        buildChart(values, total);
        return () => {
            chart.destroy();
        };
    }, [beb]);

    return (
        <section className="chart-wrapper">
            <canvas ref={barChart} />
        </section>
    );
};

export default KasamChart;
