import type { CalenderEvent } from "@ommej/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ArrowIcon from "~/src/media/images/svg/arrow_down.svg";
import ClockGreyIcon from "~/src/media/images/svg/clock-grey.svg";
import HeartIcon from "~/src/media/images/svg/event-heart.svg";
import RocketIcon from "~/src/media/images/svg/event-rocket.svg";
import SkeletonHomeCard from "~/src/skeletons/skeletonHomeCard";
import type { GlobalState } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import language from "~/src/utils/language";
import { formatDate } from "~/src/utils/systemFunctions";
import "./events.css";

const eventUrl = "https://ommej.se/evenemang/";

const Events = () => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { EVENTS } = language[locales].HOME;
    const [events, setEvents] = useState<CalenderEvent[]>();

    const fetchEvents = async () => {
        try {
            const res = await reqWithPath("events", "get", "");
            const data = await res.json();
            setEvents(data);
        } catch (error) {
            // no need to handle error, we don't show component if
            // events array have no items
            console.error(error);
            setEvents([]);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    const renderIcon = (text: string) => {
        const isEducation = text.toLowerCase().includes("utbildning");
        return (
            <div className={`events-card-icon ${isEducation ? "events-peach" : ""}`}>
                <img src={isEducation ? HeartIcon : RocketIcon} alt="event" />
            </div>
        );
    };

    if (!events) {
        return <SkeletonHomeCard />;
    }
    if (events.length === 0) {
        return null;
    }
    return (
        <div className="home-card" style={{ padding: "1.5rem 1rem" }}>
            <div className="home-card-header">
                <h2 className="events-header">{EVENTS.HEADER}</h2>
                <a className="events-link" href={eventUrl}>
                    {EVENTS.SEE_ALL}
                    <img src={ArrowIcon} alt="arrow" />
                </a>
            </div>
            {events.slice(0, 3).map((event) => {
                return (
                    <a
                        href={event.url}
                        className="events-card"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={event.id}
                    >
                        {renderIcon(event.name)}
                        <div className="events-card-text">
                            <p className="events-card-header">{event.name}</p>
                            <p>{`${formatDate(event.start, locales)}\u00A0 | \u00A0${new Date(
                                event.start,
                            ).toLocaleTimeString(locales, { timeStyle: "short" })}`}</p>
                        </div>
                        <div className="events-card-time">
                            <img src={ClockGreyIcon} alt="" />
                            {event.duration !== 0 && <p>{`${event.duration} h`}</p>}
                        </div>
                    </a>
                );
            })}
        </div>
    );
};

export default Events;
