import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import language from "../../../../../utils/language";
import NormalButton from "../../../../tools/buttons/normalButton/normalButton";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import UserCard from "../../cards/userCard";
import "./unitUser.css";

const UnitUser = ({ setTab, userList, role }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });

    return (
        <FluidWrapper height="hg-normal">
            <div className="unitUser-header">
                <h3>{language[locales].ADMIN.UNIT.ADD_UNIT.UNIT_USERS}</h3>
                {!userList && (
                    <NormalButton
                        icon="plus"
                        text={language[locales].ADMIN.UNIT.ADD_UNIT.INVITE_BUTTON}
                        handleButton={() => {
                            setTab(language[locales].ADMIN.UNIT.ADD_UNIT.CRUMB_NAME);
                        }}
                    />
                )}
            </div>
            <div className="unitUser-noscroll-wrapper">
                {userList?.map((user) => {
                    return (
                        <UserCard
                            key={user._id}
                            user={user}
                            role={role}
                            text={language[locales].ADMIN.UNIT.ADD_UNIT.BUTTON_USERCARD}
                            icon="trashbin"
                            visibleButton={false}
                        />
                    );
                })}
            </div>
        </FluidWrapper>
    );
};

UnitUser.defaultProps = {
    userList: undefined,
};

UnitUser.propTypes = {
    role: PropTypes.string.isRequired,
    setTab: PropTypes.func.isRequired,
    userList: PropTypes.arrayOf(PropTypes.object),
};

export default UnitUser;
