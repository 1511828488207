import ConversationSmilyes from "./conversationSmilyes";

const ConversationEnd = ({ className }: { className: string }) => {
    return (
        <section className={`conversation-section-wrapper ${className}`}>
            <h2>Innan vi avslutar</h2>
            <h3>För att sammanfatta vårt samtal...</h3>
            <ul className="conversation-list">
                <li className="conversation-sub-header-talk">Så vad har vi pratat om?</li>
                <li className="conversation-sub-header-talk">Vad händer nu?</li>
                <li className="conversation-sub-header-talk">
                    Är det något du undrar över eller vill tillägga?
                </li>
                <li className="conversation-sub-header-talk">Hur känns detta för dig?</li>
                <p className="conversation-paragraph">
                    Att prata om känslor är inte alltid lätt. Ibland hittar vi inte rätt ord. Då kan
                    smileys vara till hjälp!
                </p>
                <ConversationSmilyes />
            </ul>
        </section>
    );
};

export default ConversationEnd;
