// @ts-check

import { useSelector } from "react-redux";
import language from "../../../utils/language";

import Indicator from "./indicator";

/** @type {import('react').FunctionComponent<{}>} */
const LoadingIndicator = () => {
    const { locales } = useSelector(
        /**
         * @param {import('~/src/types').GlobalState} state
         * @returns {import('~/src/types').GlobalState["language"]}
         */
        (state) => {
            return state.language;
        },
    );
    const { LOADING } = language[locales].TOOLS;

    // Svg by Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL
    return (
        <Indicator modifier="loading" description={LOADING}>
            <svg width="56" height="56" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                <title>Laddar...</title>
                <defs>
                    <linearGradient
                        x1="8.042%"
                        y1="0%"
                        x2="65.682%"
                        y2="23.865%"
                        id="loading-indicator-gradient"
                    >
                        <stop stopColor="currentColor" stopOpacity="0" offset="0%" />
                        <stop stopColor="currentColor" stopOpacity=".631" offset="63.146%" />
                        <stop stopColor="currentColor" offset="100%" />
                    </linearGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(10 10)">
                        <path
                            d="M36 18c0-9.94-8.06-18-18-18"
                            stroke="url(#loading-indicator-gradient)"
                            strokeWidth="2"
                        >
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="0.9s"
                                repeatCount="indefinite"
                            />
                        </path>
                        <circle fill="currentColor" cx="36" cy="18" r="1">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="0.9s"
                                repeatCount="indefinite"
                            />
                        </circle>
                    </g>
                </g>
            </svg>
        </Indicator>
    );
};

export default LoadingIndicator;
