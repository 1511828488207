import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Table from "~/src/components/tools/table";
import Tabs, { type TabsObject } from "~/src/components/tools/tabs";
import SkeletonCases from "~/src/skeletons/skeletonCases";
import type {
    CaseListDataModel,
    CaseListDataModels,
    GlobalState,
    InvitationView,
} from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import { route } from "~/src/utils/constants";
import language from "~/src/utils/language";
import { getCaseListDataModel } from "./caseModels";
import "./caseList.css";

export const CASES_ROUTES = {
    ALL_CASES: "all",
    MY_CASES: "my_cases",
    SHARED_BY_ME: "shared_by",
    SHARED_WITH_ME: "shared_with",
};

type CaseListProps = {
    searchString: string;
};

const CaseList = ({ searchString }: CaseListProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });

    const loggedInUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const invitations = useSelector((state: GlobalState) => {
        return state.invitation.invitations;
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [dataModels, setDataModels] = useState<CaseListDataModels | null>(null);
    const navigate = useNavigate();
    const params = useParams();
    let isMounted = true;

    const handleCaseRowClicked = (row: InvitationView) => {
        navigate(`/case/${row.id}`);
    };

    const getInvitationsView = async () => {
        try {
            const response = await reqWithPath("invitations", "get", "view");
            const inv = await response.json();
            const caseListDataModels = getCaseListDataModel(inv, locales, loggedInUser._id);
            setDataModels(caseListDataModels);
            if (isMounted) {
                setErrorMessage(null);
            }
        } catch (error) {
            if (isMounted) {
                console.error(error);
                setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
            }
        }
    };

    useEffect(() => {
        getInvitationsView();
        return () => {
            isMounted = false;
        };
    }, [invitations]);

    const caseSkeleton = (
        <div
            className="caseList-wrapper"
            style={{ marginTop: "4.7rem", borderTopLeftRadius: "var(--border-radius-20)" }}
        >
            {Array(10)
                .fill(0)
                .map((_, i) => {
                    // key doesn't matter here, we don't care if elements are mixed up
                    // biome-ignore lint/suspicious/noArrayIndexKey: see above
                    return <SkeletonCases key={i} />;
                })}
        </div>
    );
    if (errorMessage) {
        return <div className="app-component-container">{errorMessage}</div>;
    }
    if (!dataModels) {
        return caseSkeleton;
    }

    const tabs = Object.entries(dataModels);

    const tabTitles: TabsObject[] = tabs.map(([key, value]: [string, CaseListDataModel]) => {
        return { tabString: value.title, route: `${route.CASES}/${CASES_ROUTES[key]}` };
    });

    let activeTab = tabs.findIndex((item) => {
        return CASES_ROUTES[item[0]] === params.route;
    });

    if (activeTab === -1) {
        activeTab = 0;
    }

    const activeCaseListDataModel: CaseListDataModel | null = tabs[activeTab]?.[1] ?? null;
    if (!activeCaseListDataModel) {
        return null;
    }

    return (
        <Tabs tabs={tabTitles}>
            <div className="caseList-wrapper">
                <Table<InvitationView>
                    data={activeCaseListDataModel.data}
                    columns={activeCaseListDataModel.columns || []}
                    noDataMessage={language[locales].CASE_LIST.NO_DATA_MESSAGE}
                    searchString={searchString}
                    handleRowClicked={handleCaseRowClicked}
                    sortingId="created"
                />
            </div>
        </Tabs>
    );
};

export default CaseList;
