import ConversationSmilyes from "./conversationSmilyes";
import "./conversationIntro.css";

const ConversationIntro = ({ className }: { className: string }) => {
    return (
        <section className={`conversation-section-wrapper conversationIntro-wrapper ${className}`}>
            <h2>Inledning</h2>
            <h3>Varför är vi här och vad ska vi göra?</h3>
            <p className="conversation-paragraph">
                Idag ska vi titta på det du svarat i Ommej-appen. Du vet att du alltid kan ändra dig
                både i appen eller här i samtalet, och du kan också berätta mer om du vill det.
            </p>
            <ul className="conversation-list">
                <li className="conversation-sub-header-talk">Varför är vi här?</li>
                <li className="conversation-sub-header-talk">Hur länge ska vi hålla på?</li>
                <li className="conversation-sub-header-talk">Vad ska vi göra?</li>
                <li className="conversation-sub-header-talk">Om sekretess</li>
                <li className="conversation-sub-header-talk">Är det något du vill prata om?</li>
                <li className="conversation-sub-header-talk">Är det något du undrar?</li>
                <li className="conversation-sub-header-talk">
                    Känns det OK för dig att vi börjar nu?
                </li>
                <li className="conversation-sub-header-talk">
                    Hur känns detta för dig?
                    <p className="conversation-paragraph">
                        Att prata om känslor är inte alltid lätt. Ibland hittar vi inte rätt ord. Då
                        kan smileys vara till hjälp!
                    </p>
                    <ConversationSmilyes />
                </li>
            </ul>
        </section>
    );
};

export default ConversationIntro;
