import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import { getAllUsers } from "../../redux/actions/users/actions";
import language from "../../utils/language";
import CaseHeader from "./caseHeader";
import CaseList from "./caseList/caseList";

const Case = () => {
    const usersLoaded = useSelector((state: GlobalState) => {
        return state.user.loaded;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const [searchString, setSearchString] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const dispatch = useDispatch();
    let isMounted = true;
    const loadResources = async () => {
        try {
            if (!usersLoaded) {
                dispatch(getAllUsers());
            }
            if (isMounted) {
                setErrorMessage(null);
            }
        } catch (_err) {
            if (isMounted) {
                setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
            }
        }
    };

    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    return (
        <div className="app-component-container">
            <CaseHeader setSearchString={setSearchString} />
            <CaseList searchString={searchString} />
        </div>
    );
};

export default Case;
