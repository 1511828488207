"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
require("./phoneInput.css");
const supportedCountries = {
    se: {
        localization: "Sverige",
        countryCode: "se",
        dialCode: "+46",
        validator: {
            pattern: "^07[0-9]{8}",
            maxLength: 10,
            minLength: 10,
        },
    },
};
const FlagComponent = ({ countries, setChosenCountry, chosenCountry }) => {
    const [open, setOpen] = (0, react_1.useState)(false);
    const wrapperElem = (0, react_1.useRef)(null);
    const handleClick = (countryString) => {
        setChosenCountry(countryString);
        setOpen(!open);
    };
    (0, react_1.useEffect)(() => {
        const clickOutside = (e) => {
            var _a;
            if (!((_a = wrapperElem.current) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("button", { className: "phoneInput-flag-wrapper", onClick: () => {
                    setOpen(!open);
                }, type: "button", "aria-label": "V\u00E4lj ett land", children: [(0, jsx_runtime_1.jsx)("div", { className: `phoneInput-flag-flag ${chosenCountry}` }), (0, jsx_runtime_1.jsx)("div", { className: `phoneInput-flag-arrow ${open && "phoneInput-flag-arrow-active"}` })] }), open && ((0, jsx_runtime_1.jsx)("ul", { className: "phoneInput-flag-list-wrapper", ref: wrapperElem, children: Object.entries(countries).map(([key, country]) => {
                    return ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsxs)("button", { type: "button", onClick: () => {
                                handleClick(key);
                            }, tabIndex: 0, children: [(0, jsx_runtime_1.jsx)("div", { role: "img", "aria-label": chosenCountry, className: `phoneInput-flag-flag ${key}` }), (0, jsx_runtime_1.jsx)("span", { children: `${country.localization} ${country.dialCode}` })] }) }, key));
                }) }))] }));
};
const PhoneInput = ({ name, handleChange, error, wrapperClass }) => {
    const [chosenCountry, setChosenCountry] = (0, react_1.useState)(Object.keys(supportedCountries)[0]);
    const [currentCountry, setCurrentCountry] = (0, react_1.useState)(supportedCountries[chosenCountry]);
    const inputElem = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        setCurrentCountry(supportedCountries[chosenCountry]);
    }, [chosenCountry]);
    const getFormattedPhoneNumber = (0, react_1.useCallback)((nonFormatted) => {
        if (!nonFormatted) {
            return "";
        }
        let formattedTel = nonFormatted;
        // Remove 0 from formattedTel if present and add dialCode
        formattedTel = formattedTel.startsWith("0")
            ? `${currentCountry.dialCode}${formattedTel.substring(1)}`
            : `${currentCountry.dialCode}${formattedTel}`;
        return formattedTel;
    }, [currentCountry]);
    (0, react_1.useEffect)(() => {
        Object.defineProperty(inputElem.current, "formattedValue", {
            get: () => {
                var _a;
                return getFormattedPhoneNumber((_a = inputElem.current) === null || _a === void 0 ? void 0 : _a.value);
            },
        });
    }, []);
    const placeholder = `Mobilnummer (${currentCountry.validator.minLength} siffror)`;
    return ((0, jsx_runtime_1.jsx)("div", { className: `phoneInput-wrapper ${wrapperClass} ${error ? "textInput-error" : ""}`, children: (0, jsx_runtime_1.jsxs)("label", { htmlFor: name, "aria-label": placeholder, children: [(0, jsx_runtime_1.jsx)(FlagComponent, { countries: supportedCountries, setChosenCountry: setChosenCountry, chosenCountry: chosenCountry }), (0, jsx_runtime_1.jsx)("input", { ref: inputElem, type: "tel", maxLength: currentCountry.validator.maxLength, minLength: currentCountry.validator.minLength, pattern: currentCountry.validator.pattern, id: name, className: "phoneInput-input", placeholder: placeholder, name: name, required: true, onChange: handleChange, autoComplete: "tel-national" })] }) }));
};
exports.PhoneInput = PhoneInput;
