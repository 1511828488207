import { type ReactNode, useCallback, useEffect, useRef, useState } from "react";
import type * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import type { GlobalState } from "~/src/types";
import { apiUrl, route } from "~/src/utils/constants";
import loginLogo from "../../media/images/svg/logo_full.svg";
import { login } from "../../redux/actions/login/actions";
import language from "../../utils/language/index";
import BigButton from "../tools/buttons/bigButton/bigButton";
import Textfield from "../tools/form/textfield/textfield";
import "./login.css";

const Login = ({ showPassword = false }: { showPassword?: boolean }) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { LOGIN } = language[locales];
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
    const [validInputsSignIn, setValidInputsSignIn] = useState<boolean>(false);
    const [validCookieChecked, setValidCookieChecked] = useState(false);
    const numberOfErrors = useRef(0);
    const eFormSignIn = useRef<HTMLFormElement>(null);
    const dispatch = useDispatch();

    const username = localStorage.getItem("ommej-username");

    if (!showPassword) {
        try {
            sessionStorage.setItem("ommej-path", window.location.pathname);
        } catch (error) {
            // no need to handle error. User is coming to home instead.
            console.error(error);
        }
    }

    let isMounted = true;
    const checkForValidCookie = async () => {
        try {
            await dispatch(login(null));
            if (isMounted) {
                setValidCookieChecked(true);
            }
        } catch (_e) {
            if (isMounted) {
                setValidCookieChecked(true);
            }
        }
    };

    useEffect(() => {
        checkForValidCookie();
        return () => {
            isMounted = false;
        };
    }, []);

    const onChange = useCallback(() => {
        setErrorMessage(null);
        setValidInputsSignIn(eFormSignIn?.current ? eFormSignIn.current.checkValidity() : false);
    }, []);

    const onSubmitPassword = async (ev) => {
        ev.preventDefault();
        try {
            const { password } = ev.target;
            const body = {
                email: username,
                password: password.value,
            };
            await dispatch(login(body));
            const path = sessionStorage.getItem("ommej-path");
            sessionStorage.removeItem("ommej-path");
            navigate(path || "/");
        } catch (error: any) {
            if (error.status >= 500) {
                setErrorMessage(LOGIN.SERVER);
            } else if (error.status >= 400) {
                if (error.status === 403) {
                    setErrorMessage(LOGIN.FORBIDDEN);
                    return;
                }
                numberOfErrors.current += 1;
                if (numberOfErrors.current > 2) {
                    const helpText = (
                        <>
                            {LOGIN.CREDENTIALS_EXTRA}
                            &nbsp;
                            <a
                                href="/resetpwd"
                                style={{ color: "var(--color-grey)", textDecoration: "none" }}
                            >
                                {LOGIN.HERE}
                            </a>
                            .
                        </>
                    );
                    setErrorMessage(helpText);
                } else {
                    setErrorMessage(LOGIN.CREDENTIALS);
                }
            } else {
                setErrorMessage(LOGIN.GENERIC);
            }
        }
    };

    function onSubmitUsername(e: React.FormEvent<HTMLFormElement>) {
        // biome-ignore lint/complexity/useLiteralKeys: allow
        localStorage.setItem("ommej-username", e.target["email"].value);
    }

    if (!validCookieChecked) {
        return null;
    }

    if (!showPassword) {
        return (
            <div className="login-fullscreen">
                <a href="/" className="login-logo-wrapper">
                    <img src={loginLogo} alt="logo" />
                </a>
                <div className="login-wrapper">
                    <h3>{language[locales].LOGIN.HEADING}</h3>
                    <form
                        onSubmit={(e) => {
                            onSubmitUsername(e);
                        }}
                        className="login-form-wrapper"
                        method="POST"
                        action={`${apiUrl}sso/saml/login`}
                    >
                        <Textfield
                            defaultValue={username ?? undefined}
                            type="email"
                            name="email"
                            text={language[locales].LOGIN.EMAIL}
                            customStyle={{ width: "100%" }}
                            autocomplete="username email"
                            required
                        />
                        <BigButton
                            text={language[locales].RESET_PASSWORD.BUTTON_RESET}
                            type="submit"
                        />
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="login-fullscreen">
            <a href="/" className="login-logo-wrapper">
                <img src={loginLogo} alt="logo" />
            </a>
            <div className="login-wrapper">
                <h3>{username}</h3>
                <form ref={eFormSignIn} className="login-form-wrapper" onSubmit={onSubmitPassword}>
                    <Textfield
                        type="password"
                        name="password"
                        text={language[locales].LOGIN.PASSWORD}
                        min={3}
                        required
                        handleChange={onChange}
                        customStyle={{ width: "100%" }}
                        autocomplete="current-password"
                    />
                    <div className="login-error-wrapper">
                        <p className="text-error">{errorMessage}</p>
                    </div>
                    <BigButton
                        type="submit"
                        text={language[locales].LOGIN.BUTTON}
                        disabled={!validInputsSignIn}
                    />
                    <Link to={route.RESET_PASSWORD} className="login-forgot-pwd">
                        {language[locales].LOGIN.FORGOT_PASSWORD}
                    </Link>
                </form>
            </div>
        </div>
    );
};
export default Login;
