import type { Invitation } from "@ommej/types";
import { useState } from "react";
import { useSelector } from "react-redux";
import FluidWrapper from "~/src/components/tools/fluidWrapper/fluidWrapper";
import Tooltip from "~/src/components/tools/tooltip/tooltip";
import CopyIcon from "~/src/media/images/svg/copy.svg";
import SmileyIcon from "~/src/media/images/svg/smiley.svg";
import type { GlobalState } from "~/src/types";
import { QuestionMarkIcon } from "~/src/utils/icons";
import language from "~/src/utils/language";
import { copyToClipboard } from "~/src/utils/systemFunctions";
import "./caseCopy.css";

type CaseCopyProps = {
    invitation: Invitation.InvitationWithId;
};

const CaseCopy = ({ invitation }: CaseCopyProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { SEND_INVITE } = language[locales].CASE.ADD_INVITATION;
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        copyToClipboard(invitation.code.replace(/.{3}/, "$&-"));
        setCopied(true);
    };

    if (!invitation) {
        return null;
    }
    return (
        <FluidWrapper height="hg-small caseCopy-wrapper">
            <div className="caseCopy-tooltip-wrapper">
                <h3>{`${SEND_INVITE.CASE_HEADING}`}</h3>
                <Tooltip title={`${SEND_INVITE.TOOLTIP}`} position="bottom">
                    <QuestionMarkIcon />
                </Tooltip>
            </div>
            <p className="text-description caseCopy-explainer-text">{SEND_INVITE.TEXT}</p>
            <div className="caseCopy-link-wrapper">
                <div className="caseCopy-text-wrapper">
                    <p>{SEND_INVITE.LINK_TEXT}</p>
                    <h2>{invitation.code.replace(/.{3}/, "$&-")}</h2>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        handleCopy();
                    }}
                    className={`caseCopy-button-wrapper ${copied && "caseCopy-animate"}`}
                >
                    {copied ? (
                        <>
                            <img src={SmileyIcon} alt="icon" />
                            {SEND_INVITE.COPIED}
                        </>
                    ) : (
                        <>
                            <img src={CopyIcon} alt="icon" />
                            {SEND_INVITE.COPY_BUTTON}
                        </>
                    )}
                </button>
            </div>
        </FluidWrapper>
    );
};

export default CaseCopy;
