import type { Invitation } from "@ommej/types";
import { useState } from "react";
import { useSelector } from "react-redux";
import NormalButton from "~/src/components/tools/buttons/normalButton/normalButton";
import Textfield from "~/src/components/tools/form/textfield/textfield";
import SuccessImg from "~/src/media/images/png/smsSuccessImg.png";
import SuccessIcon from "~/src/media/images/svg/success-arrow.svg";
import type { LanguageState } from "~/src/types";
import { reqWithPayload } from "~/src/utils/api";
import { supportedCountries } from "~/src/utils/constants";
import language from "~/src/utils/language";
import "./caseSend.css";

type CaseSendProps = {
    invitation: Invitation.ToClient;
};

const CaseSend = ({ invitation }: CaseSendProps) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const [textValue, setTextValue] = useState("");
    const [inputError, setInputError] = useState("");
    const [successIcon, setSuccessIcon] = useState(false);
    const [successImg, setSuccessImg] = useState(false);
    const { COMMON } = language[locales];
    const { SEND } = language[locales].CASE.EDIT_INVITATION;

    const handleChange = (sendValue: string) => {
        setInputError("");
        setTextValue(sendValue);
        if (
            sendValue.match(supportedCountries.se.validator.sms) ||
            sendValue.match(supportedCountries.se.validator.email)
        ) {
            setSuccessIcon(true);
        } else {
            setSuccessIcon(false);
        }
    };

    const sendInvite = async () => {
        if (!successIcon) {
            setInputError(SEND.INPUT_ERROR);
            return;
        }
        if (!invitation) {
            return;
        }
        let sendObj: string;
        if (textValue.includes("@")) {
            sendObj = textValue;
        } else {
            const formatSms = textValue.substring(1).replace(/-|\s/g, "");
            sendObj = `${supportedCountries.se.dialCode}${formatSms}`;
        }
        try {
            await reqWithPayload(`invitations/${invitation.id}/clients/pending`, "put", {
                username: sendObj,
            });
            setTextValue("");
            setSuccessIcon(false);
            setSuccessImg(true);
            setTimeout(() => {
                setSuccessImg(false);
            }, 5000);
        } catch (error) {
            console.error(error);
        }
    };

    // reminder should only be shown for single cases when there's one in pending
    const showReminder =
        invitation.clients?.pending &&
        invitation.clients.pending.length === 1 &&
        invitation.clients?.accepted &&
        invitation.clients.accepted.length === 0;

    if (!successImg) {
        return (
            <div className="caseSend-wrapper">
                <h3>{showReminder ? SEND.HEADER_REMINDER : SEND.HEADER}</h3>
                <div className="caseSend-textfield">
                    <Textfield
                        text={SEND.PLACEHOLDER}
                        type="email"
                        name="mobileNumber"
                        value={textValue}
                        handleChange={(e) => {
                            handleChange(e.target.value);
                        }}
                        customStyle={{ width: "17rem" }}
                        inputError={inputError}
                    />
                    <NormalButton
                        text={COMMON.SEND}
                        customStyle={{ marginBottom: "1rem" }}
                        color="green"
                        handleButton={() => {
                            sendInvite();
                        }}
                    />
                    {successIcon && (
                        <img src={SuccessIcon} alt="success" className="caseSend-success-icon" />
                    )}
                </div>
            </div>
        );
    }
    return (
        <div className="caseSend-success-wrapper">
            <h3>{showReminder ? SEND.REMINDER_SENT : SEND.SENT}</h3>
            <img src={SuccessImg} alt="sent reminder success" />
        </div>
    );
};

export default CaseSend;
