import PropTypes, { string } from "prop-types";
import "./modalHeader.css";

const ModalHeader = ({ children, customStyle }) => {
    return (
        <header className="modalHeader-wrapper" style={customStyle}>
            {children}
        </header>
    );
};

ModalHeader.defaultProps = {
    customStyle: null,
};

ModalHeader.propTypes = {
    children: PropTypes.node.isRequired,
    customStyle: PropTypes.objectOf(string),
};

export default ModalHeader;
