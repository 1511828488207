import { combineReducers } from "redux";
import invitationReducer from "./invitationReducer";
import languageReducer from "./languageReducer";
import loginReducer from "./loginReducer";
import unitReducer from "./unitReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
    unit: unitReducer,
    user: userReducer,
    login: loginReducer,
    invitation: invitationReducer,
    language: languageReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default (state, action) => {
    return rootReducer(action.type === "LOGOUT" ? undefined : state, action);
};
