"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAvatarPath = void 0;
const metadata_1 = require("@ommej/metadata");
function getAvatarPath(id) {
    if (!id) {
        return `/assets/avatars/${metadata_1.avatars.DEFAULT[0]}.svg`;
    }
    return `/assets/avatars/${id}.svg`;
}
exports.getAvatarPath = getAvatarPath;
