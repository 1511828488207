import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import type { GlobalState } from "~/src/types";
import { route } from "~/src/utils/constants";
import { ArrowRightIcon } from "~/src/utils/icons";
import language from "~/src/utils/language/index";
import { getRoutingState } from "~/src/utils/systemFunctions";
import "./breadCrumbs.css";

type PathEntry = {
    path: string;
    text: string;
};

function getPath(pathMap, pathName): PathEntry[] {
    return pathMap.filter((pathEntry) => pathName.startsWith(pathEntry.path));
}

const BreadCrumbs = () => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const location = useLocation();
    const routingState = getRoutingState(location);

    const pathMap: PathEntry[] = useMemo(() => {
        // IMPORTANT: the order of the elements for each group in this array needs
        // to be in the order of number of parts in the path. For example these paths:
        //
        //    /admin
        //    /admin/users
        //    /admin/profile
        //    /admin/users/123
        //
        // need to be in this order.
        return [
            // admin
            { path: route.ADMIN, text: language[locales].ADMIN.ADMIN },
            { path: route.ADMIN_UNITS, text: language[locales].ADMIN.HANDLE_UNIT },
            { path: route.ADMIN_USERS, text: language[locales].ADMIN.HANDLE_USER },
            { path: route.ADMIN_PROFILE, text: language[locales].ADMIN.MY_PROFILE },

            // statistics
            { path: route.STATISTICS, text: language[locales].APP.ROUTE_STATISTICS },
            { path: route.STATISTICS_QUESTIONS, text: language[locales].STATISTICS.QUESTIONS },
            { path: route.STATISTICS_FORMS, text: language[locales].STATISTICS.FORMS },
        ];
    }, []);

    return (
        <header className="breadCrumbs-wrapper">
            {getPath(pathMap, location.pathname).map((pathElement, index) => {
                return (
                    <Fragment key={pathElement.path}>
                        {index !== 0 && <ArrowRightIcon customStyle={{ margin: "0 0.5rem" }} />}
                        <Link
                            to={pathElement.path}
                            state={routingState}
                            className="breadCrumbs-link"
                        >
                            <h4>{pathElement.text}</h4>
                        </Link>
                    </Fragment>
                );
            })}
        </header>
    );
};

export default BreadCrumbs;
