import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BigButton from "~/src/components/tools/buttons/bigButton/bigButton";
import ErrorComponent from "~/src/components/tools/errorCompnent/errorComponent";
import Textfield from "~/src/components/tools/form/textfield/textfield";
import PasswordStrength from "~/src/components/tools/passwordStrength";
import loginLogo from "~/src/media/images/svg/logo_full.svg";
import { reqWithPayload } from "~/src/utils/api";
import { minimumPasswordLength } from "~/src/utils/constants";
import language from "~/src/utils/language/index";
import { validateEmail } from "~/src/utils/validate";
import "./login.css";
import type { RootState } from "~/src/redux/reducers/rootReducer";

const ResetPassword = () => {
    const { locales } = useSelector((state: RootState) => {
        return state.language;
    });
    const [token, setToken] = useState<string | null>();
    const [mailSent, setMailSent] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputError, setInputError] = useState<string>();
    const [password1, setPassword1] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setToken(params.get("token"));
    }, []);

    const onPassword1Change = useCallback((e) => {
        setPassword1(e.target.value);
    }, []);

    const handleSubmit = async () => {
        setErrorMessage(false);
        if (token) {
            // changing password
            const ePassword1: HTMLInputElement | null = document.querySelector("#password1");
            const ePassword2: HTMLInputElement | null = document.querySelector("#password2");
            if (!ePassword1 || !ePassword2) {
                setErrorMessage(true);
                return;
            }
            if (ePassword1.value !== ePassword2.value) {
                setInputError(language[locales].RESET_PASSWORD.ERROR_NOT_MATCHING);
                return;
            }

            if (ePassword1.value.length < minimumPasswordLength) {
                setInputError(language[locales].RESET_PASSWORD.ERROR_TOO_SHORT);
                return;
            }

            const msg = {
                password: ePassword1.value,
                token,
            };

            try {
                await reqWithPayload("user/resetpwd", "post", msg);
                setPasswordChanged(true);
            } catch (_err) {
                setErrorMessage(true);
            }
        } else {
            // start reset flow
            const eEmail: HTMLInputElement | null = document.querySelector("#email");
            if (!eEmail) {
                setErrorMessage(true);
                return;
            }
            const email = eEmail.value;
            const valid = validateEmail(email);
            if (!valid) {
                setInputError(language[locales].RESET_PASSWORD.ERROR_INVALID_EMAIL);
                return;
            }

            try {
                await reqWithPayload("user/resetpwd/start", "post", { email });
                setMailSent(true);
            } catch (_err) {
                setErrorMessage(true);
            }
        }
    };

    // change password view
    if (token) {
        return (
            <div className="login-fullscreen">
                <a href="/" className="login-logo-wrapper">
                    <img src={loginLogo} alt="logo" />
                </a>
                <div className="login-wrapper">
                    <h3>{language[locales].RESET_PASSWORD.HEADING_CHANGE}</h3>
                    <div className="login-form-wrapper">
                        {passwordChanged ? (
                            <>
                                {language[locales].RESET_PASSWORD.PASSWORD_CHANGED}
                                <BigButton
                                    text={language[locales].RESET_PASSWORD.GOTO_LOGIN}
                                    handleButton={() => {
                                        window.location.href = "/";
                                    }}
                                    customStyle={{ marginTop: "2rem" }}
                                />
                            </>
                        ) : (
                            <>
                                <Textfield
                                    type="password"
                                    name="password1"
                                    handleChange={onPassword1Change}
                                    text={language[locales].RESET_PASSWORD.PASSWORD}
                                    required
                                    customStyle={{ width: "15rem" }}
                                    autocomplete="new-password"
                                />
                                <PasswordStrength password={password1} locales={locales} />
                                <Textfield
                                    type="password"
                                    name="password2"
                                    text={language[locales].RESET_PASSWORD.PASSWORD_AGAIN}
                                    required
                                    customStyle={{ width: "15rem" }}
                                    autocomplete="new-password"
                                />
                                {inputError && (
                                    <div className="login-error-wrapper">
                                        <p className="text-error">{inputError}</p>
                                    </div>
                                )}
                                <BigButton
                                    text={language[locales].RESET_PASSWORD.HEADING_CHANGE}
                                    handleButton={handleSubmit}
                                />
                            </>
                        )}
                    </div>
                </div>
                {errorMessage && <ErrorComponent />}
            </div>
        );
    }

    // start reset flow
    return (
        <div className="login-fullscreen">
            <a href="/" className="login-logo-wrapper">
                <img src={loginLogo} alt="logo" />
            </a>
            <div className="login-wrapper">
                <h3>{language[locales].RESET_PASSWORD.HEADING_RESET}</h3>
                <div className="login-form-wrapper">
                    {mailSent ? (
                        <span
                            // biome-ignore lint/security/noDangerouslySetInnerHtml: we control the string
                            dangerouslySetInnerHTML={{
                                __html: language[locales].RESET_PASSWORD.CHECK_EMAILS,
                            }}
                        />
                    ) : (
                        <>
                            <Textfield
                                type="email"
                                name="email"
                                text={language[locales].LOGIN.EMAIL}
                                required
                                customStyle={{ width: "100%" }}
                                autocomplete="username email"
                            />
                            {inputError && (
                                <div className="login-error-wrapper">
                                    <p className="text-error">{inputError}</p>
                                </div>
                            )}
                            <BigButton
                                text={language[locales].RESET_PASSWORD.BUTTON_RESET}
                                handleButton={handleSubmit}
                            />
                        </>
                    )}
                </div>
            </div>
            {errorMessage && <ErrorComponent />}
        </div>
    );
};

export default ResetPassword;
