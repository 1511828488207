import type { BBObject, Beb2, BebModel, Form, Invitation } from "@ommej/types";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ErrorComponent from "~/src/components/tools/errorCompnent/errorComponent";
import BBLogo from "~/src/media/images/svg/BB-logo.svg";
import ArrowIcon from "~/src/media/images/svg/arrow_down.svg";
import type { ClientInfo, GlobalState } from "~/src/types";
import { reqWithPath, reqWithPayload } from "~/src/utils/api";
import { willReplaceForm } from "~/src/utils/invitationFunctions";
import language from "~/src/utils/language";
import Dropdown, { type DropdownItemsArray } from "../../tools/dropdown/dropdown";
import BackaBarnetChart from "./backaBarnetChart";
import ConversationAnswers from "./conversationAnswers";
import "./backaBarnet.css";

const bbForm: Form.Ref = {
    id: "bb_base",
    version: "1",
};

type BackaBarnetProps = {
    beb: Beb2;
    invitation: Invitation.ToClient;
    setInvitation: (invitation: Invitation.ToClient) => void;
    client: ClientInfo;
};

const questionTags: Form.QuestionTagsBB[] = [
    // important to follow this order since the categories follow the categories of an image
    "BB_CARE",
    "BB_SAFETY",
    "BB_FEEL_GOOD",
    "BB_LEISURE",
    "BB_CONTEXT",
    "BB_RESPONSIBLE",
    "BB_RESPECT",
    "BB_DEVELOPING",
];

const BackaBarnet = ({ beb, invitation, setInvitation, client }: BackaBarnetProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { BACKA_BARNET, QUESTION_TAGS, COMMON } = language[locales];
    const [dropdownValue, setDropDownValue] = useState<DropdownItemsArray>();
    const [bbArray, setBBArray] = useState<BBObject[] | []>();
    const [chartData, setChartData] = useState<BBObject>();
    const [chartAlarms, setChartAlarms] = useState<Partial<Record<Form.QuestionTagsBB, boolean>>>(
        {},
    );
    const [showSentFormText, setShowSentFormText] = useState(false);
    const [formWillOverride, setFormWillOverride] = useState(false);
    const [bbData, setBBData] = useState<BebModel["BB"]>();
    const [showError, setShowError] = useState(false);

    const getClientBBById = async (clientId: string) => {
        try {
            const response = await reqWithPath(
                "invitations",
                "get",
                `${invitation.id}/clients/${clientId}/bb`,
            );
            const res = await response.json();
            setBBData(res);
        } catch (err) {
            console.error(err);
            setShowError(true);
        }
    };

    useEffect(() => {
        getClientBBById(client.id);

        return () => {
            setShowError(false);
        };
    }, []);

    const checkValues = (item: BBObject) => {
        const bbValues = {};
        for (const tag of questionTags) {
            if (item.values[tag]) {
                bbValues[tag] = item.values[tag];
            } else {
                bbValues[tag] = 0;
            }
        }
        return bbValues;
    };

    useEffect(() => {
        if (bbData) {
            // need to traverse data to check if there are empty values
            const filteredData: BBObject[] = bbData.map((item) => {
                return {
                    timestamp: new Date(item.timestamp),
                    done: item.done,
                    values: checkValues(item),
                    answers: item.answers,
                };
            });
            setBBArray(filteredData);
            const newChartData = filteredData[filteredData.length - 1];
            setChartData(newChartData || chartData);
        }
    }, [bbData]);

    useEffect(() => {
        if (!beb?.answerTags.ALARM) {
            return;
        }
        const newAlarms: Map<Form.QuestionTagsBB, boolean> = new Map();
        for (const tag of questionTags) {
            newAlarms.set(tag, false);
            for (const qid of beb.questionTags[tag] || []) {
                if (beb.answerTags.ALARM[qid]) {
                    newAlarms.set(tag, true);
                    break;
                }
            }
        }
        setChartAlarms(Object.fromEntries(newAlarms));
    }, [beb]);

    useEffect(() => {
        if (bbData) {
            const dropdown = bbData.map((item) => {
                // Dropdown component takes date as a string
                return {
                    value: new Date(item.timestamp).getTime().toString(),
                    text: new Date(item.timestamp).toLocaleDateString("sv-SE"),
                };
            });
            setDropDownValue(dropdown);
        }
    }, [bbData]);

    useEffect(() => {
        async function load() {
            const willReplace = await willReplaceForm(bbForm, invitation);
            setFormWillOverride(willReplace);
        }
        load();
    }, []);

    const handleDropdown = (timestamp: string) => {
        if (!bbArray) {
            return;
        }
        const bbObj = bbArray.find((item) => {
            return timestamp === new Date(item.timestamp).getTime().toString();
        });
        if (bbObj) {
            setChartData(bbObj);
        }
    };

    async function addForm(invId: string) {
        try {
            const res = await reqWithPayload(`invitations/${invId}/forms`, "post", {
                id: "bb_base",
                version: "1",
            });
            const updatedInvitation = await res.json();
            setShowSentFormText(true);
            // this timeout is for showing the text above for a couple of seconds
            // we need the rerender (new invitation) to not show the button next time
            // the user visit this tab
            setTimeout(() => {
                setInvitation(updatedInvitation);
            }, 5000);
        } catch (err) {
            console.error(err);
        }
    }

    if (showError) {
        return <ErrorComponent />;
    }

    if (!dropdownValue || !chartData) {
        return null;
    }

    return (
        <div className="backaBarnet-wrapper">
            <div className="backaBarnet-header">
                <h1>{BACKA_BARNET.PLAN}</h1>
                <img src={BBLogo} alt="Backa barnet logotype" />
            </div>
            <div className="backaBarnet-chart-wrapper">
                <div className="backaBarnet-sub-header">
                    <span>
                        <h2>{BACKA_BARNET.COMPASS}</h2>
                        <p>{BACKA_BARNET.BB_INTRO}</p>
                        <Dropdown
                            items={dropdownValue}
                            value={
                                chartData.timestamp &&
                                new Date(chartData.timestamp).getTime().toString()
                            }
                            handleChange={(timestamp) => {
                                handleDropdown(timestamp);
                            }}
                        />
                    </span>
                    {invitation.id &&
                        !formWillOverride &&
                        (!showSentFormText ? (
                            <button
                                className="backaBarnet-button"
                                type="button"
                                onClick={() => {
                                    addForm(invitation.id);
                                }}
                            >
                                {BACKA_BARNET.SEND_BUTTON}
                            </button>
                        ) : (
                            <p className="backaBarnet-sent">{`${COMMON.SENT_NEW_QUESTIONS}!`}</p>
                        ))}
                </div>
                {!chartData.done && (
                    <p style={{ marginTop: "0.75rem", fontSize: "0.75rem" }}>
                        {BACKA_BARNET.NO_ANSWER}
                    </p>
                )}
                <div className="backaBarnet-chart">
                    <BackaBarnetChart data={{ ...chartData, alarms: chartAlarms }} />
                </div>

                <details style={{ textAlign: "center" }} className="backaBarnet-bb-answers-details">
                    <summary
                        style={{ width: "12rem", margin: "auto", userSelect: "none" }}
                        className="iconButton-wrapper iconButton-noIcon"
                    >
                        {COMMON.SHOW_ANSWERS}
                        <img src={ArrowIcon} alt="" />
                    </summary>
                    {questionTags.map((tag) => {
                        return (
                            <Fragment key={tag}>
                                <h3
                                    style={{ marginTop: "1.5rem" }}
                                    className="text-bold"
                                >{`${QUESTION_TAGS[tag]} (${COMMON.AVERAGE_VALUE.toLowerCase()}: ${Math.round(chartData.values[tag] ?? 0)})`}</h3>

                                {(chartData.answers[tag] || []).map((answer) => {
                                    return (
                                        <Fragment key={answer.question.text?.[locales]}>
                                            {answer.question.text[locales]}:{" "}
                                            <span className="text-bold">
                                                {answer.answer.text?.[locales]}
                                            </span>
                                            <br />
                                        </Fragment>
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                </details>
            </div>

            {beb && (
                <>
                    <div className="backaBarnet-ommej-wrapper" style={{ marginBottom: "1rem" }}>
                        <h2>{BACKA_BARNET.BB_OMMEJ_HEADER}</h2>
                        <p>{BACKA_BARNET.BB_OMMEJ_INTRO}</p>
                    </div>

                    <ConversationAnswers
                        beb={beb}
                        client={client}
                        questionTagsToInclude={questionTags}
                        showOthers
                    />
                </>
            )}
        </div>
    );
};

export default BackaBarnet;
