// @ts-check

import PropTypes from "prop-types";

// Language related
import { useSelector } from "react-redux";
import language from "../../utils/language";

import { SunburstChart, findRootDataNode } from "../../utils/charts/sunburst";
import { getAnswerDescription, getQuestionName } from "./question-data";

/** @typedef {import('../../utils/charts/sunburst').SunburstData & { name: string }} SunburstAnswerData */

/**
 * @param {object} form
 * @param {import('./question-graph').TreeStatistics[]} answers
 * @param {import('../../utils/ts-types').LanguageLocales} locale
 * @param {string} [prefix]
 * @returns {SunburstAnswerData[]}
 */
const formatAnswerData = (form, answers, locale, prefix) => {
    return answers.map(({ questionId, answerId, clientCount, correlations }) => {
        const id = `${prefix || ""}::${questionId}::${answerId}`;
        const name = getAnswerDescription(form, answerId, questionId, locale);

        /** @type {SunburstAnswerData} */
        const result = {
            id,
            name,
            ...(correlations
                ? { children: formatAnswerData(form, correlations, locale, id) }
                : { value: clientCount, children: [] }),
        };

        return result;
    });
};

/**
 * @param {object} props
 * @param {import('./question-graph').StatisticsResponse} props.data
 * @param {object} props.form
 * @returns {JSX.Element | null}
 */
const StatisticsPieChart = ({ form, data }) => {
    const { locales } = useSelector(
        /**
         * @param {import('~/src/types').GlobalState} state
         * @returns {import('~/src/types').GlobalState["language"]}
         */
        (state) => {
            return state.language;
        },
    );

    const { ANSWER_COUNT, ANSWER_COUNT_OF_TOTAL } = language[locales].STATISTICS;

    const {
        questions: [mainQ],
        answers,
    } = data;

    /** @type {import('../../utils/charts/sunburst').SunburstChartTextFn} */
    const rootLabel = (node) => {
        const count = node.children?.reduce(
            (sum, { __dataNode: { value: clientCount = 0 } = {} }) => {
                return sum + clientCount;
            },
            0,
        );

        return `${ANSWER_COUNT} ${count}`;
    };

    /** @type {import('../../utils/charts/sunburst').SunburstChartTextFn} */
    const tooltipContent = (_node, dataNode) => {
        const rootValue = (dataNode && findRootDataNode(dataNode))?.value || 0;
        const currentValue = dataNode?.value || 0;
        const relativeValue = Math.round((currentValue / rootValue) * 100);
        return `${currentValue} (${relativeValue}%) ${ANSWER_COUNT_OF_TOTAL} ${rootValue}`;
    };

    return !form || !mainQ ? null : (
        <SunburstChart
            data={{
                id: mainQ,
                name: getQuestionName(form, mainQ, locales),
                children: formatAnswerData(form, answers, locales),
            }}
            {...{
                rootLabel,
                tooltipContent,
            }}
        />
    );
};

StatisticsPieChart.defaultProps = {
    form: undefined,
};

StatisticsPieChart.propTypes = {
    form: PropTypes.object,
    data: PropTypes.exact({
        questions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        answers: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
};

export default StatisticsPieChart;
