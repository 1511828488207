"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenderAvatarParts = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const avatarFunctions_1 = require("../helpers/avatarFunctions");
const types_1 = require("../types");
require("./renderAvatarParts.css");
const RenderAvatarParts = ({ avatarModel, type, title, downloadable = false, }) => {
    const [svgArray, setSvgArray] = (0, react_1.useState)([]);
    const [error, setError] = (0, react_1.useState)();
    const eSvg = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        function getSvgArray(model) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const svgModel = yield (0, avatarFunctions_1.getSvgModel)(model);
                    setSvgArray(svgModel);
                }
                catch (err) {
                    // TODO implement error component in componente
                    console.error(err);
                    setError("Fel vid laddning av avatar");
                }
            });
        }
        getSvgArray(avatarModel);
    }, [avatarModel]);
    if (error) {
        return (0, jsx_runtime_1.jsx)("p", { children: error });
    }
    if (!svgArray) {
        return (0, jsx_runtime_1.jsx)("p", { children: "Loading..." });
    }
    function download(svgElement) {
        if (!svgElement) {
            return;
        }
        const svg = svgElement.outerHTML;
        const blob = new Blob([svg.toString()]);
        const element = document.createElement("a");
        element.download = "avatar.svg";
        element.href = window.URL.createObjectURL(blob);
        element.click();
        element.remove();
    }
    function getSvg(elements, width, height) {
        if (eSvg.current) {
            // we need to clear the previous svg, otherwise they will just add more
            // and more paths. Unclear why this happens, but can probably thank react
            // for it...
            const eG = eSvg.current.getElementById("avatar-parts");
            while (eG.firstChild) {
                eG.removeChild(eG.firstChild);
            }
        }
        const svg = ((0, jsx_runtime_1.jsxs)("svg", { ref: eSvg, width: width, height: height, viewBox: "0 0 200 300", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("title", { children: title }), (0, jsx_runtime_1.jsx)("filter", { id: "shadow-filter", x: "0", y: "0", width: "100%", height: "100%", children: (0, jsx_runtime_1.jsxs)("feComponentTransfer", { children: [(0, jsx_runtime_1.jsx)("feFuncR", { type: "linear", slope: "0.6" }), (0, jsx_runtime_1.jsx)("feFuncG", { type: "linear", slope: "0.6" }), (0, jsx_runtime_1.jsx)("feFuncB", { type: "linear", slope: "0.6" })] }) }), (0, jsx_runtime_1.jsx)("g", { id: "avatar-parts" })] }));
        if (eSvg.current) {
            const eG = eSvg.current.getElementById("avatar-parts");
            eG.insertAdjacentHTML("beforeend", elements.map((element) => element.data).join());
        }
        return svg;
    }
    if (type === types_1.RenderAvatarType.THUMBNAIL) {
        return getSvg(svgArray, 100, 100);
    }
    if (downloadable) {
        return ((0, jsx_runtime_1.jsx)("section", { style: { cursor: "pointer" }, onClick: () => {
                download(eSvg.current);
            }, onKeyPress: () => {
                download(eSvg.current);
            }, className: "renderAvatarParts-wrapper", children: getSvg(svgArray, 200, 300) }));
    }
    return (0, jsx_runtime_1.jsx)("section", { className: "renderAvatarParts-wrapper", children: getSvg(svgArray, 200, 300) });
};
exports.RenderAvatarParts = RenderAvatarParts;
