import { useEffect, useRef, useState } from "react";
import language from "~/src/utils/language";
import type { LanguageLocales } from "~/src/utils/ts-types";
import "./passwordStrength.css";

type PasswordStrength = {
    password: string;
    locales: LanguageLocales;
};

const PasswordStrength = ({ password, locales }: PasswordStrength) => {
    const [passwordScore, setPasswordScore] = useState<number>(0);
    const zxcvbnRef = useRef<any>();

    useEffect(() => {
        async function load() {
            try {
                zxcvbnRef.current = await import("@zxcvbn-ts/core");
                const { dictionary, adjacencyGraphs } = await import("@zxcvbn-ts/language-common");

                const options = {
                    dictionary: {
                        ...dictionary,
                    },
                    graphs: adjacencyGraphs,
                };
                zxcvbnRef.current.zxcvbnOptions.setOptions(options);
            } catch (err) {
                console.error(err);
            }
        }
        load();
    }, []);

    useEffect(() => {
        if (!password || !zxcvbnRef.current) {
            setPasswordScore(0);
            return;
        }
        const check = zxcvbnRef.current.zxcvbn(password);
        setPasswordScore(check.score);
    }, [password]);

    return (
        <div className="password-strength">
            <span className="password-strength-label">
                {language[locales].TOOLS.PASSWORD_STRENGTH}:
            </span>
            <meter
                className="password-strength-meter"
                min={0}
                max={4}
                low={2}
                high={3}
                optimum={4}
                value={passwordScore}
            />
        </div>
    );
};

export default PasswordStrength;
