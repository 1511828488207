import { useSelector } from "react-redux";
import Modal from "~/src/components/tools/modal/modal";
import SquareWrapper from "~/src/components/tools/squareWrapper/squareWrapper";
import GroupBigIcon from "~/src/media/images/svg/groupicon_big.svg";
import PersonBigIcon from "~/src/media/images/svg/singleicon_big.svg";
import type { LanguageState } from "~/src/types";
import { INVITE_CRUMBS } from "~/src/utils/constants";
import language from "~/src/utils/language";
import "./chooseInvitation.css";

type ChooseInvitationProps = {
    handleCrumbs: (crumb: string) => void;
    setIsGroup: (group: boolean) => void;
};

const ChooseInvitation = ({ handleCrumbs, setIsGroup }: ChooseInvitationProps) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const { CHOOSE_INV } = language[locales].CASE.ADD_INVITATION;

    const handleInvite = (type: string) => {
        handleCrumbs(type);
        setIsGroup(type === INVITE_CRUMBS.GROUP);
    };

    return (
        <Modal.ModalBody>
            <main className="chooseInvitation-main-wrapper">
                <h1>{CHOOSE_INV.HEADING}</h1>
                <div className="chooseInvitation-square-wrapper">
                    <SquareWrapper>
                        <button
                            className="chooseInvitation-button-wrapper"
                            type="button"
                            onClick={() => {
                                handleInvite(INVITE_CRUMBS.SINGLE);
                            }}
                        >
                            <img src={PersonBigIcon} alt="person icon" />
                            <h4>{CHOOSE_INV.SUBHEADING2}</h4>
                        </button>
                    </SquareWrapper>
                    <SquareWrapper>
                        <button
                            className="chooseInvitation-button-wrapper"
                            type="button"
                            onClick={() => {
                                handleInvite(INVITE_CRUMBS.GROUP);
                            }}
                        >
                            <img src={GroupBigIcon} alt="group icon" />
                            <h4>{CHOOSE_INV.SUBHEADING1}</h4>
                        </button>
                    </SquareWrapper>
                </div>
            </main>
        </Modal.ModalBody>
    );
};

export default ChooseInvitation;
