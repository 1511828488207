import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Types from "~/src/utils/types";
import Can from "../../../../../rbac/can";
import { updateUnit } from "../../../../../redux/actions/units/actions";
import language from "../../../../../utils/language";
import NormalButton from "../../../../tools/buttons/normalButton/normalButton";
import RoundButton from "../../../../tools/buttons/roundButton/roundButton";
import SecondaryButton from "../../../../tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import Textfield from "../../../../tools/form/textfield/textfield";
import "./unitName.css";

const UnitName = ({ unitObj, setUnitObj, role }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [toggleComponent, setToggleComponent] = useState(false);
    const [inputError, setInputError] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [defaultName] = useState(unitObj.name);
    const dispatch = useDispatch();

    useEffect(() => {
        if (unitObj.name.length > 2) {
            setDisabled(false);
            setInputError("");
        }
        return () => {
            setDisabled(true);
            setInputError("Namnet måste bestå av minst 2 bokstäver");
        };
    }, [unitObj.name]);

    const handleChange = (e) => {
        setUnitObj({ ...unitObj, [e.target.name]: e.target.value });
    };

    const handleCancel = () => {
        setToggleComponent(false);
        setUnitObj({ ...unitObj, name: defaultName });
    };

    const sendName = () => {
        if (unitObj.name.length > 2 && !disabled) {
            dispatch(updateUnit(unitObj));
            setToggleComponent(false);
        } else {
            setInputError("Namnet måste bestå av minst 2 bokstäver");
        }
    };

    const handleYes = useCallback(() => {
        return (
            <RoundButton
                icon="pencil"
                className="unitName-roundButton"
                handleButton={() => {
                    return setToggleComponent(true);
                }}
            />
        );
    }, []);

    return (
        <FluidWrapper height="hg-small">
            {!toggleComponent ? (
                <div className="unitName-closed-wrapper">
                    <h3>{language[locales].ADMIN.UNIT.ADD_UNIT.NAME}</h3>
                    <h4 className="unitName-unit">{unitObj.name}</h4>
                    <Can
                        role={role}
                        perform="unit:update"
                        yes={() => {
                            return handleYes();
                        }}
                        no={() => {
                            return null;
                        }}
                    />
                </div>
            ) : (
                <>
                    <h3>{language[locales].ADMIN.UNIT.ADD_UNIT.NAME}</h3>
                    <Textfield
                        text={language[locales].ADMIN.UNIT.ADD_UNIT.NAME_INPUT}
                        type="text"
                        name="name"
                        value={unitObj.name}
                        handleChange={handleChange}
                        inputError={inputError}
                        customStyle={{ width: "18rem" }}
                    />
                    <div className="unitAdress-save-wrapper">
                        <SecondaryButton text="Avbryt" handleButton={handleCancel} />
                        <NormalButton
                            className="unitAdress-save-button"
                            text="Spara"
                            disabled={disabled}
                            handleButton={sendName}
                            customStyle={{ marginLeft: "0.5rem" }}
                        />
                    </div>
                </>
            )}
        </FluidWrapper>
    );
};

UnitName.propTypes = {
    setUnitObj: PropTypes.func.isRequired,
    unitObj: PropTypes.shape(Types.unit).isRequired,
    role: PropTypes.string.isRequired,
};

export default UnitName;
