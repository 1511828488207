// @ts-check

import { useSelector } from "react-redux";
import language from "../../utils/language";

import FluidWrapper from "../tools/fluidWrapper/fluidWrapper";
import ErrorIndicator from "../tools/indicator/errorIndicator";
import PageHeader from "../tools/pageHeader/pageHeader";

const NotFoundPage = () => {
    const { locales } = useSelector(
        /**
         * @param {import('~/src/types').GlobalState} state
         * @returns {import('~/src/types').GlobalState["language"]}
         */
        (state) => {
            return state.language;
        },
    );
    const { HEADER_HEADING, HEADER_SUBHEADING } = language[locales].NOT_FOUND;

    return (
        <div className="app-component-container">
            <FluidWrapper height="hg-small">
                <PageHeader heading={HEADER_HEADING} subheading={HEADER_SUBHEADING} />
                <ErrorIndicator description="" />
            </FluidWrapper>
        </div>
    );
};

export default NotFoundPage;
