import type { Invitation } from "@ommej/types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { LanguageState } from "~/src/types";
import { updateInvitation } from "../../../../redux/actions/invitation/actions";
import language from "../../../../utils/language";
import { generateRandomString } from "../../../../utils/systemFunctions";
import GhostButton from "../../../tools/buttons/ghostButton/ghostButton";
import NormalButton from "../../../tools/buttons/normalButton/normalButton";
import RoundButton from "../../../tools/buttons/roundButton/roundButton";
import SecondaryButton from "../../../tools/buttons/secondaryButton/secondaryButton";
import Textfield from "../../../tools/form/textfield/textfield";
import Tooltip from "../../../tools/tooltip/tooltip";
import "./caseKey.css";

const CaseKey = ({
    invitation,
    setInvitation,
}: {
    invitation: Invitation.ToClient;
    setInvitation: (invitation: Invitation.ToClient) => void;
}) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const [changeName, setChangeName] = useState(false);
    const [currentName, setCurrentName] = useState(invitation.name);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setCurrentName(e.target.value);
    };

    const handleGenerate = () => {
        const value = generateRandomString();
        setCurrentName(value);
    };

    const handleCancel = () => {
        setCurrentName(invitation.name);
        setChangeName(false);
    };

    const handleSend = () => {
        if (invitation) {
            if (invitation.name.length > 2) {
                const updatedInvitation = { ...invitation, name: currentName };
                setInvitation(updatedInvitation);
                dispatch(updateInvitation(updatedInvitation));
                setChangeName(false);
            }
        }
    };

    return changeName ? (
        <div className="caseKey-wrapper">
            <div className="caseKey-textfield-header">
                <h3>{language[locales].CASE.EDIT_INVITATION.KEY.HEADING}</h3>
                <Tooltip
                    title={language[locales].CASE.EDIT_INVITATION.KEY.TOOLTIP}
                    position="bottom"
                >
                    <RoundButton icon="question" border={false} color="beige" />
                </Tooltip>
            </div>
            <div className="caseKey-textfield-wrapper">
                <Textfield
                    type="text"
                    name="name"
                    text={language[locales].CASE.EDIT_INVITATION.KEY.NAME_INPUT}
                    value={currentName}
                    handleChange={(e) => {
                        handleChange(e);
                    }}
                    customStyle={{ width: "15rem", marginRight: "1rem" }}
                />
                <GhostButton
                    icon="refresh"
                    text={language[locales].CASE.EDIT_INVITATION.KEY.GENERATE_BUTTON}
                    handleGhostButton={handleGenerate}
                    customStyle={{ marginBottom: "0.8rem" }}
                />
            </div>
            <div className="caseKey-textfield-button">
                <SecondaryButton
                    text={language[locales].CASE.EDIT_INVITATION.KEY.CANCEL_BUTTON}
                    handleButton={handleCancel}
                />
                <NormalButton
                    text={language[locales].CASE.EDIT_INVITATION.KEY.SAVE_BUTTON}
                    handleButton={handleSend}
                    customStyle={{ marginLeft: "0.5rem" }}
                />
            </div>
        </div>
    ) : (
        <>
            <div className="caseKey-noChange-wrapper">
                <h3>{language[locales].CASE.EDIT_INVITATION.KEY.HEADING}</h3>
                <RoundButton
                    icon="pencil"
                    handleButton={() => {
                        setChangeName(true);
                    }}
                />
            </div>
            <h4 className="caseKey-name">{invitation.name}</h4>
        </>
    );
};

export default CaseKey;
