// @ts-check

import PropTypes from "prop-types";
import "./indicator.css";

import classNameModifiers from "../../../utils/class-name-modifier";

/**
 * @typedef IndicatorProps
 * @property {React.ReactNode} children
 * @property {string} [modifier]
 * @property {string} [description]
 */

/** @type {import('react').FunctionComponent<IndicatorProps>} */
const Indicator = ({ children, description, modifier }) => {
    return (
        <div className={classNameModifiers("indicator-wrapper", modifier)}>
            {children}
            {description && <span className="indicator-wrapper__description">{description}</span>}
        </div>
    );
};

Indicator.defaultProps = {
    description: undefined,
    modifier: undefined,
};

Indicator.propTypes = {
    children: PropTypes.element.isRequired,
    description: PropTypes.string,
    modifier: PropTypes.string,
};

export default Indicator;
