import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { updateUser } from "../../../../../redux/actions/users/actions";
import language from "../../../../../utils/language";
import { validateEmail } from "../../../../../utils/validate";
import NormalButton from "../../../../tools/buttons/normalButton/normalButton";
import RoundButton from "../../../../tools/buttons/roundButton/roundButton";
import SecondaryButton from "../../../../tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import Textfield from "../../../../tools/form/textfield/textfield";
import "./userEmail.css";

const UserEmail = ({ user, setUser, userEmail }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [toggleComponent, setToggleComponent] = useState(false);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    let isMounted = true;

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    const handleCancel = () => {
        setUser({ ...user, email: userEmail });
        setToggleComponent(false);
        setError("");
    };

    const handleChange = (e) => {
        setError("");
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSend = async () => {
        // get two api request due to backend dont return full unitobj //
        const validate = validateEmail(user.email);
        if (!validate) {
            setError(language[locales].ADMIN.USER.EDIT_USER.INVALID_EMAIL);
            return;
        }
        try {
            await dispatch(updateUser(user));
            if (isMounted) {
                setToggleComponent(false);
            }
        } catch (_err) {
            if (isMounted) {
                setToggleComponent(true);
            }
        }
    };

    if (!user) {
        return (
            <FluidWrapper height="hg-small">Något blev fel när komponenten laddades.</FluidWrapper>
        );
    }
    return (
        <FluidWrapper height="hg-small">
            {toggleComponent ? (
                <>
                    <div className="userEmail-wrapper">
                        <h3>E-post</h3>
                        <Textfield
                            text="Ändra E-post"
                            type="email"
                            name="email"
                            value={user.email}
                            inputError={error}
                            handleChange={handleChange}
                            customStyle={{ width: "18rem" }}
                        />
                    </div>
                    <div className="userEmail-button-wrapper">
                        <SecondaryButton text="Avbryt" handleButton={handleCancel} />
                        <NormalButton
                            text="Spara"
                            handleButton={handleSend}
                            customStyle={{ marginLeft: "0.5rem" }}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="userEmail-noChange-wrapper">
                        <h3>E-post</h3>
                        <RoundButton
                            icon="pencil"
                            handleButton={() => {
                                setToggleComponent(true);
                            }}
                        />
                    </div>
                    <h4 className="userEmail-noChange-email">{user.email}</h4>
                </>
            )}
        </FluidWrapper>
    );
};

UserEmail.defaultProps = {
    userEmail: undefined,
};

UserEmail.propTypes = {
    user: PropTypes.shape({
        _id: PropTypes.string,
        roleCode: PropTypes.string,
        email: PropTypes.string,
        username: PropTypes.string,
        unit: PropTypes.shape(Types.unit),
    }).isRequired,
    setUser: PropTypes.func.isRequired,
    userEmail: PropTypes.string,
};

export default UserEmail;
