import { useSelector } from "react-redux";
import ExternalLinkIcon from "~/src/media/images/svg/external.svg";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language/index";
import "./openHouse.css";

export default function OpenHouse() {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { OPEN_HOUSE } = language[locales].HOME;

    return (
        <div className="home-card openHouse-wrapper">
            <h2>{OPEN_HOUSE.HEADER}</h2>
            <p>
                <span className="openHouse-subheader">{OPEN_HOUSE.SUB_HEADER}</span>
                {OPEN_HOUSE.TEXT}
            </p>
            <p className="openHouse-registration">{OPEN_HOUSE.NO_REGISTRATION}</p>
            <a
                href="http://ommej.se/oppet-hus"
                className="normalButton-wrapper normalButton-blue normalButton-icon"
                style={{
                    color: "white",
                    height: "2.75rem",
                    padding: "0 1.5rem 0 1rem",
                    textDecoration: "none",
                    width: "fit-content",
                }}
                target="_blank"
                rel="noreferrer"
            >
                <img src={ExternalLinkIcon} alt="Extern länk" />
                {OPEN_HOUSE.BUTTON_LINK}
            </a>
        </div>
    );
}
