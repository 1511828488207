{
    "APP": {
        "ROUTE1": "Överblick",
        "ROUTE3": "Admin",
        "ROUTE_ARTICLES": "Artiklar",
        "ROUTE5": "Händelser",
        "ROUTE6": "Hjälp",
        "ROUTE_STATISTICS": "Statistik"
    },
    "NAV": {
        "LOGOUT": "Logga ut",
        "DEMO": "Barnappen",
        "MENU": "Meny"
    },
    "LOGIN": {
        "HEADING": "Logga in",
        "EMAIL": "E-post",
        "PASSWORD": "Lösenord",
        "BUTTON": "Logga in",
        "ERROR": "Användarnamn eller lösenord är fel",
        "HEADING_ORG": "Logga in via din organisation",
        "BUTTON_ORG": "Logga in via organisation",
        "OR": "Eller",
        "FORGOT_PASSWORD": "Glömt lösenordet?",
        "CREDENTIALS": "Fel användarnamn eller lösenord",
        "CREDENTIALS_EXTRA": "Fel användarnamn eller lösenord. Har du glömt lösenordet kan du återställa det",
        "FORBIDDEN": "Du har inte längre tillgång till Ommej, kontakta oss om du tror något blivit fel",
        "HERE": "här",
        "SERVER": "Vi har stött på problem på vår sida, var vänlig och försök igen",
        "GENERIC": "Något gick tyvärr fel"
    },
    "RESET_PASSWORD": {
        "HEADING_RESET": "Återställ lösenord",
        "HEADING_CHANGE": "Ändra lösenord",
        "PASSWORD": "Nytt lösenord",
        "PASSWORD_AGAIN": "Upprepa lösenord",
        "BUTTON_RESET": "OK",
        "CHECK_EMAILS": "Om vi har ett konto som använder epostadressen så kommer du att få ett epost. Kolla din inkorg så får du hjälp att återställa ditt lösenord. Det kan ta ett litet tag för e-posten att komma fram. Om du har vidare problem, <a href=\"https://ommej.notion.site/Inloggning-8f2a90c2af234c4094b922929f801c30\">läs här</a>.",
        "PASSWORD_CHANGED": "Ditt lösenord har uppdaterats",
        "GOTO_LOGIN": "Gå till loginsidan",
        "ERROR_NOT_MATCHING": "Lösenorden stämmer inte överens, var vänlig försök igen",
        "ERROR_TOO_SHORT": "Lösenordet är för kort, var vänlig försök igen",
        "ERROR_INVALID_EMAIL": "Du verkar inte ha angett en giltig e-postadress"
    },
    "HOME": {
        "WELCOME": {
            "HEADER": "Dags att komma igång!",
            "SUB_HEADER": "Välkommen",
            "TEXT": "För att sätta igång en process med ett barn behöver du först skapa ditt första ärende.",
            "BUTTON_CREATE": "Skapa ärende"
        },
        "MY_INVITATIONS": {
            "HEADER": "Din överblick"
        },
        "GENDER": {
            "HEADING": "Mina användare",
            "MALE": "Pojkar",
            "FEMALE": "Flickor",
            "NEUTRAL": "Icke-binär",
            "NO_ANSWER": "Inget svar"
        },
        "AGE": {
            "HEADING": "Åldersfördelning",
            "NO_ANSWER": "Inget svar"
        },
        "NEWS": {
            "SUBJECT": "Mitt förslag till förbättring"
        },
        "NOTIFICATIONS": {
            "HEADING": "Notiser",
            "NO_NOTIFICATION": "Du har inga notiser!",
            "NO_INVITE": "Du har inte bjudit in någon ny användare på",
            "DAYS": "Dagar",
            "NEW_INVITATION": "Vill du göra en ny inbjudan?",
            "USER_LOGGED_IN": "Du har användare som fortfarande inte accepterat sin väntande inbjudan på",
            "USER_NO_ANSWER": "dagar. Skicka en påminnelse!",
            "USER_NOT_LOGGED_IN": "Du har användare som inte uppdaterat sina svar på 3 månader.",
            "NOT_UPDATED": "Det har snart gått tre månader sedan användaren har lagt till några svar.",
            "PAUSE": "Vill du pausa eller göra en uppföljning på ärendet?"
        },
        "OPEN_HOUSE": {
            "HEADER": "Välkommen till digitalt Öppet hus!",
            "SUB_HEADER": "Varje fredag klockan 9",
            "TEXT": " är våra användare välkomna till Öppet Hus med Ommej via videosamtal. Här kan du ställa frågor, få hjälp med eventuella problem eller ge förslag på förbättringar om du har några idéer.",
            "NO_REGISTRATION": "Ingen anmälan krävs!",
            "BUTTON_LINK": "Till möteslänken"
        },
        "EVENTS": {
            "HEADER": "Kommande händelser",
            "SEE_ALL": "Se alla"
        },
        "ACTIVITIES": {
            "HEADER": "Barnens aktivitet",
            "CASES": "Dina ärenden",
            "ANSWERED": "har svarat på",
            "QUESTIONS": "nya frågor",
            "ACCEPTED": "har godkänt din kontaktförfrågan",
            "LOGIN": "loggade in",
            "CREATED": "har börjat använda Ommej!",
            "ALARM1": "har gett",
            "ALARM2": "nya risksvar"
        }
    },
    "CASE": {
        "HEADER_SEARCH": "Sök ärende",
        "HEADER_BUTTON": "Skapa ärende",
        "TAB1": "Mina ärenden",
        "TAB2": "Delade ärenden",
        "CASE_NOT_EXIST": "Något gick fel, detta ärende finns inte",
        "CASE_LIST": {
            "NO_CASE": "Inga ärenden",
            "LIST_BUTTON": "Visa fler"
        },
        "CASE_GROUP": {
            "CRUMB": "Alla ärende",
            "ERROR_TEXT": "Något gick fel och vi kunde inte hitta ditt ärende",
            "NO_ANSWER": "Inga svar"
        },
        "INVITATION_CARD": {
            "HEADING": "Inbjudan",
            "SUBHEADING1": "Gruppinbjudan",
            "SUBHEADING2": "Inbjudan",
            "ANSWER": "barn har svarat",
            "BUTTON1": "Rapport",
            "BUTTON2": "Hantera"
        },
        "ADD_INVITATION": {
            "CREATE": "Skapa ärende",
            "GROUP": "En grupp",
            "SINGLE": "Ett barn",
            "INVITE": "Inbjudan",
            "CREATED": "Skapad",
            "CHOOSE_INV": {
                "HEADING": "Vill du bjuda in en grupp eller ett barn?",
                "SUBHEADING1": "En grupp",
                "SUBHEADING2": "Ett barn"
            },
            "CHOOSE_NAME": {
                "CASE_HEADING": "Vad ska ärendet kallas?",
                "TOOLTIP": "Här döper du ditt ärende. Välj något som är lätt att komma ihåg! Alternativt kan du låta oss automatiskt generera ett namn åt ärendet om du klickar på genereraknappen. Ditt namn måste ha minst 3 tecken.",
                "NAME_INPUT": "Ange ett eget namn eller generera",
                "GENERATE_BUTTON": "Generera",
                "CREATE_BUTTON": "Skapa ärende"
            },
            "SEND_INVITE": {
                "HEADING_SINGLE": "Skicka inbjudningslänk",
                "TOOLTIP_SINGLE": "Skriv in barnets mobilnummer (10 siffror, börjar med 07) eller epost och skicka inbjudan till barnet genom att klicka på skicka-knappen (för närvarande stödjer vi bara svenska mobilnummer).\n\n Barnet kan sedan acceptera eller neka inbjudan.",
                "INPUT_SINGLE": "Barnets telefonnummer eller e-post",
                "INPUT_ERROR_SINGLE": "Fel e-post eller mobilnummer (svenskt nummer och 10 siffror).",
                "CASE_HEADING": "Inbjudningskod",
                "ERROR": "Något gick tyvärr fel. Prova att logga ut och in igen eller kontakta Ommej!",
                "LINK_TEXT": "Inbjudningskod",
                "LINK": "ommej.se",
                "TOOLTIP": "Inbjudningskoden är det du använder för att bjuda in barn till gruppärendet. Barnen anger koden i Ommej-appen och ansluter då till ärendet.\n\n Koden är automatiskt genererad och går inte att ändra på.\n\n Du kan senare hitta koden när du hanterar ditt ärende om du vill bjuda in fler barn. ",
                "TEXT": "Skicka denna kod till de barn du vill inkludera i ärendet.",
                "COPY_BUTTON": "Kopiera till urklipp",
                "COPIED": "Kopierat!",
                "SMS_HEADING": "Sms:a ärendet",
                "MOBILE_INPUT": "Ange barnets mobilnummer",
                "ADD_BUTTON": "Lägg till fler barn",
                "MESSAGE_HEADING": "SMS-meddelande till barnen",
                "MESSAGE_INPUT": "Meddelande",
                "MESSAGE": "Du är inbjuden till Ommej av",
                "SEND_BUTTON": "Skicka SMS",
                "READY_BUTTON": "Klar"
            },
            "CASE_CREATED": {
                "HEADING": "Ärende skapat!",
                "SUB_HEADING": "Nu kan du bjuda in ett barn till ärendet.",
                "INVITE_CHILDREN": "Bjud in barn",
                "HEADING_SUCCESS": "Inbjudan har skickats till barnet",
                "SUB_HEADING_SUCCESS": "Barnet behöver först godkänna din inbjudan innan du kan se barnets svar."
            },
            "IMPORTANT": {
                "HEADER": "Viktig information",
                "TEXT": "Nu har ett ärende skapats. Nu kan du bjuda in ett barn genom att skicka ett SMS eller ett e-post till barnet (här ovanför). Det är viktigt att barnet har skapat ett konto med samma telefonnummer eller e-postadress som du bjuder in barnet med, annars kommer inte barnet se din inbjudan.\n\n Fråga barnet hur de loggar in i appen om du är osäker."
            }
        },
        "EDIT_INVITATION": {
            "HEADING1": "Hantera",
            "HEADING2": "grupp",
            "HEADING3": "inbjudan",
            "SUBHEADING1": "Gruppinbjudan",
            "SUBHEADING2": "barn har svarat",
            "SUBHEADING3": "Inbjudan",
            "READY_BUTTON": "Klar",
            "INFO": {
                "HEADING": "Information",
                "CREATED_BY": "Skapad av",
                "OWNED_BY": "Ärendet ägs av",
                "DATE": "Datum",
                "UNIT": "Verksamhet",
                "PAUSE": "Pausad",
                "PAUSE1": "Nej",
                "PAUSE2": "Ja",
                "INVITE": "Inbjudningskod",
                "LINK_TEXT": "Länk till ärende",
                "LINK": "ommej.se"
            },
            "KEY": {
                "HEADING": "Ärendenyckel",
                "TOOLTIP": "Ange ditt ärendenamn",
                "NAME_INPUT": "Ändra ärendenyckel",
                "GENERATE_BUTTON": "Generera",
                "CANCEL_BUTTON": "Avbryt",
                "SAVE_BUTTON": "Spara"
            },
            "SETTINGS": {
                "HEADING": "Inställningar",
                "PAUSE_BUTTON": "Pausa ärende",
                "UNPAUSE_BUTTON": "Återstarta ärende",
                "DELETE_BUTTON": "Ta bort ärende",
                "CLOSE_BUTTON": "Avsluta ärende",
                "ERROR_MESSAGE_PAUSE": "Ärendet gick inte att pausa, var god kontakta din administratör.",
                "ERROR_MESSAGE_CLOSE": "Något gick fel när ärendet skulle avslutas, var god och kontakta din administratör.",
                "ERROR_MESSAGE_DELETE": "Något gick fel när ärendet skulle tas bort, var god och kontakta din administratör.",
                "CONFIRM_DELETE": "Barn kopplade till denna inbjudan samt deras svar kommer också tas bort.",
                "CONFIRM_CLOSE": "När du avslutar har du inte längre tillgång till ärendet och kan inte söka efter ärendet med ärendenyckel. Verksamhetens koppling till barnet tas bort"
            },
            "SEND": {
                "HEADER": "Skicka inbjudan",
                "HEADER_REMINDER": "Skicka påminnelse att svara",
                "INPUT_ERROR": "Fel e-post eller mobilnummer (svenskt nummer och 10 siffror).",
                "PLACEHOLDER": "Telefonnummer eller e-post",
                "SENT": "Inbjudan skickad!",
                "REMINDER_SENT": "Påminnelse skickad!"
            },
            "SMS": {
                "SMS_MESSAGE": "Du är inbjuden till Ommej av",
                "SMS_COUNTRY": "+46",
                "ERROR_MESS": "Telefonnumret måste innehålla 10 siffror och börja med 07xx",
                "HEADING": "Inbjudan och uppföljning",
                "SMS_HEADING": "Bjud in via SMS",
                "TOOLTIP": "Skicka en inbjudningslänk till barnets telefon genom att ange telefonnummer (10 siffror) och tryck \"lägg till\". \n\n Du kan lägga till hur många nummer du vill.",
                "MOBILE_INPUT": "Ange barnets mobilnummer (10 siffror)",
                "ADD_BUTTON": "Lägg till",
                "MESSAGE_HEADING": "SMS-meddelande till barnen",
                "MESSAGE_INPUT": "Meddelande",
                "FOLLOW_LINK": "Följ länken och skapa ett konto.",
                "CANCEL_BUTTON": "Avbryt",
                "SEND_BUTTON": "Skicka SMS",
                "SUCCESS": "SMS har skickats",
                "SENT": "Skickat",
                "SMS_CARD": {
                    "ERROR_TEXT": "Något gick fel, kontakta din administratör"
                },
                "ADD_SUB_INVITATION": "+ Skapa ny uppföljning"
            },
            "EMAIL": {
                "HEADING": "Bjud in via e-post",
                "TOOLTIP": "Skicka en inbjudningslänk till barnets e-post genom att skriva in e-postadress i fältet och tryck \"lägg till\". \n\n Du kan lägga till hur många e-postadresser du vill.",
                "CHILD_EMAIL": "Ange barnets e-postadress",
                "BUTTON_ADD": "Lägg till",
                "SUB_HEADING": "E-postmeddelande till barnen",
                "MESSAGE": "Meddelande",
                "BUTTON_SEND": "Skicka mejl",
                "BUTTON_CANCEL": "Avbryt",
                "INVITED": "Du är inbjudan till Ommej",
                "ERROR_TEXT": "Ange minst 1 tecken ett @ och en domän ex. a@b.se",
                "SUCCESS": "E-post har skickats"
            },
            "SHARE": {
                "HEADING": "Dela ärendet med en annan användare",
                "SUB_HEADING": "Användare som kan se ärendet",
                "CONFIRM_SHARE": "Bekräfta delning av ärendet till ovanstående användare",
                "CANCEL_BUTTON": "Avbryt",
                "CONFIRM_BUTTON": "Bekräfta"
            },
            "INTERVENTIONS": {
                "HEADING": "Insatser",
                "START": "Start",
                "STOP": "Slut",
                "TYPE": "Typ",
                "STATE": "Status",
                "HEADER_ADD": "Registrera insats",
                "HEADER_INTERVENTION": "Insats",
                "BUTTON_SELECT": "+ Välj insats",
                "FILTER_ALL": "Alla insatser",
                "FILTER_RELEVANT": "Relevanta för min enhet"
            },
            "MOVE": {
                "HEADING": "Flytta ärende",
                "CONFIRM_MOVE": "Bekräfta flytt av ärendet till",
                "CANCEL_BUTTON": "Avbryt",
                "MOVE_BUTTON": "Flytta ärende"
            },
            "RESET": {
                "HEADING": "Återställ användarens lösenord",
                "NEW_PASSWORD_BUTTON": "Nytt lösenord",
                "SUB_HEADING": "SMS-meddelande till barnet",
                "TEXT": "Här kommer din återställningskod till Ommej.",
                "VISIT": "Gå in på",
                "INPUT_TEXT": "Ange barnets telefonnummer 07xx-xxxxxx",
                "RESET_CODE": "Återställningskod",
                "SEND_BUTTON": "Skicka SMS",
                "CANCEL_BUTTON": "Avbryt",
                "SMS_SUCCESS": "SMS:et har skickats",
                "MORE_BUTTON": "Skicka fler",
                "ERROR_MESSAGE": "SMS:et kunde inte skickas, kontakta din administratör"
            },
            "FORMS": {
                "HEADING": "Skicka fler frågor",
                "TOOLTIP": "Du kan skicka ett formulär till barn för att t.ex. veta hur barnet upplevde ett möte eller insats.\n\nVälj formulär i listan och klicka på knappen \"Skicka\". Barnet kommer då se formuläret i appen nästa gång de loggar in. När du skickat formuläret kan du se det här under. Så fort barnet börjar svara på frågorna kommer svaren visas och du kan se dem genom att klicka på knappen se svar",
                "BUTTON_ADD": "Skicka",
                "PREVIEW_FORM": "Förhandsgranska formulär",
                "PREVIEW": "Förhandsgranska",
                "SUBHEADER": "Skickade formulär",
                "DUPLICATE_FORM_WARNING": "Det finns redan ett sådant här formulär som ett eller flera barn inte svarat på. Du kan fortfarande välja att lägga till det, då kommer barnen med ofullständiga svar inte längre få möjlighet att svara färdigt på det föregående formuläret utan det kommer ersättas av det som nu skickas ut."
            },
            "POKE": {
                "HEADING": "Skicka meddelande",
                "BUTTON_ADD": "Skicka",
                "TEXT_HEADER": "Meddelande som skickas:",
                "MESSAGE_SENT": "Meddelande skickat",
                "TOOLTIP": "Här kan du skicka förbestämda små meddelanden som visas på barnets telefon, även när barnet inte aktivt använder Ommej-appen.\n\nDu kan till exempel påminna barnet att fortsätta berätta, uppmuntra, peppa eller bara säga “bra jobbat!"
            }
        },
        "HANDLE_INVITATION": {
            "HEADING_SINGLE": "Hantera ärende",
            "MEASURE_HEADING": "Åtgärder",
            "FINISHED_BUTTON": "Klar"
        },
        "REPORT": {
            "BUTTON1": "Om barnet",
            "BUTTON2": "Rapport",
            "BUTTON3": "BEB",
            "OPEN_COMPARE_VIEW": "Öppna jämförelse",
            "CLOSE_COMPARE_VIEW": "Stäng jämförelse",
            "TAB_REPORT": {
                "HEADING": "Identifierade behov",
                "KASAM": "BarnKASAM",
                "KASAM_ANSWER": "Här är alla svaren på det standardiserade BarnKASAM-självsvarsformuläret.",
                "CHART_HELP": "För musen över staplarna i diagrammet för att få exakta värden.",
                "NO_ANSWER": "Inga svar har angetts",
                "STEGEN": "Stegen",
                "STEGEN_LABEL1": "1 år sedan",
                "STEGEN_LABEL2": "Just nu",
                "STEGEN_LABEL3": "Om 1 år",
                "STEGEN_UPPER_VALUE": "Övre normalvärde",
                "STEGEN_MIDDLE_VALUE": "Medelvärde",
                "STEGEN_NETHER_VALUE": "Undre normalvärde",
                "STEGEN_CHILD_VALUE": "Värde för barnet",
                "KASAM_LABEL1": "Meningsfullhet",
                "KASAM_LABEL2": "Hanterbarhet",
                "KASAM_LABEL3": "Begriplighet",
                "KASAM_LABEL4": "Totalt",
                "CHILD_VALUE": "Barnets värde",
                "MOOD_HEADER": "Barnets humör och känslor över tid",
                "MOOD_TEXT": "Grafen visar barnets egna uppskattning av sitt humör och sina känslor.",
                "MOOD_NO_MOOD": "Barnet har inte fyllt i något humör eller känslor än. Varje dag får barnet en fråga om sitt mående i barnappen (som är frivilligt att skriva in). Vill du följa barnets mående kan du be hen att börja fylla i sitt mående.",
                "REPORT_LIST": {
                    "HELP": "Barnet vill ha hjälp med",
                    "WORRY": "Barnet är oroligt för",
                    "PROBLEM": "Identifierade problem"
                }
            }
        }
    },
    "ADMIN": {
        "ADMIN": "Admin",
        "HANDLE_UNIT": "Hantera enheter",
        "HANDLE_USER": "Hantera användare",
        "MY_PROFILE": "Min profil",
        "UNIT": {
            "UNIT_HEADER": "Välj enhet",
            "ADD_UNIT_BUTTON": "Skapa enhet",
            "UNIT_LEVEL": "Inga enheter",
            "NO_UNIT": "Inga enheter. Skapa gärna en ny!",
            "ACCESS": "Enheter jag har åtkomst till",
            "ADD_UNIT": {
                "HEADING": "Skapa enhet",
                "SUBHEADING": "under",
                "NAME": "Namn",
                "NAME_INPUT": "Namn på enhet",
                "ADRESS": "Adress",
                "ADRESS_INPUT": "Adress",
                "ZIP_INPUT": "Postnummer",
                "CITY_INPUT": "Ort",
                "ORG_INPUT": "Organisationsnummer",
                "UNIT_USERS": "Enhetens användare",
                "INVITE_BUTTON": "Bjud in",
                "CRUMB_NAME": "Access",
                "BUTTON_USERCARD": "Ta bort",
                "CREATE_BUTTON": "Skapa",
                "TAB_ACCESS_HEADING": "Användare",
                "TAB_ACCESS_SEARCH": "Sök namn eller e-post",
                "TAB_ACCESS_ACCESS": "Behörighet",
                "TAB_ACCESS_RADIO1": "Användare",
                "TAB_ACCESS_RADIO2": "Admin",
                "TAB_ACCESS_BUTTON": "Bjud in",
                "UNIT_TYPE": {
                    "HEADING": "Typ av enhet",
                    "PICK_TAGS": "Välj typer",
                    "PICK_TAGS_HELP": "Välj alla typer som passar in på enheten"
                }
            },
            "EDIT_UNIT": {
                "ADDRESS_HEADING": "Adress",
                "ACCESS_HEADING": "Access till användare utanför enheten",
                "ACCESS_BUTTON": "Ge access",
                "ACCESS_QUANTITY": "st",
                "SETTINGS_HEADING": "Inställningar",
                "SETTINGS_BUTTON": "Ta bort enhet",
                "SETTINGS_DELETE_UNIT_ERROR": "Du kan inte ha någon enhet under eller någon användare knuten för att kunna ta bort enheten",
                "USERS_HEADING": "Användare",
                "USERS_QUANTITY": "st",
                "NO_ADMIN_ERROR": "Du behöver minst en administratör i enheten",
                "TAB_ACCESS_HEADING": "Användare",
                "TAB_ACCESS_SEARCH": "Sök namn eller e-post",
                "TAB_ACCESS_ACCESS": "Behörighet",
                "TAB_ACCESS_RADIO1": "Användare",
                "TAB_ACCESS_RADIO2": "Admin",
                "TAB_ACCESS_BUTTON": "Ge access",
                "MOVE": {
                    "HEADING": "Flytta enhet under",
                    "CONFIRM": "Bekräfta flytt av enhet",
                    "CANCEL_BUTTON": "Avbryt",
                    "MOVE_BUTTON": "Flytta enhet",
                    "ERROR": "Något gick fel. Det gick inte att flytta enheten"
                }
            },
            "ADMIN_CARDS": {
                "SEARCH_UNIT_CARD": "Välj",
                "SEARCH_USER_CARD": "Välj",
                "UNIT_CARD": "Hantera"
            }
        },
        "USER": {
            "USERHEADER_HEADING": "Alla användare",
            "USERHEADER_ADD_BUTTON": "Ny användare",
            "USERCARD_NO_USER": "Inga användare är tillagda. Lägg till en ny genom att klicka på knappen 'Ny användare'.",
            "USERCARD_TEXT": "Hantera",
            "ADD_USER": {
                "HEADING": "Skapa ny användare",
                "NAME": "Namn",
                "NAME_INPUT": "Namn",
                "EMAIL_INPUT": "E-post",
                "COMPETENCE": "Behörighet",
                "RADIO1": "Användare",
                "RADIO2": "Admin",
                "UNIT": "Vilken enhet ska användaren tillhöra?",
                "SEARCH": "Sök",
                "SELECTED_UNIT": "Vald enhet",
                "CHOOSE_UNIT": "Välj enhet",
                "CREATE_BUTTON": "Skapa",
                "USER_ERROR": "Det finns ingen användare när vi försöker skicka till serven, kontakta support",
                "GENERAL_ERROR": "Något gick fel, försök igen",
                "DUPLICATE_USER": "Det finns redan en användare registrerad med denna e-postadress!"
            },
            "EDIT_USER": {
                "HEADING": "Hantera användare",
                "NAME": "Namn",
                "NAME_INPUT": "Namn",
                "EMAIL_INPUT": "E-post",
                "PASSWORD_INPUT": "Lösenord",
                "COMPETENCE": "Behörighet",
                "RADIO1": "Användare",
                "RADIO2": "Admin",
                "SETTINGS": "Inställningar",
                "SETTINGS_BUTTON": "Ta bort användare",
                "API_ERROR": "Det gick inte att ta bort användaren, kontakta support",
                "USER_ERROR": "Det finns ingen användare när vi försöker skicka till serven, kontakta support",
                "UNIT": "Enhet",
                "SEARCH": "Sök",
                "SELECTED_UNIT": "Vald enhet",
                "CHOOSE_UNIT": "Välj enhet",
                "PASSWORD_ERROR": "Ett fel uppstod när lösenordet skulle ändras. Om felet kvarstår kontakta din administratör.",
                "PASSWORD_MISMATCH": "De två textfälten måste matcha varandra",
                "INVALID_EMAIL": "Du verkar inte ha angett en giltig e-postadress",
                "TOOLTIP": "Användaren äger eller delges ett ärende, ägaren måste tas bort från ärendet.",
                "USER_NAME": {
                    "HEADING": "Namn",
                    "INPUT_NAME": "Ändra namn",
                    "INPUT_ERROR": "Namnet måste ha minst 2 bokstäver",
                    "SAVE_BUTTON": "Spara",
                    "CANCEL_BUTTON": "Avbryt"
                }
            }
        },
        "PROFILE": {
            "PROFILE_UNIT": {
                "HEADING": "Din enhet"
            },
            "PROFILE_ACCESS": {
                "HEADING": "Enheter du har behörighet till"
            }
        }
    },
    "HELP": {
        "HEADING": "Manual"
    },
    "TOOLS": {
        "DROPDOWN": "Välj",
        "DROPDOWN_NO_OPTION": "Inga alternativ",
        "LOADING": "Laddar...",
        "BASIC_NOT_FOUND_TEXT": "Hittades ej",
        "BASIC_ERROR_TEXT": "Något gick tyvärr fel",
        "ERROR_COMPONENT_BUTTON": "Stäng",
        "PRINT": "Skriv ut",
        "PRINT_FRIENDLY": "Utskriftvänligt format",
        "FILTER": "Filter",
        "SELECT": "Välj",
        "SELECTED": "Valda",
        "SEARCH": "Sök",
        "FILTER_ALL": "Alla",
        "CHILDS_OWN_STORY": "Barnets egen berättelse",
        "PEOPLE_IN_MY_LIFE": "Personer i barnets liv",
        "PLACES_I_LIVE": "Barnets boenden",
        "I_WAS_BORN": "Jag föddes",
        "PASSWORD_STRENGTH": "Lösenordsstyrka"
    },
    "COMPONENT": {
        "ERROR_RESOURCE_LOADING": "Fel vid hämtning av resurser för komponenten"
    },
    "NOT_FOUND": {
        "HEADER_HEADING": "Sidan hittades inte",
        "HEADER_SUBHEADING": "Vänligen försök igen eller kontakta oss"
    },
    "STATISTICS": {
        "ANSWER_COUNT": "Totalt antal svar",
        "ANSWER_COUNT_OF_TOTAL": "av totalt antal svar",
        "HEADER_HEADING_ALL": "Statistik för alla ärenden",
        "HEADER_HEADING_CASE": "Statistik för ",
        "HEADER_SUBHEADING": "Dela upp ett påstående i kön och ålder",
        "NO_MATCHES": "Inga träffar",
        "SELECTOR_LABEL": "Välj påstående",
        "GENDER_VS_BIRTHYEAR": "Könstillhörighet vs födelseår",
        "QUESTIONS": "Ommejs frågor",
        "FORMS": "Tillagda frågor",
        "STATS_OMMEJ": "Statistik för Ommejs frågor",
        "STATS_FORMS": "Statistik för tillagda frågor",
        "STATS_CLOSED_CASES": "Inkludera stängda ärenden",
        "STATS_ALL_CASES": "Alla barn"
    },
    "QUESTION_TAGS": {
        "SCHOOL": "Skola",
        "FAMILY": "Familj",
        "MYSELF": "Mej själv",
        "LEISURE": "Fritid",
        "BASE": "Övrigt",
        "KASAM": "KASAM",
        "STEGEN": "Stegen",
        "OMMEJ": "Frågor om Ommej",
        "METADATA": "Kön och ålder",
        "BB_CARE": "Omsorg",
        "BB_SAFETY": "Trygghet",
        "BB_FEEL_GOOD": "Må bra",
        "BB_LEISURE": "Fritid",
        "BB_CONTEXT": "Tillhörighet",
        "BB_RESPONSIBLE": "Ansvarstagande",
        "BB_RESPECT": "Respekteras",
        "BB_DEVELOPING": "Utvecklas"
    },
    "COMMON": {
        "REMOVED_USER": "Raderad användare",
        "NO_USER": "Ingen användare",
        "EMAIL_SENT": "E-post skickad",
        "DONE": "Klar",
        "SHOW_MORE": "Visa fler",
        "SHOWS": "Visar",
        "OF": "av",
        "USERS": "användare",
        "INACTIVE_ANSWERS_SHOW": "Visa tidigare svar",
        "INACTIVE_ANSWERS_HIDE": "Dölj tidigare svar",
        "CANCEL": "Hoppa över",
        "SEND": "Skicka",
        "CLOSE": "Stäng",
        "READ_FULL_ARTICLE": "Läs hela artikeln",
        "NUMBER_OF": "Antal",
        "ALARM_ANSWERS": "Identifierade risker",
        "ANSWERS": "Svar",
        "SENT": "Skickade",
        "FOLLOW_UPS": "Uppföljningar",
        "FROM": "Från",
        "CASES": "Ärenden",
        "NEXT": "Nästa",
        "PREVIOUS": "Föregående",
        "AVERAGE_VALUE": "Medelvärde",
        "BOYS": "Pojkar",
        "GIRLS": "Flickor",
        "TOTAL": "Totalt",
        "SENT_NEW_QUESTIONS": "Skickat frågor",
        "SHOW_ANSWERS": "Visa svar",
        "ACCOMMODATION": "Boende",
        "FEELING": "Mående",
        "YOU_LIVE_HERE": "Här bor du",
        "ABOUT": "Om",
        "NO_LOGIN": "Obesvarad inbjudan",
        "CATEGORY": "Kategorier",
        "ACTIVE": "Aktivt",
        "RISK_OVERVIEW": "Risköverblick",
        "NETWORK": "Nätverk",
        "LIVING_SITUATION": "Boendesituation",
        "OVERVIEW": "Översikt",
        "PRORENATA_MANAGE_HEADER": "Koppla till Prorenata",
        "PRORENATA_MANAGE_ADD_LINK": "Klistra in ID på ärendet från Prorenata, exempel: 420",
        "PRORENATA_MANAGE_LINK": "Öppna Prorenata-journal",
        "PRORENATA_MANAGE_COPY_LINK": "Kopiera Ommej-länk",
        "SAVED": "Sparat"
    },
    "FORMS": {
        "HEADING": "Svar på"
    },
    "CASE_DETAILS": {
        "ABOUT_CHILD": "Om barnet",
        "REPORT": "Rapport",
        "BEB": "Alla svar",
        "HANDLE": "Hantera",
        "ABOUT_GROUP": "Om gruppen",
        "STATISTICS": "Statistik",
        "BACKA_BARNET": "Barnsamtal",
        "CONVERSATION": "Vårt samtal",
        "ERROR_MESSAGE": "Något gick fel när vi skulle ladda ärendet. Det kan exempelvis bero på att ärendet inte finns eller att du inte har åtkomst till det.",
        "BACK": "Tillbaka till mina ärenden",
        "REMOVED": "Barnet har raderat sitt konto och alla sina svar. Förslagsvis kan du avsluta detta ärende under inställningar nedan."
    },
    "ABOUT_CASE": {
        "HEADING": "Ärende ",
        "GROUP": "Gruppärende ",
        "GROUP_WITH": "Grupp med",
        "CHILDREN": "barn",
        "CODE": "Kod till ärendet",
        "YEAR": "Född år",
        "CREATED": "Skapat",
        "LAST_ANSWER": "Senaste svar"
    },
    "TABLE": {
        "EMPTY_HEADING": "Hoppsan, här var det tomt!"
    },
    "CASE_LIST": {
        "CASE_LIST_TAB_TITLES": {
            "ALL_CASES": "Alla ärenden",
            "MY_CASES": "Mina ärenden",
            "SHARED_BY_ME": "Delas av mig",
            "SHARED_WITH_ME": "Delas med mig"
        },
        "ID": "ID",
        "TYPE": "Typ",
        "NAME": "Namn",
        "ALARM": "Risker",
        "CREATED": "Skapad",
        "STATUS": "Status",
        "OWNER": "Ägare",
        "UNIT": "Enhet",
        "SHARED_WITH": "Delas med",
        "NO_CHILD": "Inget barn inbjudet",
        "CHILDS_ANSWER": "Barn har svarat",
        "CHILD_CONNECTED": " barn har anslutit",
        "INVITATION_SENT": "Förfrågan skickad",
        "INVITATION_DENIED": "Barn har nekat inbjudan",
        "INVITATION_REMOVED": "Barnet har raderat sitt konto",
        "EMPTY_CASE": "Tomt ärende",
        "EMPTY": "Tomt",
        "REMOVED": "Raderat",
        "PAUSED": "Pausad",
        "PENDING": "Väntar",
        "DENIED": "Nekad",
        "NO_DATA_MESSAGE": "Du har inga ärenden än!"
    },
    "RELATIVE_TIME": {
        "RECENTLY": "Alldeles nyss",
        "HOUR_AGO": "timme sedan",
        "HOURS_AGO": "timmar sedan"
    },
    "ARTICLES": {
        "ARTICLES_HEADER": "Ommejs artikelsamling",
        "ARTICLES_HEADER_TEXT": "När barnet bygger sin berättelse i Ommej-appen så klarnar bilden av barnets risker & resurser. I Ommejs artikelsamling har vi samlat information och fakta om olika områden för barnen. Här nedan kan du läsa och titta på en del av denna artikelsamling som barnet kan komma att se. Artiklarna presenteras för barnet i takt med att barnets egna berättelse skapas i Ommej-appen.\n\n Tänk på att vissa länkar i artiklarna går till sidor i barnens app och fungerar inte här. Vi har därför gjort att de länkarna inte går att trycka på.",
        "ERROR": "Hittade inga artiklar"
    },
    "BACKA_BARNET": {
        "CHILD_VALUE": "Barnets värde",
        "DIAGRAM": "Backa barnet diagram",
        "PLAN": "Barnsamtal",
        "COMPASS": "Behovskompass",
        "BB_INTRO": "Dessa svar kommer från Backa barnet-specifika frågor som barnet svarat på i Ommej-appen. Om du ändrar datum kan du se hur barnet har svarat vid andra tillfällen på samma frågor.",
        "NO_ANSWER": "Barnet har ännu inte svarat på alla frågorna. Grafen är bara delvis redovisad.",
        "SEND_BUTTON": "Be barnet svara på nytt",
        "BB_OMMEJ_HEADER": "Svar på Ommejs frågor",
        "BB_OMMEJ_INTRO": "Svaren nedan kommer från Ommej-appen och är Ommej-frågor som kategoriserats med Backa barnets kategorier.",
        "ALARM": "Risker",
        "RESOURCE": "Resurser",
        "NORMAL": "Övriga svar"
    },
    "CONVERSATION": {
        "STEGEN_HEADER": "Mitt liv just nu",
        "STEGEN_SUBHEADER": "På en skala 1-10 betyder nummer 10 att jag har det bästa tänkbara livet. Nummer 1 betyder att jag har det sämsta tänkbara livet.",
        "STEGEN_UP": "Vad skulle behöva hända för att hoppa upp ett steg?",
        "STEGEN_REASON": "Vad är den främsta anledningen till att du är på steg X nu och inte Y?",
        "STEGEN_LAST_YEAR": "Vad har hänt sen förra året för att du ska vara där du är nu?",
        "FORM_HEADER": "Vad tyckte du om vårt möte?",
        "FORM_SUBHEADER": "Du och dina åsikter är viktiga!",
        "FORM_TEXT": "Därför vill jag gärna skicka några frågor om hur du tycker vårt möte varit idag. Frågorna kommer komma i Ommej-appen och du svarar när du vill. Är det OK?"
    }
}
