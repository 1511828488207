import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getRoutingState } from "~/src/utils/systemFunctions";
import logo from "../../../media/images/svg/logo_notext.svg";
import { getAllUnits } from "../../../redux/actions/units/actions";
import SkeletonNav from "../../../skeletons/skeletonNav";
import { route } from "../../../utils/constants";
import language from "../../../utils/language/index";
import NavComponent from "../navComponent/navComponent";
import "./navDesktop.css";

const NavDesktop = ({ routes }) => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const unitsLoaded = useSelector((state) => {
        return state.unit.loaded;
    });
    const unitObj = useSelector((state) => {
        return state.unit.unitObj;
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const location = useLocation();
    const routingState = getRoutingState(location);

    let isMounted = true;
    const loadResources = async () => {
        if (!unitsLoaded) {
            try {
                dispatch(getAllUnits());
                if (isMounted) {
                    setErrorMessage(null);
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };

    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    return (
        <div className="app-nav-container">
            <div className="nav-content-wrapper">
                <Link to={route.ROOT} state={routingState}>
                    <img className="nav-logo-img" src={logo} alt="Logo" />
                </Link>
                <p className="swanky">{currentUser.username}</p>
                {!unitsLoaded ? (
                    <SkeletonNav />
                ) : (
                    <p className="text-description">{unitObj[currentUser.unit]?.name || "-"}</p>
                )}
            </div>
            <NavComponent routes={routes} />
        </div>
    );
};

NavDesktop.propTypes = {
    routes: PropTypes.arrayOf(Object).isRequired,
};

export default NavDesktop;
