import { OrganizationIcon } from "~/src/utils/icons";
import "./profileIcon.css";

const ProfileIcon = () => {
    return (
        <div className="profileIcon-wrapper">
            <div className="profileIcon-icon-wrapper" style={{ background: "var(--color-beige)" }}>
                <OrganizationIcon />
            </div>
        </div>
    );
};

export default ProfileIcon;
