import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoutingState } from "~/src/utils/systemFunctions";
import Types from "~/src/utils/types";
import Can from "../../../../../rbac/can";
import { deleteUser } from "../../../../../redux/actions/users/actions";
import { route } from "../../../../../utils/constants";
import language from "../../../../../utils/language";
import { checkIfUserHasInvitation } from "../../../../../utils/userFunctions";
import SecondaryButton from "../../../../tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import "./userSettings.css";

const UserSettings = ({ user }) => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const invitations = useSelector((state) => {
        return state.invitation.invitations;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);
    const [error, setError] = useState("");
    const userHasInvitation = checkIfUserHasInvitation(user, invitations);

    const sendDelete = async () => {
        if (user) {
            try {
                await dispatch(deleteUser(user._id));
                const options = { state: routingState };
                navigate(route.ADMIN_USERS, options);
            } catch (_e) {
                setError(language[locales].ADMIN.USER.EDIT_USER.API_ERROR);
            }
        } else {
            setError(language[locales].ADMIN.USER.EDIT_USER.USER_ERROR);
        }
    };

    const handleYes = useCallback(() => {
        return (
            <FluidWrapper height="hg-small">
                <h3>{language[locales].ADMIN.USER.EDIT_USER.SETTINGS}</h3>
                <div className="userSettings-wrapper">
                    {userHasInvitation ? (
                        <div title={language[locales].ADMIN.USER.EDIT_USER.TOOLTIP}>
                            <SecondaryButton
                                icon="trashbin"
                                text={language[locales].ADMIN.USER.EDIT_USER.SETTINGS_BUTTON}
                                handleButton={sendDelete}
                                disabled
                            />
                        </div>
                    ) : (
                        <SecondaryButton
                            icon="trashbin"
                            text={language[locales].ADMIN.USER.EDIT_USER.SETTINGS_BUTTON}
                            handleButton={sendDelete}
                        />
                    )}
                </div>
                <p className="userSetting-error-wrapper">{error}</p>
            </FluidWrapper>
        );
    }, [error]);

    return (
        <Can
            role={currentUser.roleCode}
            perform="user:delete"
            yes={() => {
                return handleYes();
            }}
            no={() => {
                return null;
            }}
        />
    );
};

UserSettings.propTypes = {
    user: PropTypes.shape({
        _id: PropTypes.string,
        roleCode: PropTypes.string,
        email: PropTypes.string,
        username: PropTypes.string,
        unit: PropTypes.shape(Types.unit),
    }).isRequired,
};

export default UserSettings;
