import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import Can from "../../../../rbac/can";
import { ArrowRightIcon } from "../../../../utils/icons";
import language from "../../../../utils/language";
import GhostButton from "../../../tools/buttons/ghostButton/ghostButton";
import UnitIcon from "../../common/unitIcon/unitIcon";
import "./unitCard.css";

const UnitCard = ({ unit, role, handleClick, handleIcon }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [showButton, setShowButton] = useState(false);

    const handleYes = useCallback(() => {
        return (
            <>
                <button
                    className="unitcard-button-wrapper"
                    type="button"
                    tabIndex="0"
                    onClick={handleClick}
                    onKeyPress={handleClick}
                    aria-label="Hantera enhet"
                >
                    <UnitIcon unit={unit} />
                </button>
                <div className="unitcard-icon-wrapper">
                    {showButton && (
                        <GhostButton
                            className="unitcard-iconbutton"
                            tabIndex="0"
                            text={language[locales].ADMIN.UNIT.ADMIN_CARDS.UNIT_CARD}
                            icon="pencil"
                            handleGhostButton={handleIcon}
                        />
                    )}
                    <ArrowRightIcon className="unitcard-ArrowRightIcon" />
                </div>
            </>
        );
    }, [showButton, unit]);

    const handleNo = useCallback(() => {
        return (
            <div className="unitcard-nobutton-wrapper">
                <UnitIcon unit={unit} />
                {showButton && (
                    <GhostButton
                        className="unitcard-iconbutton"
                        tabIndex="0"
                        text={language[locales].ADMIN.UNIT.ADMIN_CARDS.UNIT_CARD}
                        icon="pencil"
                        handleGhostButton={handleIcon}
                    />
                )}
            </div>
        );
    }, [showButton, unit]);

    if (!unit) {
        return <p>Du kan inte se denna enhet</p>;
    }
    return (
        <>
            <div
                className="unitcard-wrapper"
                onMouseEnter={() => {
                    setShowButton(true);
                }}
                onMouseLeave={() => {
                    setShowButton(false);
                }}
            >
                <Can
                    role={role}
                    perform="unit:browse"
                    yes={() => {
                        return handleYes();
                    }}
                    no={() => {
                        return handleNo();
                    }}
                />
            </div>
            <span className="unitcard-span-border" />
        </>
    );
};

UnitCard.defaultProps = {
    unit: undefined,
};

UnitCard.propTypes = {
    unit: PropTypes.shape(Types.unit),
    role: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleIcon: PropTypes.func.isRequired,
};

export default UnitCard;
