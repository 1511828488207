import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "~/src/components/tools/breadCrumbs";
import type { GlobalState, Unit } from "~/src/types";
import { getAllUnits } from "../../../redux/actions/units/actions";
import { roles } from "../../../utils/constants";
import language from "../../../utils/language";
import { checkRole } from "../../../utils/userFunctions";
import FluidWrapper from "../../tools/fluidWrapper/fluidWrapper";
import UnitCard from "./cards/unitCard";
import UnitHeader from "./header/unitHeader";
import EditUnit from "./modals/editUnit/editUnit";
import UnitLevel from "./unitLevel";
import "./units.css";

const Units = () => {
    const unitsLoaded = useSelector((state: GlobalState) => {
        return state.unit.loaded;
    });

    const units = useSelector((state: GlobalState) => {
        return state.unit.units;
    });
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const unitObj = useSelector((state: GlobalState) => {
        return state.unit.unitObj;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    // selected = unit that you click when changing level
    const [selected, setSelected] = useState<Unit | null>(null);
    // currentUnit = unit that you click to handle (in modal)
    const [currentUnit, setCurrentUnit] = useState();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [crumbs, setCrumbs] = useState<(Unit | never)[]>([]);
    const [step, setStep] = useState<number>(crumbs.length);
    const [show, setShow] = useState(false);
    const [accessUnits, setAccessUnits] = useState<(Unit | never)[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (step === 0) {
            setSelected(null);
        }
    }, [step]);

    let isMounted = true;
    const loadResources = async () => {
        if (!unitsLoaded) {
            try {
                await dispatch(getAllUnits());
                if (isMounted) {
                    setErrorMessage(null);
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };

    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (unitsLoaded) {
            const adminArr = units.filter((unit) => {
                const newArr = unit.administrators.some((admin) => {
                    return admin === currentUser._id;
                });
                return newArr;
            });
            const userArr = units.filter((unit) => {
                const newArr = unit.users.some((user) => {
                    return user === currentUser._id;
                });
                return newArr;
            });
            setAccessUnits([...userArr, ...adminArr]);
        }
    }, [units]);

    const selectedCrumb = (crumb) => {
        setCrumbs([...crumbs, crumb]);
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const handleStep = (currentStep) => {
        setStep(currentStep + 1);
    };

    const handleIcon = (unit) => {
        setCurrentUnit(unit);
        setShow(!show);
    };

    const handleClick = (unit) => {
        setSelected(unit);
        setCrumbs([...crumbs, unit]);
        setStep(step + 1);
    };

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }

    if (!unitsLoaded) {
        return null;
    }
    return (
        <div className="app-component-container">
            <BreadCrumbs />
            <FluidWrapper height="hg-minHeight" customStyle={{ marginBottom: "7rem" }}>
                <UnitHeader
                    crumbs={crumbs}
                    setCrumbs={setCrumbs}
                    handleStep={handleStep}
                    selected={selected}
                    setSelected={setSelected}
                    role={checkRole(selected, currentUser, unitObj)}
                />
                {units.length === 0 && (
                    <div className="units-no-unit">{language[locales].ADMIN.UNIT.NO_UNIT}</div>
                )}
                {step === 0
                    ? units
                          .filter((filteredUnit) => {
                              return filteredUnit._id === currentUser.unit;
                          })
                          .map((unit) => {
                              return (
                                  <UnitCard
                                      key={unit._id}
                                      unit={unit}
                                      role={checkRole(unit, currentUser, unitObj)}
                                      handleClick={() => {
                                          handleClick(unit);
                                      }}
                                      handleIcon={() => {
                                          handleIcon(unit);
                                      }}
                                  />
                              );
                          })
                    : units.map((unit) => {
                          return unit._id === selected?._id ? (
                              <UnitLevel
                                  key={unit._id}
                                  unit={unit}
                                  role={checkRole(unit, currentUser, unitObj)}
                                  unitObj={unitObj}
                                  nextStep={nextStep}
                                  setSelected={setSelected}
                                  selectedCrumb={selectedCrumb}
                                  setCurrentUnit={setCurrentUnit}
                                  show={show}
                                  setShow={setShow}
                              />
                          ) : null;
                      })}
                {step === 0 &&
                    currentUser.roleCode !== roles.systemAdmin &&
                    accessUnits.length > 0 && (
                        <div className="units-access-wrapper">
                            <h4 className="units-access-header">
                                {language[locales].ADMIN.UNIT.ACCESS}
                            </h4>
                            {accessUnits.map((accessUnit) => {
                                return (
                                    <UnitCard
                                        key={accessUnit._id}
                                        unit={accessUnit}
                                        role={checkRole(accessUnit, currentUser, unitObj)}
                                        handleClick={() => {
                                            handleClick(accessUnit);
                                        }}
                                        handleIcon={() => {
                                            handleIcon(accessUnit);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
            </FluidWrapper>
            {show && currentUnit && (
                <EditUnit
                    show={show}
                    setShow={setShow}
                    unit={currentUnit}
                    role={checkRole(currentUnit, currentUser, unitObj)}
                />
            )}
        </div>
    );
};

export default Units;
