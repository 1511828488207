// @ts-check

import PropTypes from "prop-types";

export default {
    unit: {
        childUnits: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        _id: PropTypes.string,
        administrators: PropTypes.arrayOf(PropTypes.string),
        users: PropTypes.arrayOf(PropTypes.string),
        parentUnit: PropTypes.string,
    },
    invitation: {
        _id: PropTypes.string,
        name: PropTypes.string,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                form: PropTypes.shape({
                    id: PropTypes.string,
                    version: PropTypes.number,
                }),
                created: PropTypes.string,
            }),
        ),
        numberOfClientsRegistered: PropTypes.number,
        createdBy: PropTypes.shape({
            _id: PropTypes.string,
            username: PropTypes.string,
        }),
        ownedBy: PropTypes.shape({
            _id: PropTypes.string,
            username: PropTypes.string,
            unit: PropTypes.shape({
                _id: PropTypes.string,
                name: PropTypes.string,
            }),
        }),
        sharedToUsers: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.object),
        ]),
        sharedToUnits: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.objectOf(PropTypes.string),
        created: PropTypes.string,
    },
};
