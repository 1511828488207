import type { User } from "@ommej/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyInvitations from "~/src/components/home/myInvitations/myInvitations";
import { startGuide } from "~/src/guide";
import SkeletonHomeCard from "~/src/skeletons/skeletonHomeCard";
import type { GlobalState } from "~/src/types";
import { checkIfUserOwnsInvitation } from "~/src/utils/userFunctions";
import { getAllInvitations } from "../../redux/actions/invitation/actions";
import { getLoggedInUser } from "../../redux/actions/login/actions";
import language from "../../utils/language";
import Events from "./news/events";
import News from "./news/news";
import Welcome from "./welcome/welcome";
import "./home.css";
import Activities from "./news/activities";
import OpenHouse from "./news/openHouse";

const Home = () => {
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const invitations = useSelector((state: GlobalState) => {
        return state.invitation.invitations;
    });
    const invitationsLoaded = useSelector((state: GlobalState) => {
        return state.invitation.loaded;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [hasInvitations, setHasInvitations] = useState(false);
    const dispatch = useDispatch();

    let isMounted = true;
    const loadResources = async () => {
        if (!invitationsLoaded) {
            try {
                await dispatch(getAllInvitations());
                if (isMounted) {
                    setErrorMessage(null);
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };

    const onGuideDone = () => {
        // Reload so we get fresh guides prop in loggedInUser object
        dispatch(getLoggedInUser());
    };

    useEffect(() => {
        loadResources();
        const onboardingGuide: User.UserGuideId = "ONBOARDING";
        startGuide(currentUser, onboardingGuide, locales, onGuideDone);
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        setHasInvitations(checkIfUserOwnsInvitation(currentUser._id, invitations));
    }, [invitations]);

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    return (
        <div className="home-container">
            {!invitationsLoaded && (
                <SkeletonHomeCard style={{ gridColumnStart: 1, gridColumnEnd: 3 }} />
            )}
            {invitationsLoaded && hasInvitations ? <MyInvitations /> : <Welcome />}
            <Events />
            {hasInvitations && <Activities />}
            <OpenHouse />
            <News />
        </div>
    );
};

export default Home;
