import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "~/src/components/tools/breadCrumbs";
import { getAllInvitations } from "../../../redux/actions/invitation/actions";
import { getLoggedInUser } from "../../../redux/actions/login/actions";
import { getAllUnits } from "../../../redux/actions/units/actions";
import { getAllUsers } from "../../../redux/actions/users/actions";
import ProfileAccess from "./profileAccess/profileAccess";
import ProfileEmail from "./profileEmail/profileEmail";
import ProfileName from "./profileName/profileName";
import ProfilePassword from "./profilePassword/profilePassword";
import ProfileUnit from "./profileUnit/profileUnit";
import "./profile.css";

const Profile = () => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (!currentUser.username) {
            dispatch(getLoggedInUser());
            dispatch(getAllUnits());
            dispatch(getAllUsers());
            dispatch(getAllInvitations());
        }
    }, []);

    if (!currentUser.username) {
        return <p>Loading...</p>;
    }
    return (
        <div className="app-component-container">
            <BreadCrumbs />
            <div className="profile-wrapper">
                <div className="profile-column">
                    <ProfileName />
                    <ProfileEmail />
                    <ProfilePassword />
                </div>
                <div className="profile-column">
                    <ProfileUnit />
                    <ProfileAccess />
                </div>
            </div>
        </div>
    );
};

export default Profile;
