import { reqWithPath, reqWithPathAndPayload, reqWithPayload } from "../../../utils/api";
import { dispatchError } from "../actionHelpers";

import {
    ADD_PARENT_UNIT,
    ADD_UNIT,
    BUILD_UNIT_OBJECT,
    DELETE_CHILD_UNITS,
    DELETE_UNIT,
    GET_ALL_UNITS,
    LOADING_DONE,
    LOADING_UNITS,
    UNIT_ERROR,
    UPDATE_NEW_PARENT_UNIT,
    UPDATE_OLD_PARENT_UNIT,
    UPDATE_UNIT,
} from "./type";

export const getAllUnits = () => {
    return async (dispatch) => {
        dispatch({ type: LOADING_UNITS });
        const response = await reqWithPath("units", "get", "");
        const units = await response.json();
        await dispatch({
            type: GET_ALL_UNITS,
            payload: units,
        });
        await dispatch({
            type: BUILD_UNIT_OBJECT,
        });
        await dispatch({
            type: LOADING_DONE,
        });
        return units;
    };
};

export const addUnit = (newUnit) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_UNITS });
        try {
            const response = await reqWithPayload("units", "post", newUnit);
            if (response.ok) {
                const unit = await response.json();
                await dispatch({
                    type: ADD_UNIT,
                    payload: unit,
                });
                await dispatch({
                    type: ADD_PARENT_UNIT,
                    payload: unit,
                });
                await dispatch({
                    type: BUILD_UNIT_OBJECT,
                });
                await dispatch({
                    type: LOADING_DONE,
                });
            } else {
                throw response;
            }
        } catch (error) {
            dispatchError(error, dispatch, UNIT_ERROR);
        }
    };
};

export const deleteUnit = (unitId) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_UNITS });
        try {
            const response = await reqWithPath("units", "delete", unitId);
            if (response.ok) {
                const id = await response.json();
                await dispatch({
                    type: DELETE_UNIT,
                    payload: id,
                });
                await dispatch({
                    type: DELETE_CHILD_UNITS,
                    payload: id,
                });
                await dispatch({
                    type: BUILD_UNIT_OBJECT,
                });
                await dispatch({
                    type: LOADING_DONE,
                });
            } else {
                throw response;
            }
        } catch (error) {
            dispatchError(error, dispatch, UNIT_ERROR);
        }
    };
};

export const updateUnit = (unitToUpdate, oldParentUnit) => {
    return async (dispatch) => {
        const { _id } = unitToUpdate;
        dispatch({ type: LOADING_UNITS });
        try {
            const response = await reqWithPathAndPayload("units", "put", _id, unitToUpdate);
            const unit = await response.json();
            await dispatch({
                type: UPDATE_UNIT,
                payload: unit,
            });
            if (oldParentUnit) {
                await dispatch({
                    type: UPDATE_NEW_PARENT_UNIT,
                    payload: unitToUpdate,
                });
                await dispatch({
                    type: UPDATE_OLD_PARENT_UNIT,
                    payload: {
                        unit: unitToUpdate,
                        oldParent: oldParentUnit,
                    },
                });
            }
            await dispatch({
                type: BUILD_UNIT_OBJECT,
            });
            await dispatch({
                type: LOADING_DONE,
            });
        } catch (error) {
            dispatchError(error, dispatch, UNIT_ERROR);
            throw error;
        }
    };
};
