import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import language from "../../../../utils/language";
import FluidWrapper from "../../../tools/fluidWrapper/fluidWrapper";
import ProfileCard from "../components/profileCard/profileCard";
import "./profileAccess.css";

const ProfileAccess = () => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const units = useSelector((state) => {
        return state.unit.units;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [accessUnits, setAccessUnits] = useState([]);

    useEffect(() => {
        const adminArr = units.filter((un) => {
            const newArr = un.administrators.some((admin) => {
                return admin === currentUser._id;
            });
            return newArr;
        });
        const userArr = units.filter((un) => {
            const newArr = un.users.some((user) => {
                return user === currentUser._id;
            });
            return newArr;
        });
        setAccessUnits([...userArr, ...adminArr]);
    }, [units]);

    if (accessUnits.length === 0) {
        return null;
    }
    return (
        <FluidWrapper height="hg-small">
            <div className="profileAccess-noChange-header">
                <h3>{language[locales].ADMIN.PROFILE.PROFILE_ACCESS.HEADING}</h3>
            </div>
            {accessUnits.map((unit) => {
                return <ProfileCard key={unit._id} unit={unit} />;
            })}
        </FluidWrapper>
    );
};

export default ProfileAccess;
