import type { CaseListDataModels, InvitationView } from "~/src/types";
import language from "~/src/utils/language";
import { getCaseListColumns } from "./caseColumns";

type DataFiltersType = {
    [Property in keyof typeof language.sv.CASE_LIST.CASE_LIST_TAB_TITLES]: (
        item: InvitationView | never,
    ) => boolean;
};

// NOTE: These needs to be in sync with CASE_LIST_TAB_TITLES in language files
const datafilters: DataFiltersType = {
    ALL_CASES() {
        return true;
    },
    MY_CASES(item) {
        return item.ownedBy.id === String(this);
    },
    SHARED_BY_ME(item) {
        return item.ownedBy.id === String(this) && item.sharedToUsers.length > 0;
    },
    SHARED_WITH_ME(item) {
        return item.sharedToUsers.some((user) => {
            return user.id === String(this);
        });
    },
};

export const getCaseListDataModel = (
    invitationArray: InvitationView[],
    locales: string,
    loggedInUserId: string,
): CaseListDataModels | null => {
    const columns = getCaseListColumns(locales);
    const { CASE_LIST } = language[locales];
    const caseListDataModels: CaseListDataModels | NonNullable<unknown> = {};
    for (const item of Object.keys(CASE_LIST.CASE_LIST_TAB_TITLES)) {
        caseListDataModels[item] = {
            title: CASE_LIST.CASE_LIST_TAB_TITLES[item],
            // loggedInUserId becomes "this" in datafilter functions.
            // Used by those filterfunctions that need to compare user ids
            data: invitationArray.filter(datafilters[item], loggedInUserId),
            columns: columns[item],
        };
    }
    // Asserting that we really have a CaseListDataModel here.
    // Both data and columns are checked by typescript to have property
    // for every tab. They cannot be null/undefined(or anything else)
    return Object.keys(caseListDataModels).length !== 0
        ? (caseListDataModels as CaseListDataModels)
        : null;
};
