import type { Unit, User } from "@ommej/types";
import { useState } from "react";
import { useSelector } from "react-redux";
import GhostButton from "~/src/components/tools/buttons/ghostButton/ghostButton";
import type { GlobalState } from "~/src/types";
import { OnePersonIcon } from "~/src/utils/icons";
import language from "~/src/utils/language";
import "./userCard.css";

type UserWithIdAndPopulatedUnit = Omit<User.UserType, "unit"> & {
    _id: string;
    unit: Unit.UnitType;
};

type UserCardProps = {
    user: UserWithIdAndPopulatedUnit;
    text: string;
    handleGhostButton: () => void;
    noButton: boolean;
};

const UserCard = ({ user, text, handleGhostButton, noButton }: UserCardProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { COMMON } = language[locales];
    const [showButton, setShowButton] = useState(false);

    if (!user) {
        return <p>{COMMON.NO_USER}</p>;
    }
    return (
        <>
            <div
                className="userCard-wrapper"
                onMouseEnter={() => {
                    setShowButton(true);
                }}
                onMouseLeave={() => {
                    setShowButton(false);
                }}
            >
                <div className="userCard-main-wrapper">
                    <div className="userCard-person-wrapper">
                        <div className="userCard-icon-wrapper">
                            <OnePersonIcon />
                        </div>
                        <div className="userCard-text-wrapper">
                            <h4>{user.username}</h4>
                            <p className="text-description text-grey">{`${user.unit.name} ${!user.guides ? `- ${COMMON.NO_LOGIN}` : ""}`}</p>
                        </div>
                    </div>
                    {showButton && noButton && (
                        <div className="userCard-button-wrapper">
                            <GhostButton
                                text={text}
                                icon="pencil"
                                handleGhostButton={handleGhostButton}
                            />
                        </div>
                    )}
                </div>
            </div>
            <span className="userCard-span-border" />
        </>
    );
};

export default UserCard;
