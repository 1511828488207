import type { Unit } from "~/src/types";
import {
    ADD_PARENT_UNIT,
    ADD_UNIT,
    BUILD_UNIT_OBJECT,
    DELETE_CHILD_UNITS,
    DELETE_UNIT,
    GET_ALL_UNITS,
    LOADING_DONE,
    LOADING_UNITS,
    UNIT_ERROR,
    UPDATE_NEW_PARENT_UNIT,
    UPDATE_OLD_PARENT_UNIT,
    UPDATE_UNIT,
} from "../actions/units/type";

type UnitState = {
    units: Unit[];
    unitObj: { [key: Unit["_id"]]: Unit };
    loading: boolean;
    loaded: boolean;
    error: null | string;
};

const initialState: UnitState = {
    units: [],
    unitObj: {},
    loading: false,
    loaded: false,
    error: null,
};

/* Here we basically vouch for the stuff we put in unit state.
 * It should be validated when collected from the outside world,
 * e.g. backend.
 */
const unitReducer = (currentState, action): UnitState => {
    const state = currentState || initialState;
    switch (action.type) {
        case LOADING_UNITS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ALL_UNITS:
            return {
                ...state,
                units: action.payload,
                loading: true,
                error: null,
            };
        case BUILD_UNIT_OBJECT:
            return {
                ...state,
                unitObj: state.units.reduce((acc, inv) => {
                    // biome-ignore lint/performance/noAccumulatingSpread: allow fow now
                    return { ...acc, [inv._id]: inv };
                }, {}),
                loading: true,
            };
        case LOADING_DONE:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null,
            };
        case ADD_UNIT:
            return {
                ...state,
                units: [...state.units, action.payload],
                loading: true,
                error: null,
            };
        case ADD_PARENT_UNIT:
            return {
                ...state,
                units: state.units.map((unit) => {
                    return unit._id === action.payload.parentUnit
                        ? {
                              ...unit,
                              childUnits: [...unit.childUnits, action.payload._id],
                          }
                        : unit;
                }),
                loading: true,
                error: null,
            };
        case DELETE_UNIT:
            return {
                ...state,
                units: state.units.filter((unit) => {
                    return unit._id !== action.payload._id;
                }),
                loading: true,
                error: null,
            };
        case DELETE_CHILD_UNITS:
            return {
                ...state,
                units: state.units.map((unit) => {
                    return {
                        ...unit,
                        childUnits: unit.childUnits.filter((child) => {
                            return child !== action.payload._id;
                        }),
                    };
                }),
                loading: true,
                error: null,
            };
        case UPDATE_UNIT:
            return {
                ...state,
                units: state.units.map((unit) => {
                    return unit._id === action.payload._id ? action.payload : unit;
                }),
                loading: true,
                error: null,
            };
        case UPDATE_NEW_PARENT_UNIT:
            return {
                ...state,
                units: state.units.map((unit) => {
                    return unit._id === action.payload.parentUnit
                        ? {
                              ...unit,
                              childUnits: [...unit.childUnits, action.payload._id],
                          }
                        : unit;
                }),
                loading: true,
                error: null,
            };
        case UPDATE_OLD_PARENT_UNIT:
            return {
                ...state,
                units: state.units.map((unit) => {
                    return unit._id === action.payload.oldParent._id
                        ? {
                              ...unit,
                              childUnits: unit.childUnits.filter((id) => {
                                  return id !== action.payload.unit._id;
                              }),
                          }
                        : unit;
                }),
                loading: true,
                error: null,
            };
        case UNIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default unitReducer;
