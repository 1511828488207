import { CheckIcon, PauseIcon, PlayIcon, RefreshIcon, TrashBinIcon } from "~/src/utils/icons";
import "./secondaryButton.css";

const ICONS = {
    trashbin: <TrashBinIcon />,
    pause: <PauseIcon />,
    check: <CheckIcon />,
    refresh: <RefreshIcon />,
    play: <PlayIcon />,
};

export default function SecondaryButton({
    icon,
    text,
    handleButton,
    disabled = false,
}: {
    icon?: keyof typeof ICONS;
    text: string;
    handleButton?: () => void;
    disabled?: boolean;
}) {
    return (
        <button
            type="button"
            className="secondaryButton-wrapper"
            onClick={handleButton}
            disabled={disabled}
        >
            {icon && ICONS[icon]}
            <p className="secondaryButton-text">{text}</p>
        </button>
    );
}
