import { GetAvatar } from "@ommej/componente";
import type { Form, Invitation } from "@ommej/types";
import BaseIcon from "~/src/media/images/svg/base.svg";
import FamilyIcon from "~/src/media/images/svg/family.svg";
import LeisureIcon from "~/src/media/images/svg/leisure.svg";
import OmmejIcon from "~/src/media/images/svg/logo_notext.svg";
import SchoolIcon from "~/src/media/images/svg/school.svg";
import type { ClientInfo } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import type { SentFormWithMetadata } from "../modals/components/cards/caseFormCard";

// XXX: should find a better solution for this
export function getQuestionTagImage(client: ClientInfo, questionKey: Form.QuestionTags) {
    switch (questionKey) {
        case "SCHOOL":
            return <img src={SchoolIcon} alt={questionKey} />;
        case "FAMILY":
            return <img src={FamilyIcon} alt={questionKey} />;
        case "LEISURE":
            return <img src={LeisureIcon} alt={questionKey} />;
        case "BASE":
            return <img src={BaseIcon} alt={questionKey} />;
        case "OMMEJ":
            return <img src={OmmejIcon} alt={questionKey} />;
        case "MYSELF":
            return <GetAvatar avatarData={client.profile?.avatar} />;
        default:
            return null;
    }
}

export async function getSentForms(invitation: Invitation.ToClient) {
    if (!invitation.forms) {
        return [];
    }
    let availableForms: Array<Form.Metadata> = [];

    try {
        const req = await reqWithPath("data/forms", "get", "", {});
        const formsMetadata: Form.Metadata[] = await req.json();
        availableForms = formsMetadata;
    } catch (error) {
        console.error(error);
    }

    if (!availableForms || availableForms.length === 0) {
        return [];
    }

    const forms: Array<SentFormWithMetadata> = [];
    for (const sentForm of invitation.forms) {
        const foundInAvailable = availableForms.find((availableForm) => {
            return (
                availableForm.id === sentForm.form.id &&
                availableForm.version === sentForm.form.version
            );
        });

        if (foundInAvailable) {
            forms.push(
                Object.assign(sentForm, {
                    metadata: foundInAvailable,
                }),
            );
        }
    }

    return forms;
}
