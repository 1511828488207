import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import { getAllUsers } from "../../../../../redux/actions/users/actions";
import language from "../../../../../utils/language";
import RoundButton from "../../../../tools/buttons/roundButton/roundButton";
import type { SharedUser } from "../caseShare";
import "./sharedUserCard.css";

type SharedUserCardProps = {
    user: SharedUser;
    deleteUser: (user: SharedUser) => void;
};

const SharedUserCard = ({ user, deleteUser }: SharedUserCardProps) => {
    const usersLoaded = useSelector((state: GlobalState) => {
        return state.user.loaded;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const [showButton, setShowButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();

    let isMounted = true;
    const loadResources = async () => {
        if (!usersLoaded) {
            try {
                await dispatch(getAllUsers());
                if (isMounted) {
                    setErrorMessage("");
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };
    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    if (!usersLoaded) {
        return null;
    }
    return (
        <>
            <div
                className="sharedUserCard-wrapper"
                onMouseEnter={() => {
                    setShowButton(true);
                }}
                onMouseLeave={() => {
                    setShowButton(false);
                }}
            >
                <button type="button" className="sharedUserCard-button">
                    {user.username}
                </button>
                {showButton && (
                    <RoundButton
                        icon="trashbin"
                        handleButton={() => {
                            return deleteUser(user);
                        }}
                    />
                )}
            </div>
            <span className="sharedUserCard-border" />
        </>
    );
};

export default SharedUserCard;
