import rules from "./rules";

const check = (role, perform, data) => {
    const permissions = rules[role];
    if (!permissions) {
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions?.includes(perform)) {
        return true;
    }

    const dynamicPermissons = permissions.dynamic;

    if (dynamicPermissons) {
        const permissionCondition = dynamicPermissons[perform];
        if (!permissionCondition) {
            return false;
        }
        return permissionCondition(data);
    }
    return false;
};

const Can = ({ role, perform, data, yes, no }) => {
    return check(role, perform, data) ? yes() : no();
};

export default Can;
