import type { Form, Uuid } from "@ommej/types";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import language from "~/src/utils/language";
import "./articles.css";

type Article = {
    id: keyof Form.FormQuestions;
    header: NonNullable<Form.QuestionArticleText["header"]>;
    video: Form.QuestionArticleText["video"];
    text: Form.QuestionArticleText["text"];
};

const Articles = () => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { ARTICLES, APP } = language[locales];
    const detailsElem = useRef<HTMLDetailsElement | null>(null);
    const [articles, setArticles] = useState<Article[]>();
    const [errorMessage, setErrorMessage] = useState("");
    const shownHeaders = new Set<string>();

    const fetchArticles = async () => {
        try {
            const res = await reqWithPath("data/forms/general/2?tag=ARTICLE_INFO", "get", "");
            const data: Form.FormData = await res.json();

            const articleArray: Article[] = [];
            // Type assertion: We trust backend to only serve us articles, i.e. data.questions = {Uuid:QuestionArticleText}
            for (const [key, article] of Object.entries(data.questions) as [
                Uuid,
                Form.QuestionArticleText,
            ][]) {
                if (article.header?.sv && key && article.text?.sv) {
                    const articleObj: Article = {
                        id: key,
                        header: article.header,
                        video: article.video,
                        text: article.text,
                    };
                    articleArray.push(articleObj);
                } else {
                    console.error(`Bad article: ${key}`);
                }
            }
            setArticles(articleArray);
        } catch (error) {
            console.error(error);
            setArticles([]);
            setErrorMessage(ARTICLES.ERROR);
        }
    };

    useEffect(() => {
        fetchArticles();
    }, []);

    useEffect(() => {
        // closing open details, when open a new
        const details = document.querySelectorAll(".article-details");
        // biome-ignore lint/complexity/noForEach: allow for NodeListOf
        details.forEach((targetDetail) => {
            targetDetail.addEventListener("click", () => {
                // biome-ignore lint/complexity/noForEach: allow for NodeListOf
                details.forEach((detail) => {
                    if (detail !== targetDetail) {
                        detail.removeAttribute("open");
                    }
                });
            });
        });
    }, [articles]);

    if (!articles) {
        return null;
    }
    return (
        <div className="app-component-container">
            <div className="articles articles-header">
                <h2>{ARTICLES.ARTICLES_HEADER}</h2>
                <p>{ARTICLES.ARTICLES_HEADER_TEXT}</p>
            </div>
            <div className="articles articles-wrapper">
                <h2>{APP.ROUTE_ARTICLES}</h2>
                {!errorMessage ? (
                    <>
                        {articles.map((article) => {
                            if (shownHeaders.has(article.header.sv)) {
                                return null;
                            }
                            shownHeaders.add(article.header.sv);
                            return (
                                <details
                                    key={article.id}
                                    className="article-details"
                                    ref={detailsElem}
                                >
                                    <summary>{article.header.sv}</summary>
                                    <section className="articles-content">
                                        {article.video && (
                                            <video
                                                controls
                                                poster={`https://files.ommej.se/video/${article.video}/${article.video}_hd.mp4.jpg`}
                                            >
                                                <source
                                                    src={`https://files.ommej.se/video/${article.video}/${article.video}_hd.mp4`}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        )}
                                        <h3>{article.header.sv}</h3>
                                        {/* biome-ignore lint/security/noDangerouslySetInnerHtml: we control the string */}
                                        <p dangerouslySetInnerHTML={{ __html: article.text.sv }} />
                                    </section>
                                </details>
                            );
                        })}
                    </>
                ) : (
                    <p>{errorMessage}</p>
                )}
            </div>
        </div>
    );
};

export default Articles;
