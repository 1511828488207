import type { Invitation } from "@ommej/types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import type { GlobalState, UserWithId } from "~/src/types";
import { getRoutingState } from "~/src/utils/systemFunctions";
import { getAllInvitations, updateInvitation } from "../../../../redux/actions/invitation/actions";
import { roles, route } from "../../../../utils/constants";
import language from "../../../../utils/language";
import { findUserObject, userHasAdminAccessToCase } from "../../../../utils/userFunctions";
import Accordion from "../../../tools/accordion/accordion";
import NormalButton from "../../../tools/buttons/normalButton/normalButton";
import SecondaryButton from "../../../tools/buttons/secondaryButton/secondaryButton";
import Dropdown, { type DropdownItemsArray } from "../../../tools/dropdown/dropdown";
import { CASES_ROUTES } from "../../caseList/caseList";
import "./caseMove.css";

const CaseMove = ({ invitation }: { invitation: Invitation.ToClient }) => {
    const users = useSelector((state: GlobalState) => {
        return state.user.users;
    });
    const units = useSelector((state: GlobalState) => {
        return state.unit.units;
    });
    const loggedInUser = useSelector((state: GlobalState) => {
        return state.login.loggedInUser;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const [dropdownArray, setDropdownArray] = useState<DropdownItemsArray>([]);
    const [accordion, setAccordion] = useState(false);
    const [userToMove, setUserToMove] = useState<UserWithId | undefined>();
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);

    const hasAdminAccessToCase = useMemo(() => {
        return userHasAdminAccessToCase(loggedInUser, invitation, units);
    }, [loggedInUser, invitation, units]);

    useEffect(() => {
        setDropdownArray(
            users
                .filter((user) => {
                    return (
                        user.roleCode !== roles.systemAdmin &&
                        (user._id !== loggedInUser._id || hasAdminAccessToCase) &&
                        user._id !== invitation.ownedBy._id
                    );
                })
                .sort((a, b) => {
                    if (a.username.toLowerCase() < b.username.toLowerCase()) {
                        return -1;
                    }
                    if (a.username.toLowerCase() > b.username.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
                .map((user) => {
                    return { value: user._id, text: user.username };
                }),
        );
    }, []);

    const handleChange = (userId) => {
        const currentUser = findUserObject(userId, users);
        setUserToMove(currentUser);
        setAccordion(true);
    };

    const handleCancel = () => {
        setAccordion(false);
        setUserToMove(undefined);
    };

    const handleMoveCase = async () => {
        if (!userToMove) {
            return;
        }
        const sendObj = { ...invitation, ownedBy: userToMove._id };
        await dispatch(updateInvitation(sendObj));
        dispatch(getAllInvitations());
        const options = { state: routingState };
        navigate(`${route.CASES}/${CASES_ROUTES.ALL_CASES}`, options);
    };
    return (
        <>
            <h3 className="caseMove-header">{`${language[locales].CASE.EDIT_INVITATION.MOVE.HEADING}`}</h3>
            <div className="caseShare-dropdown-wrapper">
                <Dropdown
                    items={dropdownArray}
                    handleChange={(user) => {
                        handleChange(user);
                    }}
                />
            </div>
            <Accordion accordionActive={accordion}>
                <div className="caseMove-accordion-wrapper">
                    <p>{`${language[locales].CASE.EDIT_INVITATION.MOVE.CONFIRM_MOVE} ${
                        userToMove?.username
                    }?`}</p>
                    <div className="caseMove-accordion-buttons">
                        <SecondaryButton
                            handleButton={handleCancel}
                            text={`${language[locales].CASE.EDIT_INVITATION.MOVE.CANCEL_BUTTON}`}
                        />
                        <NormalButton
                            handleButton={handleMoveCase}
                            text={`${language[locales].CASE.EDIT_INVITATION.MOVE.MOVE_BUTTON}`}
                            customStyle={{ marginLeft: "0.5rem" }}
                        />
                    </div>
                </div>
            </Accordion>
        </>
    );
};

export default CaseMove;
