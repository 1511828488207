import { reqWithPath, reqWithPathAndPayload, reqWithPayload } from "../../../utils/api";
import { dispatchError } from "../actionHelpers";
import {
    ADD_USER,
    BUILD_USER_OBJECT,
    DELETE_USER,
    GET_ALL_USERS,
    LOADING_USERS,
    UPDATE_USER,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_DONE,
    USER_ERROR,
} from "./type";

export const getAllUsers = () => {
    return async (dispatch) => {
        dispatch({ type: LOADING_USERS });
        try {
            const response = await reqWithPath("users", "get", "");
            const users = await response.json();
            await dispatch({
                type: GET_ALL_USERS,
                payload: users,
            });
            await dispatch({
                type: BUILD_USER_OBJECT,
            });
        } catch (error) {
            dispatchError(error, dispatch, USER_ERROR);
            throw error;
        }
    };
};

export const addUser = (newUser) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_USERS });
        try {
            const response = await reqWithPayload("users", "post", newUser);
            if (response.ok) {
                const user = await response.json();
                const userRequest = await reqWithPath("users", "get", user._id);
                if (userRequest.ok) {
                    const userResponse = await userRequest.json();
                    await dispatch({
                        type: ADD_USER,
                        payload: userResponse,
                    });
                    await dispatch({
                        type: BUILD_USER_OBJECT,
                    });
                } else {
                    throw response;
                }
            } else {
                throw response;
            }
        } catch (error) {
            dispatchError(error, dispatch, USER_ERROR);
            throw error;
        }
    };
};

export const deleteUser = (userId) => {
    return async (dispatch) => {
        dispatch({ type: LOADING_USERS });
        try {
            const response = await reqWithPath("users", "delete", userId);
            if (response.ok) {
                const id = await response.json();
                await dispatch({
                    type: DELETE_USER,
                    payload: id,
                });
                await dispatch({
                    type: BUILD_USER_OBJECT,
                });
            } else {
                throw response;
            }
        } catch (error) {
            await dispatchError(error, dispatch, USER_ERROR);
            throw error;
        }
    };
};

export const updateUser = (userToUpdate) => {
    return async (dispatch) => {
        const { _id } = userToUpdate;
        dispatch({ type: LOADING_USERS });
        try {
            const response = await reqWithPathAndPayload("users", "PATCH", _id, userToUpdate);
            if (response.ok) {
                const user = await response.json();
                const userRequest = await reqWithPath("users", "get", user._id);
                if (userRequest.ok) {
                    const userResponse = await userRequest.json();
                    await dispatch({
                        type: UPDATE_USER,
                        payload: userResponse,
                    });
                    await dispatch({
                        type: BUILD_USER_OBJECT,
                    });
                } else {
                    throw userRequest;
                }
            } else {
                throw response;
            }
        } catch (error) {
            dispatchError(error, dispatch, USER_ERROR);
            throw error;
        }
    };
};
export const updateUserPassword = (userId, password) => {
    return async (dispatch) => {
        await dispatch({ type: UPDATE_USER_PASSWORD });
        await reqWithPayload(`users/${userId}/password`, "put", { password: `${password}` });
        await dispatch({ type: UPDATE_USER_PASSWORD_DONE });
    };
};
