import type { Form, Invitation } from "@ommej/types";
import { reqWithPath } from "./api";

export function getInvitationQuantity(invitation): number {
    let quantity = 0;
    if (!invitation?.clients) {
        return quantity;
    }
    if (invitation.clients.accepted) {
        quantity += invitation.clients.accepted.length;
    }
    if (invitation.clients.denied) {
        quantity += invitation.clients.denied.length;
    }
    if (invitation.clients.pending) {
        quantity += invitation.clients.pending.length;
    }
    return quantity;
}

// returns true if adding a new form request for the provided form would
// replace an already exising request for the same form where any client has
// not fully answered all the questions.
export async function willReplaceForm(form: Form.Ref, invitation: Invitation.ToClient) {
    if (!invitation.forms || invitation.forms.length === 0 || !invitation.clients?.accepted) {
        return false;
    }

    let latestRequestForForm: Invitation.FormRequest | undefined;
    for (const formRequest of invitation.forms) {
        // we only care about request for the same form that we try to add
        if (formRequest.form.id !== form.id || formRequest.form.version !== form.version) {
            continue;
        }

        if (!latestRequestForForm || formRequest.created > latestRequestForForm.created) {
            latestRequestForForm = formRequest;
        }
    }

    if (!latestRequestForForm) {
        return false;
    }

    try {
        const clientRequestsPromises = invitation.clients.accepted.map((client) => {
            return reqWithPath(`invitations/${invitation.id}/clients/${client.client}`, "get", "");
        });
        const clientRequests = await Promise.all(clientRequestsPromises);
        const clientDataPromises = clientRequests.map((client) => {
            return client.json();
        });
        const clients = await Promise.all(clientDataPromises);

        for (const client of clients) {
            if (!client.formsStat[latestRequestForForm.id]?.done) {
                return true;
            }
        }
    } catch (error) {
        console.error(error);
    }

    return false;
}

export function getInvitationStatus(
    status: string,
    clients: Invitation.Clients | undefined,
    quantity: number,
    language,
): { status: "ACTIVE" | "PAUSED" | "REMOVED" | "DENIED" | "EMPTY" | "PENDING"; string: string } {
    const { CASE_LIST, COMMON } = language;
    if (status === "inactive" || status === "paused") {
        return { status: "PAUSED", string: CASE_LIST.PAUSED.toLowerCase() };
    }
    if (clients?.removed && clients.removed.length > 0) {
        return { status: "REMOVED", string: CASE_LIST.REMOVED.toLowerCase() };
    }
    if (quantity === 0 || !clients) {
        return { status: "EMPTY", string: CASE_LIST.EMPTY.toLowerCase() };
    }
    if (clients.pending && clients.pending.length >= 1) {
        return { status: "PENDING", string: CASE_LIST.PENDING.toLowerCase() };
    }
    if (clients.denied && clients.denied.length >= 1) {
        return { status: "DENIED", string: CASE_LIST.DENIED.toLowerCase() };
    }
    if (quantity >= 1 || (clients?.accepted && clients.accepted.length >= 1)) {
        return { status: "ACTIVE", string: COMMON.ACTIVE.toLowerCase() };
    }
    return { status: "EMPTY", string: CASE_LIST.EMPTY.toLowerCase() };
}
