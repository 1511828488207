import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CASES_ROUTES } from "~/src/components/case/caseList/caseList";
import BigButton from "~/src/components/tools/buttons/bigButton/bigButton";
import SecondaryButton from "~/src/components/tools/buttons/secondaryButton/secondaryButton";
import Modal from "~/src/components/tools/modal/modal";
import CreatedImg from "~/src/media/images/svg/case-created.svg";
import SuccessImg from "~/src/media/images/svg/invite-sent.svg";
import type { GlobalState } from "~/src/types";
import { INVITE_CRUMBS, route } from "~/src/utils/constants";
import language from "~/src/utils/language";
import "./caseCreated.css";

type CaseCreatedProps = {
    setCrumbs: (crumb: string[]) => void;
    handleCrumbs: (crumb: string) => void;
    isSuccess: boolean;
};

const CaseCreated = ({ setCrumbs, handleCrumbs, isSuccess }: CaseCreatedProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { CASE_CREATED } = language[locales].CASE.ADD_INVITATION;
    const { COMMON } = language[locales];
    const navigate = useNavigate();

    useEffect(() => {
        setCrumbs([]);
    }, []);

    const handleInviteChildren = () => {
        handleCrumbs(INVITE_CRUMBS.INVITE);
    };

    return (
        <>
            <Modal.ModalBody>
                <div className="caseCreated-wrapper">
                    {isSuccess ? (
                        <>
                            <img src={SuccessImg} alt="" />
                            <h2>{CASE_CREATED.HEADING_SUCCESS}</h2>
                            <p className="text-grey">{CASE_CREATED.SUB_HEADING_SUCCESS}</p>
                        </>
                    ) : (
                        <>
                            <img src={CreatedImg} alt="" />
                            <h2>{CASE_CREATED.HEADING}</h2>
                            <p className="text-grey">{CASE_CREATED.SUB_HEADING}</p>
                        </>
                    )}
                </div>
            </Modal.ModalBody>
            <Modal.ModalFooter>
                <div className="caseCreated-buttons">
                    {!isSuccess && (
                        <SecondaryButton
                            text={COMMON.CANCEL}
                            handleButton={() => {
                                navigate(`${route.CASES}/${CASES_ROUTES.ALL_CASES}`);
                            }}
                        />
                    )}
                    <BigButton
                        text={isSuccess ? COMMON.DONE : CASE_CREATED.INVITE_CHILDREN}
                        handleButton={() => {
                            return isSuccess
                                ? navigate(`${route.CASES}/${CASES_ROUTES.ALL_CASES}`)
                                : handleInviteChildren();
                        }}
                    />
                </div>
            </Modal.ModalFooter>
        </>
    );
};

export default CaseCreated;
