import { AnswersViewer } from "@ommej/componente";
import type { Form, Invitation } from "@ommej/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import close from "~/src/media/images/svg/close.svg";
import type { LanguageState, StatsAnswerCount } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import language from "~/src/utils/language";
import type { SentFormWithMetadata } from "./cards/caseFormCard";
import "./showFollowUp.css";

type ShowFollowUpProps = {
    show: boolean;
    invitation: Invitation.ToClient;
    setShow: (show: boolean) => void;
    formMetadata: SentFormWithMetadata;
};

const ShowFollowUp = ({ show, invitation, setShow, formMetadata }: ShowFollowUpProps) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const { FORMS } = language[locales];
    const [form, setForm] = useState<Form.FormData>();
    const [statsState, setStatsState] = useState<StatsAnswerCount>();

    useEffect(() => {
        const getForm = async () => {
            try {
                const req = await reqWithPath(
                    `data/forms/${formMetadata.metadata.id}/${formMetadata.metadata.version}`,
                    "get",
                    "",
                );
                const formData = await req.json();
                setForm(formData);
            } catch (error) {
                console.error(error);
                // TODO handle error
            }
        };
        getForm();
    }, []);

    useEffect(() => {
        const getStats = async () => {
            try {
                const req = await reqWithPath(
                    `statistics/${formMetadata.metadata.id}/${formMetadata.metadata.version}/${invitation.id}/${formMetadata.id}`,
                    "get",
                    "",
                );
                const stats = await req.json();
                setStatsState(stats);
            } catch (error) {
                console.error(error);
                // TODO handle error
            }
        };
        getStats();
    }, [form]);

    if (!form || !statsState || !show) {
        return null;
    }

    return (
        <div className="showFollowUp-overlay">
            <section className="showFollowUp-wrapper">
                <button
                    className="modal-close-button"
                    type="button"
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    <img src={close} alt="close modal" />
                </button>
                <div className="showFollowUp-header">
                    <div>
                        <h3 style={{ display: "inline" }}>
                            {FORMS.HEADING} {form.metadata.name}
                        </h3>
                    </div>
                </div>
                <div className="showFollowUp-content">
                    <AnswersViewer
                        form={form}
                        answersData={{ format: "stats", data: statsState }}
                    />
                </div>
            </section>
        </div>
    );
};

export default ShowFollowUp;
