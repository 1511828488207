import { BarElement, Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";

Chart.register(BarElement);
Chart.defaults.font.family = "'Quicksand', sans-serif";

type StegenChartProps = {
    chartData: number[];
};

const Stegen = ({ chartData }: StegenChartProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { TAB_REPORT } = language[locales].CASE.REPORT;
    const stegenChart = useRef<HTMLCanvasElement | null>(null);
    let chart: Chart;

    const config = {
        labels: [TAB_REPORT.STEGEN_LABEL1, TAB_REPORT.STEGEN_LABEL2, TAB_REPORT.STEGEN_LABEL3],
        tooltipLabel: TAB_REPORT.STEGEN_CHILD_VALUE,
        bgColors: ["#B2B7EC", "rgba(73, 86, 202, 1)", "#B2B7EC"],
    };

    const buildChart = () => {
        if (!stegenChart.current) {
            return;
        }
        chart = new Chart(stegenChart.current, {
            type: "bar",
            data: {
                labels: config.labels,
                datasets: [
                    {
                        label: config.tooltipLabel,
                        data: chartData,
                        backgroundColor: config.bgColors,
                        borderRadius: 20,
                    },
                ],
            },
            plugins: [ChartDataLabels],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        suggestedMax: 10,
                    },
                },
                layout: {
                    padding: {
                        top: 30,
                    },
                },
                plugins: {
                    tooltip: {
                        displayColors: false,
                        padding: 10,
                    },
                    datalabels: {
                        display: true,
                        color: "#000000",
                        align: "top",
                        anchor: "end",
                        font: {
                            weight: "bold",
                            size: 20,
                        },
                    },
                    legend: {
                        display: false,
                    },
                },
            },
        });
    };

    useEffect(() => {
        buildChart();
        return () => {
            chart.destroy();
        };
    }, [chartData]);

    return (
        <section
            style={{
                minHeight: "20rem",
                margin: "2rem 0",
            }}
        >
            <canvas ref={stegenChart} className="stegen-canvas" />
        </section>
    );
};

export default Stegen;
