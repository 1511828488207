import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import language from "../../../../../../utils/language";
import BigButton from "../../../../../tools/buttons/bigButton/bigButton";
import FluidWrapper from "../../../../../tools/fluidWrapper/fluidWrapper";
import RadioButton from "../../../../../tools/form/radio/radioButton";
import SearchInput from "../../../../../tools/form/search/searchInput";
import Modal from "../../../../../tools/modal/modal";
import SearchUserCard from "../../../../common/searchUserCard/searchUserCard";
import "./tabGiveAccess.css";

const TabGiveAccess = ({ unitObj, setUnitObj, userList, setTab }) => {
    const users = useSelector((state) => {
        return state.user.users;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [isActive, setIsActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [userToAdd, setUserToAdd] = useState({
        role: "administrator",
        _id: "",
    });

    const sortUserList = () => {
        const userWhoHasAccess = [...unitObj.administrators, ...unitObj.users];
        const filterSystemAdmin = users.filter((user) => {
            return user.roleCode !== "SYSTEM_ADMIN";
        });
        const filterUsersWithAccess = filterSystemAdmin.filter((user) => {
            return userWhoHasAccess.every((usr) => {
                return usr !== user._id;
            });
        });
        const filterThisUnitsUsers = filterUsersWithAccess.filter((filteredUser) => {
            return userList.every((unitUser) => {
                return unitUser._id !== filteredUser._id;
            });
        });
        return filterThisUnitsUsers;
    };

    const [filteredUsers, setFilteredUsers] = useState(sortUserList());

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        const search = e.target.value.toLowerCase();
        setFilteredUsers(
            sortUserList().filter((user) => {
                return user.username.toLowerCase().includes(search);
            }),
        );
    };

    const handleRadio = (e) => {
        setUserToAdd({ ...userToAdd, role: e.target.value });
    };

    const handleRoundButton = () => {
        setIsActive(false);
        setFilteredUsers(sortUserList());
        setSearchValue("");
        setErrorMessage("");
    };

    const handleUser = (user) => {
        setIsActive(true);
        setFilteredUsers([user]);
        setSearchValue("");
        setUserToAdd({
            role: userToAdd.role,
            _id: user._id,
        });
    };

    const addToAccessArray = () => {
        if (userToAdd.role === "administrator") {
            setUnitObj({ ...unitObj, administrators: [...unitObj.administrators, userToAdd._id] });
        } else {
            setUnitObj({ ...unitObj, users: [...unitObj.users, userToAdd._id] });
        }
        setTab();
    };

    if (!users) {
        return null;
    }

    return (
        <>
            <Modal.ModalBody>
                <div className="tabGiveAccess-body-wrapper">
                    <FluidWrapper customStyle={{ height: "22rem", width: "31rem" }}>
                        <h3>{language[locales].ADMIN.UNIT.EDIT_UNIT.TAB_ACCESS_HEADING}</h3>
                        <div className="tabGiveAccess-search-wrapper">
                            <SearchInput
                                name="search"
                                placeholder={
                                    language[locales].ADMIN.UNIT.EDIT_UNIT.TAB_ACCESS_SEARCH
                                }
                                value={searchValue}
                                handleChange={(e) => {
                                    handleSearch(e);
                                }}
                            />
                        </div>
                        <div className="tabGiveAccess-noscroll-wrapper">
                            {filteredUsers.length > 0 ? (
                                filteredUsers.map((user) => {
                                    return (
                                        <SearchUserCard
                                            key={user._id}
                                            user={user}
                                            handleUser={() => {
                                                handleUser(user);
                                            }}
                                            isActive={isActive}
                                            handleRoundButton={handleRoundButton}
                                        />
                                    );
                                })
                            ) : (
                                <div className="tabGiveAccess-no-users">
                                    Det finns inga användare som du kan ge access
                                </div>
                            )}
                        </div>
                    </FluidWrapper>
                    <FluidWrapper height="hg-small" customStyle={{ width: "31rem" }}>
                        <h3>{language[locales].ADMIN.UNIT.EDIT_UNIT.TAB_ACCESS_ACCESS}</h3>
                        <div className="tabAddUser-input-wrapper">
                            <RadioButton
                                id="1"
                                label={language[locales].ADMIN.UNIT.EDIT_UNIT.TAB_ACCESS_RADIO1}
                                value="user"
                                checked={userToAdd.role === "user"}
                                handleRadio={(e) => {
                                    handleRadio(e);
                                }}
                            >
                                {language[locales].ADMIN.UNIT.EDIT_UNIT.TAB_ACCESS_RADIO1}
                            </RadioButton>
                            <RadioButton
                                id="2"
                                label={language[locales].ADMIN.UNIT.EDIT_UNIT.TAB_ACCESS_RADIO2}
                                value="administrator"
                                checked={userToAdd.role === "administrator"}
                                handleRadio={(e) => {
                                    handleRadio(e);
                                }}
                            >
                                {language[locales].ADMIN.UNIT.EDIT_UNIT.TAB_ACCESS_RADIO2}
                            </RadioButton>
                        </div>
                    </FluidWrapper>
                    {errorMessage}
                </div>
            </Modal.ModalBody>
            <Modal.ModalFooter>
                <BigButton
                    text={language[locales].ADMIN.UNIT.EDIT_UNIT.TAB_ACCESS_BUTTON}
                    color="green"
                    handleButton={() => {
                        addToAccessArray();
                    }}
                    disabled={!isActive}
                />
            </Modal.ModalFooter>
        </>
    );
};

TabGiveAccess.propTypes = {
    unitObj: PropTypes.shape(Types.unit).isRequired,
    setUnitObj: PropTypes.func.isRequired,
    userList: PropTypes.arrayOf(PropTypes.object).isRequired,
    setTab: PropTypes.func.isRequired,
};

export default TabGiveAccess;
