import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "core-js/stable";
import { useSelector } from "react-redux";
import type { GlobalState, Route as RouteType } from "~/src/types";
import Admin from "./components/admin/admin";
import Profile from "./components/admin/profile/profile";
import Units from "./components/admin/units/units";
import Users from "./components/admin/users/users";
import Articles from "./components/articles/articles";
import Case from "./components/case/case";
import CaseDetails from "./components/case/caseDetails/caseDetails";
import { CASES_ROUTES } from "./components/case/caseList/caseList";
import Home from "./components/home/home";
import Login from "./components/login/login";
import ResetPassword from "./components/login/resetPassword";
import NavDesktop from "./components/nav/navDesktop/navDesktop";
import NavMobile from "./components/nav/navMobile/navMobile";
import NotFound from "./components/notFound/notFound";
import { Statistics } from "./components/statistics/statistics";
import StatisticsForms from "./components/statistics/statisticsForms";
import StatisticsQuestions from "./components/statistics/statisticsQuestions";
import { isDemo, route } from "./utils/constants";
import { isActiveFeature } from "./utils/feature-flags";
import language from "./utils/language/index";
import "./App.css";

const ADMIN_PATH = "/";

const App = () => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const loggedInUser = useSelector((state: GlobalState) => {
        return state.login.loggedInUser;
    });
    const { APP, COMMON } = language[locales];

    const bodyElem = document.querySelector("body");
    if (isDemo) {
        bodyElem?.classList.add("app-demo-background");
    }

    const routes: RouteType[] = [
        {
            text: APP.ROUTE1,
            link: route.HOME,
            icon: "arrowUp",
            guideClass: "guide-onboarding-home",
        },
        {
            text: COMMON.CASES,
            link: `${route.CASES}/${CASES_ROUTES.ALL_CASES}`,
            icon: "account",
            guideClass: "guide-onboarding-cases",
        },
        { text: APP.ROUTE3, link: route.ADMIN, icon: "gear", guideClass: "guide-onboarding-admin" },
        { text: APP.ROUTE_ARTICLES, link: route.ARTICLES, icon: "heart" },
        {
            text: APP.ROUTE_STATISTICS,
            link: route.STATISTICS,
            icon: "statistics",
            exact: false,
            guideClass: "guide-onboarding-statistics",
        },
    ];

    if (!loggedInUser) {
        return (
            <Router basename={ADMIN_PATH}>
                <Routes>
                    <Route path={route.RESET_PASSWORD} element={<ResetPassword />} />
                    <Route path={route.LOGIN_PASSWORD} element={<Login showPassword />} />
                    <Route path={`${route.ROOT}*`} element={<Login />} />
                </Routes>
            </Router>
        );
    }
    return (
        <Router basename={ADMIN_PATH}>
            <div className="app-main-container">
                <NavDesktop routes={routes} />
                <NavMobile routes={routes} />
                <Routes>
                    <Route path={route.ROOT} element={<Home />} />
                    <Route path={route.HOME} element={<Home />} />
                    <Route
                        path={`${route.CASE_REPORT}/:invitationId/:clientId`}
                        element={<CaseDetails />}
                    />
                    <Route path={`${route.CASE}/:invitationId/:route`} element={<CaseDetails />} />
                    <Route path={`${route.CASE}/:invitationId`} element={<CaseDetails />} />
                    <Route path={`${route.CASES}/:route`} element={<Case />} />
                    <Route path={route.CASES_ADD} element={<Case />} />
                    <Route path={route.CASES} element={<Case />} />
                    <Route path={`${route.ADMIN_USERS}/:id`} element={<Users />} />
                    <Route path={route.ADMIN_USERS_ADD} element={<Users />} />
                    <Route path={route.ADMIN_USERS} element={<Users />} />
                    <Route path={route.ADMIN_UNITS} element={<Units />} />
                    <Route path={route.ADMIN_PROFILE} element={<Profile />} />
                    <Route path={route.ADMIN} element={<Admin />} />
                    <Route path={route.ARTICLES} element={<Articles />} />
                    {isActiveFeature("statisticsGroupInvitation") && (
                        <Route
                            path={`${route.STATISTICS}/case/:invitationId/:questionsParam`}
                            element={<StatisticsQuestions />}
                        />
                    )}
                    {isActiveFeature("statisticsGroupInvitation") && (
                        <Route
                            path={`${route.STATISTICS}/case/:invitationId`}
                            element={<StatisticsQuestions />}
                        />
                    )}
                    <Route path={route.STATISTICS} element={<Statistics />} />
                    <Route path={route.STATISTICS_FORMS} element={<StatisticsForms />} />
                    <Route
                        path={`${route.STATISTICS_QUESTIONS}/:questionsParam`}
                        element={<StatisticsQuestions />}
                    />
                    <Route path={route.STATISTICS_QUESTIONS} element={<StatisticsQuestions />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
