import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoutingState } from "~/src/utils/systemFunctions";
import { updateUser } from "../../../../../redux/actions/users/actions";
import { route } from "../../../../../utils/constants";
import language from "../../../../../utils/language";
import BigButton from "../../../../tools/buttons/bigButton/bigButton";
import GhostButton from "../../../../tools/buttons/ghostButton/ghostButton";
import ErrorComponent from "../../../../tools/errorCompnent/errorComponent";
import Modal from "../../../../tools/modal/modal";
import UserCompetence from "../components/userCompetence";
import UserEmail from "../components/userEmail";
import UserName from "../components/userName";
import UserSettings from "../components/userSettings";
import UserUnit from "../components/userUnit";
import "./user.css";

const User = ({ id }) => {
    const users = useSelector((state) => {
        return state.user.users;
    });
    const apiError = useSelector((state) => {
        return state.user.error;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);
    const [currentUser, setCurrentUser] = useState(
        users.find((user) => {
            return user._id === id;
        }),
    );
    const [username, setUsername] = useState();
    const [userEmail, setUserEmail] = useState();
    const [error, setError] = useState("");
    const [noValidUser, setNoValidUser] = useState(false);
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (!currentUser) {
            setNoValidUser(true);
        } else {
            setUsername(currentUser.username);
            setUserEmail(currentUser.email);
        }
        return () => {
            setNoValidUser(false);
        };
    }, []);

    useEffect(() => {
        if (apiError) {
            setError(apiError);
        }
        return () => {
            setError("");
        };
    }, [apiError]);

    const sendUser = () => {
        // get two api request due to backend dont return full unitobj //
        const sendUserObject = {
            roleCode: currentUser.roleCode,
            _id: currentUser._id,
            email: currentUser.email,
            username: currentUser.username,
            unit: currentUser.unit._id,
        };
        if (sendUserObject) {
            setError("");
            dispatch(updateUser(sendUserObject)).then(() => {
                if (apiError) {
                    setError(apiError);
                } else {
                    const options = { state: routingState };
                    navigate(route.ADMIN_USERS, options);
                }
            });
        } else {
            setError("Något blev fel, försök igen");
        }
    };

    if (!currentUser) {
        return null;
    }
    if (noValidUser) {
        return (
            <Modal show={show} setShow={setShow} type="desktop" shouldRoute={false}>
                <Modal.ModalHeader>&nbsp;</Modal.ModalHeader>
                <Modal.ModalBody>
                    <div className="user-NoValid-wrapper">
                        <h4>{`Kunde inte hitta användare ${id}`}</h4>
                        <GhostButton
                            text="Gå Tillbaka"
                            handleGhostButton={() => {
                                const options = { state: routingState };
                                navigate(route.ADMIN_USERS, options);
                            }}
                        />
                    </div>
                </Modal.ModalBody>
            </Modal>
        );
    }
    return (
        <Modal show={show} setShow={setShow} type="desktop">
            <Modal.ModalHeader>
                <h3>{language[locales].ADMIN.USER.EDIT_USER.HEADING}</h3>
                <h4 className="text-grey">{currentUser.username}</h4>
            </Modal.ModalHeader>
            <Modal.ModalBody>
                <div className="user-body-wrapper">
                    <div className="user-column">
                        <UserName
                            user={currentUser}
                            setUser={setCurrentUser}
                            username={username}
                            setUsername={setUsername}
                        />
                        <UserEmail
                            user={currentUser}
                            setUser={setCurrentUser}
                            userEmail={userEmail}
                        />
                        <UserCompetence user={currentUser} setUser={setCurrentUser} />
                        <UserSettings user={currentUser} />
                    </div>
                    <div className="user-column">
                        <UserUnit user={currentUser} setUser={setCurrentUser} />
                        {error && (
                            <ErrorComponent
                                errorText={`${language[locales].TOOLS.BASIC_ERROR_TEXT} (${error})`}
                            />
                        )}
                    </div>
                </div>
            </Modal.ModalBody>
            <Modal.ModalFooter>
                <BigButton text={language[locales].COMMON.DONE} handleButton={sendUser} />
            </Modal.ModalFooter>
        </Modal>
    );
};

User.propTypes = {
    id: PropTypes.string.isRequired,
};

export default User;
