import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { logout } from "~/src/redux/actions/login/actions";
import type { GlobalState, Routes } from "~/src/types";
import { isDemo, route } from "~/src/utils/constants";
import language from "~/src/utils/language/index";
import { getRoutingState } from "~/src/utils/systemFunctions";
import { CASES_ROUTES } from "../../case/caseList/caseList";
import ICONS from "../navIcons";
import "./navComponent.css";

type NavComponentProps = {
    routes: Routes;
    show: boolean;
    setShow: (show: boolean) => void;
};

const NavComponent = ({ routes, show, setShow }: NavComponentProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);
    const navLinkClassName = "nav-navlink";
    const activeNavLinkClassName = `${navLinkClassName} ${navLinkClassName}-active`;
    const navLinkTextClassName = "nav-navlink-text";
    const activeNavLinkTextClassName = `${navLinkTextClassName} ${navLinkTextClassName}-active`;
    const { NAV, APP } = language[locales];

    const subMenuObj = {
        logout: { text: NAV.LOGOUT, link: "#" },
        demo: { text: NAV.DEMO, link: "https://demo-app.ommej.se/" },
        help: {
            text: APP.ROUTE6,
            link: "https://ommej.se/support/",
        },
    };

    const handleLogout = async () => {
        await dispatch(logout());
        const options = { state: routingState };
        navigate(route.ROOT, options);
    };

    // This svg is used inline to be able to change color when hovering button
    const externalLinkIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none">
            <title>Extern länk</title>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".9"
                d="M12.6 9v2.8a1 1 0 0 1-.9 1h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h2.7m1.9-1.3H14v2.8M9 9.5l5-5"
            />
        </svg>
    );

    return (
        <nav className="nav-component">
            {routes.map((currentRoute) => {
                return currentRoute.icon ? (
                    <NavLink
                        key={currentRoute.link}
                        to={currentRoute.link}
                        state={routingState}
                        className={({ isActive }) => {
                            let active: string;
                            if (
                                currentRoute.link.includes(
                                    `${route.CASES}/${CASES_ROUTES.ALL_CASES}`,
                                )
                            ) {
                                active = location.pathname.includes("case")
                                    ? activeNavLinkClassName
                                    : navLinkClassName;
                            } else {
                                active = isActive ? activeNavLinkClassName : navLinkClassName;
                            }
                            return `nav-items ${currentRoute.guideClass} ${active}`;
                        }}
                        onClick={() => {
                            return setShow?.(!show);
                        }}
                    >
                        <span className="nav-icon">{ICONS[currentRoute.icon]}</span>
                        {currentRoute.text}
                    </NavLink>
                ) : (
                    <NavLink
                        key={currentRoute.link}
                        to={currentRoute.link}
                        state={routingState}
                        className={({ isActive }) => {
                            const active = isActive
                                ? activeNavLinkTextClassName
                                : navLinkTextClassName;
                            return `nav-items ${currentRoute.guideClass} ${active}`;
                        }}
                        onClick={() => {
                            return setShow?.(!show);
                        }}
                    >
                        {currentRoute.text}
                    </NavLink>
                );
            })}
            <div className="nav-submenu-wrapper">
                {isDemo && (
                    <a
                        className="nav-submenu-item nav-demo"
                        href={subMenuObj.demo.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {subMenuObj.demo.text}
                        {externalLinkIcon}
                    </a>
                )}
                <a
                    className="nav-submenu-item guide-onboarding-help"
                    href={subMenuObj.help.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {subMenuObj.help.text}
                    {externalLinkIcon}
                </a>
                <NavLink
                    to={subMenuObj.logout.link}
                    onClick={handleLogout}
                    state={routingState}
                    className="nav-submenu-item"
                >
                    {subMenuObj.logout.text}
                </NavLink>
            </div>
        </nav>
    );
};

export default NavComponent;
