"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultAvatarParts = exports.avatarPartsColors = void 0;
exports.avatarPartsColors = {
    HAIR: [
        "#312F2F",
        "#685A56",
        "#B2978F",
        "#FAD7A3",
        "#FFB800",
        "#FFE600",
        "#FFA077",
        "#EC6E6E",
        "#F0CECE",
        "#B9F0EA",
        "#A1AD7E",
    ],
    HEADS: ["#AEE276", "#F7D1C3", "#E5B5A3", "#E4AA89", "#B67754", "#7C6058", "#FFD977"],
    CLOTHES: [
        "#3A4263",
        "#676D82",
        "#C15252",
        "#BFD6D8",
        "#FD846B",
        "#528CC1",
        "#B73E87",
        "#2E2E2E",
        "#685A56",
        "#707E72",
        "#98BA6A",
        "#D0D8BF",
        "#F277C1",
        "#FFFFFF",
    ],
    BEARDS: [
        "#312F2F",
        "#685A56",
        "#B2978F",
        "#FAD7A3",
        "#FFB800",
        "#FFE600",
        "#FFA077",
        "#EC6E6E",
        "#F0CECE",
        "#B9F0EA",
        "#A1AD7E",
    ],
};
exports.defaultAvatarParts = [
    { id: "hair-01", color: "#312F2F" },
    { id: "clothes-01", color: "#3A4263" },
    { id: "head-01", color: "#AEE276" },
    { id: "eye-01" },
    { id: "nose-01" },
    { id: "mouth-01" },
];
