import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { ArrowRightIcon } from "../../../../../utils/icons";
import language from "../../../../../utils/language";
import Modal from "../../../../tools/modal/modal";
import TabAddUnit from "./tab/tabAddUnit";
import TabGiveAccess from "./tab/tabGiveAccess";
import "./addUnit.css";

const AddUnit = ({ show, setShow, unit, role }) => {
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [tab, setTab] = useState();
    const [resolution] = useState(window.innerWidth);
    const [unitObj, setUnitObj] = useState({
        name: "",
        parentUnit: !unit.parentUnit ? currentUser.unit : unit._id,
        administrators: [currentUser._id],
        users: [],
    });

    const renderTab = () => {
        switch (tab) {
            case language[locales].ADMIN.UNIT.ADD_UNIT.CRUMB_NAME:
                return (
                    <TabGiveAccess
                        setUnitObj={setUnitObj}
                        unitObj={unitObj}
                        setTab={setTab}
                        role={role}
                    />
                );
            default:
                return (
                    <TabAddUnit
                        setUnitObj={setUnitObj}
                        unitObj={unitObj}
                        show={show}
                        setShow={setShow}
                        setTab={setTab}
                        role={role}
                    />
                );
        }
    };

    return (
        <Modal
            show={show}
            setShow={setShow}
            shouldRoute={false}
            type={resolution < 850 ? "mobile" : "desktop"}
            style={{ height: "45.375rem" }}
        >
            <Modal.ModalHeader>
                <div className="addUnit-breadcrumb-wrapper">
                    <button
                        className="addUnit-button-home"
                        type="button"
                        aria-label="add unit"
                        onClick={() => {
                            setTab();
                        }}
                    >
                        <h3>{language[locales].ADMIN.UNIT.ADD_UNIT.HEADING}</h3>
                    </button>
                    {tab !== undefined && (
                        <button
                            className="addUnit-button-user"
                            type="button"
                            onClick={() => {
                                setTab();
                            }}
                        >
                            <ArrowRightIcon />
                            &nbsp;
                            <h3>{tab}</h3>
                        </button>
                    )}
                </div>
                {unit.parentUnit && (
                    <h4 className="text-description text-grey">{`${language[locales].ADMIN.UNIT.ADD_UNIT.SUBHEADING} ${unit.name}`}</h4>
                )}
            </Modal.ModalHeader>
            {renderTab()}
        </Modal>
    );
};

AddUnit.defaultProps = {
    unit: null,
};

AddUnit.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    unit: PropTypes.shape(Types.unit),
};

export default AddUnit;
