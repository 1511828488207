import type { Invitation } from "@ommej/types";
import { useState } from "react";
import type * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import BigButton from "~/src/components/tools/buttons/bigButton/bigButton";
import FluidWrapper from "~/src/components/tools/fluidWrapper/fluidWrapper";
import Textfield from "~/src/components/tools/form/textfield/textfield";
import Modal from "~/src/components/tools/modal/modal";
import Tooltip from "~/src/components/tools/tooltip/tooltip";
import ErrorImg from "~/src/media/images/svg/errorImage.svg";
import RefreshIcon from "~/src/media/images/svg/refresh.svg";
import { addInvitation } from "~/src/redux/actions/invitation/actions";
import type { GlobalState } from "~/src/types";
import { INVITE_CRUMBS } from "~/src/utils/constants";
import { QuestionMarkIcon } from "~/src/utils/icons";
import language from "~/src/utils/language";
import { generateRandomString } from "~/src/utils/systemFunctions";
import "./chooseName.css";

type ChooseNameProps = {
    invitationName: string;
    setInvitationName: (name: string) => void;
    handleCrumbs: (crumb: string) => void;
    setFullInvitation: (invitation: Invitation.InvitationWithId) => void;
    isGroup: boolean;
};

const ChooseName = ({
    invitationName,
    setInvitationName,
    handleCrumbs,
    setFullInvitation,
    isGroup,
}: ChooseNameProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { CHOOSE_NAME, SEND_INVITE } = language[locales].CASE.ADD_INVITATION;
    const [errorMessage, setErrorMessage] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInvitationName(e.target.value);
    };

    const handleGenerate = () => {
        const randomString = generateRandomString();
        setInvitationName(randomString);
    };

    const sendInvite = async () => {
        const fullInvitation = await dispatch(
            addInvitation({ name: invitationName, maxNumberOfClients: isGroup ? 0 : 1 }),
        );
        if (!fullInvitation) {
            setErrorMessage(true);
        } else {
            setFullInvitation(fullInvitation as unknown as Invitation.InvitationWithId);
            handleCrumbs(INVITE_CRUMBS.CREATED);
        }
    };

    if (errorMessage) {
        return (
            <Modal.ModalBody>
                <div className="chooseName-error-wrapper">
                    <img src={ErrorImg} alt="" />
                    <p>{SEND_INVITE.ERROR}</p>
                </div>
            </Modal.ModalBody>
        );
    }
    return (
        <>
            <Modal.ModalBody>
                <main className="chooseName-main-wrapper">
                    <FluidWrapper height="hg-small">
                        <div className="chooseName-question-wrapper">
                            <h3>{CHOOSE_NAME.CASE_HEADING}</h3>
                            <Tooltip title={CHOOSE_NAME.TOOLTIP} position="top">
                                <QuestionMarkIcon />
                            </Tooltip>
                            {invitationName.length === 0 && (
                                <button
                                    type="button"
                                    onClick={handleGenerate}
                                    className="chooseName-ghostButton"
                                >
                                    <img src={RefreshIcon} alt="Refresh icon" />
                                    {CHOOSE_NAME.GENERATE_BUTTON}
                                </button>
                            )}
                        </div>
                        <Textfield
                            text={CHOOSE_NAME.NAME_INPUT}
                            type="text"
                            name="name"
                            value={invitationName}
                            handleChange={(e) => {
                                handleChange(e);
                            }}
                            customStyle={{ width: "18rem" }}
                        />
                    </FluidWrapper>
                </main>
            </Modal.ModalBody>
            <Modal.ModalFooter>
                <BigButton
                    text={CHOOSE_NAME.CREATE_BUTTON}
                    color="green"
                    disabled={invitationName.length < 3}
                    handleButton={sendInvite}
                />
            </Modal.ModalFooter>
        </>
    );
};

export default ChooseName;
