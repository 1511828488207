// @ts-check

import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAllInvitations } from "../../../redux/actions/invitation/actions";

/** @type {import('react').FunctionComponent<{ invitationId: string }>} */
const InvitationName = ({ invitationId }) => {
    const invitations = useSelector(
        /**
         * @param {import('~/src/types').GlobalState} state
         * @returns {import('~/src/types').GlobalState["invitation"]["invitations"]}
         */
        (state) => {
            return state.invitation.invitations;
        },
    );
    const invitationsLoaded = useSelector(
        /**
         * @param {import('~/src/types').GlobalState} state
         * @returns {import('~/src/types').GlobalState["invitation"]["loaded"]}
         */
        (state) => {
            return state.invitation.loaded;
        },
    );
    const dispatch = useDispatch();

    /** @type {import('../../../utils/ts-types').UseState<boolean|undefined>} */
    const [isError, setError] = useState();

    const loadResources = async () => {
        if (!invitationsLoaded) {
            try {
                await dispatch(getAllInvitations());
                setError(false);
            } catch (_err) {
                setError(true);
            }
        }
    };
    useEffect(() => {
        loadResources();
    }, []);

    if (isError) {
        return <>#-</>;
    }

    const invitationName = invitations?.find((item) => {
        return item.id === invitationId;
    })?.name;

    if (isError || (invitationsLoaded && !invitationName)) {
        return <>#-</>;
    }
    return <>{`#${invitationName || ""}`}</>;
};

InvitationName.propTypes = {
    invitationId: PropTypes.string.isRequired,
};

export default InvitationName;
