export const LOADING_USERS = "LOADING_USERS";

export const GET_ALL_USERS = "GET_ALL_USERS";

export const ADD_USER = "ADD_USER";

export const DELETE_USER = "DELETE_USER";

export const UPDATE_USER = "UPDATE_USER";

export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_DONE = "UPDATE_USER_PASSWORD_DONE";

export const BUILD_USER_OBJECT = "BUILD_USER_OBJECT";

export const USER_ERROR = "USER_ERROR";
