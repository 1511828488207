import uFuzzy from "@leeoniya/ufuzzy";
import { GetAvatar } from "@ommej/componente";
import { personTypes } from "@ommej/metadata";
import type { Beb2, Form, Invitation, QuestionId } from "@ommej/types";
import type React from "react";
import { type ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GhostButton from "~/src/components/tools/buttons/ghostButton/ghostButton";
import Dropdown, { type DropdownItemsObject } from "~/src/components/tools/dropdown/dropdown";
import SearchInput from "~/src/components/tools/form/search/searchInput";
import close from "~/src/media/images/svg/close.svg";
import type { ClientInfo, GlobalState } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import language from "~/src/utils/language";
import { formatDate } from "~/src/utils/systemFunctions";
import ConversationAnswers from "./conversationAnswers";
import QuestionSection from "./questionSection";
import "./beb.css";
import { startGuide } from "~/src/guide";
import { getLoggedInUser } from "~/src/redux/actions/login/actions";
import getAbortController from "~/src/utils/abort";
import type { LanguageLocales } from "~/src/utils/ts-types";
import type { SentFormWithMetadata } from "../modals/components/cards/caseFormCard";
import { getSentForms } from "./utils";

type BebProps = {
    bebCurrent: Beb2;
    client: ClientInfo;
    invitation: Invitation.ToClient;
    snapshots: Array<string>;
};

export const BEB_FILTERS = {
    ALARM: { text: "Identifierade risker", value: 1 },
    RESOURCES: { text: "Resurser", value: 2 },
    HELP: { text: "Vill ha hjälp med", value: 4 },
    WORRIES: { text: "Oroar", value: 8 },
    PROBLEM: { text: "Andra problem", value: 16 },
    OTHER: { text: "Övriga", value: 32 },
};

const FormRequests = ({
    invitation,
    locales,
    client,
    openAll,
    setHaystack,
    filter,
}: {
    invitation: Invitation.ToClient;
    locales: LanguageLocales;
    client: ClientInfo;
    openAll: boolean;
    setHaystack: (texts: Array<string>, ids: Array<QuestionId>) => void;
    filter: Set<QuestionId>;
}) => {
    const { CASE } = language[locales];
    const [sentForms, setSentForms] = useState<Array<SentFormWithMetadata>>([]);
    const [formRequestData, setFormRequestData] = useState<
        Array<{ id: string; name: string; created: Date; beb: Beb2 }>
    >([]);

    useEffect(() => {
        getSentForms(invitation).then((forms) => setSentForms(forms));
    }, [invitation]);

    useEffect(() => {
        if (sentForms.length === 0) {
            return;
        }
        const abortController = getAbortController();
        async function getFormAnswers() {
            const bebsPromises: Array<Promise<Response>> = [];
            for (const sentForm of sentForms) {
                bebsPromises.push(
                    reqWithPath(
                        `invitations/${invitation.id}/clients/${client.id}/beb2/${sentForm.id}`,
                        "get",
                        "",
                        { signal: abortController.signal },
                    ),
                );
            }

            try {
                const res = await Promise.all(bebsPromises);
                const jsonPromises: Array<Promise<{ active: Beb2 }>> = [];
                for (const r of res) {
                    jsonPromises.push(r.json());
                }
                const bebs = await Promise.allSettled(jsonPromises);
                const formRequests: typeof formRequestData = [];
                // for searching
                const allIds: Array<QuestionId> = [];
                const allTexts: Array<string> = [];
                for (let i = 0; i < bebs.length; i++) {
                    const bebRes = bebs[i];
                    if (!bebRes || bebRes.status === "rejected") {
                        continue;
                    }
                    const beb = bebRes.value;
                    const formReq = sentForms[i];
                    if (!formReq) {
                        continue;
                    }
                    formRequests.push({
                        id: formReq.id,
                        name: formReq.metadata.name,
                        created: new Date(formReq.created),
                        beb: beb.active,
                    });

                    for (const [qid, entry] of Object.entries(beb.active.answers)) {
                        if (entry.question.type === "article_text") {
                            continue;
                        }
                        allIds.push(qid);
                        allTexts.push(entry.question.text.sv);
                    }
                }
                setHaystack(allTexts, allIds);
                setFormRequestData(formRequests);
            } catch (err) {
                console.error(err);
            }
        }
        getFormAnswers();

        return () => {
            abortController.abort();
        };
    }, [client, invitation, sentForms]);

    if (formRequestData.length === 0) {
        return null;
    }

    return (
        <div key="beb-tags" className="tabBeb-section-wrapper">
            <h2 className="beb-section-header">{CASE.EDIT_INVITATION.FORMS.SUBHEADER}</h2>
            {formRequestData.map((formRequest) => {
                return (
                    <QuestionSection
                        id={formRequest.id}
                        key={`beb.formRequest.${formRequest.id}`}
                        beb={formRequest.beb}
                        client={client}
                        questionsToInclude={new Set(Object.keys(formRequest.beb.answers))}
                        header={
                            <>
                                {formRequest.name}
                                <div style={{ fontWeight: "normal" }}>
                                    {formatDate(formRequest.created)}
                                </div>
                            </>
                        }
                        showOthers
                        showSubHeaders={false}
                        showAnswers
                        openSection={openAll}
                        filter={filter}
                    />
                );
            })}
        </div>
    );
};

const Beb = ({ bebCurrent, client, invitation, snapshots }: BebProps) => {
    const [bebs, setBebs] = useState<Map<string, Beb2>>(new Map([["current", bebCurrent]]));
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });

    const [filter, setFilter] = useState(0);
    const [allQuestionTexts, setAllQuestionTexts] = useState<Array<string>>([]);
    const [allQuestionIds, setAllQuestionIds] = useState<Array<string>>([]);
    const [bebCurrentQuestionTexts, setBebCurrentQuestionTexts] = useState<Array<string>>([]);
    const [bebCurrentQuestionIds, setBebCurrentQuestionIds] = useState<Array<QuestionId>>([]);
    const [matchedIds, setMatchedIds] = useState<Set<QuestionId>>(new Set());
    const [markedIds, setMarkedIds] = useState<Set<QuestionId>>(new Set());
    const [openAll, setOpenAll] = useState(false);
    const [hideAll, setHideAll] = useState(false);
    const [dropdownItems, _setDropdownItems] = useState<DropdownItemsObject>(() => {
        const filterDropdown: DropdownItemsObject = {};
        for (const timestamp of snapshots) {
            filterDropdown[timestamp] = {
                value: timestamp,
                text: new Date(Number(timestamp) * 1000).toLocaleDateString(),
            };
        }
        filterDropdown.current = {
            value: "current",
            text: "Senaste svaren",
        };
        return filterDropdown;
    });
    const uf = useRef(new uFuzzy({ alpha: "a-zåäö", intraMode: 1 }));
    const urlSearchParams = new URLSearchParams(location.search);
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const dispatch = useDispatch();

    const TAGS_TO_INCLUDE: Array<Form.QuestionTags> = [
        "MYSELF",
        "SCHOOL",
        "FAMILY",
        "LEISURE",
        "BASE",
        "OMMEJ",
    ];

    const getClientBebById = async (clientId: string, snapshot: string) => {
        const response = await reqWithPath(
            "invitations",
            "get",
            `${invitation.id}/clients/${clientId}/beb2/${snapshot === "current" ? "" : snapshot}`,
        );
        const data = await response.json();
        setBebs(new Map([[snapshot, data.active]]));
    };

    useEffect(() => {
        if (!bebCurrent) {
            return;
        }
        startGuide(currentUser, "CASE_BEB_VIEW", locales, () => {
            dispatch(getLoggedInUser());
        });
    }, [bebCurrent]);

    useEffect(() => {
        if (!bebCurrent) {
            return;
        }

        const ids: Array<string> = [];
        const texts: Array<string> = [];
        for (const [qid, entry] of Object.entries(bebCurrent.answers)) {
            if (entry.question.type === "article_text") {
                continue;
            }
            ids.push(qid);
            texts.push(entry.question.text.sv);
        }
        setBebCurrentQuestionIds(ids);
        setBebCurrentQuestionTexts(texts);
        // there might be a potential race here which would make us overwrite
        // what has been set in setHaystack, so that the search would only be
        // done on items in bebCurrent. Haven't seen it actually happen though,
        // so let's hope for the best...
        setAllQuestionIds(ids);
        setAllQuestionTexts(texts);
    }, [bebCurrent]);

    function setHaystack(texts: Array<string>, ids: Array<QuestionId>) {
        setAllQuestionTexts([...bebCurrentQuestionTexts, ...texts]);
        setAllQuestionIds([...bebCurrentQuestionIds, ...ids]);
    }

    function onSearchChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setHideAll(false);
        if (!allQuestionTexts || !allQuestionIds) {
            return;
        }

        // clear filter, we search among all answers so it should be reflected
        // in the ui too
        setFilter(0);

        const matches = new Set<QuestionId>();
        const indices = uf.current.filter(allQuestionTexts, ev.target.value);
        if (!indices || indices.length === 0) {
            if (ev.target.value.length === 0) {
                setOpenAll(false);
            } else {
                setHideAll(true);
            }
            setMatchedIds(matches);
            return;
        }
        for (const index of indices) {
            if (!allQuestionIds[index]) {
                continue;
            }
            // biome-ignore lint/style/noNonNullAssertion: checked above
            matches.add(allQuestionIds[index]!);
        }

        setOpenAll(!!ev.target.value && matches.size > 0);
        setMatchedIds(matches);
    }

    function onFilterClick(val, checked) {
        let newFilter = filter;
        if (checked) {
            newFilter |= val;
        } else {
            newFilter &= ~val;
        }

        setFilter(newFilter);
    }

    useEffect(() => {
        if (!bebCurrent) {
            return;
        }

        const match = new Set<string>();
        const mark = new Set<string>();
        if (filter & BEB_FILTERS.ALARM.value && bebCurrent.answerTags.ALARM) {
            for (const qid of Object.keys(bebCurrent.answerTags.ALARM)) {
                match.add(qid);
            }
        }

        if (filter & BEB_FILTERS.RESOURCES.value && bebCurrent.answerTags.RESOURCE) {
            for (const qid of Object.keys(bebCurrent.answerTags.RESOURCE)) {
                match.add(qid);
            }
        }

        if (filter & BEB_FILTERS.HELP.value && bebCurrent.answerTags.HELP) {
            for (const qid of Object.keys(bebCurrent.answerTags.HELP)) {
                match.add(qid);
            }
        }

        if (filter & BEB_FILTERS.WORRIES.value && bebCurrent.answerTags.WORRIES) {
            for (const qid of Object.keys(bebCurrent.answerTags.WORRIES)) {
                match.add(qid);
            }
        }

        if (filter & BEB_FILTERS.PROBLEM.value && bebCurrent.answerTags.PROBLEM) {
            for (const qid of Object.keys(bebCurrent.answerTags.PROBLEM)) {
                match.add(qid);
            }
        }

        if (filter & BEB_FILTERS.OTHER.value) {
            for (const qid of Object.keys(bebCurrent.answers)) {
                if (
                    bebCurrent.answerTags.HELP?.[qid] ||
                    bebCurrent.answerTags.WORRIES?.[qid] ||
                    bebCurrent.answerTags.PROBLEM?.[qid] ||
                    bebCurrent.answerTags.ALARM?.[qid] ||
                    bebCurrent.answerTags.RESOURCE?.[qid]
                ) {
                    continue;
                }
                match.add(qid);
            }
        }

        // check if we have fromDate in query paramaters
        if (location.search) {
            const fromDate = urlSearchParams.get("fromDate");

            if (fromDate) {
                const questionsToFilter =
                    filter === 0
                        ? Object.entries(bebCurrent?.answers || {})
                        : Object.entries(bebCurrent?.answers || {}).filter(([qid]) =>
                              match.has(qid),
                          );
                const date = new Date(fromDate);
                if (date instanceof Date && !Number.isNaN(date.getTime())) {
                    for (const [qid, entry] of questionsToFilter) {
                        if (
                            entry.answers.some((ans) => {
                                return new Date(ans.timestamp) > date;
                            })
                        ) {
                            match.add(qid);
                        }
                    }
                }
            }
        }

        // filter is set but no matches, hide all and show no matches info
        if (match.size === 0 && filter !== 0) {
            setHideAll(true);
        } else {
            setHideAll(false);
        }

        setOpenAll(match.size !== 0);

        setMatchedIds(match);
        setMarkedIds(mark);
    }, [filter, location, bebCurrent]);

    useEffect(() => {
        if (!location.search) {
            return;
        }
        const filter = Number.parseInt(urlSearchParams.get("filter") || "");

        if (filter && !Number.isNaN(filter)) {
            setFilter(filter);
        }
    }, [location]);

    const getPersons = useCallback(
        (beb: Beb2 | undefined) => {
            if (!client.profile?.persons || !beb) {
                return null;
            }

            return (
                <div key="beb-persons" className="tabBeb-section-wrapper">
                    <h2 className="beb-section-header">
                        {language[locales].TOOLS.PEOPLE_IN_MY_LIFE}
                    </h2>
                    {Object.entries(client.profile.persons).map(([personId, personData]) => {
                        const mentioned = beb.persons[personId];

                        return (
                            <QuestionSection
                                id={personId}
                                key={`beb-${personId}`}
                                beb={beb}
                                client={client}
                                questionsToInclude={new Set(Object.keys(mentioned || {}))}
                                header={
                                    personData.type
                                        ? personTypes[personData.type]?.lang.sv || "-"
                                        : "-"
                                }
                                icon={<GetAvatar avatarData={personData.avatar} />}
                                filter={matchedIds}
                                marked={markedIds}
                                showOthers
                                showAnswers={false}
                                openSection={openAll}
                            />
                        );
                    })}
                </div>
            );
        },
        [client, openAll, matchedIds, markedIds],
    );

    const getAccommodations = useCallback(
        (beb: Beb2 | undefined) => {
            if (!client.profile?.houses || !beb) {
                return null;
            }
            return (
                <div key="beb-accommodations" className="tabBeb-section-wrapper">
                    <h2 className="beb-section-header">{language[locales].TOOLS.PLACES_I_LIVE}</h2>
                    {Object.entries(client.profile.houses).map(([houseId, houseData], index) => {
                        const persons = client.profile?.housePersonMap[houseId];

                        const mentioned = beb.houses[houseId];

                        const personsString: Array<string> = [];
                        const intro: Array<ReactNode> = [];
                        if (persons) {
                            for (const person of persons) {
                                const personData = client.profile?.persons[person];
                                if (!personData) {
                                    continue;
                                }
                                const type =
                                    personData.type && personTypes[personData.type]
                                        ? // biome-ignore lint/style/noNonNullAssertion: checked above
                                          personTypes[personData.type]!.lang.sv
                                        : "";
                                personsString.push(type);

                                const node = (
                                    <span
                                        key={`beb.intro.${houseId}.${person}`}
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "6rem",
                                        }}
                                    >
                                        <GetAvatar avatarData={personData.avatar} />
                                        {type}
                                    </span>
                                );
                                intro.push(node);
                            }
                        }

                        return (
                            <QuestionSection
                                id={houseId}
                                key={`beb-${houseId}`}
                                beb={beb}
                                client={client}
                                questionsToInclude={new Set(Object.keys(mentioned || {}))}
                                header={
                                    <>
                                        {`Boende ${index + 1}`}
                                        <div className="text-grey" style={{ fontWeight: "normal" }}>
                                            {personsString.join(", ") || "-"}
                                        </div>
                                    </>
                                }
                                icon={
                                    <img
                                        src={`/assets/houses/${houseData.avatar}.svg`}
                                        className="backaBarnetAnswers-house-img"
                                        alt="house"
                                        width="3rem"
                                    />
                                }
                                intro={
                                    <>
                                        <h3>Personer som bor här</h3>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "1.5rem",
                                                marginTop: "1.5rem",
                                            }}
                                        >
                                            {intro}
                                        </div>
                                    </>
                                }
                                filter={matchedIds}
                                marked={markedIds}
                                showOthers
                                showAnswers={false}
                                openSection={openAll}
                            />
                        );
                    })}
                </div>
            );
        },
        [client, openAll, matchedIds, markedIds],
    );

    function getBeb(beb: Beb2 | undefined) {
        if (!beb) {
            return null;
        }
        const dom: Array<React.JSX.Element | null> = [];
        dom.push(getPersons(beb));

        dom.push(getAccommodations(beb));

        const node = (
            <div key="beb-tags" className="tabBeb-section-wrapper">
                <h2 className="beb-section-header">{language[locales].COMMON.CATEGORY}</h2>
                <ConversationAnswers
                    className="guide-case_beb_view-category"
                    beb={beb}
                    client={client}
                    questionTagsToInclude={TAGS_TO_INCLUDE}
                    filter={matchedIds}
                    marked={markedIds}
                    showOthers
                    openAll={openAll}
                />
            </div>
        );
        dom.push(node);

        // hide formRequests if the user selects a snapshot, we don't show
        // historic answers for formRequests
        if (bebs.get("current")) {
            dom.push(
                <FormRequests
                    client={client}
                    locales={locales}
                    invitation={invitation}
                    openAll={openAll}
                    setHaystack={setHaystack}
                    filter={matchedIds}
                />,
            );
        }

        return dom;
    }

    function getActiveFiltersDOM() {
        const nodes: Array<ReactNode> = [];

        // filters
        Object.values(BEB_FILTERS).map((f) => {
            if (filter & f.value) {
                const node = (
                    <div
                        key={`filter-info-${f.text}`}
                        className="unitTags-selected-item"
                        style={{ marginBottom: 0 }}
                    >
                        {f.text}
                        <button
                            className="unitTags-selected-item-remove"
                            type="button"
                            onClick={() => {
                                const newFilter = filter & ~f.value;
                                setFilter(newFilter);
                            }}
                        >
                            <img src={close} alt="remove filter" />
                        </button>
                    </div>
                );

                nodes.push(node);
            }
        });

        // from date
        if (urlSearchParams.get("fromDate")) {
            const date = new Date(urlSearchParams.get("fromDate") || "");
            if (date instanceof Date && !Number.isNaN(date.getTime())) {
                const node = (
                    <div
                        key="filter-info-fromdate"
                        className="unitTags-selected-item"
                        style={{ marginBottom: 0 }}
                    >
                        {`Svar efter ${formatDate(date)}`}
                        <button
                            className="unitTags-selected-item-remove"
                            type="button"
                            onClick={() => {
                                // TODO: this is a bit brutal but seems, if not
                                // impossible, at least very hard to get the
                                // useEffects with location in their
                                // dependencis to run. We would need the
                                // useEffect responsible for calculating the
                                // matchedIds to run.
                                // Filtering by data is not available in the UI
                                // at the moment, only via query parameter
                                // (from alarm emails, is the idea), so might
                                // be okay to be a bit brutal for now.

                                window.location.search = "";
                            }}
                        >
                            <img src={close} alt="remove filter" />
                        </button>
                    </div>
                );
                nodes.push(node);
            }
        }

        // snapshots
        if (!bebs.get("current")) {
            for (const timestamp of bebs.keys()) {
                const date = new Date(Number.parseInt(timestamp) * 1000);
                if (date instanceof Date && !Number.isNaN(date.getTime())) {
                    const node = (
                        <div
                            key="filter-info-afterDate"
                            className="unitTags-selected-item"
                            style={{ marginBottom: 0 }}
                        >
                            {`Svaren vid ${formatDate(date)}`}
                            <button
                                className="unitTags-selected-item-remove"
                                type="button"
                                onClick={() => {
                                    setBebs(new Map([["current", bebCurrent]]));
                                }}
                            >
                                <img src={close} alt="remove filter" />
                            </button>
                        </div>
                    );
                    nodes.push(node);
                }
            }
        }

        if (nodes.length === 0) {
            return null;
        }

        const dom = (
            <div style={{ display: "flex", alignItems: "center", marginTop: "1.5rem" }}>
                <span style={{ fontWeight: "bold", marginRight: "1rem" }}>Aktiva filter:</span>
                {nodes}
            </div>
        );
        return dom;
    }

    function getFilters() {
        return (
            <>
                <details
                    className="inactive-answers-details print-hide guide-case_beb_view-filter_wrapper"
                    style={{ marginTop: 0 }}
                >
                    <summary>Avancerat</summary>
                    <div className="beb-filters">
                        <fieldset>
                            <legend>Tidpunkt</legend>
                            <Dropdown
                                items={dropdownItems}
                                handleChange={(item) => {
                                    getClientBebById(client.id, item);
                                }}
                            />
                        </fieldset>

                        <fieldset>
                            <legend>Filter</legend>
                            {Object.entries(BEB_FILTERS).map(([filterOption, val]) => {
                                return (
                                    <label key={`filter-${filterOption}`} htmlFor={filterOption}>
                                        <input
                                            id={filterOption}
                                            type="checkbox"
                                            onChange={(ev) => {
                                                return onFilterClick(val.value, ev.target.checked);
                                            }}
                                            checked={(filter & val.value) !== 0}
                                        />
                                        {val.text}
                                    </label>
                                );
                            })}
                        </fieldset>

                        <fieldset>
                            <legend>Annat</legend>
                            <label key="filter-open_all" htmlFor="beb-open_all">
                                <input
                                    id="beb-open_all"
                                    type="checkbox"
                                    onChange={(ev) => {
                                        setOpenAll(ev.target.checked);
                                    }}
                                />
                                Expandera alla
                            </label>
                        </fieldset>
                    </div>
                </details>

                {getActiveFiltersDOM()}
            </>
        );
    }

    return (
        <div className="tabBeb-wrapper">
            <div className="tabBeb-header-wrapper print-hide">
                <h2 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
                    {language[locales].CASE_DETAILS.BEB}
                </h2>
                <div style={{ display: "flex", alignItems: "end" }}>
                    <SearchInput
                        name="beb-search"
                        placeholder="Sök bland besvarade frågor"
                        handleChange={(e) => {
                            onSearchChange(e);
                        }}
                    />

                    <GhostButton
                        customStyle={{ marginLeft: "1rem" }}
                        icon="print"
                        text={language[locales].TOOLS.PRINT}
                        handleGhostButton={() => {
                            window.print();
                        }}
                    />
                </div>
            </div>

            {getFilters()}

            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${bebs.size}, auto)`,
                    gap: "2rem",
                }}
            >
                {Array.from(bebs).map(([date, beb]) => {
                    return hideAll ? (
                        <div className="beb-no-results" key={`beb.no-results.${date}`}>
                            Inga resultat från sökningen
                        </div>
                    ) : (
                        <div key={`beb.${date}`}>{getBeb(beb)}</div>
                    );
                })}
            </div>
        </div>
    );
};

export default Beb;
