export const isLocal = process.env.NODE_ENV === "development";
let isDemoLocal = false;
let isDevLocal = false;
let remoteApiUrl = "https://api.ommej.se/";
export let adminUrl = "https://admin.ommej.se";
if (window.location.hostname.startsWith("dev-admin")) {
    remoteApiUrl = "https://dev-api.ommej.se/";
    adminUrl = "https://dev-admin.ommej.se";
    isDevLocal = true;
} else if (window.location.hostname.startsWith("demo-admin")) {
    remoteApiUrl = "https://demo-api.ommej.se/";
    adminUrl = "https://demo-admin.ommej.se";
    isDemoLocal = true;
}

if (isLocal) {
    adminUrl = "http://localhost:9000";
}

export const isDemo = isDemoLocal;
export const isDevEnv = isDevLocal;

const setDevApi = () => {
    return "http://localhost:8080/";
};

export const apiUrl = isLocal ? setDevApi() : remoteApiUrl;

// Fields like these should be synced with backend through a call to
// GET /api/constants or something like that.
// There is api for get roles on backend.
export const roles = {
    systemAdmin: "SYSTEM_ADMIN",
    administrator: "ADMINISTRATOR",
    user: "USER",
};

export const INVITE_CRUMBS = {
    CREATE: "CREATE",
    GROUP: "GROUP",
    SINGLE: "SINGLE",
    INVITE: "INVITE",
    CREATED: "CREATED",
    SUCCESS: "SUCCESS",
};

export const userRoles = {
    SYSTEM_ADMIN: "Systemadministratör",
    ADMINISTRATOR: "Administratör",
    USER: "Användare",
};

export const route = {
    ROOT: "/",
    HOME: "/home",
    CASES: "/cases",
    CASES_ADD: "/cases/add",
    CASE: "/case",
    CASE_REPORT: "/case/report",
    ADMIN: "/admin",
    ADMIN_USERS: "/admin/users",
    ADMIN_USERS_ADD: "/admin/users/add",
    ADMIN_UNITS: "/admin/units",
    ADMIN_PROFILE: "/admin/profile",
    ARTICLES: "/articles",
    STATISTICS: "/statistics",
    STATISTICS_QUESTIONS: "/statistics/questions",
    STATISTICS_FORMS: "/statistics/forms",
    RESET_PASSWORD: "/resetpwd",
    LOGIN_PASSWORD: "/password",
};

export const minimumPasswordLength = 6;

export const supportedCountries = {
    se: {
        localization: "Sverige",
        countryCode: "se",
        dialCode: "+46",
        validator: {
            sms: /^(?:\s*-*\s*\d){10}$/,
            email: /^\S+@\S+$/,
        },
    },
};
