"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarTabs = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./avatarTabs.css");
const AvatarTabs = ({ tabsArray, activeTab, handleTab }) => {
    return ((0, jsx_runtime_1.jsx)("section", { className: "avatarTabs-wrapper", children: tabsArray.map((tab) => {
            return ((0, jsx_runtime_1.jsx)("button", { className: `avatarTabs-button ${tab.key === activeTab && "avatarTabs-active"}`, type: "button", onClick: () => {
                    handleTab(tab.key);
                }, children: tab.translatedTab }, tab.key));
        }) }));
};
exports.AvatarTabs = AvatarTabs;
