import type { Form } from "@ommej/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import close from "~/src/media/images/svg/close.svg";
import type { LanguageState } from "~/src/types";
import { reqWithPayload } from "~/src/utils/api";
import language from "~/src/utils/language";
import "./formPreview.css";

type showPreviewProps = {
    setShow: (show: boolean) => void;
    form: Form.Metadata;
};

const FormPreview = ({ setShow, form }: showPreviewProps) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const { FORMS } = language[locales].CASE.EDIT_INVITATION;
    const [currentForm, setCurrentForm] = useState<Form.FormData>();

    useEffect(() => {
        const getForm = async () => {
            try {
                const req = await reqWithPayload(`data/forms/${form.id}/${form.version}`, "get");
                const previewForm = await req.json();
                setCurrentForm(previewForm);
            } catch (error) {
                console.error(error);
            }
        };
        getForm();
    }, []);

    if (!currentForm) {
        return null;
    }
    return (
        <div className="showFollowUp-overlay">
            <section className="showFollowUp-wrapper">
                <button
                    className="modal-close-button"
                    type="button"
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    <img src={close} alt="close modal" />
                </button>
                <div className="showFollowUp-header">
                    <h3>{`${FORMS.PREVIEW} "${currentForm.metadata.name}"`}</h3>
                </div>
                <div className="showFollowUp-content">
                    {Object.entries(currentForm.questions).map(([formKey, formObj]) => {
                        return (
                            <div key={formKey} className="formPreview-question-wrapper">
                                <p>{formObj.text.sv}</p>
                                <ul>
                                    {Object.entries(formObj.answers).map(([answerKey, answer]) => {
                                        if (answer.type === "text") {
                                            return (
                                                <li key={answerKey} className="formPreview-answer">
                                                    {answer.text.sv}
                                                </li>
                                            );
                                        }
                                        return null;
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            </section>
        </div>
    );
};

export default FormPreview;
