import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GhostButton from "~/src/components/tools/buttons/ghostButton/ghostButton";
import ErrorComponent from "~/src/components/tools/errorCompnent/errorComponent";
import FluidWrapper from "~/src/components/tools/fluidWrapper/fluidWrapper";
import PickerPopup from "~/src/components/tools/pickerPopup";
import close from "~/src/media/images/svg/close.svg";
import { reqWithPath } from "~/src/utils/api";
import language from "~/src/utils/language";
import Types from "~/src/utils/types";
import "./unitTags.css";

const UnitTags = ({ unitObj, setUnitObj }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [availableTags, setAvailableTags] = useState([]);
    const [showPicker, setShowPicker] = useState(false);
    const [selectedTags, setSelectedTags] = useState();
    const [tagsMap, setTagsMap] = useState();
    const [errorMessage, setErrorMessage] = useState();

    async function getAvailableTags() {
        let response = await reqWithPath(`data/units/tags/${locales}`, "GET");
        const allTags = await response.json();
        setAvailableTags(allTags);

        response = await reqWithPath("data/units/tags/maps", "GET");
        const resTags = await response.json();

        const tmap = new Map();
        for (const m of resTags) {
            const tags = {};
            for (const tagId of m.tags) {
                tags[tagId] = allTags[tagId];
            }
            const values = {};
            for (const value of m.values) {
                values[value] = allTags[value];
            }
            tmap.set({ text: m.text[locales], id: m.id, values }, tags);
        }

        setTagsMap(tmap);
    }

    useEffect(() => {
        getAvailableTags().catch(() => {
            setErrorMessage(true);
        });
    }, []);

    useEffect(() => {
        if (selectedTags) {
            const newTags = unitObj.tags
                ? [...new Set([...unitObj.tags, ...Object.keys(selectedTags)])]
                : Object.keys(selectedTags);
            setUnitObj({ ...unitObj, tags: newTags });
        }
    }, [selectedTags]);

    const handleRemoveTag = (event) => {
        const tagId = event.currentTarget.dataset.id;
        const index = unitObj.tags.indexOf(tagId);
        if (index === -1) {
            return;
        }
        unitObj.tags.splice(index, 1);
        setUnitObj({ ...unitObj, tags: unitObj.tags });
    };

    return (
        <FluidWrapper height="hg-small">
            <h3>{language[locales].ADMIN.UNIT.ADD_UNIT.UNIT_TYPE.HEADING}</h3>
            <div id="unitTags-selected">
                {unitObj.tags?.map((tag) => {
                    return (
                        <div key={tag} className="unitTags-selected-item">
                            {availableTags[tag]}
                            <button
                                data-id={tag}
                                className="unitTags-selected-item-remove"
                                type="button"
                                onClick={handleRemoveTag}
                            >
                                <img src={close} alt="close modal" />
                            </button>
                        </div>
                    );
                })}
            </div>
            <GhostButton
                handleGhostButton={() => {
                    setShowPicker(true);
                }}
                text={language[locales].ADMIN.UNIT.ADD_UNIT.UNIT_TYPE.PICK_TAGS}
                customStyle={{ marginTop: "0.5rem" }}
            />
            {showPicker && tagsMap && (
                <PickerPopup
                    header={language[locales].ADMIN.UNIT.ADD_UNIT.UNIT_TYPE.PICK_TAGS}
                    items={tagsMap}
                    setSelected={setSelectedTags}
                    setShow={setShowPicker}
                    allowMultiple
                    helpText={language[locales].ADMIN.UNIT.ADD_UNIT.UNIT_TYPE.PICK_TAGS_HELP}
                    addAllCategory
                />
            )}
            {errorMessage && <ErrorComponent />}
        </FluidWrapper>
    );
};

UnitTags.propTypes = {
    setUnitObj: PropTypes.func.isRequired,
    unitObj: PropTypes.shape(Types.unit).isRequired,
};

export default UnitTags;
