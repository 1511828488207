import { useEffect, useRef, useState } from "react";
import "./textfield.css";

const Textfield = ({
    className,
    type,
    name,
    text,
    value,
    min,
    inputError,
    handleChange,
    customStyle,
    disabled,
    required,
    defaultValue,
    autocomplete,
    pattern,
}: {
    className?: string;
    type: string;
    name: string;
    text: string;
    value?: string;
    min?: number;
    inputError?: string;
    handleChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    customStyle?: React.CSSProperties;
    disabled?: boolean;
    required?: boolean;
    defaultValue?: string;
    autocomplete?: React.HTMLInputAutoCompleteAttribute;
    pattern?: string;
}) => {
    const [isActive, setIsActive] = useState(false);
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current && ref.current.value?.length > 0) {
            setIsActive(true);
        }
    }, [value]);

    return (
        <div className="textfield-wrapper">
            <div className="textfield-input-wrapper">
                <input
                    className={`textfield-input ${className}`}
                    id={name}
                    type={type}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    minLength={min}
                    onChange={handleChange}
                    onFocus={() => {
                        setIsActive(true);
                    }}
                    onBlur={() => {
                        setIsActive(false);
                    }}
                    ref={ref}
                    style={customStyle}
                    disabled={disabled}
                    required={required}
                    autoComplete={autocomplete}
                    pattern={pattern}
                />
                <span className="textfield-span-border" style={customStyle} />
                <label
                    className={`textfield-label ${isActive ? "textfield-label-active" : null} ${
                        ref.current?.value && ref.current?.value?.length > 0
                            ? "textfield-has-value"
                            : ""
                    }`}
                    htmlFor={name}
                >
                    {text}
                </label>
            </div>
            <p className="textfield-error">{inputError}</p>
        </div>
    );
};

export default Textfield;
