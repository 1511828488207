import Shimmer from "./shimmer";
import Skeleton from "./skeleton";

const SkeletonCases = () => {
    return (
        <div className="skeletonCases-shimmer">
            <div className="skeletonCases-wrapper">
                <div className="skeletonCases-content">
                    <Skeleton type="round-small" />
                    <div className="skeletonCases-texts">
                        <Skeleton type="heading-small" />
                        <Skeleton type="text-small" />
                    </div>
                </div>
                <div className="skeletonCases-buttons">
                    <Skeleton type="button" />
                    <Skeleton type="button" />
                </div>
            </div>
            <Shimmer />
        </div>
    );
};

export default SkeletonCases;
