import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BigButton from "~/src/components/tools/buttons/bigButton/bigButton";
import type { GlobalState } from "~/src/types";
import { route } from "~/src/utils/constants";
import language from "~/src/utils/language/index";
import "./welcome.css";

const Welcome = () => {
    const currentUser = useSelector((state: GlobalState) => {
        return state.login.currentUser;
    });
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { WELCOME } = language[locales].HOME;
    const navigate = useNavigate();

    return (
        <div className="home-card welcome-wrapper">
            <div className="welcome-text-wrapper">
                <p className="swanky">{`${WELCOME.SUB_HEADER} ${currentUser.username ?? ""}!`}</p>
                <h1>{WELCOME.HEADER}</h1>
                <p>{WELCOME.TEXT}</p>
                <BigButton
                    icon="plus"
                    text={WELCOME.BUTTON_CREATE}
                    className="welcome-create-button"
                    handleButton={() => {
                        navigate(route.CASES_ADD);
                    }}
                />
            </div>
        </div>
    );
};

export default Welcome;
