import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import language from "../../../../../utils/language";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import Textfield from "../../../../tools/form/textfield/textfield";

const UnitCreate = ({ unitObj, setUnitObj }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });

    const handleChange = (e) => {
        setUnitObj({ ...unitObj, [e.target.name]: e.target.value });
    };

    return (
        <FluidWrapper height="hg-small" customStyle={{ height: "7.8125rem" }}>
            <h3>{language[locales].ADMIN.UNIT.ADD_UNIT.NAME}</h3>
            <Textfield
                text={language[locales].ADMIN.UNIT.ADD_UNIT.NAME_INPUT}
                type="text"
                name="name"
                value={unitObj.name}
                handleChange={handleChange}
                customStyle={{ width: "18rem" }}
            />
        </FluidWrapper>
    );
};

UnitCreate.propTypes = {
    setUnitObj: PropTypes.func.isRequired,
    unitObj: PropTypes.shape(Types.unit).isRequired,
};

export default UnitCreate;
