import Chartjs from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import "./chart.css";
import type { Beb2 } from "@ommej/types";

// ***** Chart Options ***** //
Chartjs.defaults.font.family = "'Quicksand', sans-serif";

const StegenChart = ({ beb }: { beb: Beb2 }) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { TAB_REPORT } = language[locales].CASE.REPORT;
    let chart;
    const lineChart = useRef<HTMLCanvasElement | null>(null);

    const STEGEN_QIDS = [
        "9e46fbdf-e833-4050-b1aa-db497ac55a04", // LABEL1
        "c1fe7494-a302-4466-ade4-63c64c7f9cf8", // LABEL2
        "f1b98335-cd3b-480b-bf37-8d21ef5b0bd6", // LABEL3
    ];

    const labelConfig = {
        labels: [TAB_REPORT.STEGEN_LABEL1, TAB_REPORT.STEGEN_LABEL2, TAB_REPORT.STEGEN_LABEL3],
        upperValue: TAB_REPORT.STEGEN_UPPER_VALUE,
        middleValue: TAB_REPORT.STEGEN_MIDDLE_VALUE,
        netherValue: TAB_REPORT.STEGEN_NETHER_VALUE,
        childValue: TAB_REPORT.STEGEN_CHILD_VALUE,
    };

    function buildChart(values: Array<number>) {
        if (!lineChart.current) {
            return;
        }
        chart = new Chartjs(lineChart.current, {
            type: "bar",
            data: {
                labels: labelConfig.labels,
                datasets: [
                    {
                        label: labelConfig.upperValue,
                        fill: false,
                        data: [10.0, 10.0, 10.0],
                        borderColor: "rgba(166, 202, 103, 1)",
                        backgroundColor: "rgba(166, 202, 103, 1)",
                        type: "line",
                    },
                    {
                        label: labelConfig.middleValue,
                        fill: false,
                        data: [8.0, 8.8, 8.6],
                        borderColor: "rgba(243, 230, 114, 1)",
                        backgroundColor: "rgba(243, 230, 114, 1)",
                        type: "line",
                    },
                    {
                        label: labelConfig.netherValue,
                        fill: false,
                        data: [5.8, 7.1, 6.6],
                        borderColor: "rgba(225, 141, 149, 1)",
                        backgroundColor: "rgba(225, 141, 149, 1)",
                        type: "line",
                    },
                    {
                        label: labelConfig.childValue,
                        data: values,
                        backgroundColor: "rgba(107, 118, 218, 1)",
                    },
                ],
            },
            plugins: [ChartDataLabels],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        suggestedMax: 12,
                    },
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        display: true,
                        position: "bottom",
                        align: "start",
                        labels: {
                            usePointStyle: true,
                            padding: 13,
                            font: {
                                size: 12,
                            },
                            boxHeight: 7,
                            boxWidth: 7,
                        },
                    },
                },
            },
        });
    }

    useEffect(() => {
        if (!beb) {
            return;
        }
        const values: Array<number> = [];
        for (const qid of STEGEN_QIDS) {
            const ans = beb.answers[qid];
            if (!ans?.answers?.[0]?.text?.[locales]) {
                values.push(0);
                continue;
            }

            // TODO: we should use weights for this
            values.push(Number(ans.answers[0].text[locales]));
        }
        buildChart(values);
        return () => {
            chart.destroy();
        };
    }, [beb]);

    return (
        <section className="chart-wrapper">
            <canvas ref={lineChart} />
        </section>
    );
};

export default StegenChart;
