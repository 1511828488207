import { pokeMessages } from "@ommej/metadata";
import type { Invitation } from "@ommej/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Accordion from "~/src/components/tools/accordion/accordion";
import type { AccordionProps } from "~/src/components/tools/accordion/accordion";
import NormalButton from "~/src/components/tools/buttons/normalButton/normalButton";
import SecondaryButton from "~/src/components/tools/buttons/secondaryButton/secondaryButton";
import Dropdown from "~/src/components/tools/dropdown/dropdown";
import type { DropdownProps } from "~/src/components/tools/dropdown/dropdown";
import Tooltip from "~/src/components/tools/tooltip/tooltip";
import CheckImg from "~/src/media/images/svg/case-created.svg";
import type { GlobalState } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import { QuestionMarkIcon } from "~/src/utils/icons";
import language from "~/src/utils/language";
import "./casePoke.css";

type CasePokeProps = {
    invitationId: Invitation.ToClient["id"];
};
const CasePoke = ({ invitationId }: CasePokeProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { POKE } = language[locales].CASE.EDIT_INVITATION;

    const [currentPoke, setCurrentPoke] = useState<keyof typeof pokeMessages | null>(null);
    const [pokeText, setPokeText] = useState<string | null>(null);
    const [showSentImage, setShowSentImage] = useState<boolean>(false);
    const [showAccordion, setShowAccordion] = useState<boolean>(false);

    const sendPoke = async () => {
        if (!currentPoke) {
            return;
        }
        try {
            await reqWithPath("invitations", "post", `${invitationId}/poke/${currentPoke}`);
            setShowSentImage(true);
            setCurrentPoke(null);
            setTimeout(() => {
                setShowSentImage(false);
            }, 5000);
        } catch (error) {
            console.error(error);
            // TODO handle error
        }
    };

    const onAccordionTransitionEnd: AccordionProps["onTransitionEnd"] = () => {
        let newPokeText: typeof pokeText = pokeText;
        if (!showAccordion) {
            // Accordion is now fully collapsed, set text to null
            newPokeText = null;
        }
        setPokeText(newPokeText);
    };

    useEffect(() => {
        let newPokeText: typeof pokeText = null;
        let shouldShowAccordion: typeof showAccordion = false;
        if (currentPoke && !showAccordion) {
            // new currentPoke selected while collpased accordion,
            // expand accordion and set new text
            shouldShowAccordion = true;
            newPokeText = pokeMessages[currentPoke].notification;
        } else if (!currentPoke && showAccordion) {
            // currentPoke reset to null while expanded accordion,
            // close accordion but keep text until fully collapsed
            shouldShowAccordion = false;
            newPokeText = pokeText;
        } else if (currentPoke && showAccordion) {
            // new currentPoke selected while expanded accordion,
            // keep accordion expanded and set new text
            shouldShowAccordion = true;
            newPokeText = pokeMessages[currentPoke].notification;
        }
        setShowAccordion(shouldShowAccordion);
        setPokeText(newPokeText);
    }, [currentPoke]);

    const setPoke = (value: string) => {
        if (pokeMessages[value]) {
            setCurrentPoke(value as keyof typeof pokeMessages);
        }
    };

    const getDropItems = (): DropdownProps["items"] => {
        return Object.entries(pokeMessages).map(([key, value]) => {
            return { value: key, text: value.admin };
        });
    };

    if (showSentImage) {
        return (
            <>
                <div className="casePoke-header">
                    <h3>{POKE.HEADING}</h3>
                    <Tooltip title={POKE.TOOLTIP} position="left">
                        <QuestionMarkIcon />
                    </Tooltip>
                </div>

                <div>
                    <img className="casePoke-done-img" src={CheckImg} alt="" />
                </div>
                <p className="casePoke-done-text">{POKE.MESSAGE_SENT}</p>
            </>
        );
    }
    return (
        <>
            <div className="casePoke-header">
                <h3>{POKE.HEADING}</h3>
                <Tooltip title={POKE.TOOLTIP} position="left">
                    <QuestionMarkIcon />
                </Tooltip>
            </div>
            <div className="casePoke-dropdown">
                <Dropdown
                    items={getDropItems()}
                    handleChange={setPoke}
                    value={!currentPoke ? undefined : currentPoke}
                />
            </div>
            {showAccordion && (
                <h4 style={{ marginBottom: "1rem" }} className="casePoke-text-header, text-grey">
                    {POKE.TEXT_HEADER}
                </h4>
            )}
            <Accordion onTransitionEnd={onAccordionTransitionEnd} accordionActive={showAccordion}>
                <div style={{ display: "flex" }}>
                    <p className="casePoke-text">&quot;</p>
                    <p className="casePoke-text">{`${pokeText}"`}</p>
                </div>
            </Accordion>
            {showAccordion && (
                <div className="casePoke-accordion-buttons">
                    <SecondaryButton
                        handleButton={() => {
                            setCurrentPoke(null);
                        }}
                        text={`${language[locales].CASE.EDIT_INVITATION.MOVE.CANCEL_BUTTON}`}
                    />
                    <NormalButton
                        text={POKE.BUTTON_ADD}
                        color="green"
                        handleButton={() => {
                            sendPoke();
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default CasePoke;
