import type { Invitation } from "@ommej/types";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import NormalButton from "~/src/components/tools/buttons/normalButton/normalButton";
import RoundButton from "~/src/components/tools/buttons/roundButton/roundButton";
import Textfield from "~/src/components/tools/form/textfield/textfield";
import Tooltip from "~/src/components/tools/tooltip/tooltip";
import CopyIcon from "~/src/media/images/svg/copy.svg";
import ExternalLinkIcon from "~/src/media/images/svg/external.svg";
import CheckIcon from "~/src/media/images/svg/success-arrow.svg";
import type { LanguageState } from "~/src/types";
import { reqWithPath, reqWithPayload } from "~/src/utils/api";
import { adminUrl } from "~/src/utils/constants";
import language from "~/src/utils/language";

export default function ManageExternalRefs({ invitation }: { invitation: Invitation.ToClient }) {
    enum STATE {
        INITIAL = 0,
        SET = 1,
        UNSET = 2,
        SETTING = 3,
        ERROR = 4,
    }

    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const eForm = useRef<HTMLFormElement>(null);
    const [validInput, setValidInput] = useState<boolean>(false);
    const [state, setState] = useState(STATE.INITIAL);
    const [prorenataId, setProrenataId] = useState<string>();

    const caseLink = `${adminUrl}/case/${invitation.id}`;
    const prorenataLink = `https://journal.prorenata.se/prn/issues/${prorenataId}`;

    function onSubmit(ev: React.FormEvent<HTMLFormElement>) {
        ev.preventDefault();
        if (ev.target instanceof HTMLFormElement) {
            const prorenataId = (ev.target as HTMLFormElement).prorenataId?.value;
            setState(STATE.SETTING);
            reqWithPayload(`invitations/${invitation.id}/refs/prorenata`, "put", {
                value: prorenataId,
            })
                .then(() => {
                    setState(STATE.SET);
                    setProrenataId(prorenataId);
                })
                .catch((err) => {
                    console.error(err);
                    setState(STATE.ERROR);
                });
        }
    }

    function onChange() {
        setValidInput(eForm?.current ? eForm.current.checkValidity() : false);
    }

    useEffect(() => {
        reqWithPath(`invitations/${invitation.id}/refs/prorenata`, "get", "")
            .then((res) => {
                return res.json();
            })
            .then((valueObj) => {
                if (valueObj?.value) {
                    setProrenataId(valueObj.value);
                    setState(STATE.SET);
                } else {
                    setState(STATE.UNSET);
                }
            })
            .catch((err) => {
                console.error(err);
                setState(STATE.ERROR);
            });
    }, []);

    return (
        <div className="caseKey-wrapper">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h3>{language[locales].COMMON.PRORENATA_MANAGE_HEADER}</h3>
                <Tooltip
                    title="Ange ärendets ID som det har i Prorenata för att enkelt kunna gå till journalen där."
                    position="bottom"
                >
                    <RoundButton icon="question" border={false} color="beige" />
                </Tooltip>
            </div>
            {state === STATE.SET ? (
                <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            fontSize: "0.85rem",
                            gap: "0.75rem",
                            justifyContent: "start",
                            marginBottom: "1rem",
                        }}
                    >
                        <span>
                            Prorenata-ID:{" "}
                            <a
                                href={prorenataLink}
                                style={{
                                    color: "var(--color-indigo)",
                                    fontWeight: "bold",
                                    textDecoration: "none",
                                }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {prorenataId}
                            </a>
                        </span>
                        <RoundButton
                            icon="pencil"
                            handleButton={() => {
                                setState(STATE.UNSET);
                            }}
                        />
                    </div>
                    <a
                        href={prorenataLink}
                        className="normalButton-wrapper normalButton-blue normalButton-icon"
                        style={{
                            textDecoration: "none",
                            color: "white",
                            width: "15rem",
                        }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={ExternalLinkIcon} alt="Extern länk" />
                        {language[locales].COMMON.PRORENATA_MANAGE_LINK}
                    </a>
                </div>
            ) : (
                <form
                    ref={eForm}
                    onSubmit={onSubmit}
                    style={{
                        display: "grid",
                        alignItems: "baseline",
                        gridTemplateColumns: "auto 4rem",
                        gap: "1.5rem",
                    }}
                >
                    <Textfield
                        type="text"
                        name="prorenataId"
                        text={language[locales].COMMON.PRORENATA_MANAGE_ADD_LINK}
                        handleChange={onChange}
                        required
                        pattern="[0-9]+"
                        inputError={
                            state === STATE.ERROR
                                ? language[locales].TOOLS.BASIC_ERROR_TEXT
                                : undefined
                        }
                    />
                    <NormalButton
                        type="submit"
                        text={language[locales].CASE.EDIT_INVITATION.KEY.SAVE_BUTTON}
                        disabled={!validInput}
                    />
                </form>
            )}

            <div>
                <h4 style={{ fontSize: "0.725rem", marginBottom: "0.5rem" }}>
                    Länk till detta Ommej-ärende
                </h4>
                <input
                    type="text"
                    readOnly
                    value={caseLink}
                    style={{
                        background: "var(--color-ice-grey)",
                        border: "none",
                        borderBottom: "2px solid var(--color-light-grey)",
                        color: "var(--color-midnight-indigo)",
                        padding: "0.25rem",
                    }}
                    onFocus={(ev) => ev.target.select()}
                />
                <button
                    type="button"
                    style={{
                        alignItems: "center",
                        background: "none",
                        cursor: "pointer",
                        display: "flex",
                        fontSize: "0.725rem",
                        gap: "0.5rem",
                        marginTop: "0.5rem",
                    }}
                    onClick={(ev) => {
                        navigator.clipboard.writeText(caseLink).then(() => {
                            if (!(ev.target instanceof HTMLButtonElement)) {
                                return;
                            }
                            const timeout = 2000;
                            const eImg = ev.target.querySelector("#copy-icon");
                            if (eImg instanceof HTMLImageElement) {
                                eImg.src = CheckIcon;
                                setTimeout(() => {
                                    eImg.src = CopyIcon;
                                }, timeout);
                            }
                            const eText = ev.target.querySelector("#copy-text");
                            if (eText instanceof HTMLSpanElement) {
                                eText.textContent =
                                    language[locales].CASE.ADD_INVITATION.SEND_INVITE.COPIED;
                                setTimeout(() => {
                                    eText.textContent =
                                        language[locales].COMMON.PRORENATA_MANAGE_COPY_LINK;
                                }, timeout);
                            }
                        });
                    }}
                >
                    <img
                        src={CopyIcon}
                        width="10"
                        alt="Kopiera"
                        id="copy-icon"
                        style={{ pointerEvents: "none" }}
                    />
                    <span
                        id="copy-text"
                        style={{
                            color: "var(--color-indigo)",
                            fontSize: "0.725rem",
                            pointerEvents: "none",
                        }}
                    >
                        {language[locales].COMMON.PRORENATA_MANAGE_COPY_LINK}
                    </span>
                </button>
            </div>
        </div>
    );
}
