import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UnitConfig from "~/src/components/admin/units/modals/components/unitConfig";
import UnitTags from "~/src/components/admin/units/modals/components/unitTags";
import ErrorComponent from "~/src/components/tools/errorCompnent/errorComponent";
import Types from "~/src/utils/types";
import { updateUnit } from "../../../../../../redux/actions/units/actions";
import language from "../../../../../../utils/language";
import BigButton from "../../../../../tools/buttons/bigButton/bigButton";
import Modal from "../../../../../tools/modal/modal";
import UnitAccess from "../../components/unitAccess";
import UnitMove from "../../components/unitMove";
import UnitName from "../../components/unitName";
import UnitSettings from "../../components/unitSettings";
import UnitUser from "../../components/unitUser";
import "./tabEditUnit.css";
import { checkIfUnitHasOwner } from "../../../../../../utils/unitFunctions";

const TabEditUnit = ({ unitObj, setUnitObj, show, setShow, userList, setTab, role }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const users = useSelector((state) => {
        return state.user.users;
    });
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState("");
    const [disabled, setDisabled] = useState(true);
    const unitHasOwner = checkIfUnitHasOwner(unitObj, users);
    const currentUser = useSelector((state) => {
        return state.login.currentUser;
    });

    useEffect(() => {
        if (!unitObj?.administrators?.length && !unitHasOwner) {
            setErrorMessage(language[locales].ADMIN.UNIT.EDIT_UNIT.NO_ADMIN_ERROR);
        } else {
            setDisabled(false);
        }
    }, [unitObj]);

    const sendUnit = async () => {
        if (unitObj) {
            setErrorMessage("");
            try {
                await dispatch(updateUnit(unitObj));
                setShow(!show);
            } catch (_err) {
                setErrorMessage(language[locales].TOOLS.BASIC_ERROR_TEXT);
            }
        }
    };

    return (
        <>
            <Modal.ModalBody>
                <div className="tabEditUnit-body-wrapper">
                    <div className="tabEditUnit-column">
                        <UnitName unitObj={unitObj} setUnitObj={setUnitObj} role={role} />
                        <UnitTags unitObj={unitObj} setUnitObj={setUnitObj} />
                        <UnitAccess
                            unitObj={unitObj}
                            setUnitObj={setUnitObj}
                            role={role}
                            setTab={setTab}
                        />
                        <UnitMove
                            unitObj={unitObj}
                            setUnitObj={setUnitObj}
                            role={role}
                            setShow={setShow}
                        />
                    </div>
                    <div className="tabEditUnit-column">
                        <UnitSettings unitObj={unitObj} show={show} setShow={setShow} role={role} />
                        <UnitUser setTab={setTab} userList={userList} role={role} />
                        {currentUser.roleCode === "SYSTEM_ADMIN" && (
                            <UnitConfig unitObj={unitObj} />
                        )}
                        {errorMessage && <ErrorComponent errorText={errorMessage} />}
                    </div>
                </div>
            </Modal.ModalBody>
            <Modal.ModalFooter>
                <BigButton
                    disabled={disabled}
                    text={language[locales].COMMON.DONE}
                    handleButton={() => {
                        sendUnit();
                    }}
                />
            </Modal.ModalFooter>
        </>
    );
};

TabEditUnit.propTypes = {
    unitObj: PropTypes.shape(Types.unit).isRequired,
    setUnitObj: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    userList: PropTypes.arrayOf(PropTypes.object).isRequired,
    role: PropTypes.string.isRequired,
};

export default TabEditUnit;
