import type { Beb2 } from "@ommej/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import Stegen from "./stegen";

type ConversationStegenProps = {
    beb: Beb2;
    className: string;
};

const ConversationStegen = ({ beb, className }: ConversationStegenProps) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { CONVERSATION } = language[locales];
    const [stegenData, setStegenData] = useState<number[] | null>();

    useEffect(() => {
        if (!beb.questionTags.STEGEN) {
            return;
        }
        const stegen: Array<number> = [];
        for (const qid of beb.questionTags.STEGEN) {
            const questionObject = beb.answers[qid];
            if (questionObject?.answers[0]?.type !== "text") {
                continue;
            }
            const number = Number(questionObject.answers[0].text?.sv);
            stegen.push(number);
        }
        setStegenData(stegen);
    }, [beb]);

    if (!stegenData) {
        return null;
    }
    return (
        <div className={`conversation-section-wrapper ${className}`}>
            <h2>{CONVERSATION.STEGEN_HEADER}</h2>
            <p>{CONVERSATION.STEGEN_SUBHEADER}</p>
            <Stegen chartData={stegenData} />
            <h3 className="conversation-sub-header-talk">{CONVERSATION.STEGEN_UP}</h3>
            <h3 className="conversation-sub-header-talk">{CONVERSATION.STEGEN_REASON}</h3>
            <h3 className="conversation-sub-header-talk">{CONVERSATION.STEGEN_LAST_YEAR}</h3>
        </div>
    );
};

export default ConversationStegen;
