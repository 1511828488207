import type { Invitation } from "@ommej/types";

import {
    ADD_INVITATION,
    BUILD_INVITATION_OBJECT,
    CLOSE_INVITATION,
    DELETE_INVITATION,
    GET_ALL_INVITATIONS,
    INVITATION_ERROR,
    LOADING_INVITATION,
    UPDATE_INVITATION,
} from "../actions/invitation/type";

type InvitationState = {
    invitations: Invitation.ToClient[];
    invitationObj: { [key: Invitation.ToClient["id"]]: Invitation.ToClient };
    loading: boolean;
    loaded: boolean;
    error: null;
};

const initialState = {
    invitations: [],
    invitationObj: {},
    loading: false,
    loaded: false,
    error: null,
};

/* Here we basically vouch for the stuff we put in invitations state.
 * It should be validated when collected from the outside world,
 * e.g. backend.
 */
const invitationReducer = (currentState, action): InvitationState => {
    const state = currentState || initialState;
    switch (action.type) {
        case LOADING_INVITATION:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_INVITATIONS:
            return {
                ...state,
                invitations: action.payload,
                loading: false,
                error: null,
            };
        case BUILD_INVITATION_OBJECT:
            return {
                ...state,
                invitationObj: state.invitations.reduce((acc, inv) => {
                    // biome-ignore lint/performance/noAccumulatingSpread: allow for now
                    return { ...acc, [inv.id]: inv };
                }, {}),
                error: null,
                loading: false,
                loaded: true,
            };
        case ADD_INVITATION:
            return {
                ...state,
                invitations: [...state.invitations, action.payload],
                loading: false,
                error: null,
            };
        case DELETE_INVITATION:
            return {
                ...state,
                invitations: state.invitations.filter((invitation) => {
                    return invitation.id !== action.payload.id;
                }),
                loading: false,
                error: null,
            };
        case CLOSE_INVITATION:
            return {
                ...state,
                invitations: state.invitations.filter((invitation) => {
                    return invitation.id !== action.payload;
                }),
                loading: false,
                error: null,
            };
        case UPDATE_INVITATION:
            return {
                ...state,
                invitations: state.invitations.map((invitation) => {
                    return invitation.id === action.payload.id ? action.payload : invitation;
                }),
                loading: false,
                error: null,
            };
        case INVITATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default invitationReducer;
