// @ts-check

//import EN from "./en.json";
import SE from "./sv.json";

const language = {
    //en: EN,
    sv: SE,
};

export default language;
