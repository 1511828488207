import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { roles } from "../../../../../utils/constants";
import language from "../../../../../utils/language";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import RadioButton from "../../../../tools/form/radio/radioButton";
import "./userCompetence.css";

const UserCompetence = ({ user, setUser }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });

    const handleRadio = (e) => {
        setUser({ ...user, roleCode: e.target.value });
    };

    return (
        <FluidWrapper height="hg-small">
            <h3>{language[locales].ADMIN.USER.ADD_USER.COMPETENCE}</h3>
            <div className="userCompetence-wrapper">
                <RadioButton
                    id="1"
                    label={language[locales].ADMIN.USER.ADD_USER.RADIO1}
                    value={roles.user}
                    checked={user.roleCode === roles.user}
                    handleRadio={(e) => {
                        return handleRadio(e);
                    }}
                >
                    {language[locales].ADMIN.USER.ADD_USER.RADIO1}
                </RadioButton>
                <RadioButton
                    id="2"
                    label={language[locales].ADMIN.USER.ADD_USER.RADIO2}
                    value={roles.administrator}
                    checked={user.roleCode === roles.administrator}
                    handleRadio={(e) => {
                        return handleRadio(e);
                    }}
                >
                    {language[locales].ADMIN.USER.ADD_USER.RADIO1}
                </RadioButton>
            </div>
        </FluidWrapper>
    );
};

UserCompetence.propTypes = {
    user: PropTypes.shape({
        _id: PropTypes.string,
        roleCode: PropTypes.string,
        email: PropTypes.string,
        username: PropTypes.string,
        unit: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Types.unit)]),
    }).isRequired,
    setUser: PropTypes.func.isRequired,
};

export default UserCompetence;
