import { houseStrings, personTypes } from "@ommej/metadata";
import type { QuestionObject, Uuid } from "@ommej/types";
import type { ClientInfo } from "../types";
import type { LanguageLocales } from "./ts-types";

export function ensureArray<Type>(value: Type | Array<Type>): Array<Type> {
    return Array.isArray(value) ? value : [value];
}

function getPersonalQuestion(
    profile: ClientInfo["profile"],
    question: QuestionObject["question"],
    personId: Uuid,
    locale: LanguageLocales,
) {
    if (!question || !question.text || !profile) {
        return "";
    }
    const q = profile.persons?.[personId]?.type
        ? question.text.sv.replaceAll(
              "%data%",
              // biome-ignore lint/style/noNonNullAssertion: checked before usage
              personTypes[profile.persons[personId]!.type!]?.lang[locale] ?? "",
          )
        : question.text.sv;
    return q;
}

export function getQuestionAndAnswersStrings(
    profile: ClientInfo["profile"],
    questionObject: QuestionObject,
    locale: LanguageLocales,
) {
    const result: Array<{
        question: string;
        answers: Array<string>;
    }> = [];

    switch (questionObject.question.type) {
        case "frequency":
        case "single":
        case "rate":
        case "multiple": {
            const answers = questionObject.answers.map((answer) => {
                return answer.text?.[locale];
            });
            result.push({
                question: questionObject.question.text[locale],
                answers,
            });
            break;
        }
        case "persons":
        case "accommodations": {
            const answers: string[] = [];
            for (const answer of questionObject.answers) {
                if (answer.type === "text") {
                    answers.push(answer.text?.[locale]);
                    continue;
                }

                for (const resourceRef of answer.data) {
                    const type =
                        profile?.[answer.type === "persons" ? "persons" : "houses"][resourceRef]
                            ?.type;
                    if (!type) {
                        continue;
                    }

                    // XXX: should we do this conversation from type to a friendly
                    // string, or let the caller handle the type string? Maybe the
                    // caller wants to show an avatar instead of a string.
                    const stringResource = answer.type === "persons" ? personTypes : houseStrings;
                    const typeString = stringResource[type].lang[locale];
                    answers.push(typeString);
                }
            }
            result.push({
                question: questionObject.question.text[locale],
                answers,
            });
            break;
        }

        case "single_person":
        case "multiple_person":
        case "single_accommodation":
            {
                const refAnswers = new Map<Uuid /* resourceRef */, string[] /* answerTexts */>();
                const handledRefs = new Set<Uuid>();
                for (const answer of questionObject.answers) {
                    for (const resourceRef of answer.data) {
                        handledRefs.add(resourceRef);
                        if (answer.type === "text") {
                            if (refAnswers.has(resourceRef)) {
                                // biome-ignore lint/style/noNonNullAssertion: checked before usage
                                refAnswers.get(resourceRef)!.push(answer.text?.[locale]);
                            } else {
                                refAnswers.set(resourceRef, [answer.text?.[locale]]);
                            }
                        }
                    }
                }
                for (const resourceRef of handledRefs) {
                    const questionString = getPersonalQuestion(
                        profile,
                        questionObject.question,
                        resourceRef,
                        locale,
                    );
                    result.push({
                        question: questionString,
                        answers: refAnswers.get(resourceRef) || [],
                    });
                }
            }
            break;

        default:
            break;
    }

    return result;
}
