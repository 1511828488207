import PropTypes, { string } from "prop-types";
import "./fluidWrapper.css";

const FluidWrapper = ({ children, className, padding, background, height, customStyle }) => {
    return (
        <div
            className={`fluidwrapper-container ${className} ${padding} ${background} ${height}`}
            style={customStyle}
        >
            {children}
        </div>
    );
};

FluidWrapper.defaultProps = {
    className: null,
    padding: "pad-normal",
    background: "bg-white",
    height: "hg-normal",
    customStyle: null,
};

FluidWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    padding: PropTypes.string,
    background: PropTypes.string,
    height: PropTypes.string,
    customStyle: PropTypes.objectOf(string),
};

export default FluidWrapper;
