import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { getAllUnits } from "../../../../../redux/actions/units/actions";
import language from "../../../../../utils/language";
import FluidWrapper from "../../../../tools/fluidWrapper/fluidWrapper";
import SearchInput from "../../../../tools/form/search/searchInput";
import SearchUnitCard from "../../cards/searchUnitCard";
import "./userUnit.css";

const UserUnit = ({ user, setUser }) => {
    const units = useSelector((state) => {
        return state.unit.units;
    });
    const unitsLoaded = useSelector((state) => {
        return state.unit.loaded;
    });
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [filteredUnits, setFilteredUnits] = useState(
        Object.keys(user.unit).length === 0 ? units : [user.unit],
    );
    const [searchValue, setSearchValue] = useState("");
    const [isActive, setIsActive] = useState(Object.keys(user.unit).length !== 0);
    const [errorMessage, setErrorMessage] = useState(null);
    const dispatch = useDispatch();
    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        const search = e.target.value.toLowerCase();
        setFilteredUnits(
            units.filter((unit) => {
                return unit.name?.toLowerCase().includes(search);
            }),
        );
    };

    const handleButton = (unit) => {
        setIsActive(true);
        setFilteredUnits([unit]);
        setUser({ ...user, unit });
        setSearchValue("");
    };

    const handleRoundButton = () => {
        setIsActive(false);
        setFilteredUnits(units);
        setUser({ ...user, unit: {} });
        setSearchValue("");
    };

    let isMounted = true;
    const loadResources = async () => {
        if (!unitsLoaded) {
            try {
                const loadedUnits = await dispatch(getAllUnits());
                if (isMounted) {
                    setFilteredUnits(loadedUnits);
                    setErrorMessage(null);
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };

    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    if (!user) {
        return (
            <FluidWrapper height="hg-small">
                {language[locales].ADMIN.USER.ADD_USER.USER_ERROR}
            </FluidWrapper>
        );
    }

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    if (!unitsLoaded) {
        return null;
    }
    return (
        <FluidWrapper customStyle={{ height: "32.5rem" }}>
            <h3>{language[locales].ADMIN.USER.ADD_USER.UNIT}</h3>
            <div className="userUnit-wrapper">
                <SearchInput
                    name="search"
                    placeholder={language[locales].ADMIN.USER.ADD_USER.SEARCH}
                    value={searchValue}
                    handleChange={(e) => {
                        handleSearch(e);
                    }}
                />
            </div>
            <div className="userUnit-card-wrapper">
                <p className="userUnit-text text-description">
                    {isActive
                        ? language[locales].ADMIN.USER.ADD_USER.SELECTED_UNIT
                        : language[locales].ADMIN.USER.ADD_USER.CHOOSE_UNIT}
                </p>
                <div className="userUnit-noscroll-wrapper">
                    {filteredUnits.map((unit) => {
                        return (
                            unit.name && (
                                <SearchUnitCard
                                    key={unit._id}
                                    unit={unit}
                                    handleClick={() => {
                                        handleButton(unit);
                                    }}
                                    handleRoundButton={handleRoundButton}
                                    isActive={isActive}
                                />
                            )
                        );
                    })}
                </div>
            </div>
        </FluidWrapper>
    );
};

UserUnit.propTypes = {
    user: PropTypes.shape({
        _id: PropTypes.string,
        roleCode: PropTypes.string,
        email: PropTypes.string,
        username: PropTypes.string,
        unit: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Types.unit)]),
    }).isRequired,
    setUser: PropTypes.func.isRequired,
};

export default UserUnit;
