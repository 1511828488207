import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import errorImg from "../../../media/images/png/errorImg.png";
import close from "../../../media/images/svg/close.svg";
import language from "../../../utils/language/index";
import GhostButton from "../buttons/ghostButton/ghostButton";
import "./errorComponent.css";

const ErrorComponent = ({ errorText, onClose }) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const [show, setShow] = useState(true);

    const hideErrorComponent = () => {
        setShow(!show);
        if (onClose) {
            onClose();
        }
    };

    if (!show) {
        return null;
    }

    return (
        <div className="errorComponent-overlay">
            <section className="errorComponent-wrapper">
                <button
                    className="errorComponent-close-button"
                    type="button"
                    onClick={hideErrorComponent}
                >
                    <img src={close} alt="close modal" />
                </button>
                <img src={errorImg} className="errorComponent-img" alt="error" />
                <h3>{errorText || language[locales].TOOLS.BASIC_ERROR_TEXT}</h3>
                <GhostButton
                    text={language[locales].TOOLS.ERROR_COMPONENT_BUTTON}
                    handleGhostButton={hideErrorComponent}
                />
            </section>
        </div>
    );
};

ErrorComponent.defaultProps = {
    errorText: undefined,
    onClose: undefined,
};

ErrorComponent.propTypes = {
    errorText: PropTypes.string,
    onClose: PropTypes.func,
};

export default ErrorComponent;
