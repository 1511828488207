import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { userRoles } from "../../../../utils/constants";
import language from "../../../../utils/language";
import GhostButton from "../../../tools/buttons/ghostButton/ghostButton";
import RoundButton from "../../../tools/buttons/roundButton/roundButton";
import "./searchUserCard.css";

const SearchUserCard = ({ handleUser, user, isActive, handleRoundButton }) => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const [showButton, setShowButton] = useState(false);

    return (
        <>
            <section
                className="searchUserCard-button-wrapper"
                onMouseEnter={() => {
                    setShowButton(true);
                }}
                onMouseLeave={() => {
                    setShowButton(false);
                }}
            >
                <div className="searchUserCard-main-wrapper">
                    <div>
                        <p>{user.username}</p>
                        <p className="text-description text-grey">{`${userRoles[user.roleCode]} - ${
                            user.unit.name
                        }`}</p>
                    </div>
                    {isActive ? (
                        <RoundButton
                            className="searchUserCard-roundButton"
                            icon="cancel"
                            handleButton={handleRoundButton}
                        />
                    ) : (
                        showButton && (
                            <GhostButton
                                tabIndex="0"
                                text={language[locales].ADMIN.UNIT.ADMIN_CARDS.SEARCH_USER_CARD}
                                handleGhostButton={handleUser}
                            />
                        )
                    )}
                </div>
            </section>
            <span className="searchUserCard-span-border" />
        </>
    );
};

SearchUserCard.propTypes = {
    handleUser: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    handleRoundButton: PropTypes.func.isRequired,
    user: PropTypes.shape({
        _id: PropTypes.string,
        email: PropTypes.string,
        unit: PropTypes.shape(Types.unit),
        username: PropTypes.string,
        roleCode: PropTypes.string,
    }).isRequired,
};

export default SearchUserCard;
