import { AnswersViewer } from "@ommej/componente";
import type { Form } from "@ommej/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BreadCrumbs from "~/src/components/tools/breadCrumbs";
import type { DropdownItemsArray, OptionItem } from "~/src/components/tools/dropdown/dropdown";
import Dropdown from "~/src/components/tools/dropdown/dropdown";
import FluidWrapper from "~/src/components/tools/fluidWrapper/fluidWrapper";
import type { LanguageState, StatsAnswerCount } from "~/src/types";
import { reqWithPath } from "~/src/utils/api";
import language from "~/src/utils/language";

function getDropDownItems(forms: Form.Metadata[]): DropdownItemsArray {
    const dropdownItems: DropdownItemsArray = forms.reduce(
        (workingCopy: DropdownItemsArray, item: Form.Metadata) => {
            if (!item.shareable && !item.mutable) {
                const oI: OptionItem = {
                    value: `${item.id}-${item.version}`,
                    text: item.name,
                };
                workingCopy.push(oI);
            }
            return workingCopy;
        },
        [],
    );
    return dropdownItems.sort((a, b) => {
        return a.text.localeCompare(b.text);
    });
}

const StatisticsForms = () => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const { FORMS } = language[locales];
    const [form, setForm] = useState<Form.FormData>();
    const [availableForms, setAvailableForms] = useState<Form.Metadata[]>([]);
    const [stats, setStats] = useState<StatsAnswerCount>();
    const [dropDownItems, setDropDownItems] = useState<DropdownItemsArray>([]);
    const [currentForm, setCurrentForm] = useState<Form.Metadata>();

    useEffect(() => {
        const getAvailableForms = async () => {
            try {
                const req = await reqWithPath("data/forms", "get", "");
                const formsMetadata: Form.Metadata[] = await req.json();
                setAvailableForms(formsMetadata);
                const items = getDropDownItems(formsMetadata);
                setDropDownItems(items);
            } catch (error) {
                console.error(error);
            }
        };
        getAvailableForms();
    }, []);

    useEffect(() => {
        const getForm = async () => {
            if (!currentForm) {
                return;
            }
            try {
                const req = await reqWithPath(
                    `data/forms/${currentForm.id}/${currentForm.version}`,
                    "get",
                    "",
                );
                const formData = await req.json();
                setForm(formData);
            } catch (error) {
                console.error(error);
            }
        };

        const getStats = async () => {
            if (!currentForm) {
                return;
            }
            try {
                const req = await reqWithPath(
                    `statistics/${currentForm.id}/${currentForm.version}`,
                    "post",
                    "",
                );
                const statsRes = await req.json();
                setStats(statsRes);
            } catch (error) {
                console.error(error);
            }
        };

        getForm();
        getStats();
    }, [currentForm]);

    if (!dropDownItems) {
        return null;
    }

    return (
        <div className="app-component-container">
            <BreadCrumbs />
            <FluidWrapper height="hg-small" padding="pad-no-bottom">
                <Dropdown
                    items={dropDownItems}
                    handleChange={(value) => {
                        const selectedform = availableForms.find((item: Form.Metadata) => {
                            return value === `${item.id}-${item.version}`;
                        });
                        setCurrentForm(selectedform);
                    }}
                    value={!currentForm ? undefined : `${currentForm.id}-${currentForm.version}`}
                />
                {form && stats && (
                    <>
                        <div style={{ marginTop: "1.5rem" }} className="showFollowUp-header">
                            <div>
                                <h3 style={{ display: "inline" }}>
                                    {FORMS.HEADING}: {form.metadata.name}
                                </h3>
                            </div>
                        </div>
                        <div className="showFollowUp-content" style={{ height: "initial" }}>
                            <AnswersViewer
                                form={form}
                                answersData={{ format: "stats", data: stats }}
                            />
                        </div>
                    </>
                )}
            </FluidWrapper>
        </div>
    );
};

export default StatisticsForms;
