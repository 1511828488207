import PropTypes, { string } from "prop-types";
import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoutingState } from "~/src/utils/systemFunctions";
import close from "../../../media/images/svg/close.svg";
import { route } from "../../../utils/constants";
import ModalBody from "./modalBody";
import ModalFooter from "./modalFooter";
import ModalHeader from "./modalHeader";
import "./modal.css";

const Modal = ({ show, setShow, type, style, shouldRoute, children }) => {
    const modal = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);

    useEffect(() => {
        if (show) {
            if (document.body.clientHeight > window.innerHeight) {
                document.body.style.overflow = "hidden";
                document.body.style.paddingRight = "8px";
            }
        }
        return () => {
            document.body.style.overflow = "unset";
            document.body.style.paddingRight = "0px";
        };
    }, [show]);

    const hideModal = () => {
        if (!shouldRoute) {
            return setShow(!show);
        }
        setShow(!show);
        const options = { state: routingState };
        if (location.state?.from) {
            return navigate(location.state.from, options);
        }
        return location.pathname?.includes("admin")
            ? navigate(route.ADMIN, options)
            : navigate(route.ROOT, options);
    };

    const clickOutside = (e) => {
        if (modal.current !== null) {
            if (modal.current.contains(e.target)) {
                return;
            }
        }
        hideModal();
    };

    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
    }, []);

    return ReactDOM.createPortal(
        show && (
            <div className="overlay">
                <section className={`modal-container ${type}`} ref={modal} style={style}>
                    <button className="modal-close-button" type="button" onClick={hideModal}>
                        <img src={close} alt="close modal" />
                    </button>
                    {children}
                </section>
            </div>
        ),
        document.getElementById("modal"),
    );
};

Modal.ModalHeader = ModalHeader;
Modal.ModalFooter = ModalFooter;
Modal.ModalBody = ModalBody;

Modal.defaultProps = {
    type: "mobile",
    style: null,
    shouldRoute: true,
};

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    type: PropTypes.string,
    style: PropTypes.objectOf(string),
    shouldRoute: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default Modal;
