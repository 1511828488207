"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenderAvatarType = exports.AvatarPartConstants = void 0;
var AvatarPartConstants;
(function (AvatarPartConstants) {
    AvatarPartConstants["HAIR"] = "HAIR";
    AvatarPartConstants["CLOTHES"] = "CLOTHES";
    AvatarPartConstants["HEADS"] = "HEADS";
    AvatarPartConstants["EYES"] = "EYES";
    AvatarPartConstants["NOSES"] = "NOSES";
    AvatarPartConstants["MOUTHS"] = "MOUTHS";
    AvatarPartConstants["BEARDS"] = "BEARDS";
    AvatarPartConstants["EYEWEARS"] = "EYEWEARS";
    AvatarPartConstants["HEADWEARS"] = "HEADWEARS";
    AvatarPartConstants["ACCESSORIES"] = "ACCESSORIES";
})(AvatarPartConstants || (exports.AvatarPartConstants = AvatarPartConstants = {}));
var RenderAvatarType;
(function (RenderAvatarType) {
    RenderAvatarType["THUMBNAIL"] = "THUMBNAIL";
})(RenderAvatarType || (exports.RenderAvatarType = RenderAvatarType = {}));
