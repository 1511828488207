import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import BreadCrumbs from "~/src/components/tools/breadCrumbs";
import { getRoutingState } from "~/src/utils/systemFunctions";
import { getAllUnits } from "../../redux/actions/units/actions";
import type { RootState } from "../../redux/reducers/rootReducer";
import { roles, route } from "../../utils/constants";
import { GroupBigIcon, OrganizationBigIcon, PersonBigIcon } from "../../utils/icons";
import language from "../../utils/language/index";
import SquareWrapper from "../tools/squareWrapper/squareWrapper";
import "./admin.css";

const Admin = () => {
    const units = useSelector((state: RootState) => {
        return state.unit.units;
    });
    const currentUser = useSelector((state: RootState) => {
        return state.login.currentUser;
    });
    const unitsLoaded = useSelector((state: RootState) => {
        return state.unit.loaded;
    });
    const { locales } = useSelector((state: RootState) => {
        return state.language;
    });
    const [adminAccess, setAdminAccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>("");
    const dispatch = useDispatch();
    const location = useLocation();
    const routingState = getRoutingState(location);

    let isMounted = true;
    const loadResources = async () => {
        if (!unitsLoaded) {
            try {
                await dispatch(getAllUnits());
                if (isMounted) {
                    setErrorMessage(null);
                }
            } catch (_err) {
                if (isMounted) {
                    setErrorMessage(language[locales].COMPONENT.ERROR_RESOURCE_LOADING);
                }
            }
        }
    };
    useEffect(() => {
        loadResources();
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        let administrator = false;
        if ([roles.administrator, roles.systemAdmin].includes(currentUser.roleCode)) {
            setAdminAccess(true);
        } else {
            administrator = units.some((unit) => {
                return unit.administrators.some((admin) => {
                    return admin === currentUser._id;
                });
            });
            setAdminAccess(administrator);
        }
    }, [units]);

    if (errorMessage) {
        return <p>{errorMessage}</p>;
    }
    if (!unitsLoaded) {
        return null;
    }
    return (
        <div className="app-component-container">
            <BreadCrumbs />
            {adminAccess && (
                <>
                    <SquareWrapper>
                        <Link className="big-link-card" to={route.ADMIN_UNITS} state={routingState}>
                            <div className="admin-icon-organization">
                                <OrganizationBigIcon />
                            </div>
                            <h2>{language[locales].ADMIN.HANDLE_UNIT}</h2>
                        </Link>
                    </SquareWrapper>
                    <SquareWrapper>
                        <Link className="big-link-card" to={route.ADMIN_USERS} state={routingState}>
                            <div className="admin-icon-user">
                                <GroupBigIcon />
                            </div>
                            <h2>{language[locales].ADMIN.HANDLE_USER}</h2>
                        </Link>
                    </SquareWrapper>
                </>
            )}
            {currentUser.roleCode !== roles.systemAdmin && (
                <SquareWrapper>
                    <Link className="big-link-card" to={route.ADMIN_PROFILE} state={routingState}>
                        <div className="admin-icon-profile">
                            <PersonBigIcon />
                        </div>
                        <h2>{language[locales].ADMIN.MY_PROFILE}</h2>
                    </Link>
                </SquareWrapper>
            )}
        </div>
    );
};

export default Admin;
