import PropTypes from "prop-types";
import "./modalFooter.css";

const ModalFooter = ({ children }) => {
    return <footer className="modalFooter-wrapper">{children}</footer>;
};

ModalFooter.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ModalFooter;
