import type { Beb2, Form, QuestionId } from "@ommej/types";
import { useSelector } from "react-redux";
import type { GlobalState } from "~/src/types";
import language from "~/src/utils/language";
import type { ClientData } from "./caseDetails";
import QuestionSection from "./questionSection";
import { getQuestionTagImage } from "./utils";

const ConversationAnswers = ({
    beb,
    client,
    questionTagsToInclude,
    filter = undefined,
    marked = undefined,
    className = "",
    showOthers = false,
    openAll = false,
}: {
    beb: Beb2;
    client: ClientData["info"];
    questionTagsToInclude: Array<Form.QuestionTags>;
    className?: string;
    filter?: Set<QuestionId>;
    marked?: Set<QuestionId>;
    showOthers?: boolean;
    openAll?: boolean;
}) => {
    const { locales } = useSelector((state: GlobalState) => {
        return state.language;
    });
    const { QUESTION_TAGS } = language[locales];

    return (
        <div className={className}>
            {questionTagsToInclude.map((tag) => {
                return (
                    <QuestionSection
                        id={tag}
                        key={`conversationAnswers-${tag}`}
                        beb={beb}
                        client={client}
                        questionsToInclude={new Set(beb.questionTags[tag] || [])}
                        header={QUESTION_TAGS[tag]}
                        icon={getQuestionTagImage(client, tag)}
                        filter={filter}
                        marked={marked}
                        showOthers={showOthers}
                        openSection={openAll}
                    />
                );
            })}
        </div>
    );
};

export default ConversationAnswers;
