import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoutingState } from "~/src/utils/systemFunctions";
import { addUser } from "../../../../../redux/actions/users/actions";
import { roles, route } from "../../../../../utils/constants";
import language from "../../../../../utils/language";
import BigButton from "../../../../tools/buttons/bigButton/bigButton";
import Modal from "../../../../tools/modal/modal";
import UserCompetence from "../components/userCompetence";
import UserCredentials from "../components/userCredentials";
import UserUnit from "../components/userUnit";
import "./addUser.css";

const AddUser = () => {
    const { locales } = useSelector((state) => {
        return state.language;
    });
    const { ADD_USER } = language[locales].ADMIN.USER;
    const users = useSelector((state) => {
        return state.user.users;
    });
    const dispatch = useDispatch();
    const [resolution] = useState(window.innerWidth);
    const [errorMess, setErrorMess] = useState("");
    const [show, setShow] = useState(true);
    const [user, setUser] = useState({
        username: "",
        email: "",
        roleCode: roles.user,
        unit: {},
    });
    const navigate = useNavigate();
    const location = useLocation();
    const routingState = getRoutingState(location);

    const sendUser = async () => {
        // get two api request due to backend dont return full unitobj //
        if (user.username.length > 2 && Object.keys(user.unit).length) {
            const testEmail = users.some((userItem) => {
                return userItem.email === user.email;
            });
            if (testEmail) {
                setErrorMess(ADD_USER.DUPLICATE_USER);
            } else {
                const sendObj = { ...user, unit: user.unit._id };
                try {
                    await dispatch(addUser(sendObj));
                    const options = { state: routingState };
                    navigate(route.ADMIN_USERS, options);
                } catch (_err) {
                    setErrorMess(ADD_USER.GENERAL_ERROR);
                }
            }
        } else {
            setErrorMess("Något blev fel i formuläret");
        }
    };

    return (
        <Modal show={show} setShow={setShow} type={resolution < 850 ? "mobile" : "desktop"}>
            <Modal.ModalHeader>
                <h3>{ADD_USER.HEADING}</h3>
            </Modal.ModalHeader>
            <Modal.ModalBody>
                <div className="addUser-row-wrapper">
                    <div className="addUser-column">
                        <UserCredentials setUser={setUser} user={user} />
                        <UserCompetence setUser={setUser} user={user} />
                        <p className="addUser-error-text" style={{ margin: "0.5rem 1rem" }}>
                            {errorMess}
                        </p>
                    </div>
                    <div className="addUser-column">
                        <UserUnit setUser={setUser} user={user} />
                    </div>
                </div>
            </Modal.ModalBody>
            <Modal.ModalFooter>
                <BigButton
                    text={ADD_USER.CREATE_BUTTON}
                    color="green"
                    handleButton={sendUser}
                    disabled={user.username.length < 2 || Object.keys(user.unit).length === 0}
                />
            </Modal.ModalFooter>
        </Modal>
    );
};

export default AddUser;
