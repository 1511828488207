import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import type { LanguageState } from "~/src/types";
import language from "../../../utils/language";
import "./dropdown.css";

export type OptionItem = { value: string | number; text: string };
export type DropdownItemsObject = Record<string, OptionItem>;
export type DropdownItemsArray = Array<OptionItem>;

export type DropdownProps = {
    items: DropdownItemsObject | DropdownItemsArray;
    handleChange: (value: string) => void;
    value?: string | undefined;
    attrs?: object;
    theme?: "bb";
};

const OptGroup = ({ label, items }: { label: string; items: DropdownItemsArray }) => {
    return (
        <optgroup label={label} key={label}>
            {items.map((item) => {
                return (
                    <option value={item.value} key={item.value}>
                        {item.text}
                    </option>
                );
            })}
        </optgroup>
    );
};

// TODO: Fix proper type handling below.
type ItemValue = { value?: string; text?: string };
const getFromObject = (items: { string: ItemValue }) => {
    const res = Object.entries(items).map(([key, val]) => {
        if (Array.isArray(val)) {
            return <OptGroup label={key} items={val} key={key} />;
        }
        if (val.text && val.value) {
            return (
                <option value={val.value} key={val.value}>
                    {val.text}
                </option>
            );
        }
        return (
            <option value={val.value} key={val.value}>
                {val.text}
            </option>
        );
    });
    return res;
};

const getOptions = (items) => {
    if (Array.isArray(items)) {
        return items.map((item) => {
            return (
                <option value={item.value} key={item.value}>
                    {item.text}
                </option>
            );
        });
    }
    return getFromObject(items);
};

const isEmptyArrayOrObject = (items) => {
    return (
        items?.length === 0 ||
        (Object.getPrototypeOf(items) === Object.prototype && Object.keys(items).length === 0)
    );
};

const Dropdown = ({ items, handleChange, value, attrs = {}, theme }: DropdownProps) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const eSelect = useRef<HTMLSelectElement>(null);

    const defaultValue = "DEFAULT";

    useEffect(() => {
        if (value !== undefined) {
            return;
        }
        if (!eSelect.current) {
            return;
        }
        // Without this, if items is updated, the first enabled option would be
        // selected and not possible to pick. Instead we set the selected option
        // here to the disabled placeholder option
        eSelect.current.value = defaultValue;
    }, [items, value]);

    return (
        <div className="dropdown-wrapper">
            {isEmptyArrayOrObject(items) ? (
                <select
                    {...attrs}
                    className={`dropdown-select ${theme === "bb" ? "dropdown-bb" : "dropdown-normal"}`}
                    ref={eSelect}
                    value={value}
                    disabled
                >
                    <option value={defaultValue}>
                        {language[locales].TOOLS.DROPDOWN_NO_OPTION}
                    </option>
                </select>
            ) : (
                <select
                    {...attrs}
                    className={`dropdown-select ${theme === "bb" ? "dropdown-bb" : "dropdown-normal"}`}
                    ref={eSelect}
                    value={value}
                    onChange={(ev) => {
                        handleChange(ev.target.value);
                    }}
                >
                    <option value={defaultValue} disabled>
                        {language[locales].TOOLS.DROPDOWN}
                    </option>
                    {getOptions(items)}
                </select>
            )}
        </div>
    );
};

export default Dropdown;
