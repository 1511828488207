// @ts-check
import { genderTypes } from "@ommej/metadata";

/**
 * @param {object} form
 * @param {string} answerId
 * @param {string} questionId
 * @param {import('../../utils/ts-types').LanguageLocales} locale
 * @returns {string}
 */
export const getAnswerDescription = (form, answerId, questionId, locale) => {
    if (questionId === "gender") {
        return genderTypes[answerId]?.[locale] || "okänt";
    }

    if (questionId === "birthYear") {
        // null as string is correct
        return answerId === "null" ? "okänt" : `${answerId} år`;
    }

    if (questionId === "age") {
        // null as string is correct
        return answerId === "null" ? "okänd" : `${answerId} år`;
    }

    const questionItem = form.questions[questionId];

    if (!questionItem) {
        console.error("Could not resolve answer description for question:", questionId);
        return "";
    }

    switch (questionItem.answers?.[answerId]?.type) {
        case "text":
            return questionItem.answers[answerId]?.text?.[locale] || "-";
        case "persons":
            return "Någon person";
        case "accommodations":
            return "Något boende";
        case "none":
            return "Inget svar";
        default:
            return "-";
    }
};

/**
 * @param {import('../../utils/forms/schema').FormsQuestion} question
 * @param {import('../../utils/ts-types').LanguageLocales} locale
 * @returns {string}
 */
const getQuestionStatement = (question, locale) => {
    return (question.statementText || question.text)[locale] || "";
};

/**
 * @param {any} form
 * @param {string} questionId
 * @param {import('../../utils/ts-types').LanguageLocales} locale
 * @returns {string}
 */
export const getQuestionName = (form, questionId, locale) => {
    // TODO: translations, unclear how, though...
    if (questionId === "gender") {
        return "Könstillhörighet vs ålder";
    }
    if (questionId === "birthyear") {
        return "Ålder vs könstillhörighet";
    }

    const question = form.questions[questionId];

    if (!question) {
        return questionId;
    }

    return `${questionId}: "${getQuestionStatement(question, locale)}"`;
};

/**
 * @param {any} form
 * @param {import('../../utils/ts-types').LanguageLocales} locale
 * @param {object} language
 * @returns {Map}
 */
export const getQuestions = (form, locale, language) => {
    if (!form) {
        return new Map();
    }

    /** @type {Record<string, import('../tools/dropdown/dropdown').OptionItem[]>} */
    const result = {};

    // TODO: translations, unclear how, though...
    result.METADATA = [];
    result.METADATA.push({
        value: "gender",
        text: "Könstillhörighet vs ålder",
    });
    result.METADATA.push({
        value: "birthYear",
        text: "Ålder vs könstillhörighet",
    });

    for (const [questionId, question] of Object.entries(form.questions)) {
        if (
            (question.type !== "article_text" &&
                question.type !== "single_person" &&
                question.type !== "multiple_person") ||
            question.showInStatistics
        ) {
            const key = question.tags?.[0] || "-";
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push({
                value: questionId,
                text: getQuestionStatement(question, locale),
            });
        }
    }

    // create a Map that PickerPopup can digest
    const questionMap = new Map();
    for (const [tag, questions] of Object.entries(result)) {
        const questionsForTag = {};
        for (const question of questions) {
            questionsForTag[question.value] = question.text;
        }
        questionMap.set(
            { text: language[locale].QUESTION_TAGS[tag] || tag, id: tag },
            questionsForTag,
        );
    }

    return questionMap;
};
