import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Types from "~/src/utils/types";
import { ArrowRightIcon } from "../../../../../utils/icons";
import Modal from "../../../../tools/modal/modal";
import TabAddUser from "./tab/tabAddUser";
import TabEditUnit from "./tab/tabEditUnit";
import TabGiveAccess from "./tab/tabGiveAccess";
import "./editUnit.css";

const EditUnit = ({ show, setShow, unit, role }) => {
    const users = useSelector((state) => {
        return state.user.users;
    });
    const [userList, setUserList] = useState([]);
    const [unitObj, setUnitObj] = useState();
    const [tab, setTab] = useState();

    useEffect(() => {
        setUnitObj({
            _id: unit._id,
            administrators: unit.administrators,
            users: unit.users,
            name: unit.name,
            tags: unit.tags,
            parentUnit: unit.parentUnit,
            childUnits: unit.childUnits,
        });
    }, []);

    useEffect(() => {
        const filteredList = users.filter((user) => {
            if (user.unit._id === unit._id) {
                return user;
            }
            return null;
        });
        setUserList(filteredList);
    }, [users]);

    const renderTab = () => {
        if (!unitObj) {
            return null;
        }
        switch (tab) {
            case "Access":
                return (
                    <TabGiveAccess
                        setUnitObj={setUnitObj}
                        unitObj={unitObj}
                        userList={userList}
                        setTab={setTab}
                    />
                );
            case "user":
                return <TabAddUser setTab={setTab} unitObj={unitObj} />;
            default:
                return (
                    <TabEditUnit
                        setUnitObj={setUnitObj}
                        unitObj={unitObj}
                        show={show}
                        setShow={setShow}
                        userList={userList}
                        setTab={setTab}
                        role={role}
                    />
                );
        }
    };

    return (
        <Modal show={show} setShow={setShow} type="desktop" shouldRoute={false}>
            <Modal.ModalHeader>
                <div className="editUnit-breadcrumb-wrapper">
                    <button
                        className="editunit-button-home"
                        type="button"
                        aria-label="edit unit"
                        onClick={() => {
                            setTab();
                        }}
                    >
                        <h3>{unit.name}</h3>
                    </button>
                    {tab !== undefined && (
                        <button
                            className="editUnit-button-access"
                            type="button"
                            onClick={() => {
                                setTab();
                            }}
                        >
                            <ArrowRightIcon />
                            &nbsp;
                            <h3>{tab}</h3>
                        </button>
                    )}
                </div>
            </Modal.ModalHeader>
            {renderTab()}
        </Modal>
    );
};

EditUnit.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    unit: PropTypes.shape(Types.unit).isRequired,
};

export default EditUnit;
