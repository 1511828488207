import type { Invitation } from "@ommej/types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CaseCopy from "~/src/components/case/modals/components/caseCopy";
import BigButton from "~/src/components/tools/buttons/bigButton/bigButton";
import SecondaryButton from "~/src/components/tools/buttons/secondaryButton/secondaryButton";
import FluidWrapper from "~/src/components/tools/fluidWrapper/fluidWrapper";
import Textfield from "~/src/components/tools/form/textfield/textfield";
import Modal from "~/src/components/tools/modal/modal";
import Tooltip from "~/src/components/tools/tooltip/tooltip";
import SuccessIcon from "~/src/media/images/svg/success-arrow.svg";
import { getAllInvitations } from "~/src/redux/actions/invitation/actions";
import type { LanguageState } from "~/src/types";
import { reqWithPayload } from "~/src/utils/api";
import { INVITE_CRUMBS, route, supportedCountries } from "~/src/utils/constants";
import { QuestionMarkIcon } from "~/src/utils/icons";
import language from "~/src/utils/language";
import { CASES_ROUTES } from "../../../caseList/caseList";
import "./sendInvite.css";

type SendInviteProps = {
    isGroup: boolean;
    fullInvitation: Invitation.InvitationWithId | undefined;
    handleCrumbs: (crumb: string) => void;
};

const SendInvite = ({ isGroup, fullInvitation, handleCrumbs }: SendInviteProps) => {
    const { locales } = useSelector((state: LanguageState) => {
        return state.language;
    });
    const { SEND_INVITE } = language[locales].CASE.ADD_INVITATION;
    const { COMMON } = language[locales];
    const [textValue, setTextValue] = useState("");
    const [inputError, setInputError] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (value: string) => {
        setInputError("");
        setTextValue(value);
        if (
            value.match(supportedCountries.se.validator.sms) ||
            value.match(supportedCountries.se.validator.email)
        ) {
            setShowSuccess(true);
        } else {
            setShowSuccess(false);
        }
    };

    const sendInvite = async () => {
        if (!showSuccess) {
            setInputError(SEND_INVITE.INPUT_ERROR_SINGLE);
            return;
        }
        if (fullInvitation) {
            let sendObj: string;
            if (textValue.includes("@")) {
                sendObj = textValue;
            } else {
                const formatSms = textValue.substring(1).replace(/-|\s/g, "");
                sendObj = `${supportedCountries.se.dialCode}${formatSms}`;
            }
            const req = await reqWithPayload(
                `invitations/${fullInvitation.id}/clients/pending`,
                "put",
                {
                    username: sendObj,
                },
            );
            if (req.ok) {
                handleCrumbs(INVITE_CRUMBS.SUCCESS);
                dispatch(getAllInvitations());
            }
        }
    };

    if (!fullInvitation) {
        return (
            <FluidWrapper className="sendInvite-error-wrapper hg-small">
                <p>{SEND_INVITE.ERROR}</p>
            </FluidWrapper>
        );
    }
    return (
        <>
            <Modal.ModalBody>
                {isGroup ? (
                    <div className="sendInvite-main-wrapper">
                        <CaseCopy invitation={fullInvitation} />
                    </div>
                ) : (
                    <div className="sendInvite-main-wrapper">
                        <FluidWrapper className="hg-small">
                            <div className="sendInvite-tooltip-wrapper">
                                <h3>{SEND_INVITE.HEADING_SINGLE}</h3>
                                <Tooltip title={SEND_INVITE.TOOLTIP_SINGLE} position="bottom">
                                    <QuestionMarkIcon />
                                </Tooltip>
                            </div>
                            <div className="sendInvite-textfield-wrapper">
                                <Textfield
                                    text={SEND_INVITE.INPUT_SINGLE}
                                    type="email"
                                    name="mobileNumber"
                                    value={textValue}
                                    handleChange={(e) => {
                                        handleChange(e.target.value);
                                    }}
                                    customStyle={{ width: "25rem" }}
                                    inputError={inputError}
                                />
                                {showSuccess && (
                                    <img
                                        src={SuccessIcon}
                                        alt="success"
                                        className="sendinvite-img"
                                    />
                                )}
                            </div>
                        </FluidWrapper>
                    </div>
                )}
            </Modal.ModalBody>
            <Modal.ModalFooter>
                <div className="sendinvite-buttons">
                    {!isGroup && (
                        <SecondaryButton
                            text={COMMON.CANCEL}
                            handleButton={() => {
                                navigate(`${route.CASES}/${CASES_ROUTES.ALL_CASES}`);
                            }}
                        />
                    )}
                    {isGroup ? (
                        <BigButton
                            text={COMMON.CLOSE}
                            handleButton={() => {
                                navigate(`${route.CASES}/${CASES_ROUTES.ALL_CASES}`);
                            }}
                        />
                    ) : (
                        <BigButton
                            text={COMMON.SEND}
                            handleButton={() => {
                                sendInvite();
                            }}
                        />
                    )}
                </div>
            </Modal.ModalFooter>
        </>
    );
};

export default SendInvite;
