import type { UserWithId } from "~/src/types";

import {
    ADD_USER,
    BUILD_USER_OBJECT,
    DELETE_USER,
    GET_ALL_USERS,
    LOADING_USERS,
    UPDATE_USER,
    UPDATE_USER_PASSWORD,
    USER_ERROR,
} from "../actions/users/type";

type UserState = {
    users: UserWithId[];
    userObj: { [key: UserWithId["_id"]]: UserWithId };
    loading: boolean;
    loaded: boolean;
    error: null | string;
};

const initialState = {
    users: [],
    userObj: {},
    loading: false,
    loaded: false,
    error: null,
};

/* Here we basically vouch for the stuff we put in user state.
 * It should be validated when collected from the outside world,
 * e.g. backend.
 */
const userReducer = (currentState, action): UserState => {
    const state = currentState || initialState;
    switch (action.type) {
        case LOADING_USERS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ALL_USERS:
            return {
                ...state,
                users: action.payload,
                loading: true,
                error: null,
            };
        case BUILD_USER_OBJECT:
            return {
                ...state,
                userObj: state.users.reduce((acc, inv) => {
                    // biome-ignore lint/performance/noAccumulatingSpread: allow for now
                    return { ...acc, [inv._id]: inv };
                }, {}),
                loading: false,
                loaded: true,
                error: null,
            };
        case ADD_USER:
            return {
                ...state,
                users: [...state.users, action.payload],
                loading: false,
                error: null,
            };
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter((user) => {
                    return user._id !== action.payload._id;
                }),
                loading: false,
                error: null,
            };
        case UPDATE_USER:
            return {
                ...state,
                users: state.users.map((user) => {
                    return user._id === action.payload._id ? action.payload : user;
                }),
                loading: false,
                error: null,
            };
        case UPDATE_USER_PASSWORD:
            // Nothing to do
            return state;
        case USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;
